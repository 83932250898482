import firebase from "firebase/app";
import "firebase/storage";

class StorageService {
	PICTURES_BASE_PATH = "users/profilePics";
	RESUMES_BASE_PATH = "users/profileResumes";
	FILES_BASE_PATH = "users/files";
	TAGS_BASE_PATH = "tags.json";
	CONTACT_US_PATH = "contactUs";

	getTagsURL = async () => {
		const downloadURL = await firebase.storage().ref(this.TAGS_BASE_PATH).getDownloadURL();
		return downloadURL;
	};

	deleteImage(uid, imageName) {
		return firebase
			.storage()
			.ref(this.PICTURES_BASE_PATH)
			.child(uid)
			.child("pics")
			.child(imageName)
			.delete();
	}

	async saveImage(uid, imageName, file) {
		const storageImageSnap = await firebase
			.storage()
			.ref(this.PICTURES_BASE_PATH)
			.child(uid)
			.child("pics")
			.child(imageName)
			.putString(file, "data_url");
		return {
			url: await firebase.storage().ref(storageImageSnap.metadata.fullPath).getDownloadURL(),
			size: storageImageSnap.metadata.size / 1048576, // convert file size from bytes to megaByte
		};
	}

	async saveResume(uid, fileName, file) {
		const storageFileSnap = await firebase
			.storage()
			.ref(this.RESUMES_BASE_PATH)
			.child(uid)
			.child("resumes")
			.child(fileName)
			.putString(file, "data_url");
		return {
			url: await firebase.storage().ref(storageFileSnap.metadata.fullPath).getDownloadURL(),
			size: storageFileSnap.metadata.size / 1048576, // convert file size from bytes to megaByte
		};
	}

	async saveFile(uid, fileName, file, fieldName) {
		const storageFileSnap = await firebase
			.storage()
			.ref(this.FILES_BASE_PATH)
			.child(uid)
			.child(fieldName)
			.child(fileName)
			.putString(file, "data_url");
		return {
			url: await firebase.storage().ref(storageFileSnap.metadata.fullPath).getDownloadURL(),
			size: storageFileSnap.metadata.size / 1048576, // convert file size from bytes to megaByte
		};
	}

	deleteFile(uid, name, fieldName) {
		return firebase
			.storage()
			.ref(this.RESUMES_BASE_PATH)
			.child(uid)
			.child(fieldName)
			.child(name)
			.delete();
	}

	deleteResume(uid, name) {
		return firebase
			.storage()
			.ref(this.RESUMES_BASE_PATH)
			.child(uid)
			.child("resumes")
			.child(name)
			.delete();
	}

	async saveContactUsImage(imageName, file) {
		const storageImageSnap = await firebase
			.storage()
			.ref(this.CONTACT_US_PATH)
			.child(imageName)
			.putString(file, "data_url");

		return await firebase.storage().ref(storageImageSnap.metadata.fullPath).getDownloadURL();
	}
}

export default new StorageService();
