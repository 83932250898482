import "assets/scss/landing/landingHeader.scss";
import logoLanding from "assets/images/svgs/landing/logoLanding.svg";
import appleLanding from "assets/images/svgs/landing/appleLanding.svg";
import googlePlayLanding from "assets/images/svgs/landing/googlePlayLanding.svg";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import logoSixDays from "assets/images/svgs/landing/logoSixDays.svg";
import UsersTypes from "consts/UsersTypes";
import functionsService from "services/functionsService";
import useUser from "hooks/useUser";

export default function LandingHeader({ isMobile = false, isFreelancers = false }) {
	const dispatch = useDispatch();
	const { userProfile } = useUser();

	const scrollToTop = () => {
		return window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const openLoginPopup = () => {
		// if (!!isFreelancers)
		return dispatch(openDialog({ dialog: dialogsTypes.Login, registerType: "talent" }));
		// return dispatch(openDialog({ dialog: dialogsTypes.Login }));
	};
	const setUserTypeHandler = async () => {
		try {
			const result = await functionsService.changeUserToTalent(userProfile.uid);
			return result;
		} catch (e) {
			console.log(e);
		}
	};

	const handleTalentRegister = async () => {
		if (!!userProfile?.uid && userProfile?.type !== "talent") {
			const result = await setUserTypeHandler(UsersTypes.Talent);
			if (result?.data?.success == false) return;
		}
		openLoginPopup();
	};

	return (
		<Box className="landingHeaderWrapper">
			<Box className="landingHeaderFixed">
				{/* <Box className="headerBackground" /> */}
				<Box className={`headerContent ${!isFreelancers ? "usersView" : ""}`}>
					<Box className="logo">
						<img onClick={scrollToTop} src={logoSixDays} alt="logo" />
					</Box>
					<Box className="actionBtns">
						{/* <img src={appleLanding} alt="apple" />
						<img src={googlePlayLanding} alt="logo" /> */}
						<Box className="noSelection joinUs">
							{!!isFreelancers ? (
								<Button onClick={openLoginPopup}>
									{isMobile
										? i18next.t("landingPage.joinNow")
										: i18next.t("landingPage.joinNowOutFreelancersTeam")}
								</Button>
							) : (
								<Box className="wrapUserBtn">
									<Button onClick={handleTalentRegister}>{"כניסת ספקים"}</Button>
									{/* <span>{i18next.t("landingPage.joinNowOutFreelancersTeam")}</span> */}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
