export default () => {
	return (
		<div className="wrapxakanon">
			<div data-inline-inject="true" rule="textbox">
				<p>
					<span>כללי</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								מסמך זה מהווה הסכם בינך לבין ששת ימים תעבוד בע"מ - ח.פ 516105640 (להלן:"מפעילת
								האתר").
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								כל משתמש המבצע פעולה באתר, מצהיר כי הינו מודע לתקנון האתר ומקבלו, וכי לא תהא לו ו/או
								למי מטעמו כל טענה ו/או תביעה כנגד בעלי האתר ו/או מפעיליו ו/או מי מטעמם מלבד טענות
								הקשורות בהפרת התחייבויות בעלי האתר ו/או מפעיליו על פי תקנון זה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בעת אישור התקנון הינך מצהיר בזאת כי קראת את התקנון בשלמותו והינך מתחייב לעמוד בכל
								תנאי האתר.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הוראת תקנון זה יגברו על כל פרסום או מודעה במידה ויהיו כאלה באתר. במידה ואינך מסכים
								לסעיף כלשהו הינך מתבקש לא להירשם לאתר או להשתמש בשירותיו.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו עשויים לשנות ו/או לערוך ו/או לעדכן את תקנון האתר מעת לעת. אתה מוזמן לבדוק את
								העמוד מדי פעם כדי לוודא שאתה מכיר את כל השינויים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>כל האמור בתקנון מנוסח מטעמי נוחות בלשון זכר, אך מכוון לגברים ונשים כאחד.</span>
						</p>
					</li>
					<li>
						<p>
							<span>"האתר" - </span>
							<span>
								"ששת ימים" או בכתובת המתחם:
								<a href="https://www.sheshet.co.il">www.sheshet.co.il</a>
							</span>
							<span> , לרבות עמודי משנה ועמודי המפרסמים באתר.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								"גולש" ו/או "משתמש"- כל אדם, חברה או גוף, אשר עושה שימוש כלשהו באתר ו/או במידע ו/או
								הנתונים הקיימים באתר ו/או בשירותים המוצעים באתר ו/או בחלק מהם, לרבות צפיה פאסיבית,
								וכן העברה, הדפסה ו/או שמירת נתונים ו/או קבצים ו/או קישורים, ולרבות מי שנרשם לאתר
								ו/או פתח חשבון באתר ו/או פתח עמוד באתר או ביצע רכישה באמצעות האתר ו/או מי מהספקים
								המוצגים באתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>"ספק"- </span>
							<span>נותן שירות ו/או ספק אחר</span>
							<span>אשר הינו&nbsp; צד ג' אשר מפרסם ומציע את שירותיו ו/או מוצריו באמצעות האתר.</span>
						</p>
					</li>
				</ol>
				<p>
					<span>אודות</span>
				</p>
				<ol>
					<li>
						<p>
							<span>מפעילת האתר מספקת </span>
							<span>פלטפורמת עזר להתקשורת בין נותני שירות וספקים ובין לקוחות. </span>
							<span>כך, האתר מהווה פלטפורמה אינטרנטית </span>
							<span>לחשיפת נותני שירות וספקים שונים בפני משתמשי האתר, ומאפשר להם</span>
							<span> להציג את מרכולתם בפני המשתמשים.&nbsp;</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מטרת האתר הינה להציג בפני משתמשי האתר נותני שירות ו/או ספקים רלוונטיים לצרכי המשתמש
								כפי שהוגדרו, וכן לחבר, להפנות את הגולשים לאותם&nbsp; נותני שירות ו/או ספקים, וכן
								לחשוף את הגולשים למידע עליהם, לפרטי התקשרות, וכן לאתרי אינטרנט מטעמם של אותם ספקים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								למען הסר ספק, יובהר כי האתר ו/או מפעילת האתר אינה מספקת אי אילו מהשירותים ו/או
								המוצרים המוצעים על ידי הספקים השונים המפורסמים באתר.
							</span>
						</p>
					</li>
				</ol>
				<h2>
					<span>אחריות</span>
				</h2>
				<ol>
					<li>
						<p>
							<span>
								מפעילת האתר אינה אחראית לנזק שיגרם למשתמשים ו/או ספקים באתר בעקבות שימוש בפלטפורמה
								ו/או
							</span>
							<span>בשירותי העזר אותם מספק האתר.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר אינה מביעה דעה ואינה תומכת בשום מידע, תוכן, שירות, מוצר או הצעה שהגישה
								אליהם ניתנת באמצעות האתר או בכל אמצעי אחר. אין לראות במפעילת האתר כמי שמעודדת או
								ממליצה להיענות להצעות או שירותים שניתנים דרך האתר. מפעילת האתר אינה בודקת את פרטי
								הספקים ו/או נותני השירות ו/או בעלי העסקים המציעים את מרכולתם ו/או את שירותיהם באתר,
								תוכן, אמיתות וכדאיות ההצעות והמידע שמוחלף באתר ו/או מועלה אליו.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר אינה מספקת את המידע והתוכן המופיעים באתר הנוגעים לצדדים שלישיים. יודגש,
								המידע והתוכן שהגישה מתאפשרת אליהם דרך האתר הוא מידע המועלה על ידי צדדים שלישיים ולא
								על ידי מפעילת האתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר אינה אחראית לפעולות או מחדלים של משתמשים ו/או ספקים העושים שימוש באתר.
								מפעילת האתר לא תישא על פי כל דין באחריות לכל פגיעה ו/או נזק שייגרם למשתמש ו/או ספק
								באתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								שימוש באתר מהווה את הסכמתך לפטור את מפעילת האתר מכל אחריות בשל פעולות צד שלישי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בכפיפות להסתייגות של הוראות כל דין המורות אחרת, אין מפעילת האתר מביעה כל עמדה ואין
								היא אחראית, במפורש או מכללא לגבי:
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								התוכן, המידע והחומר המצוי באתר, לרבות בנוגע למידע ולהצעות אותם מעלים צדדים שלישיים
								שונים;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								לגבי התוכן, החומר, המידע והפעולות אשר הגישה אליהם מתאפשרת באמצעות הגישה לאתר;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								לגבי כל מוצר, תוכנה או שירותים, שהגישה אליהם התבצעה באמצאות קישור אלקטרוני מן האתר;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								לגבי כל נזק אשר נגרם או עלול להיגרם עקב המשלוח של מידע אישי, חסוי או רגיש דרך האתר
								ו/או בכל אמצעי אחר;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								לגבי כל נזק אשר נגרם או עלול להיגרם עקב פגם או תקלה בתוכנה המפעילה את האתר או
								שמאפשרת גישה לאתר.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>השימוש באתר</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								השימוש בחלק מהשירותים המוצעים באתר עשוי להיות כרוך ברישום, ועל המשתמש ו/או הספק
								לפעול בהתאם להוראות הרישום, ובכלל זה למסור פרטים נכונים ומדויקים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								המשתמש ו/או הספק מאשר ומצהיר בזאת כי השימוש באתר הוא על אחריותו וסיכונו הבלעדי וכי
								הוא פוטר בזאת את מנהלת האתר ו/או מי מטעמה מאחריות להפרעות ו/או תקלות בשימוש
								באתר.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מטבע הדברים חלק מרכזי מהמידע שעולה לאתר עולה ישירות ע"י הספקים והמשתמש פוטר את האתר
								מכל&nbsp; אחריות למהימנות הפרסומים והתכנים באתר אשר מפורסמים באתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר אינה אחראית לזמינותו התמידית של האתר, כמו כן, לא לתקלות טכניות שיהיו בו
								או למידע שיישמר באתר, וכן, לשום נזק שיכול להיגרם מהפסקת הפעלת האתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								כל השירותים של הספקים באתר הינם שירותים ומוצרים אשר מוצעים על ידי אותם ספקים בלבד,
								ולא על ידי מנהלת האתר, אשר אינה מציע שירותים נוספים זולת עצם חשיפת הספק באתר, ובהתאם
								אין לראות בה אחראית באופן כלשהו בגינם, במישרין או בעקיפין.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>מנהלת האתר ו/או האתר אינם צד לעסקה שבין הספקים לבין הגולשים.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בהתאם, אין לראות במנהלת האתר כמי שמספקת שירות ו/או מוצר כלשהו מטעם מי מהספקים
								המפורסמים ו/או הפועלים באתר ו/או וכמי שחב באחריות כלשהי בגין שירותים ו/או מוצרים אשר
								סופקו ו/או לא סופקו על ידי ספקים, בין אם באופן ישיר באמצעות האתר ובין אם ברחבי רשת
								האינטרנט.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הגולש מסכים בזאת כי מפעילת האתר לא תישא באחריות לכל הנוגע לטיב ו/או נכונות הנתונים
								ו/או המידע ו/או התכנים, לרבות בנוגע למועד אספקת המוצר ו/או השירות, תקינות המוצר או
								טיב השירות של המוצרים ו/או השירותים המוצגים באתר ולמפעילת האתר אין כל שליטה לגביהם
								ו/או שליטה ו/או ידיעה בדבר אופן מתן השירות ו/או אספקת המוצר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								פרסום המוצרים ו/או השירותים המוצגים באתר אינו מהווה המלצה מצד מפעילת האתר והאחריות
								חלה על הספקים בלבד.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								למען הסר ספק, לאחר קבלת המידע הראשוני על ספק באתר, באחריות הגולש לבדוק ולאמת את
								המידע ואין לראות במידע ו/או בשירותים ו/או במוצאים המוצגים באתר משום "הצעה" כהגדרתה
								בחוק החוזים (חלק כללי), התשל"ג -1972. של מפעילת האתר ו/או מי מטעמה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הובהר בזאת כי כל התכנים המפורסמים באתר הינם "תוכן גולשים" מטעם הספקים, שהינם צדדים
								שלישיים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בהתאם, מפעילת האתר לא תישא באחריות כלשהי למידע או לתכנים שמקורן בספקים ו/או מי
								מטעמם, לרבות לעניין זכויות קניין רוחני באשר הן, פגיעה בפרטיות ו/או לשון הרע.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר לא תישא באחריות לפעילות בלתי חוקית שהתבצעה ע"י ספק או ע"י מי ממשתמשי
								האתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מנהלת האתר לא תישא באחריות לכל אתר חיצוני המקושר אליה באמצעות קישורית ("לינק").
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר אינה אחראית לכל נזק שעשוי להיגרם מהיעדר זמינותו התמידית של האתר, כמו כן,
								לא לתקלות טכניות שיהיו בו או למידע שיישמר באתר, וכן, לנזק שיכול להיגרם מהפסקת הפעלת
								האתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר ממליצה לבחון את מחירי השוק של המוצרים ו/או שירותים אותם הם מעוניינים
								לרכוש, טרם ביצוע הרכישה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בהירשמך לשירות הינך מסכים לכך שמפעילת האתר רשאית, על פי שיקול דעתה, לאפשר לספקים,
								וכן למשתמשים, לקבל, לשלוח, להפיץ מודעות שאינן מודעות אישיות (לרבות פרסומות), על פי
								פילוח אשר מותאם לאותם ספקים נותני שירות ו/או למשתמשי האתר. כך למשל, למשתמש ישנה
								האופציה לשלוח פנייה מרוכזת למספר ספקים במקביל.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בנוסף, בהירשמך לשירות הנך מסכים שמפעילת האתר תמליץ ו/או תשתף את כרטיס הספק שלך או את
								כרטיס המשתמש שלך (הכל בהתאם לעניין) עם משתמשים ו/או ספקים אחרים הרשומים לאתר, כל זאת
								על פי שיקול דעתה הבלעדי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר רשאית למסור לצדדים שלישיים פרטי התקשרות של ספק, בכל מקרה בו היא תמצא, על
								פי שיקול דעתה הבלעדי, כי פעולות ספק באתר מפרות או עשויות להפר את תנאי השימוש.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								חל איסור להעתיק כל מידע, טקסט, תמונות, גרפיקה, קטעי וידיאו, סאונד, ספריות, קבצים,
								וכל מידע הזמין באתר, וכלול בתוכן האתר, בין באמצעות תוכנות, רובוטים, תבליכים
								אוטומטיים, ובין באמצעות תהליכים ידניים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>חל איסור לשתול קודים ו/או וירוסים ו/או תוכנות באתר מכל סיבה שהיא.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								האתר שומר לעצמו את הזכות לשנות את מבנה האתר, תוכן האתר וכיו"ב. כמו כן, תוכל מפעילת
								האתר לשנות כל דבר שקשור בקוד האתר או בהצגתו למשתמש. אין האתר אחראי לטעויות בהצגת
								הנתונים במידה ויווצרו בעקבות כך.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>עמוד ספק</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								&nbsp;כל ספק רלוונטי רשאי לפתוח עמוד מטעמו באתר ולהציע את שירותיו, בכפוף לתנאי תקנון
								זה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הספקים מתחייבים לקבל על עצמם תנאי שימוש לכל מוצר ו/או שירות אשר יסופקו באמצעות האתר
								&ndash; לתנאי השימוש אשר ייקבעו מעת לעת על ידי מנהלת האתר, לרבות, אך לא רק:&nbsp;
								מדיניות החזרת מוצרים, תשלומים וזיכויים וכיו"ב &ndash; אשר בכל מקרה לא יגרעו מזכויות
								המשתמשים על פי חוק הגנת הצרכן, התשמ"א-1981 ותקנותיו.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הקמת עמוד ספק באתר תתבצע באמצעות טופס ייעודי אשר כולל את כל הפרטים אשר יימצאו
								כרלוונטיים על ידי מנהלת האתר, על פי שיקול דעתה הבלעדי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								ספק מתחייב לשמור על פרטי המשתמשים שיתקשרו עימו במסגרת האתר וזאת בכפוף לחוק הגנת
								הפרטיות, תשמ"א-1981 ובהתאם, מתחייב שלא לעשות בהם שימוש לרעה ולא להעבירם לצד שלישי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>פרסום מוצר ו/או שירות ע"י הספק באתר מחייב את הספק באספקתו לקונה בפועל.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הספק יפעל לתיאור מדויק של השירות ו/או המוצר, בהתאם לעניין, באופן אשר אינו מותיר פתח
								לספק ו/או הטעיה של המשתמש. תיאור המוצר ו/או השירות יכללו תיאור מילולי, תצלומים
								וקישורים בהתאם לכללי האתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הספק הינו האחראי הבלעדי לכל פרסום, פעילות, טקסט, מידע, גרפיקה, תמונות, קול, וידאו,
								קישורים לינקים או כל מידע אחר אותו הוא מציג באתר. הצגת המידע הנ"ל, כאמור, תוצג
								במקומות המיועדים לכך בלבד.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מנהלת האתר שומרת על זכותה לקבוע ולשנות מעת לעת את קטגוריות ו/או קוד החיפוש של
								המוצרים והשירותים, על פי שיקול דעתה הבלעדי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר משאירה לעצמה את הזכות לאשר או לא לאשר עמודים ו/או ספקים מסוימים מסיבות
								השמורות עמה, על פי שיקול דעתה הבלעדי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								כמו כן, מפעילת האתר משאירה לעצמה את הזכות לפסול לפי שיקול דעתה טקסט ו/או חלק מטקסט
								שהוזן ע"י ספקים ו/או משתמשים באתר, וכן, תמונות של משתמשים ו/או ספקים ו/או מטעם
								ספקים, מכל סיבה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מובהר בזאת שעל הספק להעלות לאתר אך ורק תכנים, טקסטים תמונות וכל יצירה אחרת העולה
								לכדי "יצירה" על פי חוק זכויות יוצרים, התשס"ח &ndash; 2007, אשר הינם מקוריים ו/או
								הזכויות בו הינם בבעלות הספק ו/או הספק הינו בעל רישיון להעלות את היצירות לאתר והספק
								ישפה באופן מיידי את מפעילת האתר, עם דרישתה הראשונה, בגין כל נזק אשר ייגרם לה כתוצאה
								מהפרת זכויות יוצרים ו/או קניין רוחני כלשהו על ידי הספק.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר שומרת לעצמה את הזכות לעשות התאמות טכניות במידה ויהיה צורך בכך, על מנת
								להתאים את התכנים והתמונות לאתר ולספק לא תהא כל טענה ו/או תביעה בקשר לכך.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								פתיחת עמוד ספק באתר מותרים מגיל 18 ומעלה, בהרשמך לאתר הנך מצהיר שהנך מעל גיל 18.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר רשאית לבחור את סדר הופעת ספקים בתוצאות החיפוש ו/או לבחור להציג משתמשים
								מסוימים בעמוד הבית בהתאם לשיקול דעתה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בהתאם, מפעילת האתר רשאית לבצע ו/או להציע למשתמש לבצע סינונים כלשהם בעמוד תוצאות
								החיפוש, כדוגמת הכי קרוב או הכי מומלץ וכיו"ב, אולם, אין היא מתחייבת כי סדר ההופעה
								הינו אכן בהתאם לכותרת הסינון המוצגת.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר רשאית לא לאשר/ לחסום עמודי ספקים מכל סיבה שבעיניה אינם מתאימים לרוחו או
								לתדמיתו של האתר. מפעילת האתר לא תתן הסבר למי שכרטיסם או תמונתם נדחתה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								חל איסור מוחלט על משלוח דברי פרסום לגולשי האתר שלא ברשות מפעילת האתר באופן
								מפורש.&nbsp;&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר אינה אחראית לבדיקת אמינות כל פרסום ו/או אינפורמציה המתפרסמים באתר, ולא
								תהיה אחראית לכל נזק שייגרם במישרין או בעקיפין למשתמשי האתר בעקבות הסתמכות על המידע
								אשר פורסם על ידי הספק.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								חל איסור להעלות חומר בוטה, פורנוגראפי או עם מאפיינים מיניים בוטים שפוגעים או עלולים
								לפגוע ברגשות הציבור.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אסורה כל פעילות המפרה פרטיותו של כל אדם, והכל כמפורט בהרחבה במדיניות הפרטיות של אתר
								זה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אסורה כל פעילות המהווה לשון הרע של כל אדם, השמצה או הטרדה של כל אדם לרבות אך לא רק,
								פעילות הפוגעת או העלולה לפגוע, באופן ישיר או עקיף, במוניטין החברה או המשמיצה את
								החברה וכדומה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר שומרת לעצמה את הזכות לשווק את האתר בכל אמצעי אלקטרוני שתחפוץ בו. הגרסה
								הסלולרית מותאמת למכשירים תומכים בלבד, ואינה מתאימה לכל סוגי המכשירים הקיימים בשוק.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>קניין רוחני וזכויות יוצרים</span>
				</p>
				<ol>
					<li>
						<p>
							<span>האתר, לרבות הקוד, הממשקים והתכנים באתר הינם רכושה הבלעדי של מפעילת האתר.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר הינה בעלת זכויות היוצרים, סימני המסחר וכל זכויות הקניין הרוחני באתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								כל הפרה של זכויות הקניין הרוחני של מפעילת האתר,&nbsp; במישרין ו/או בעקיפין, ללא קבלת
								היתר מפורש בכתב ממפעילת האתר, אסורה בהחלט ותגרור נקיטת הליכים משפטיים באופן מיידי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הספק ו/או המשתמש, בהתאם לעניין, מעניק בזאת למפעילת האתר את זכויות הקניין הרוחני בכל
								תוכן ו/או פרסום אשר יועלה על ידו לאתר, אלא אם יוסכם אחרת בין הצדדים בכתב, וזאת ללא
								כל תמורה, לרבות הזכות לערוך, לשנות לעדכן ולמחוק חלקים מהתכנים ו/או היצירות והפצתם
								וכן מעניק למפעילת האתר רישיון שימוש בלתי חוזר בכל הנוגע לזכויות רוחניות ביצירה ופוטר
								את מפעילת האתר מכל חובה הכרוכה בשמירה על זכויות רוחניות על פי דין.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								חל איסור חמור על פרסום תכנים המוגנים בזכויות יוצרים ו/או זכויות קניין רוחני אחרות
								ללא קבלת היתר מפורש בכתב מבעל הזכויות. מפעילת האתר לא תישא באחריות לכל נזק הנוגע
								להפרת זכויות יוצרים ו/או זכויות קניין רוחני אחרות שהתבצעו על ידי ספר או משתמש
								באתר.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								היה ותובא לידיעת מפעילת האתר, מידע בדבר הפרה של זכויות יוצרים ו/או זכויות קניין
								רוחני, כאמור, מפעילת האתר תפעל להסרתם במהירות האפשרית מרגע מתן ההודעה, ולספק/למשתמש
								לא תהיה כל טענה על כך.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>נגישות / הצהרת נגישות</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								בהתאם להוראות חוק שוויון זכויות לאנשים עם מוגבלות, בוצעו וממשיכות להתבצע באתר התאמות
								נגישות עבור אנשים עם מוגבלות, ההתאמות בוצעו בגלישה באמצעות המחשב או בגלישה באמצעות
								האתר המותאם בסלולר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								הנגשת האתר נועדה להתאימו לשימוש, כך שיהיה זמין ונוח עבור אוכלוסיות בעלות צרכים
								מיוחדים. התאמות אלו נעשות בהתאם לתקנות החוק, כמו כן, בדיקת נגישות בוצעה בדפדפן כרום
								בגרסא עדכנית.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								על כל פנים, כל שירות שאינו מתאפשר ויכול להתבצע באמצעות מוקד שירות הלקוחות, אנחנו כאן
								בשבילכם ונשמח לעזור באמצעות פנייה אלינו. עם כל תלונה על העדר התאמות נגישות או בקשה
								אחרת יש לפנות למוקדי השירות שלנו בטלפון או בכתובת הדואר האלקטרוני
								info+negishut@sheshet.co.il .
							</span>
						</p>
					</li>
				</ol>
				<h2>
					<span>שיפוי</span>
				</h2>
				<ol>
					<li>
						<p>
							<span>
								אתה מסכים לשפות ולפצות את ההנהלה, עובדיה, מנהליה, בעלי מניותיה או מי מטעמם בגין כל
								נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם, ובכלל זה שכר טרחת עו"ד והוצאות
								משפט, עקב הפרת תנאי שימוש אלה.
							</span>
						</p>
					</li>
				</ol>
				<h2>
					<span>סמכות שיפוט והדין</span>
				</h2>
				<ol>
					<li>
						<p>
							<span>
								תנאי שימוש אלו וכל הסכם אחר שנוצר על ידי ההנהלה הקשור בשימוש באתר, יהיו כפופים לדיני
								מדינת ישראל. סמכות השיפוט הבלעדית לדון בכל סכסוך העולה מהסכם זה תהיה נתונה לבתי
								המשפט בתל אביב.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								תניית שיפוט זו גוברת על כל הסכמה או חוזה, ככל שהייתה, אם בכתב, בעל פה או בכל דרך
								אחרת, בין הצדדים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								חוזה זה כפוף אך ורק לדין הישראלי, מבלי להשפיע על סוגיות במשפט בינלאומי פרטי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								על חוזה זה יחולו הוראות חוק החוזים (תרופות בשל הפרת חוזה), התשל"א &ndash; 1970. בלי
								לגרוע מן הכלליות או לגרוע מן הסעדים הניתנים על פי הוראות כל דין.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>מדיניות פרטיות</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								מדיניות פרטיות זו מהווה חלק בלתי נפרד מתקנון האתר ותנאי השימוש בו. השימוש בשירותי
								האתר ו/או היישומים הנלווים לו, כפוף לתנאי השימוש ומדיניות פרטיות זו ומעיד על הסכמה
								לתנאים אלה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אם אינך מסכים למדיניות השמירה על הפרטיות, הינך נדרש שלא להירשם לשירותי האתר.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>כללי</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								הפרטיות שלך חשובה לנו מאוד. על מנת להבין טוב יותר איזה מידע אנו אוספים ומהו השימוש
								שאנו עושים במידע זה, אנא קרא את מדיניות הפרטיות המפורטת להלן.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מדיניות פרטיות זו חלה על מידע הנאסף במסגרת השימוש שלך ביישומי החברה בלבד ובהתאם
								להסכמת המשתמש בתנאים שנזכרים להלן.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>בעצם השימוש באתר אתה מסכים ומאשר את מדיניות הפרטיות שלהלן.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו עשויים לשנות ו/או לערוך ו/או לעדכן את מדיניות הפרטיות מעת לעת. אתה מוזמן לבדוק
								את העמוד מדי פעם כדי לוודא שאתה מכיר את כל השינויים.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>מידע אודות המשתמש</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								מדיניות פרטיות זו מסבירה כיצד ששת ימים תעבוד בע"מ (להלן: "החברה") משתמשת, אוספת
								ושומרת את המידע שאתה מספק ומוסר בפלטפורמה הנ"ל, באתר
								<a href="https://www.sheshet.co.il">www.sheshet.co.il</a> (להלן: "האתר") והכל בכפוף
								לדיני מדינת ישראל.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>סוג הפרטים והמידע שנאספים</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								החברה רשאית לאסוף, לעבד ולשמור (לרבות בשרתים מחוץ לישראל) את המידע המפורט להלן
								ולעשות בו שימוש על פי מדיניות פרטיות זו:
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								כל מידע אשר נמסר לחברה בעת ההרשמה והשימוש באתר. המתבצעת באמצעות מילוי פרטים בטופס
								הרשמה, ובכלל זה עשויים לכלול פרטים אישיים, המוגדרים כ"מידע רגיש", על פי דין, כגון:
								שם, תצלומים, תכתובות בינך לבין משתמשים ו/או ספקים, אנשי קשר, מידע פיננסי (כגון פרטי
								כרטיס אשראי) וכיו"ב (להלן: "הפרטים האישיים"), אשר ייאספו, יעובדו וישמרו ויעשה בהם
								שימוש על ידי החברה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								פעולות שונות באתר עשויות לדרוש ממך לספק פרטים שונים, כגון: שם מלא, מספרי טלפון,
								כתובות דואר ודואר אלקטרוני, מספר כרטיס אשראי, אשר ייתכן שחלקם יאספו ויאוחסנו על ידי
								החברה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מידע אודות השימוש שלך באתר, מידע אודות פעולות ו/או עסקאות הנעשות באמצעות האתר, מקור
								ההפניה לאתר, כל מידע בנוגע לשימוש ולצפיה באתר ו/או כל מידע אחר שאתה מוסר ו/או מעלה
								ו/או מפרסם על גבי האתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מידע אישי בדבר מיקום המשתמש, לרבות שמירה של היסטוריית המיקומים של המשתמש, כתובת IP,
								מידע בדבר איכון המיקום, מידע על מסלול המשתמש (לדוגמה בצורה של אותות GPS) ומידע אחר
								אשר נשלח על-ידי המכשיר הסלולארי שבו מותקן ומופעל יישום החברה ו/או יישומים ו/או
								שירותים אחרים של החברה ו/או מי מטעמה.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>הצגת מיקום ושימוש במיקום</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								ככל והמשתמש אישר את הצגת מיקומו, החברה רשאית להציג את המיקום האחרון של המשתמש,
								כמיקום היסטורי - המקום בו נצפה המשתמש לאחרונה. לרבות על ידי הצגת מיקום המכשיר
								הסלולארי שבו מותקן ומופעל יישום החברה ו/או יישומים ו/או שירותים אחרים של החברה ו/או
								מי מטעמה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בנוסף, מפעילת האתר רשאית לעשות שימוש במידע איכון המיקום, על מנת לנהל מסעות פרסום,
								בין היתר, על בסיס מיקום גיאוגרפי.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>הצגת ושימוש במידע פרטי באתר&nbsp;</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								מפעילת האתר תציג את פרטי המשתמש והפרטים האישיים בפני משתמשי ו/או ספקי האתר (לרבות
								גולשים שאינם רשומים לאתר) בכלל הפלטפורמות השונות של החברה, העוסקות בכך. הן
								בפלטפורמות להצגת משתמשים ו/או ספקים לצרכים שהחברה משווקת ומציגה בפלטפורמה שלה, והן
								בפלטפורמות שיווק חיצוניות, של צדדים שלישיים, שהחברה עושה בהם שימוש.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר מציעה גם רכיב ייחודי המאפשר לקבל מידע על אנשי קשר משותפים בינך ובין
								המשתמשים ו/או הספקים האחרים באתר. באפשרות כל משתמש לבחור האם להסכים לכך בעת בחלונית
								הייעודית לכך באתר, וכן לשנות את בחירתו ע"י כניסה להגדרות החשבון שלו.&nbsp;
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>בנוסף, המידע הנזכר לעיל עשוי בין השאר לשמש לצורך:</span>
						</p>
						<ol>
							<li>
								<p>
									<span>שיפור הגישה שלך לאתר ולשימוש בשירותי החברה;</span>
								</p>
							</li>
							<li>
								<p>
									<span>
										התאמה אישית של האתר ושירותיו וכן שירותים נוספים של החברה ו/או מי מטעמה;
									</span>
								</p>
							</li>
							<li>
								<p>
									<span>שיפור שירות הלקוחות;</span>
								</p>
							</li>
							<li>
								<p>
									<span>ניהול טוב יותר את החברה ויישומיה;</span>
								</p>
							</li>
							<li>
								<p>
									<span>הספקת השירותים ו / או המוצרים שהחברה ו/או מי מטעמה מספקים;</span>
								</p>
							</li>
							<li>
								<p>
									<span>משלוח הדו"חות, תקשורת שיווקית, מיילים בדבר שירותים, פרסומות וכיו"ב;</span>
								</p>
							</li>
							<li>
								<p>
									<span>עיבוד העסקאות שנעשו באמצעות יישומי החברה;</span>
								</p>
							</li>
							<li>
								<p>
									<span>פרסום מידע ביישומי החברה אודותיך;</span>
								</p>
							</li>
							<li>
								<p>
									<span>
										הצגה או משלוח של חומרי שיווק ופרסום, לרבות שימוש במידע איכון על מנת להציג
										פרסומות מותאמות מבוססות נתונים ו/או מיקום גיאוגרפי.
									</span>
								</p>
							</li>
							<li>
								<p>
									<span>
										אנו עשויים לעשות שימוש במידע פיננסי שלך באתר על מנת לבצע התאמה לשימוש באתר, לבצע
										חיובים עתידים באתר.
									</span>
								</p>
							</li>
						</ol>
					</li>
				</ol>
				<p>
					<span>שימוש במידע על ידי צדדים שלישיים</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								אנו עשויים לחשוף את המידע האישי שלך לסוכנים שלנו או לקבלני המשנה שלנו או לכל צד
								שלישי אחר למטרות אלה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בנוסף, אנו עשויים לחשוף מידע אישי ופרטי אם וכאשר אנו סבורים כי שחרור כזה הוא ראוי ו
								/ או נחוץ בכדי לציית לחוק, כדי לקיים את פסיקת בית משפט ו / או צו בית משפט, כדי להגן
								על זכויותינו ועל זכויותיהם של צדדים אחרים ו / או על רכושם ועל מנת לאכוף את המדיניות
								באתר וביישומיו.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בנוסף, אנו עשויים לעשות שימוש במידע אודותיך לצורך הצגה מותאמת של תכנים במערכות
								,Google, Facebook, Apple, Outbrain, Taboula, Tiktok וכיו"ב.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו עשויים לעשות שימוש בשירותי חברת פרסום כדי להציג מודעות מטעמנו ברשת. חברה זו
								עשויה לאסוף מידע פרטי על הגלישה שלך באתר זה. צד שלישי זה עשוי להשתמש במידע על
								ביקוריך באתרי האינטרנט שלנו לצורך פרסום על מוצרים ושירותים העשויים לעניין אותך.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו עשויים לספק מידע פיננסי אודותיך לספקי השירותים שלנו ולכל צד שלישי וזאת לצורך
								תשלום על מנוי, תמיכה וייעול הפעילויות שלך באתר. צד שלישי זה עשוי להיות, חברות כרטיסי
								האשראי ומוסדות בנקאיים המטפלים ותומכים בעסקה או בפעילות האתר. אותם צדדים שלישיים
								יהיו רשאים לעשות שימוש במידע הפיננסי אודותיך, בכפוף להסדרים החוזיים שלנו עם צדדים
								שלישיים אלה, ובהתאם למדיניות לשמירה הפרטיות של אותם גופים.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								ככל ויוצגו בפניך פרסומות באתר הן עשויות להיות מוצגות באמצעות מערכת פרסום חיצונית של
								צד שלישי&nbsp; (לדוגמא שירות של חברת גוגל בשם admob)&nbsp; .
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>תקשורת עמך</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								מספר הטלפון שלך, ו/או כתובת דואר אלקטרוני שסופקה על ידך לחברה לצורך רישום ו/או עיבוד
								תקשורת ו/או הזמנות ו/או יצירת קשר ו/או עסקאות מכל סוג שהוא, תשמש למטרה של שליחת
								מידע, פרסומים, ועדכונים לשימוש שלך באתר האינטרנט, כמו גם לתקשורת עמך ו/או להזמנות
								שלך ולצרכים המפורטים במדיניות פרטיות זו, כפי שתתעדכן מעת לעת.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר ו/או מי מטעמה, באמצעותה ובאמצעות צדדים נוספים מטעמה, רשאים לפנות למשתמשי
								האתר באמצעות הדואר האלקטרוני לצרכי: פרסום, תקשורת ומשלוח הודעות שונות.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אם וכאשר אתה מעדיף להפסיק לקבל חומרי שיווק, פרסום ו/או הודעות דוא"ל עתידיות, אנא
								עקוב אחר ההוראות לביטול הרישום המפורטות בחלק התחתון של כל הודעת דוא"ל שאנו שולחים
								לך, או שלח לנו מייל לדואר info@sheshet.co.il.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>אבטחת מידע</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								אנו ננקוט באמצעי זהירות טכניים וארגוניים סבירים כדי למנוע אובדן, שימוש לרעה או פגיעה
								במידע האישי שלך.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו נוקטים באמצעים שונים כדי להבטיח את המידע האישי שהמשתמשים באתר נדרשים לספק לצורך
								גישה, כניסה, שליחה של מידע ותקשורת, או בכדי לשנות את המידע האישי שניתן על ידי
								המשתמש.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								בהתאם לאופי הפעילות באינטרנט, וכפי שקרה בעבר באתרים שונים בעולם. עלולים להיות מצבים
								שבו מידע רגיש מאתרי הכרויות דלף החוצה באמצעות שימוש לא חוקי, פריצה או מעשי זדון
								אחרים. עליך להימנע ממסירת מידע שאתה חושב שיש בו כדי לפגוע בפרטיותך.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>עוגיות (cookies)</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								עוגייה היא מחרוזת אותיות ומספרים המשמשת לאימות, למעקב ולאגירת מידע על אודות גולש
								באתר אינטרנט, כגון שמירת העדפות המשתמש.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								ההסכמה לשימוש שלנו בעוגיות בהתאם לתנאים של מדיניות זו בזמן הביקור הראשון באתר שלנו,
								מאפשרת לנו להשתמש בעוגיות בכל פעם שאתם מבקרים באתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו משתמשים בעוגיות למטרות שונות, לרבות, על מנת לפקח ולנתח נתונים על התנועה באתר,
								לזכור ולנתח פריטים המועברים על ידי המשתמש לאתר, כדי לשמור ולהתאים את העדפות המשתמש
								לשימוש עתידי באתר, חיסכון בצורך להזין מחדש את שם המשתמש והסיסמה בכל כניסה לשירות, על
								מנת להקל על השימוש באתר ו/או ביישום הסלולארי, על מנת לאסוף מידע סטטיסטי, לאמת את
								המידע, וכן לצורך אבטחת מידע וכל מטרה אחרת המפורטת במדיניות פרטיות זו.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								ניתן לחסום עוגיות דרך הגדרות הדפדפן. אפשרות זו עלולה לגרום לשירותים מסוימים שבאתר
								להציג תקלה ו/או שלא לפעול כראוי. במידת הצורך, המשתמש יכול עדיין לעשות שימוש באתר,
								במידת האפשר, על ידי פנייה ישירה לשירות הלקוחות שלנו בטלפון או באמצעות פנייה לדוא"ל
								שלנו.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>מחיקת מידע אישי</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								עם קבלת בקשת משתמש למחיקת הכרטיס באתר אנו נפעל להפסקת הצגת המידע הפרטי שלך באתר
								ואולם, מפעילת האתר שומרת על זכותה להמשיך לדוור ולפרסם ללקוח ואף לשמור את המידע האישי
								במאגרי החברה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אם ברצונך כי פרטייך יימחקו כליל מן מהמאגר, או אם אין ברצונך להמשיך ולקבל מידע מסחרי
								כאמור, וברצונך לבטל את הסכמתך ולחדול מקבלת המידע המסחרי, תוכל לעשות זאת, בכל עת
								באמצעות פניה לכתובת דוא"ל&nbsp; info@sheshet.co.il&nbsp; ואולם מחיקה מוחלטת מהמאגרים
								שלנו כפופה לסייגים הבאים:
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								מפעילת האתר תעשה כל מאמץ למחוק המידע האפשרי, אולם ייתכן שהדבר לא יוכל להתבצע באופן
								מיידי ממערכות הגיבוי.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>ייתכן ולא ניתן יהיה לבצע מחיקה של הנתונים ממערכות הגיבוי שלנו.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								כדי לאפשר לנו לבדוק, למנוע ולדווח לרשויות החוק על שימוש לרעה באתר אנו עשויים שלא
								לאפשר את המחיקה של הנתונים הבאים: שם משתמש, מועד הרשמה, מועד ביצוע פעולות, כתובות
								IP. גם במקרה של חסימת משתמש על ידי האתר, הפרטים הרלוונטיים ישמרו בבסיס הנתונים וזאת
								בין היתר על מנת למנוע רישום מחודש לאתר.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								אנו עשויים לשמור במאגר מידע שנועד לצורך יישוב מחלוקות, לאיתור וטיפול בבעיות ולאכיפת
								הכללים ותנאים שלנו.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>שירותים ומידע של צדדים שלישיים באתר</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								תיתכן אפשרות לאפשר לאתר זה גישה לפרטים אישיים ומדיה (תמונות, סרטים וכד') הנמצא
								באתרים חיצוניים (אתרי מדיה חברתית, וכד'). בנוסף, יתכנו דרכים שונות להתחבר וליצור
								משתמש (USER) ו/או להעלות ו/או ליצור קישור לפרטים אישיים באתר. במקרים מסוימים ניתן
								לבצע חיבור לאתר וזיהוי פרטי המשתמש דרך אתרים חיצוניים, כגון חיבור לחשבון Facebook או
								חשבון Google/Apple ואף יתכן שחלק מהמידע יאוחסן בשרתים חיצוניים, כגון&nbsp; שימוש ב
								Google cloud, Amazon,&nbsp; Cloudinary, Algulia וכדומה.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								יובהר, כי אין באפשרות האתר לפקח או לנטר על מידע שמועבר מאתרים חיצוניים ועל כן לא
								נהיה אחראים להפרות פרטיות הנגרמות עקב הזדהות או העברת נתונים מאתרים חיצוניים אלו.
							</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								יובהר, כי במתן ההרשאה לחיבור האתר עם האתרים החיצוניים הנך מאפשר לאתר גישה ושימוש מלא
								לפרטים ולמדיה אשר העלית לאותם אתרים.
							</span>
						</p>
					</li>
				</ol>
				<p>
					<span>תנאי שימוש</span>
				</p>
				<ol>
					<li>
						<p>
							<span>
								השימוש ביישומי החברה כפוף לתנאי השימוש בה, המפרטים ומרחיבים את תנאי השימוש, הויתורים
								וההגבלות השונות. בעצם השימוש ביישומי החברה אתה נחשב כמי שקרא והסכים לתנאי השימוש בה.
								ניתן לעיין בתנאי השימוש של החברה בכתובת:
							</span>
							<span>/terms-privacy/.</span>
						</p>
					</li>
					<li>
						<p>
							<span>
								יצירת קשר - בכל שאלה ו/או בקשה הקשורה לסוגיות פרטיות ומדיניות פרטיות זו, אנא אל
								תהססו ליצור עמנו קשר בחברת ששת ימים תעבוד בע"מ (ח.פ 516105640), בכתובת דואר
								אלקטרוני: <a href="mailto:info@sheshet.co.il">info@sheshet.co.il</a>&nbsp;
							</span>
						</p>
					</li>
				</ol>
				<p>&nbsp;</p>
			</div>
		</div>
	);
};
