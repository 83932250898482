import { createSelector } from "reselect";
import { orderBy, isNil } from "lodash";

import { getPublicProfiles } from "../publicProfiles/selectors";
import { getBlocksUIDs } from "../blocks/selectors";
import { getChatMeta } from "../chatMeta/selectors";

export const getChatReceivedFiltered = (state) => state.chatReceivedFiltered;

export const getChatReceivedFilteredSortByDate = createSelector(
	getChatReceivedFiltered,
	getPublicProfiles,
	getBlocksUIDs,
	(chatReceivedFiltered, publicProfiles, blockedUsers) => {
		const chatReceiveArray = Object.entries(chatReceivedFiltered).map(([key, value]) => ({
			...value,
			uid: key,
		}));
		const ChatFilteredDeleted = chatReceiveArray.filter(
			(chat) => !chat.deleted && publicProfiles[chat.uid]?.approved && isNil(blockedUsers[chat.uid])
		);
		return orderBy(ChatFilteredDeleted, "timeStamp", "desc");
	}
);

export const getChatReceivedFilteredUsers = createSelector(
	getChatReceivedFilteredSortByDate,
	getChatMeta,
	(chatReceivedFilteredSortByDate, chatMeta) => {
		const res = chatReceivedFilteredSortByDate.map((chat) => ({
			...chat,
			unread: chatMeta[chat.chatID]?.newMessages,
		}));
		return res;
	}
);

export const hasChatReceivedFiltered = createSelector(
	getChatReceivedFiltered,
	(chatReceivedFiltered) => Object.keys(chatReceivedFiltered).length > 0
);

export const getChatReceivedFilteredUser = (userUid) =>
	createSelector(getChatReceivedFiltered, (chatReceivedFiltered) => chatReceivedFiltered[userUid]);

export const getUserByChatID = (chatId) =>
	createSelector(getChatReceivedFiltered, (chatReceivedFiltered) =>
		Object.keys(chatReceivedFiltered).find(
			(userId) => chatReceivedFiltered[userId].userID === chatId
		)
	);
