import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class UserChatReceivedService {
  BASE_REF = "chat_received";

  async fetchChatReceived(uid) {
    return (
      (
        await firebase.database().ref(this.BASE_REF).child(uid).once("value")
      ).val() || {}
    );
  }

  listenChatReceivedUpdates(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_changed",
        (snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_changed", callbackFn);
    });
  }

  listenChatReceivedAdded(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_added",
        (snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_added", callbackFn);
    });
  }

  listenChatReceivedRemoved(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_removed",
        (snapshot) => subscriber.next(snapshot.key),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_removed", callbackFn);
    });
  }

  virtualDeleteChat(colleagueUid) {
    const uid = firebase.auth().currentUser?.uid;
    return firebase
      .database()
      .ref(this.BASE_REF)
      .child(uid)
      .child(colleagueUid)
      .child("deleted")
      .set(true);
  }

  cancelDeleteChat(colleagueUid) {
    const uid = firebase.auth().currentUser?.uid;
    return firebase
      .database()
      .ref(this.BASE_REF)
      .child(uid)
      .child(colleagueUid)
      .child("deleted")
      .set(false);
  }
}

export default new UserChatReceivedService();
