import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

import PromotionStatus from "consts/promotionStatus";

class PromotionService {
  BASE_REF = "promotion";

  async fetch(uid) {
    return (
      (
        await firebase
          .database()
          .ref(this.BASE_REF)
          .child(uid)
          .orderByChild("expiredIn")
          .startAt(Date.now())
          .once("value")
      ).val() || {}
    );
  }

  listenPromotionUpdates(uid) {
    return new Observable((subscriber) => {
      const ref = firebase.database().ref(this.BASE_REF).child(uid);

      const callbackFn = ref.on(
        "child_changed",
        (snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
        (error) => subscriber.error(error)
      );
      return () => ref.off("child_changed", callbackFn);
    });
  }

  updateStatusLikeOrDislike(uid, colleagueUid) {
    return firebase
      .database()
      .ref(this.BASE_REF)
      .child(uid)
      .child(colleagueUid)
      .child("status")
      .set(PromotionStatus.LikeOrDisLike);
  }

  virtualDeletePromotion(colleagueUid) {
    const uid = firebase.auth().currentUser?.uid;
    return firebase
      .database()
      .ref(this.BASE_REF)
      .child(uid)
      .child(colleagueUid)
      .child("deleted")
      .set(true);
  }
}

export default new PromotionService();
