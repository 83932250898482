import "firebase/database";
import HttpsCallable from "./httpsCallable";
import firebase from "firebase/app";
import { Observable } from "rxjs";

class FunctionsService {
	async updateProfile(data) {
		const result = await HttpsCallable.post("updateProfile", {
			extraData: { data },
		});
		return result;
	}

	async getSharedContactFriends(talentId) {
		const result = await HttpsCallable.post("getSharedContactFriends", {
			extraData: { talentId },
		});
		return result;
	}
	async getLastRecommendations() {
		const result = await HttpsCallable.post("getLastRecommendations", {
			extraData: {},
		});
		return result;
	}

	async getMyFriends() {
		const result = await HttpsCallable.post("getMyFriends", {
			extraData: {},
		});
		return result;
	}

	async addTalentAction(talentId, type) {
		const result = await HttpsCallable.post("addTalentAction", {
			extraData: { talentId, type },
		});
		return result;
	}
	async setOnline() {
		const result = await HttpsCallable.post("setOnline", {
			extraData: {},
		});
		return result;
	}

	async changeUserToTalent(uid) {
		const result = await HttpsCallable.post("changeUserToTalent", {
			uid,
		});
		return result;
	}

	async addAffiliateClickEvent(affId) {
		const result = await HttpsCallable.post("addAffiliateClickEvent", {
			extraData: { affId },
		});
		return result;
	}

	async addUserLocation(lat, lng, locationName) {
		const result = await HttpsCallable.post("addUserLocation", {
			extraData: { location: { lat, lng }, locationName },
		});
		return result;
	}

	async addFavorite(favoriteUid) {
		const result = await HttpsCallable.post("addFavorite", {
			extraData: { favoriteUid },
		});
		return result;
	}
	async removeFavorite(favoriteUid) {
		const result = await HttpsCallable.post("removeFavorite", {
			extraData: { favoriteUid },
		});
		return result;
	}
	async sendLead(receiver, message, leadType = null) {
		const result = await HttpsCallable.post("sendLead", {
			extraData: { talentId: receiver, message, leadType },
		});
		return result;
	}
	async blockUser(colleagueId) {
		const result = await HttpsCallable.post("blockUser", {
			extraData: { colleagueId },
		});
		return result;
	}

	async unBlockUser(colleagueId) {
		const result = await HttpsCallable.post("unBlockUser", {
			extraData: { colleagueId },
		});
		return result;
	}
	async reportUser(colleagueId, reason, comment = "") {
		const result = await HttpsCallable.post("reportUser", {
			extraData: { colleagueId, reason, comment },
		});
		return result;
	}
	async deleteMe() {
		const result = await HttpsCallable.post("deleteMe", {
			extraData: {},
		});
		return result;
	}
	async freezeMe() {
		const result = await HttpsCallable.post("freezeMe", {
			extraData: {},
		});
		return result;
	}
	async unFreezeMe() {
		const result = await HttpsCallable.post("unFreezeMe", {
			extraData: {},
		});
		return result;
	}

	listenFavorites$(userUid) {
		if (!userUid) return;
		return new Observable((subscriber) => {
			console.log("listen");
			const ref = firebase.database().ref("users_favorites").child(userUid);
			const callbackFn = ref.on(
				"value",
				(snapshot) => {
					subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	async getFavorite(uid) {
		const res = await firebase.database().ref("users_favorites").child(uid).get();
		return res;
	}

	listenUnreadNotification$(userUid) {
		if (!userUid) return;
		return new Observable((subscriber) => {
			console.log("listen users_unread_notifications_count");
			const ref = firebase.database().ref("users_unread_notifications_count").child(userUid);
			const callbackFn = ref.on(
				"value",
				(snapshot) => {
					subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	async resetUnreadCount(type) {
		const result = await HttpsCallable.post("resetUnreadCount", {
			extraData: { type },
		});
		return result;
	}
}

export default new FunctionsService();
