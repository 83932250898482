import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

import AdminResponseStatus from "consts/AdminResponseStatus";

class AdminActionsService {
  BASE_REF = "admin_actions";
  REQUEST_SUFFIX = "request";
  RESPONSE_SUFFIX = "response";

  _listenToResponse(uid, requestKey, autoStopListen) {
    return new Observable((subscriber) => {
      firebase
        .database()
        .ref(`${this.BASE_REF}/${uid}/${this.RESPONSE_SUFFIX}`)
        .child(requestKey)
        .on("value", (snapshot) => {
          let response = snapshot.val();

          if (response != null) {
            response.requestKey = requestKey;

            if (response.status === AdminResponseStatus.OK) {
              subscriber.next(response);
            } else {
              subscriber.error(response);
            }
            if (autoStopListen || response.data?.isDone) {
              return subscriber.complete();
            }
          }
        });

      return () => this._finishRequest(uid, requestKey);
    });
  }

  _finishRequest(uid, requestKey) {
    firebase
      .database()
      .ref(`${this.BASE_REF}/${uid}/${this.RESPONSE_SUFFIX}`)
      .child(requestKey)
      .set(null);
    firebase
      .database()
      .ref(`${this.BASE_REF}/${uid}/${this.REQUEST_SUFFIX}`)
      .child(requestKey)
      .off();
  }
}

export default new AdminActionsService();
