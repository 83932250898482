import { Box } from "@mui/material";
import Recommendations from "components/talent/Recommendations";
import useUser from "hooks/useUser";
import { useEffect } from "react";
import functionsService from "services/functionsService";
import recommendations from "services/recommendations";

export default ({ isFromWeb = false }) => {
	const { userProfile } = useUser();

	useEffect(() => {
		if (userProfile && userProfile.uid) functionsService.resetUnreadCount("recommendations");

		return () => {
			if (userProfile && userProfile.uid)
				recommendations.updateRecommendationsIsRead(userProfile.uid);
		};
	}, []);

	return (
		<Box className="recommendation-edit" style={{ marginTop: "60px" }}>
			{userProfile && userProfile.uid && (
				<Recommendations talentId={userProfile.uid} isFromWeb={isFromWeb}></Recommendations>
			)}
		</Box>
	);
};
