import "assets/scss/recommendation/recommendOn.scss";
import { Box } from "@mui/system";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useNavigator from "hooks/useNavigator";
import recommendations from "services/recommendations";
import _ from "lodash";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";
import publicProfile from "services/publicProfile";
import UserImage from "components/talent/userImage";
import { useDispatch, useSelector } from "react-redux";
import dialogsTypes from "consts/dialogsTypes";
import { openDialog } from "redux/dialog/selectors";
import { getUserProfile } from "redux/profile/selectors";
import Loader from "components/Loader";
import BaseHelmet from "components/seo/BaseHelmet";
import moment from "moment";
import UsersTypes from "consts/UsersTypes";
import { UserRecomendationDialogHeader } from "components/userRecomendation/UserRecomendationDialog";

export default ({
	idFromOut = null,
	setRecomendUserDialog = () => {},
	userFriendsRec = [],
	withoutHeader = false,
}) => {
	const { id } = useParams();
	const [listMyRecomendation, setListMyRecomendation] = useState([]);
	const [recomendUser, setRecomendUser] = useState({});
	const [noResult, setNoResult] = useState(false);
	const navigate = useNavigator();

	useEffect(async () => {
		if (id) {
			await publicProfile.fetchProfile(id).then((user) => setRecomendUser(user));
		} else if (idFromOut) {
			await publicProfile.fetchProfile(idFromOut).then((user) => setRecomendUser(user));
		}
	}, []);

	useEffect(async () => {
		let result;
		if (idFromOut) {
			result = await recommendations.getMyRecommendations(idFromOut);
		} else {
			result = await recommendations.getMyRecommendations(id);
		}
		if (_.isEmpty(result)) {
			setNoResult(true);
		} else {
			setNoResult(false);
			setListMyRecomendation(Object.values(result));
		}
	}, [id, idFromOut]);

	useEffect(async () => {
		if (recomendUser === null) return navigate.navigateToHomePage();
		if (idFromOut && !_.isEmpty(recomendUser)) {
			setRecomendUserDialog(recomendUser);
		}
	}, [recomendUser]);

	useEffect(async () => {
		if (userFriendsRec && userFriendsRec.length) {
			setListMyRecomendation(userFriendsRec);
		}
	}, [userFriendsRec]);

	return (
		<>
			<BaseHelmet
				title={i18next.t("talent.recommendations")}
				description={
					i18next.t("talent.recomendationOf") + ` ${!!recomendUser?.name ? recomendUser?.name : ""}`
				}></BaseHelmet>
			<Box
				className={`${(idFromOut || userFriendsRec?.length) && "recIdWrap"} ${
					!idFromOut && !withoutHeader && "recWithHeader"
				}`}
				sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
				{!idFromOut && !withoutHeader && (
					<>{!!recomendUser?.uid && <UserRecomendationDialogHeader recUser={recomendUser} />}</>
				)}
				<Box className="recommendationWrapper">
					<Box className="recommendationList">
						{!listMyRecomendation?.length ? (
							<>
								{!!noResult ? (
									<Box className="center" sx={{ width: "100%" }}>
										{i18next.t("noResult")}
									</Box>
								) : (
									<Box className="center wrapLoader" sx={{ width: "100%" }}>
										<Loader />
									</Box>
								)}
							</>
						) : (
							<>
								{!!listMyRecomendation?.length && (
									<h6
										style={{
											fontSize: "16px",
											color: "#716e72",
										}}>{`${listMyRecomendation?.length} המלצות`}</h6>
								)}
								{listMyRecomendation.map((item, index) => (
									<RecommendOn item={item} key={index} talentId={item.talentId} userId={item.uid} />
								))}
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export function RecommendOn({ item, talentId, userId }) {
	const [talent, setTalent] = useState({});
	const [user, setUser] = useState({});

	useEffect(async () => {
		const [recUser, talentUser] = await Promise.all([
			await publicProfile.fetchProfile(userId),
			await publicProfile.fetchProfile(talentId),
		]);
		setUser(recUser);
		setTalent(talentUser);
	}, []);

	return (
		<>
			{!talent?.uid ? (
				<></>
			) : (
				<Box className="wrapRecommendOn">
					<HeaderRecommendOn talent={talent} />
					<Box className="textRecOn">{item?.text}</Box>
					<FooterRecommendOn user={user} item={item} />
				</Box>
			)}
		</>
	);
}

export function HeaderRecommendOn({ talent }) {
	const dispatch = useDispatch();
	const openTalentPopup = () => {
		dispatch(openDialog({ dialog: dialogsTypes.ViewTalent, talentId: talent?.uid }));
	};

	return (
		<Box className="wrapHeaderRecommendOn">
			<Box
				className="userImg"
				onClick={openTalentPopup}
				style={{ cursor: talent.uid ? "pointer" : "default" }}>
				<UserImage user={talent} />
			</Box>

			<Box className="innerRecHeader">
				<Box
					className="textDate"
					sx={{ cursor: talent.uid ? "pointer" : "default" }}
					onClick={openTalentPopup}>
					<span style={{ marginLeft: "3px" }}>{i18next.t("talent.recomendOn")}</span>
					<span style={{ fontWeight: 600 }}>{talent.name}</span>
				</Box>
				{!!talent?.title && <Box className="titleRec">{talent.title}</Box>}
			</Box>
		</Box>
	);
}

export function FooterRecommendOn({ user, item }) {
	const dispatch = useDispatch();

	const openUserRecomendationPopup = () => {
		if (!!user?.uid)
			dispatch(openDialog({ dialog: dialogsTypes.UserRecomendationDialog, recId: user.uid }));
	};

	return (
		<Box className="wrapFooterRecommendOn">
			<Box
				className="userImg"
				onClick={openUserRecomendationPopup}
				style={{ cursor: user.uid ? "pointer" : "default" }}>
				<UserImage user={user} />
			</Box>

			<Box className="textDate">
				<span
					style={{ fontWeight: 600, cursor: user.uid ? "pointer" : "default" }}
					onClick={openUserRecomendationPopup}>
					{user.name ? user.name : "משתמש"}
				</span>
				{` | ${moment(item.created).format("DD.MM.YYYY")}`}
			</Box>
		</Box>
	);
}
