import { Box } from "@mui/material";
import i18next from "i18next";
import { useState, useEffect } from "react";
import googlePlay from "assets/images/googlePlay.png";
import appStore from "assets/images/appStore.png";
import homePageService from "services/homePage.service";
import logoSixDays from "assets/images/svgs/landing/logoLogin.svg";
import qrSheshet from "assets/images/qrShesht.jpeg";

export default function SearchBunnerApp({ isWeb = false }) {
	const [data, setData] = useState({});

	const handleClick = (link) => {
		if (!link) return;
		if (data[link]) window.open(data[link]);
	};

	useEffect(async () => {
		homePageService.getSystemHomePageData().then((data) => {
			if (!data) return;
			setData(data);
		});
	}, []);

	return (
		<Box className={`searchBunnerAppWrapper ${isWeb ? "webBunnerApp" : ""}`}>
			<Box className="wrapContent">
				<Box className="bolderTxt">{i18next.t("searchBunnerAppWrapper.weNoticed")}</Box>
				<Box>{i18next.t("searchBunnerAppWrapper.tooBad")}</Box>
			</Box>
			{!isWeb ? (
				<Box className="wrapImages">
					<img src={appStore} alt="appStore" onClick={() => handleClick("app_store")} />
					<img src={googlePlay} alt="googlePlay" onClick={() => handleClick("google_play")} />
				</Box>
			) : (
				<Box className="wrapWebQR">
					<Box className="downloadApp">{i18next.t("searchBunnerAppWrapper.toDownloadTheApp")}</Box>
					<img src={qrSheshet} alt="qrSheshet" onClick={() => {}} />
				</Box>
			)}
		</Box>
	);
}
