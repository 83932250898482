import "assets/scss/profile/profileContent/viewTalentProfileHeader.scss";
import { Box } from "@mui/material";
import i18next from "i18next";
import Loader from "components/Loader";
import defaultProfile from "assets/images/svgs/darkAvatar.svg";
import { TransformImg } from "helpers/cloudinary";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import { useEffect, useRef, useState } from "react";
import useUser from "hooks/useUser";
import LightBox from "components/lightBox/LightBox";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import dialogsTypes from "consts/dialogsTypes";
import backIcon from "assets/images/svgs/back.svg";
import heartIcon from "assets/images/svgs/save-selected.svg";
import heartGrayIcon from "assets/images/svgs/save-header-profile.svg";
import shareIcon from "assets/images/svgs/share-header-profile.svg";
import reccomendIcon from "assets/images/svgs/reccomendations-icon.svg";
import useNavigator from "hooks/useNavigator";
import { Share } from "@capacitor/share";
import AppService from "services/app";
import { getFiltersSelected } from "redux/search/selectors";

export default function ViewTalentProfileHeader({
	user,
	viewTalentPage = false,
	userProfile = {},
	handleDivHeight = () => {},
	isChatWeb = false,
}) {
	const filters = useSelector(getFiltersSelected);
	const dispatch = useDispatch();
	const { getProfileImage, isLoggedIn } = useUser();
	const profileImage = TransformImg(getProfileImage(user), 200, 200);
	const [images, setImages] = useState([]);
	const [selected, setSelected] = useState(false);
	const [openLightBox, setOpenLightBox] = useState(false);
	const [selectedAnimation, setSelectedAnimation] = useState(false);
	const divRef = useRef(null);

	const affId = !!userProfile?.internalID ? `?affId=${userProfile?.internalID}` : "";
	const talentUrl = `${window.location.origin}/talent/${user?.internalID}${affId}`;
	const message = `היי, ראיתי את ${user?.name} ב"ששת ימים" - פלטפורמת ספקים ונותני שירותים מבוססת המלצות חברים משותפים ובינה מלאכותית. מה דעתך?🙏`;
	const whatsappLink = `https://wa.me/?text=${encodeURIComponent(`${message}\n${talentUrl}`)}`;

	const handleWhatsapp = async () => {
		await functionsService.addTalentAction(user.uid, talentAction.clickShare);
		window.open(whatsappLink);
	};
	const { navigateToHomePage } = useNavigator();

	const handleShareTalent = async () => {
		await functionsService.addTalentAction(user.uid, talentAction.clickShare);

		if (user.internalID) {
			try {
				const canShare = await Share.canShare();
				const obj = {
					title: `מה דעתך על ${user?.name}`,
					text: message,
					url: `${window.location.origin}/talent/${user?.internalID}?${
						userProfile.internalID ? `affId=${userProfile.internalID}` : ""
					}`,
				};
				const platformApp = await AppService.getPlatform();
				if (platformApp !== "web") {
					await Share.share(obj);
				} else {
					await navigator.share(obj);
				}
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	const dialogData = useSelector((state) => state.dialogs.dialogs);

	const handleSelected = async (e) => {
		e.stopPropagation();
		if (!isLoggedIn) return dispatch(openDialog({ dialog: dialogsTypes.Login }));
		setSelected(!selected);
		setSelectedAnimation(!selected);
		if (selected) {
			await functionsService.removeFavorite(user.uid);
		} else {
			await functionsService.addFavorite(user.uid);
			await functionsService.addTalentAction(user.uid, talentAction.clickAddFavorite);
		}
	};

	const handleImgClick = () => {
		setOpenLightBox(true);
	};
	const closePopup = () => {
		const searchParams = new URLSearchParams(filters).toString();
		window.history.pushState({}, "", `/search?${searchParams}`);

		if (!!dialogData) {
			const findVtDialog = dialogData?.find((d) => d.dialog == dialogsTypes.ViewTalent);
			if (!!findVtDialog) return dispatch(closeDialogByType(dialogsTypes.ViewTalent));
		}

		return navigateToHomePage();
	};

	useEffect(() => {
		if (user?.profileImage) {
			setImages([user.profileImage?.url]);
		}
	}, []);

	useEffect(() => {
		if (!userProfile?.uid) return;
		const listen = functionsService.listenFavorites$(userProfile?.uid).subscribe((data) => {
			if (!data) return;
			const listFavo = Object.keys(data);
			setSelected(listFavo.includes(user.uid));
		});
		return () => {
			listen.unsubscribe();
		};
	}, [userProfile?.uid]);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.target === divRef.current) {
					const newHeight = entry.target.clientHeight;
					handleDivHeight(newHeight);
				}
			}
		});

		if (divRef.current) {
			observer.observe(divRef.current);
		}

		return () => {
			if (divRef.current) {
				observer.unobserve(divRef.current);
			}
		};
	}, []);

	return (
		<>
			{user == undefined ? (
				<Loader />
			) : (
				<Box className={`wrapProfileHeader ${isChatWeb ? "isChatWeb" : ""}`} ref={divRef}>
					<Box className="wrapProfileBtns">
						{!isChatWeb && (
							<Box className="wrapBackBtn" onClick={closePopup}>
								<img src={backIcon} />
							</Box>
						)}
						<Box className="wrapActionBtns">
							<Box className="actionBtn" onClick={handleShareTalent}>
								<img src={shareIcon} />
								<span>{i18next.t("profileHeader.share")}</span>
							</Box>
							<Box className="actionBtn" onClick={handleSelected}>
								{selected ? (
									<img
										className={`${!!selectedAnimation ? "heartSelectedAnimation" : ""}`}
										src={heartIcon}
									/>
								) : (
									<img src={heartGrayIcon} />
								)}
								<span>{i18next.t("profileHeader.like")}</span>
							</Box>
						</Box>
					</Box>
					<Box className="wrapProfileContent">
						<Box
							className="wrapImgAndName"
							sx={{ fontSize: "18px", fontWeight: "500", minWidth: "80px" }}>
							<Box
								className={viewTalentPage ? "wrapImg viewTalentImg" : "wrapImg"}
								onClick={viewTalentPage ? handleImgClick : () => {}}>
								{<img src={profileImage} alt={`${user?.name}-img`} />}
							</Box>
						</Box>
						<Box className="flexColumn">
							<h1 className="wrapUserName">{user.name}</h1>
							{user?.title && <h2 className="wrapUserTitle">{user?.title}</h2>}
							{!!user?.countRecommendations && (
								<span className="wrapRecommend">
									<img src={reccomendIcon} />
									{`${user?.countRecommendations || 0} ${i18next.t("talent.recommendations")}`}
								</span>
							)}
						</Box>

						{openLightBox && (
							<LightBox
								images={images}
								imgIndex={1}
								isOpen={openLightBox}
								setUpdateOpenLightBox={setOpenLightBox}
							/>
						)}
					</Box>
				</Box>
			)}
		</>
	);
}
