import { Box } from "@mui/material";
import ProfileContent from "components/profileContent/ProfileContent";
import i18next from "i18next";
import businessCardYellow from "../../assets/images/talent/businessCardYellow.png";
import ViewTalentProfileHeader from "components/profileContent/ViewTalentProfileHeader";
import { useEffect, useRef, useState } from "react";

export default ({ userProfile, talentProfile, isChatWeb = false }) => {
	const [wideView, setWideView] = useState(true);
	const [divHeight, setDivHeight] = useState(true);

	const handleDivHeight = (height = 220) => {
		if (height <= 200) return setDivHeight("260");
		return setDivHeight(height);
	};

	useEffect(() => {
		const scrollerId = document.getElementById("wrapDialogId");
		if (!scrollerId) return;
		scrollerId.style.overflow = "auto";

		const handleScroll = () => {
			const currentScrollPos = scrollerId.scrollTop;
			if (currentScrollPos >= 50) {
				setWideView(false);
			} else {
				setWideView(true);
			}
		};

		scrollerId.addEventListener("scroll", handleScroll);

		return () => {
			scrollerId.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Box
			className={`talentHeader ${wideView ? "wideView" : "backwardsAni"}`}
			sx={{
				minHeight: `${divHeight || "220"}px !important`,
				// animationFillMode: wideView ? "forwards" : "backword",
			}}>
			<ViewTalentProfileHeader
				user={talentProfile}
				viewTalentPage={true}
				userProfile={userProfile}
				handleDivHeight={handleDivHeight}
				isChatWeb={isChatWeb}
			/>
		</Box>
	);
};
