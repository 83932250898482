import "assets/scss/popups/registerClientDialog/registerClientDialog.scss";
import { Box, CircularProgress } from "@mui/material";
import happyToSeeYou from "assets/images/svgs/enterPageApp/enterAppGroup3.svg";
import { useFormik } from "formik";
import UploadImage from "components/login/UploadImage";
import ErrorLabel from "components/ui/ErrorLabel";
import TextComponent from "components/ui/TextComponent";
import useNavigator from "hooks/useNavigator";
import useProfileImages from "hooks/useProfileImages";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import * as Yup from "yup";
import SiteButton from "components/ui/SiteButton";
import functionsService from "services/functionsService";
import { closeDialogByType, getDialogs } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default function RegisterClientDialog() {
	const dispatch = useDispatch();
	const navigator = useNavigator();
	const { registerStep, userProfile } = useUser();
	const { saveNewImage } = useProfileImages();

	const [isLoading, setIsLoading] = useState(false);
	const [imageValue, setImageValue] = useState();
	const [isImageValid, setIsImageValid] = useState();
	const { dialogs } = useSelector(getDialogs);
	const RegClientDialogData = dialogs.find(
		(element) => element.dialog === dialogsTypes.RegisterClientDialog
	);

	const talentName = RegClientDialogData?.talentName ?? "";
	const isFromMessageTalent = RegClientDialogData?.isFromMessageTalent ?? false;
	const textMessage = [
		<h1>{`${talentName} בטח יתרגש לקבל ממך הודעה.. :)`}</h1>,
		<h3>{`אבל בשביל זה צריכים קצת להכיר אותך`}</h3>,
	];
	const textRecomendation = [
		<h1>{`ההמלצות בששת זה רק על אמת!`}</h1>,
		<h3>{`כדי לפרסם את ההמלצה שלך אנחנו חייבים לדעת שאת/ה אמיתי/ת.`}</h3>,
		<h3>
			{`בשבילך זה רק כמה שניות, בשביל `}
			<span style={{ fontWeight: 700 }}>{talentName}</span>
			{` זה פרגון של הלייף!`}
		</h3>,
	];
	const text = isFromMessageTalent ? textMessage : textRecomendation;

	const handleOnChangeImage = (value) => {
		setImageValue(value);
	};

	const handleOnValidityChange = (_isValid) => {
		setIsImageValid(_isValid);
	};

	const initialValues = {
		name: userProfile?.name ? userProfile?.name : "",
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.max(25, i18next.t("validation.longName"))
			.required(i18next.t("validation.required")),
	});

	const submitForm = async () => {
		setIsLoading(true);
		if (!formik.values?.name) return setIsLoading(false);
		if (imageValue && !isImageValid) {
			alert("the image is invalid");
			setIsLoading(false);
		} else {
			if (imageValue) {
				await saveNewImage(imageValue, true);
			}
			await functionsService.updateProfile(formik.values);

			//activate function after register
			if (RegClientDialogData?.actAfterRegister) RegClientDialogData.actAfterRegister();

			setIsLoading(false);
			dispatch(closeDialogByType(dialogsTypes.RegisterClientDialog));
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			await submitForm(values);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="registerClientDialogWrapper">
			<img alt="imgSite" src={happyToSeeYou} />
			<Box className="wrapTxtRegClient">{text}</Box>

			<Box sx={{ width: "100%" }}>
				<Box className="reg-title">
					{`${i18next.t("register.fullName")}`}
					<span style={{ color: "#ef2e2e" }}>{" *"}</span>
				</Box>
				<TextComponent
					id="name"
					sx={{ width: "100%" }}
					value={"name"}
					{...formik.getFieldProps("name")}
				/>
				<ErrorLabel>{formik.touched.name && formik.errors.name}</ErrorLabel>
			</Box>

			<Box>
				<Box sx={{ marginBottom: "10px" }}>{i18next.t("register.addProfilePic")}</Box>

				<UploadImage
					onChange={handleOnChangeImage}
					onValidityChange={handleOnValidityChange}
					profilePic={true}
					value={imageValue}
				/>
			</Box>
			{isLoading ? (
				<CircularProgress size={40} sx={{ color: "#386ba8" }} />
			) : (
				<SiteButton text={"המשך"} onClickBtn={submitForm} />
			)}
		</form>
	);
}
