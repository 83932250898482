import produce from "immer";

import * as types from "./actionTypes";

const initialState = {
	info: {},
};

export default produce((state, action) => {
	switch (action.type) {
		case types.SET_CLIENT_INFO:
			return { ...state, info: action.payload };
	}
}, initialState);
