import MigrationStatusEnum from "consts/migrationStatus";
import HTTPFunctionService from "services/httpFunction";

class MigrationService {
	async start() {
		const { isMigrationUser, migrationStatus } = await HTTPFunctionService.migrateUser();
		if (!isMigrationUser) {
			return MigrationStatusEnum.NOT_EXIST;
		}
		if (migrationStatus === migrationStatus.MIGRATION_IN_PROGRESS) {
			return MigrationStatusEnum.IN_PROGRESS;
		}
		if (migrationStatus === MigrationStatusEnum.DONE) {
			return MigrationStatusEnum.DONE;
		}
		return MigrationStatusEnum.NOT_EXIST;
	}
}

export default new MigrationService();
