import { Badge, Box, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import SearchCategory from "./categoriesTags/SearchCategory";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useDispatch } from "react-redux";
import useSearch from "hooks/useSearch";
import SearchTags from "./SearchTags";
import { useSelector } from "react-redux";
import { getFiltersSelected, hasMoreFilter } from "redux/search/selectors";
import i18next from "i18next";
import { useEffect, useState } from "react";
import badgeImg from "assets/images/talent/badge.png";
import SearchHeader from "./SearchHeader";
import searchIcon from "assets/images/svgs/menu-search-active.svg";
import filterIcon from "assets/images/svgs/filter.svg";
import arrowTags from "assets/images/svgs/arrow-tags.svg";

export default ({}) => {
	const search = useSearch();
	const dispatch = useDispatch();
	const [invisible, setInvisible] = useState(true);
	const [tagsOpen, setTagsOpen] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const advanceSearchData = useSelector(getFiltersSelected);

	const handleCategoryChange = async () => {
		setTagsOpen(false);
	};
	const handleClickTags = async () => {
		setTagsOpen(!tagsOpen);
	};
	const handleSubmit = async () => {
		search.search({ category: "" });
	};
	const handleBlur = async () => {
		setTimeout(() => {
			setShowSearch(false);
		}, 0);
	};
	const handleFocus = async () => {
		setShowSearch(true);
	};
	const openSearchFilter = () => {
		if (showSearch) return;
		dispatch(openDialog({ dialog: dialogsTypes.filterSearch }));
	};

	/* 	useEffect(() => {
		if (
			(!!advanceSearchData?.area && !!advanceSearchData?.area?.length) ||
			!!advanceSearchData?.maximumNumberOfGuests
		) {
			setInvisible(false);
		} else {
			setInvisible(true);
		}
	}, [advanceSearchData]); */

	return (
		<>
			<SearchTags handleBlur={handleBlur} handleFocus={handleFocus}>
				<Box className="wrapSearchAndFilter">
					<Box>
						{showSearch ? (
							<Button onClick={handleSubmit} className="center searchBtn">
								<img src={searchIcon} style={{ position: "relative", top: "3px", width: "20px" }} />
							</Button>
						) : (
							<Button onClick={openSearchFilter} className="center filterBtn">
								<img src={filterIcon} style={{ width: "20px", background: "#fff !important" }} />
							</Button>
						)}
					</Box>
					{/* <Box sx={{ position: "relative", left: "7px" }}>
						<Button onClick={openSearchFilter} className="center filterBtn">
							<Badge
								sx={{
									position: "absolute",
									top: "5px",
									right: "8px",
								}}
								color="error"
								variant="dot"
								invisible={invisible}></Badge>
							<Box
								className="center"
								sx={{ alignItems: "start", position: "relative", bottom: "-3px" }}>
								<span style={{ fontSize: "13px" }}>{i18next.t("Search.filter")}</span>
								<img src={badgeImg} alt="" />
							</Box>
						</Button>
					</Box> */}
				</Box>
			</SearchTags>

			<Box sx={{ margin: "10px 0 10px" }}>
				<SearchCategory
					setTagsOpen={setTagsOpen}
					tagsOpen={tagsOpen}
					handleCategoryChange={handleCategoryChange}></SearchCategory>
			</Box>
			<Button className="moreTagsBtn" variant="contained" onClick={handleClickTags}>
				<span>{!tagsOpen ? i18next.t("Search.moreTags") : i18next.t("Search.lessTags")}</span>
				<img src={arrowTags} className="arrowTag" style={{ transform: tagsOpen && "scale(-1)" }} />
			</Button>
		</>
	);
};
