import firebase from "firebase/app";
import "firebase/database";
import ipHelper from "helpers/ip";

class QueueService {
	BASE_REF = "queue/tasks";
	ip = "";

	constructor() {}

	async sendEvent(uid, eventName, extraData = {}) {
		const info = ipHelper.getInfoFromStore();
		return firebase
			.database()
			.ref(this.BASE_REF)
			.push({
				uid: uid,
				eventName: eventName,
				timeStamp: firebase.database.ServerValue.TIMESTAMP,
				extraData: extraData,
				ip: info && info?.ip ? info?.ip : "",
				info: info ?? "",
			});
	}
}

export default new QueueService();
