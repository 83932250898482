import { Observable } from "rxjs";
import { Device } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";

class AppService {
	async getPlatform() {
		const info = await Device.getInfo();
		return info.platform;
	}

	async registerFCMPush() {
		try {
			let permStatus = await PushNotifications.checkPermissions();
			if (permStatus.receive === "prompt") {
				permStatus = await PushNotifications.requestPermissions();
			}
			if (permStatus.receive === "granted") {
				PushNotifications.register();
			} else {
				console.log("User denied permissions!");
			}
		} catch (error) {
			console.log("no permissions for fcm granted");
		}
	}

	listenFCMToken() {
		return new Observable((subscriber) => {
			PushNotifications.addListener("registration", (token) => {
				if (!!token) {
					subscriber.next(token.value);
				}
			});
			PushNotifications.addListener("registrationError", (err) => {
				console.error(err);
			});
		});
	}
}

export default new AppService();
