import { Helmet } from "react-helmet";

export default function ({ title, description, structuredData, url }) {
	return (
		<Helmet>
			‍{!!title && <title>{title}</title>}
			{!!title && <meta property="og:title" content={title} />}
			{!!description && <meta property="og:description" content={description} />}
			{!!description && <meta name="description" content={description} />}
			{!!structuredData && <script type="application/ld+json">{`${structuredData}`}</script>}
			{!!url && (
				<link
					rel="canonical"
					href={url === "/" ? "https://sheshet.co.il" : `https://sheshet.co.il/${url}`}
				/>
			)}
		</Helmet>
	);
}
