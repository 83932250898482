import firebase from 'firebase/app';
import "firebase/database";
import { Observable } from 'rxjs';

class ChatMetaService {
  BASE_REF = 'chat_meta';

  async fetchChatMeta(uid) {
    return (
      (
        await firebase.database()
          .ref(this.BASE_REF)
          .child(uid)
          .once('value')
      ).val() || {}
    );
  }

  listenChatMeta(uid) {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(uid);

      const callbackFn = ref.on(
        'child_changed',
        snapshot => subscriber.next({ [snapshot.key]: snapshot.val() }),
        error => subscriber.error(error)
      );
      return () => ref.off('child_changed', callbackFn);
    });
  }

  listenChatMetaAdded(uid) {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(uid);

      const callbackFn = ref.on(
        'child_added',
        snapshot => subscriber.next({ [snapshot.key]: snapshot.val() }),
        error => subscriber.error(error)
      );
      return () => ref.off('child_added', callbackFn);
    });
  }

  listenChatMetaRemoved(uid) {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(uid);

      const callbackFn = ref.on(
        'child_removed',
        snapshot => subscriber.next(snapshot.key),
        error => subscriber.error(error)
      );
      return () => ref.off('child_removed', callbackFn);
    });
  }

  updateRead(uid, chatId) {
    return firebase.database()
      .ref(this.BASE_REF)
      .child(uid)
      .child(chatId)
      .update({
        newMessages: null,
      });
  }

  clearTypingOnDisconect(collegueUid, chatUid) {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(collegueUid)
        .child(chatUid);
      ref.onDisconnect().remove(() => {
        ref.onDisconnect().update({
          typing: null,
        });
      });
  }

  updateTyping(collegueUid, chatUid, typing) {
    firebase.database()
      .ref(this.BASE_REF)
      .child(collegueUid)
      .child(chatUid)
      .update({
        typing: typing,
      });
  }
}

export default new ChatMetaService();