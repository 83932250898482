import "assets/scss/profile/profileBtns.scss";

import { Box, CircularProgress } from "@mui/material";
import Header from "components/profile/Header";
import useUser from "hooks/useUser";

import Button from "components/ui/Button";
import i18next from "i18next";
import shareIcon from "assets/images/shareIcon.png";
import edit from "assets/images/profile/edit.png";
import myRecomendation from "assets/images/profile/myRecomendation.png";
import myFavorite from "assets/images/profile/myFavorite.png";
import myCard from "assets/images/profile/myCard.png";
import recomendationOnMe from "assets/images/profile/recomendationOnMe.png";
import myImages from "assets/images/profile/myImages.png";
import settings from "assets/images/profile/settings.png";

import useNavigator from "hooks/useNavigator";
import { useDispatch } from "react-redux";
import { openNotification } from "redux/notification/actions";
import UsersTypes from "consts/UsersTypes";
import { useEffect, useState } from "react";
import functionsService from "services/functionsService";
import RecommendationsService from "services/recommendations";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { isMobile } from "react-device-detect";
import SiteButton from "components/ui/SiteButton";
import MuiButton from "@mui/material/Button";

import heartProfileAct from "assets/images/svgs/profile/heartProfileAct.svg";
import heartProfileDefault from "assets/images/svgs/profile/heartProfileDefault.svg";
import cardProfileAct from "assets/images/svgs/profile/cardProfileAct.svg";
import cardProfileDefault from "assets/images/svgs/profile/cardProfileDefault.svg";
import dashboardProfileAct from "assets/images/svgs/profile/dashboardProfileAct.svg";
import dashboardProfileDefault from "assets/images/svgs/profile/dashboardProfileDefault.svg";
import imgaesProfileAct from "assets/images/svgs/profile/imgaesProfileAct.svg";
import imgaesProfileDefault from "assets/images/svgs/profile/imgaesProfileDefault.svg";
import profileProfileAct from "assets/images/svgs/profile/profileProfileAct.svg";
import profileProfileDefault from "assets/images/svgs/profile/profileProfileDefault.svg";
import reccomendationsProfileAct from "assets/images/svgs/profile/reccomendationsProfileAct.svg";
import reccomendationsProfileDefault from "assets/images/svgs/profile/reccomendationsProfileDefault.svg";
import settingsProfileAct from "assets/images/svgs/profile/settingsProfileAct.svg";
import settingsProfileDefault from "assets/images/svgs/profile/settingsProfileDefault.svg";
import shareProfile from "assets/images/svgs/profile/shareProfile.svg";

export default function ProfilePage({ webPage = false, innerPage = "" }) {
	const { navigateToPath } = useNavigator();
	const { userProfile } = useUser();
	const [unReadCount, setUnReadCount] = useState({});

	const talentLinks = [
		{
			innerClassName: innerPage === "editAccount" ? "innerMenu selectedInnerMenu" : "innerMenu",
			onClick: () => navigateToPath("/profile/editAccount"),
			icon: webPage && innerPage === "editAccount" ? profileProfileAct : profileProfileDefault,
			name: i18next.t("profile.editAccount"),
			indicator: 0,
		},
		{
			innerClassName: innerPage === "myImages" ? "innerMenu selectedInnerMenu" : "innerMenu",
			onClick: () => navigateToPath("/profile/myImages"),
			icon: webPage && innerPage === "myImages" ? imgaesProfileAct : imgaesProfileDefault,
			name: i18next.t("profile.images"),
			indicator: 0,
		},
		{
			innerClassName:
				innerPage === "recommendations"
					? "innerMenu  selectedInnerMenu wrapRecommendations"
					: "innerMenu wrapRecommendations",
			onClick: () => navigateToPath("/profile/recommendations"),
			icon:
				webPage && innerPage === "recommendations"
					? reccomendationsProfileAct
					: reccomendationsProfileDefault,
			name: i18next.t("profile.recommendationsOnMe"),
			indicator: unReadCount?.recommendations ? unReadCount?.recommendations?.count : 0,
		},
		{
			innerClassName:
				innerPage === "statistics"
					? "innerMenu  selectedInnerMenu wrapStatistics"
					: "innerMenu wrapStatistics",
			onClick: () => navigateToPath("/profile/statistics"),
			icon: webPage && innerPage === "statistics" ? dashboardProfileAct : dashboardProfileDefault,
			name: i18next.t("profile.statistics"),
			indicator: unReadCount?.talentActions ? unReadCount?.talentActions?.count : 0,
		},
	];
	let privateProfileLinks = [
		{
			onlyClient: true,
			innerClassName: innerPage === "editAccount" ? "innerMenu selectedInnerMenu" : "innerMenu",
			onClick: () => navigateToPath("/profile/editAccount"),
			icon: webPage && innerPage === "editAccount" ? profileProfileAct : profileProfileDefault,
			name: i18next.t("profile.editAccount"),
			indicator: 0,
		},

		{
			innerClassName: innerPage === "favorites" ? "innerMenu selectedInnerMenu" : "innerMenu",
			onClick: () => navigateToPath("/profile/favorites"),
			icon: webPage && innerPage === "favorites" ? heartProfileAct : heartProfileDefault,
			name: i18next.t("profile.myFavorites"),
			indicator: 0,
		},
		{
			innerClassName:
				innerPage === "myRecomendationOnSuppliers" ? "innerMenu selectedInnerMenu" : "innerMenu",
			onClick: () => navigateToPath("/profile/myRecomendationOnSuppliers"),
			icon:
				webPage && innerPage === "myRecomendationOnSuppliers"
					? reccomendationsProfileAct
					: reccomendationsProfileDefault,
			name: i18next.t("profile.recomendationWrote"),
			indicator: 0,
		},
		{
			innerClassName: innerPage === "settings" ? "innerMenu selectedInnerMenu" : "innerMenu",
			onClick: () => navigateToPath("/profile/settings"),
			icon: webPage && innerPage === "settings" ? settingsProfileAct : settingsProfileDefault,
			name: i18next.t("profile.settingsProfile"),
			indicator: 0,
		},
	];
	if (userProfile.type === UsersTypes.Talent) {
		privateProfileLinks = privateProfileLinks.filter((item) => !item?.onlyClient);
	}

	useEffect(() => {
		if (!userProfile?.uid) return;
		const listenNotifications = functionsService
			.listenUnreadNotification$(userProfile.uid)
			.subscribe((data) => setUnReadCount(data || {}));

		return () => {
			listenNotifications.unsubscribe();
		};
	}, [userProfile?.uid]);

	return (
		<>
			{userProfile && userProfile.uid && (
				<section className="profile-wrap">
					{!webPage && <Header></Header>}

					{userProfile.type === UsersTypes.Talent && (
						<MenuLinksTitle title={i18next.t("profile.talentProfile")} links={talentLinks} />
					)}
					<MenuLinksTitle
						title={
							userProfile.type === UsersTypes.Talent ? i18next.t("profile.privateProfile") : ""
						}
						links={privateProfileLinks}
						withSep={false}
					/>

					{(userProfile.type === UsersTypes.Client || !webPage) && (
						<ProfileBtns userProfile={userProfile} />
					)}
				</section>
			)}
		</>
	);
}

function MenuLinksTitle({ title = "", links = [], withSep = true }) {
	return (
		<Box className="menuAndTitleWrapper">
			{title && <h3 className="innerTitle">{title}</h3>}
			{links &&
				!!links?.length &&
				links.map((item, index) => (
					<Box className={item?.innerClassName || ""} key={index} onClick={item?.onClick}>
						<Box className="center wrapImgBackground">
							<img src={item?.icon} alt="" />
						</Box>
						<Box className="center">
							<span>{item?.name}</span>
						</Box>
						{!!item?.indicator && (
							<Box className="center indicatorLink">
								{+item?.indicator > 99 ? "99+" : item?.indicator}
							</Box>
						)}
					</Box>
				))}
			{withSep && <Box className="sep"></Box>}
		</Box>
	);
}

export function ProfileBtns({ userProfile }) {
	const [isLoading, setIsLoading] = useState(false);
	const { navigateToPath } = useNavigator();
	const dispatch = useDispatch();

	const shareTxt = [
		<span style={{ margin: "0 0 0 10px" }}>
			<img src={shareProfile} />
		</span>,
		userProfile.type === UsersTypes.Talent
			? i18next.t("register.shareMyCard")
			: i18next.t("register.shareOurSite"),
	];
	const message = `היי, ראיתי את ${userProfile?.name} ב"ששת ימים" - פלטפורמת ספקים ונותני שירותים מבוססת המלצות חברים משותפים ובינה מלאכותית. מה דעתך?🙏`;

	const openMyBusinessCard = () => {
		if (!isMobile)
			return dispatch(
				openDialog({
					dialog: dialogsTypes.ViewTalent,
					talentId: userProfile.uid,
					talent: userProfile,
				})
			);
		navigateToPath("/profile/myBusinessCard");
	};

	const handleOpenRegisterAsTalent = () => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: i18next.t("register.areYouSureRegisterAsTalent"),
				onYes: async () => {
					toggelClose();
					handleTalentRegister();
				},
				onNo: toggelClose,
				yesBtn: "כן",
			})
		);
	};
	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};

	const handleCopyUrl = async (isTalent) => {
		if (userProfile.internalID) {
			if (!isTalent) {
				navigator.clipboard.writeText(
					`${window.location.origin}/?${
						userProfile.internalID ? `affId=${userProfile.internalID}` : ""
					}`
				);
				dispatch(openNotification(i18next.t("notificationActions.copyLink")));
			} else {
				await navigator.share({
					title: `מה דעתך על ${userProfile?.name}`,
					text: message,
					url: `${window.location.origin}/talent/${userProfile?.internalID}?${
						userProfile.internalID ? `affId=${userProfile.internalID}` : ""
					}`,
				});
				return navigateToPath("/profile/myBusinessCard");
			}
		}
	};

	const setUserTypeHandler = async () => {
		try {
			const result = await functionsService.changeUserToTalent(userProfile.uid);
			return result;
		} catch (e) {
			console.log(e);
		}
	};

	const handleTalentRegister = async () => {
		setIsLoading(true);
		const result = await setUserTypeHandler(UsersTypes.Talent);
		if (result?.data?.success == false) return;

		setIsLoading(false);
		navigateToPath("profile/editAccount");
	};

	if (!userProfile) return <></>;

	return (
		<Box className="center wrapProfileBtns" sx={{ padding: "40px", justifyContent: "start" }}>
			{userProfile.type === UsersTypes.Talent && (
				<MuiButton className="myCardBtn" onClick={openMyBusinessCard} variant="outlined">
					<span>{i18next.t("register.toMyCard")}</span>
				</MuiButton>
			)}
			{userProfile.type === UsersTypes.Client && (
				<>
					{isLoading ? (
						<Box>
							<CircularProgress size={40} sx={{ color: "#168187" }} />
						</Box>
					) : (
						<MuiButton
							className="myCardBtn"
							onClick={handleOpenRegisterAsTalent}
							variant="outlined">
							<span>{i18next.t("register.registerAsTalent")}</span>
						</MuiButton>
					)}
				</>
			)}
			<SiteButton
				text={shareTxt}
				onClickBtn={() => handleCopyUrl(userProfile.type === UsersTypes.Talent)}
				styledText={{ style: { fontSize: "18px" } }}
			/>
		</Box>
	);
}
