import "assets/scss/appEnterPage/openBusinessCardAppPage.scss";
import { Box, Button, CircularProgress } from "@mui/material";
import HeaderEnterPage from "components/enterPageApp/HeaderEnterPage";
import happyToSeeYou from "assets/images/svgs/enterPageApp/enterAppGroup3.svg";
import SiteButton from "components/ui/SiteButton";
import useNavigator from "hooks/useNavigator";
import functionsService from "services/functionsService";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import UsersTypes from "consts/UsersTypes";

export default function OpenBusinessCardAppPage() {
	const { navigateToPath, navigateToSearchPage } = useNavigator();
	const { userProfile } = useUser();
	const [loading, setLoading] = useState(false);
	const [clickedTalentRegister, setClickedTalentRegister] = useState(false);

	const goToHomePage = async (goToRegister = false) => {
		setLoading(true);

		if (goToRegister && userProfile?.uid) {
			setClickedTalentRegister(true);
			if (userProfile?.type !== UsersTypes.Talent) {
				const result = await functionsService.changeUserToTalent(userProfile.uid);
			}
			return navigateToPath("/?cfas=t");
		}
		setLoading(false);

		return navigateToSearchPage();
	};

	useEffect(() => {
		if (userProfile?.type === UsersTypes.Talent && clickedTalentRegister)
			navigateToPath("/?cfas=t");
	}, [userProfile?.type, clickedTalentRegister]);

	return (
		<Box className="openBusinessCardAppPageWrapper">
			<HeaderEnterPage />
			<Box className="contentOpenBusinessCard">
				<img alt="imgSite" src={happyToSeeYou} />
				<h1>{i18next.t("Login.NiceToSeeYou")}</h1>
				<h3>{i18next.t("Login.WouldYouOpenBusinessCard")}</h3>
				{loading ? (
					<CircularProgress size={40} sx={{ color: "#386ba8" }} />
				) : (
					<Box className="wrapOpenCardBtns">
						<SiteButton
							text={i18next.t("Login.takeMeOpenSupplierCard")}
							onClickBtn={() => goToHomePage(true)}
						/>
						<Button
							className="takeMeToSearchBtn"
							variant="contained"
							onClick={() => goToHomePage(false)}>
							{i18next.t("Login.takeMeToSearch")}
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	);
}
