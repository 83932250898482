import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {} from "redux/auth/actions";
import { isUserLoggedIn, getIsAuthChecked } from "redux/auth/selectors";
import useNavigator from "hooks/useNavigator";

export default ({ children, checkAuth }) => {
	const navigator = useNavigator();
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);

	useEffect(() => {
		if (checkAuth && isAuthChecked) {
			if (!isLoggedIn) {
				navigator.navigateToSearchPage();
			}
		}
	}, [isLoggedIn, isAuthChecked]);

	return children;
};
