import "assets/scss/talentPage/talentPage.scss";
import "assets/scss/talentPage/talentWebPopup.scss";
import { Box, Skeleton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Recommendations from "components/talent/Recommendations";
import useUser from "hooks/useUser";
import _ from "lodash";
import i18next from "i18next";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useDispatch, useSelector } from "react-redux";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import WebProfileContent from "components/profileContent/WebProfileContent";
import googlePlay from "assets/images/googlePlay.png";
import appStore from "assets/images/appStore.png";
import sixDaysWedLogo from "assets/images/talent/sixDaysWedLogo.png";
import waze from "assets/images/talent/viewTalent/waze.png";
import WebTalentGalery from "components/talent/WebTalentGalery";
import { useHistory } from "react-router-dom";
import httpsCallable from "services/httpsCallable";
import Friends from "components/talent/Friends";
import reccomendIcon from "assets/images/svgs/reccomendations-icon.svg";
import { TransformImg } from "helpers/cloudinary";
import SiteButton from "components/ui/SiteButton";
import sendMsgIcon from "assets/images/svgs/mainCTA-message.svg";
import LightBox from "components/lightBox/LightBox";
import InfoTalent from "./infoTalent/InfoTalent";
import LinkBtns from "./linkBtns/LinkBtns";
import InfoAndSocial from "components/talent/InfoAndSocial";
import Experience from "components/talent/Experience";
import backDescPopup from "assets/images/backDescPopup.png";
import heartBlue from "assets/images/svgs/heartBlue.svg";
import heartBlueActive from "assets/images/svgs/heartBlueActive.svg";
import shareBlue from "assets/images/svgs/shareBlue.svg";
import { getFiltersSelected } from "redux/search/selectors";
import BaseHelmet from "components/seo/BaseHelmet";

export default ({
	setTalent = () => {},
	talent,
	setIsUserWebPopupOpen = () => {},
	favoriteList = [],
	noBack = false,
	talentId,
	withoutTop = false,
}) => {
	const filters = useSelector(getFiltersSelected);
	const { userProfile, isLoggedIn, getProfileImage } = useUser();
	const [selected, setSelected] = useState(false);
	const [favoritesKeys, setFavoritesKeys] = useState([]);
	const [displayTalent, setDisplayTalent] = useState({});
	const [friendsNumber, setFriendsNumber] = useState(0);
	const [wideView, setWideView] = useState(true);
	const divRef = useRef(null);

	const dispatch = useDispatch();
	const dialogs = useSelector((state) => state?.dialogs?.dialogs);

	const affId = !!userProfile?.internalID ? `?affId=${userProfile?.internalID}` : "";
	const talentUrl = `${window.location.origin}/talent/${displayTalent?.internalID}${affId}`;
	const history = useHistory();

	const handleSelected = async (e) => {
		e.stopPropagation();
		if (!isLoggedIn) return dispatch(openDialog({ dialog: dialogsTypes.Login }));
		setSelected(!selected);
		if (selected) {
			await functionsService.removeFavorite(displayTalent.uid);
		} else {
			await functionsService.addFavorite(displayTalent.uid);
			await functionsService.addTalentAction(displayTalent.uid, talentAction.clickAddFavorite);
		}
	};

	const closeUserWebPopup = () => {
		const searchParams = new URLSearchParams(filters).toString();
		window.history.pushState({}, "", `/search?${searchParams}`);
		if (!!dialogs) {
			const findVtDialog = dialogs?.find((d) => d.dialog == dialogsTypes.ViewTalent);
			if (!!findVtDialog) {
				return dispatch(closeDialogByType(dialogsTypes.ViewTalent));
			}
		}

		setIsUserWebPopupOpen(false);
		setTalent({});
	};

	// const goBackHistory = () => {
	// 	return history.goBack();
	// };

	useEffect(() => {
		if (!divRef.current) return;
		divRef.current.style.overflow = "auto";

		const handleScroll = () => {
			const currentScrollPos = divRef.current.scrollTop;
			if (currentScrollPos >= 350) {
				setWideView(false);
			} else {
				setWideView(true);
			}
		};

		divRef.current.addEventListener("scroll", handleScroll);

		return () => {
			if (divRef.current) divRef.current.removeEventListener("scroll", handleScroll);
		};
	}, [divRef.current]);

	useEffect(() => {
		const unblock = history.block((location, action) => {
			closeUserWebPopup();
			return true;
		});

		return () => {
			unblock();
		};
	}, []);

	useEffect(async () => {
		if (!!displayTalent?.uid) {
			functionsService.setOnline();
			functionsService.addTalentAction(displayTalent?.uid, talentAction.viewIn);
		}
	}, [displayTalent]);

	useEffect(() => {
		if (!userProfile?.uid) return;
		const favorites = functionsService.listenFavorites$(userProfile.uid).subscribe((data) => {
			if (!data) return;
			const listFavo = Object.keys(data);
			setFavoritesKeys(listFavo);
		});

		return () => {
			favorites.unsubscribe();
		};
	}, []);

	useEffect(async () => {
		if (!_.isEmpty(talent)) {
			setDisplayTalent(talent);
		} else if (!!talentId) {
			const result = await httpsCallable.post("getTalent", {
				extraData: {
					talentId,
				},
			});
			setDisplayTalent(result?.data?.data?.items[0]);
		}
	}, []);

	useEffect(() => {
		if (!displayTalent?.uid) return;
		setSelected(favoritesKeys.includes(displayTalent?.uid));
	}, [displayTalent, favoritesKeys]);

	return (
		<>
			<BaseHelmet
				title={`${
					displayTalent?.name ? displayTalent?.name : ""
				} - פרטי הקשר והמלצות של לקוחות באתר ששת ימים`}
				description={
					displayTalent?.title ? displayTalent?.title : i18next.t("seo.homePage.description")
				}></BaseHelmet>
			{!_.isEmpty(displayTalent) ? (
				<Box className="userWebPopup" style={{ top: withoutTop && 0 }}>
					{!noBack && (
						<Box>
							<img style={{ cursor: "pointer" }} src={backDescPopup} onClick={closeUserWebPopup} />
						</Box>
					)}
					<Box className="wrapTalentPage" ref={divRef}>
						<HeaderWebTalent
							userProfile={userProfile}
							user={displayTalent}
							selected={selected}
							handleSelected={handleSelected}
							TalentClass="wrapTalentStickyHeader"
						/>
						{!wideView && (
							<HeaderWebTalent
								userProfile={userProfile}
								selected={selected}
								handleSelected={handleSelected}
								user={displayTalent}
								TalentClass="wrapTalentStickyHeader smallStickyHeader"
							/>
						)}

						<Box className="talentContentWrapper">
							<Box className="talentContent">
								<Box className="padding-border innerTalentContent">
									<InfoTalent user={displayTalent} />
									{(displayTalent?.city_he || displayTalent?.pricePerHour) && <Separator />}
									<Box className="center wrapLinks">
										<LinkBtns user={displayTalent} />
										{(!!displayTalent?.whatsappDisplay || !!displayTalent?.phoneDisplay) && (
											<Separator
												styles={{
													width: "0.5px !important",
													height: "15px !important",
													borderTop: "0 !important",
													borderRight: "0.5px solid #aaaaaa",
												}}
											/>
										)}
										<InfoAndSocial user={displayTalent} userProfile={userProfile} />
									</Box>
									<Separator />
									<Box className="wrapDescAndTags">
										{displayTalent?.aboutMe && (
											<h3 className="talentAboutMe">{displayTalent.aboutMe}</h3>
										)}

										{displayTalent?.tagsObj && (
											<section className="tags">
												{!!displayTalent.tagsObj?.length &&
													displayTalent.tagsObj.map((item) => (
														<button key={item.key}>{item.name}</button>
													))}
											</section>
										)}
									</Box>
									<Separator />

									<Experience user={displayTalent} />

									{(displayTalent?.education ||
										displayTalent?.skills ||
										displayTalent?.experience) && <Separator />}

									<h4 className="headerTitle">{i18next.t("talent.images")}</h4>

									<WebTalentGalery user={displayTalent} />
								</Box>
								<Box className="padding-border talentRec">
									<Recommendations
										talentProfile={displayTalent}
										talentPage={true}
										isFromWeb={true}
										talentId={displayTalent?.uid}></Recommendations>
								</Box>
							</Box>
							<Box className={`padding-border wrapFriends  ${!!friendsNumber ? "" : "dontShow"}`}>
								<Box className="wantToSeeFriends">
									{friendsNumber ? friendsNumber : ""} {i18next.t("talent.commonAcquaintances")}
								</Box>
								{userProfile.uid !== displayTalent?.uid && (
									<Friends
										talentId={displayTalent?.uid}
										isWeb={true}
										setFriendsNumber={setFriendsNumber}></Friends>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			) : (
				<Box
					className="center"
					style={{ flexDirection: "column", gap: "20px", borderRadius: "0 0 20px 20px" }}>
					<Skeleton
						animation="pulse"
						style={{ transform: "scale(1)", maxWidth: "1140px" }}
						width={"100%"}
						height={"333px"}
					/>
					<Box
						style={{
							display: "flex",
							gap: "20px",
							borderRadius: "0 0 20px 20px",
							width: "100%",
							maxWidth: "1140px",
						}}>
						<Skeleton
							animation="pulse"
							style={{
								transform: "scale(1)",
								width: "100%",
								height: "calc(100vh - 370px)",
							}}
						/>
						<Skeleton
							animation="pulse"
							style={{
								transform: "scale(1)",
								width: "360px",
								height: "300px",
							}}
						/>
					</Box>
				</Box>
			)}
		</>
	);
};

function HeaderWebTalent({
	TalentClass = "",
	user,
	selected = false,
	handleSelected = () => {},
	userProfile,
}) {
	const dispatch = useDispatch();
	const { getProfileImage } = useUser();
	const [openLightBox, setOpenLightBox] = useState(false);
	const [images, setImages] = useState([]);
	const profileImage = TransformImg(getProfileImage(user), 500, 500);

	const handleShareTalent = async () => {
		await functionsService.addTalentAction(user.uid, talentAction.clickShare);

		if (user?.internalID) {
			const message = `היי, ראיתי את ${user?.name} ב"ששת ימים" - פלטפורמת ספקים ונותני שירותים מבוססת המלצות חברים משותפים ובינה מלאכותית. מה דעתך?🙏`;

			try {
				await navigator.share({
					title: `מה דעתך על ${user?.name}`,
					text: message,
					url: `${window.location.origin}/talent/${user?.internalID}?${
						userProfile?.internalID ? `affId=${userProfile.internalID}` : ""
					}`,
				});
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	const openSendMessagePopup = async () => {
		dispatch(openDialog({ dialog: dialogsTypes.SendMessageToTalentPopup, talent: user }));
		if (user?.uid) await functionsService.addTalentAction(user.uid, talentAction.clickSendMessage);
	};

	const handleImgClick = () => {
		if (!user?.profileImage) return;
		setOpenLightBox(true);
	};

	useEffect(() => {
		if (user?.profileImage) {
			setImages([user.profileImage?.url]);
		}
	}, []);

	return (
		<Box className={`${TalentClass}`}>
			<Box className="wrapNameAndCategory">
				<h1 className="wrapName">{user.name}</h1>
				<Box className="wrapTitleAndRec">
					{!!user?.title && <h2 className="wrapTitle">{user.title}</h2>}
					{!!user?.countRecommendations && (
						<>
							{!!user?.title && <Box className="wrapSeperator"></Box>}
							<span className="wrapRecommend">
								<img src={reccomendIcon} />
								{`${user.countRecommendations} ${i18next.t("talent.recommendations")}`}
							</span>
						</>
					)}
				</Box>
			</Box>
			<Box className="wrapTalentImgAndMsg">
				<Box className="wrapTalentBtns">
					<Box onClick={handleSelected}>
						<img className="imgTalent" src={selected ? heartBlueActive : heartBlue} />
					</Box>
					<img className="imgTalent" src={shareBlue} onClick={handleShareTalent} />
					<SiteButton
						text={[
							<img src={sendMsgIcon} style={{ marginLeft: "8px" }} />,
							<span style={{ width: "max-content" }}>{i18next.t("linkButtons.sendMsg")}</span>,
						]}
						onClickBtn={openSendMessagePopup}
						styledText={{ style: { display: "flex" } }}
					/>
				</Box>
				<Box className="wrapTalentImg">
					{<img src={profileImage} onClick={handleImgClick} alt={`${user?.name}-img`} />}
				</Box>
			</Box>
			{openLightBox && (
				<LightBox
					images={images}
					imgIndex={1}
					isOpen={openLightBox}
					setUpdateOpenLightBox={setOpenLightBox}
				/>
			)}
		</Box>
	);
}

function Separator({ styles }) {
	return <Box className="separatorWebTalent" sx={{ ...styles }}></Box>;
}
