import { useEffect, useState } from "react";
import AOS from "aos";
import { useDispatch, useSelector } from "react-redux";
import "aos/dist/aos.css";
import "./assets/scss/themes.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "animate.css";

import Routes from "./config/routes/routes";
import Popups from "components/Popups";
import PopupsListener from "components/PopupsListener";
import { initApp } from "redux/auth/actions";
import "./helpers/momentInit";
import ProfileBlockedListener from "components/ProfileBlockedListener";
import NotificationWrapper from "components/notification/NotificationWrapper";
import "assets/scss/notification/notification.scss";
import Dialogs from "components/ui/Dialogs";
import { App as CapacitorApp } from "@capacitor/app";
import AppService from "services/app";
import { SplashScreen } from "@capacitor/splash-screen";
import functionsService from "services/functionsService";
import { closeDialogByType, getDialogs, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import Cookies from "js-cookie";
import MainSwipe from "components/swipe/MainSwipe";
import { useHistory } from "react-router-dom";
import { getUserProfile } from "redux/profile/selectors";
import Shabat from "components/shabat/Shabat";
import StickyDownloadAppBtn from "components/ui/StickyDownloadAppBtn";

const App = () => {
	const dispatch = useDispatch();
	const [platform, setPlatform] = useState();
	const [shabbat, setShabbat] = useState(false);
	const [parashat, setParashat] = useState("");
	const [havdalah, setHavdalah] = useState("");
	const [showShabat, setShowShabat] = useState(false);
	const [showStickyDownloadApp, setShowStickyDownloadApp] = useState(false);
	const currentUser = useSelector(getUserProfile) || {};
	useEffect(() => {
		AOS.init({
			//disable: "phone",
		});
		AOS.refresh();
	}, []);

	useEffect(async () => {
		dispatch(initApp());
	}, []);

	useEffect(() => {
		CapacitorApp.addListener("backButton", ({ canGoBack }) => {
			window.history.back();
		});
	}, []);

	useEffect(async () => {
		await SplashScreen.show({
			showDuration: 5000,
			autoHide: true,
		});
	}, []);

	useEffect(async () => {
		const platformApp = await AppService.getPlatform();
		setPlatform(platformApp);
	}, []);

	useEffect(() => {
		//set affId in Cookie
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		const value = params.affId || params.f;

		if (!!value) {
			const MONTH = 30;
			const d = new Date();
			d.setTime(d.getTime() + MONTH * 24 * 60 * 60 * 1000);
			const expires = "expires=" + d.toUTCString();
			document.cookie = `affId=${value}; ${expires}; `;

			//addAffiliateClickEvent function
			functionsService.addAffiliateClickEvent(value);
		}
	}, []);

	const getShabbatApi = () => {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (timezone) return `https://www.hebcal.com/shabbat?cfg=json&tzid=${timezone}&M=on`;
		return "https://www.hebcal.com/shabbat?cfg=json&geonameid=293397&M=on";
	};

	const calcIsShabbat = (start, end) => {
		const currentDate = new Date();
		const startDate = new Date(start);
		const endDate = new Date(end);

		return currentDate >= startDate && currentDate <= endDate;
	};
	const checkShabbat = async () => {
		try {
			const shabbatApi = getShabbatApi();
			const response = await fetch(shabbatApi);
			const data = await response?.json();
			if (data.items) {
				debugger;
				const candles = data.items.find((item) => item.category === "candles");
				const havdalahTime = data.items.find((item) => item.category === "havdalah");
				const parashaText = data.items.find((item) => item.category === "parashat");
				const isShabbat = calcIsShabbat(candles.date, havdalahTime.date);

				setHavdalah(havdalahTime.date);
				setShabbat(isShabbat);
				setParashat(parashaText.hebrew);

				if (false && isShabbat) {
					setTimeout(() => {
						setShowShabat(true);
					}, 2500);
				}
			}
		} catch (error) {
			// Handle any errors that occurred during the request
			console.error("Error:", error);
		}
	};

	useEffect(() => {
		checkShabbat();
	}, []);

	// useEffect(() => {
	// 	setShowStickyDownloadApp(platform === "web");
	// }, [platform]);

	// useEffect(() => {
	// 	if (!Cookies.get("ldp") && !!currentUser?.uid && !!currentUser?.completeClientRegister) {
	// 		//leaveDetailsPopup
	// 		setTimeout(() => {
	// 			dispatch(openDialog({ dialog: dialogsTypes.LeaveDetails }));
	// 		}, 10000);
	// 	}
	// }, [currentUser?.uid]);

	return (
		<>
			{platform && (
				<>
					<Routes platform={platform}>
						{!!shabbat && !!showShabat && <Shabat parashat={parashat} havdalah={havdalah} />}
						{/* {!!showStickyDownloadApp && <StickyDownloadAppBtn />} */}
						<PopupsListener />
						<ProfileBlockedListener />
						<Dialogs />
						<NotificationWrapper />
						{/* <MainSwipe></MainSwipe> */}
					</Routes>
				</>
			)}
		</>
	);
};

export default App;
