import "assets/scss/notification/notification.scss";
import { useState, useEffect, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import notification from "redux/notification/reducers";
import { closeNotification, openNotification } from "redux/notification/actions";
import { Alert } from "@mui/material";

export default function Notification({ severity = "info" }) {
	const { open, message } = useSelector((state) => state.notification);
	const dispatch = useDispatch();

	const handleCloseNotification = () => {
		dispatch(closeNotification());
	};

	setTimeout(() => {
		handleCloseNotification();
	}, 3000);

	return (
		<Snackbar
			className="snackbar"
			open={open}
			autoHideDuration={4000}
			onClose={handleCloseNotification}
			message={message || ""}>
			{/* <Alert onClose={handleCloseNotification} severity={severity} sx={{ width: "100%" }}>
				{message || ""}
			</Alert> */}

			{/* <Alert severity="error">This is an error message!</Alert>
     			<Alert severity="warning">This is a warning message!</Alert>
     			<Alert severity="info">This is an information message!</Alert>
     			<Alert severity="success">This is a success message!</Alert> */}
		</Snackbar>
	);
}
