import "assets/scss/freeze/freeze.scss";
import ProfileBlocked from "components/ProfileBlocked";

export default () => {
	return (
		<div className="freezeWrapper">
			<ProfileBlocked />
		</div>
	);
};
