import { Box, CircularProgress, Button } from "@mui/material";
import SiteButton from "components/ui/SiteButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import i18next from "i18next";

export default ({ inProgress, type = "", currentStep = 1, goBack = () => {} }) => {
	return (
		<div
			style={{
				display: "flex",
				// justifyContent: "space-between",
				justifyContent: inProgress ? "end" : "space-between",
				margin: type !== "edit" ? "20px 0" : "40px 0 20px",
			}}>
			{/* {!inProgress && currentStep == 1 && ( */}
			{!inProgress && (
				<Button
					onClick={goBack}
					sx={{
						borderRadius: "20px",
						padding: "0px 16px",
						background: "#fff !important",
						border: "1px solid #386BA8 !important",
						color: "#386BA8 !important",
						fontWeight: "700",
						fontSize: "15px",
						lineHeight: "20px",
					}}>
					<Box className="center" style={{ position: "relative", right: "2px" }}>
						<KeyboardArrowRightIcon
							style={{
								color: "#386BA8",
								width: "min-width",
								height: "20px",
								position: "absolute",
								right: "-17px",
							}}
						/>
						<span>{"הקודם"}</span>
					</Box>
				</Button>
			)}
			{!inProgress && (
				<SiteButton
					styledButton={{ type: "submit" }}
					text={
						<Box className="center" style={{ position: "relative", left: "2px" }}>
							<span>{i18next.t("register.next")}</span>
							<KeyboardArrowLeftIcon
								style={{
									color: "#fff",
									width: "min-width",
									height: "20px",
									position: "absolute",
									left: "-17px",
								}}
							/>
						</Box>
					}
				/>
			)}
			{inProgress && (
				<div
					style={{
						display: "flex",
						justifyContent: "end",
						alignItems: "center",
						margin: "0 30px",
					}}>
					<CircularProgress size={40} sx={{ color: "#386BA8" }} />
				</div>
			)}
		</div>
	);
};
