import produce from "immer";

import * as types from "./actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import * as authTypes from "redux/auth/actionTypes";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHAT_SENT_FETCH_SUCCESSFULLY:
			return action.payload;
		case types.CHAT_SENT_ADDED_SUCCESSFULLY:
		case types.CHAT_SENT_UPDATED_SUCCESSFULLY:
			return { ...state, ...action.payload };
		case types.CHAT_SENT_REMOVED_SUCCESSFULLY:
			delete state[action.payload];
			break;
		case blocksTypes.BLOCKS_BLOCK_USER:
			delete state[action.user];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
