/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { FormGroup, Input, InputGroup, InputGroupText, FormFeedback } from "reactstrap";
import { isValidNumber } from "helpers/mobileNumber";
export default ({
	onValidityChange,
	onEnterPressed,
	onChange,
	hasError,
	errMsg,
	handleShowSubtitle = () => {},
	defaultValue = "",
}) => {
	const [value, setValue] = useState("");
	const [isValid, setIsValid] = useState(false);

	const handleOnChange = (val) => {
		setValue(val);
	};

	const handleKeyPress = (keyPressed) => {
		if (keyPressed === "Enter") {
			onEnterPressed();
		}
	};

	useEffect(() => {
		const _isValidNumber = isValidNumber(value);
		setIsValid(_isValidNumber);
		onChange(value);
	}, [value]);

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	useEffect(() => {
		if (!defaultValue) return;
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<FormGroup style={{ margin: "0", width: "70%" }}>
			<InputGroup style={{ position: "relative" }}>
				{/* <InputGroupText>+972</InputGroupText> */}
				{!value && (
					<span
						style={{
							position: "absolute",
							top: "15px",
							right: "20px",
							zIndex: 4,
							color: "#aaa",
							fontWeight: "600",
							zIndex: 0,
						}}>
						{i18next.t("Login.whatsYoursPhone")}
					</span>
				)}
				<Input
					autoFocus={true}
					style={{
						margin: "0",
						fontSize: "30px",
						lineHeight: "1",
						padding: "5px 15px",
						textAlign: "center",
						borderRadius: "5px",
						border: "1px solid #ddd",
						background: "#ffffff00",
					}}
					value={value}
					onFocus={() => {
						// window.scrollTo(0, 0);
						handleShowSubtitle(false);
					}}
					onChange={(e) => handleOnChange(e.target.value)}
					onKeyPress={(e) => handleKeyPress(e.key)}
					type="tel"
					onInput={(e) => {
						e.target.value = e.target.value.replace(/[^0-9]/g, "");
					}}
					invalid={hasError}
				/>
				{hasError && <FormFeedback>{errMsg}</FormFeedback>}
			</InputGroup>
		</FormGroup>
	);
};
