import { useDispatch } from "react-redux";
import * as React from "react";
import { cleanSelectedChat } from "redux/chats/actions";
import { useState, useEffect } from "react";
import chatService from "services/chatService";
import ConversationItem from "./ConversationItem";
import { useRef } from "react";
import SendMessage from "./SendMessage";
import { Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SwipeableTemporaryDrawer from "./SwipeableTemporaryDrawer";

import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import dialogsTypes from "consts/dialogsTypes";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import UsersTypes from "consts/UsersTypes";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import blockIcon from "assets/images/svgs/block.svg";
import reportIcon from "assets/images/svgs/report.svg";
import i18next from "i18next";
import functionsService from "services/functionsService";
import { TransformImg } from "helpers/cloudinary";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default ({ selectedChat }) => {
	const [messages, setMessages] = useState();
	const [handleDrawer, setHandleDrawer] = useState(false);
	const dispatch = useDispatch();
	const elementRef = useRef();
	const history = useHistory();

	const [anchorEl, setAnchorEl] = useState(null);
	const openAnchorEl = Boolean(anchorEl);
	const handleOpenAnchor = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseAnchor = () => {
		setAnchorEl(null);
	};

	const openDrawer = () => {
		setHandleDrawer(true);
	};
	const closeDrawer = () => {
		setHandleDrawer(false);
	};

	const goBack = () => {
		dispatch(cleanSelectedChat());
	};

	const handleOpenKeyboard = () => {
		if (isMobile) scrollPage();
	};

	const scrollPage = () => {
		if (selectedChat && messages && messages.length) {
			setTimeout(() => {
				const divElement = elementRef.current;
				if (divElement && divElement != null) {
					divElement.scrollTop = divElement.scrollHeight;
				}
			}, 300);
		}
	};

	const openUserDialog = () => {
		if (selectedChat?.user?.type === UsersTypes.Talent && isMobile) {
			dispatch(openDialog({ dialog: dialogsTypes.ViewTalent, talentId: selectedChat.user.uid }));
		}
	};

	const handleDialogBlockUser = () => {
		handleCloseAnchor();
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: i18next.t("blockUser.areUSure"),
				onYes: async () => {
					BlockUser();
					toggelClose();
					goBack();
				},
				onNo: toggelClose,
				yesBtn: "כן",
			})
		);
	};
	const handleDialogReportUser = () => {
		handleCloseAnchor();
		dispatch(openDialog({ dialog: dialogsTypes.ReportUser, colleagueId: selectedChat.user.uid }));
	};

	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};

	const BlockUser = () => {
		if (!selectedChat?.user?.uid) return;
		functionsService.blockUser(selectedChat.user.uid);
	};

	/**
   when user go back we block default back history and instead run goBack function
   */
	useEffect(() => {
		const unblock = history.block((location, action) => {
			if (action === "POP") {
				goBack();
				return false;
			}
		});

		return () => {
			unblock();
		};
	}, []);

	useEffect(() => {
		scrollPage();
	}, [messages]);

	useEffect(() => {
		if (selectedChat?.chatId) {
			const fetchMessages = async () => {
				const messagesArr = await chatService.fetchChatMessages(selectedChat.chatId);
				setMessages(messagesArr);
			};
			fetchMessages();
		}
	}, [selectedChat]);

	useEffect(() => {
		const listenRecommendationAdded = chatService
			.listenNewMessagesAdded(selectedChat.chatId)
			.subscribe((data) => {
				chatService.markChatAsRead(selectedChat.user.uid);
				setMessages((prevArr) => {
					return [...prevArr, data];
				});
			});

		return () => {
			listenRecommendationAdded.unsubscribe();
		};
	}, []);

	const profileImage = TransformImg(selectedChat?.user?.profileImage?.url, 100, 100);

	return (
		<Box className="chatConversationWrapper" style={{ height: "calc(100vh - 120px)" }}>
			<Box className="headerWrapper">
				<Box className="innerHeader">
					<Box className="backIcon" onClick={goBack}>
						<ArrowBackIosIcon sx={{ color: "#000000" }} />
					</Box>

					<Box className="imgWrapper" onClick={openUserDialog}>
						<img src={profileImage ? profileImage : getFirstCharImg(selectedChat?.user)} alt="" />
					</Box>
					<Box className="nameWrapper" onClick={openUserDialog}>
						{selectedChat?.user.name}
						<Box sx={{ fontWeight: "400", fontSize: "14px" }}>{selectedChat?.user?.title}</Box>
					</Box>
				</Box>
				{isMobile ? (
					<>
						<Box onClick={openDrawer}>
							<MoreVertIcon
								sx={{ cursor: "pointer", width: "34px", height: "34px", color: "#000000" }}
							/>
						</Box>
					</>
				) : (
					<>
						<Button
							id="fade-button"
							aria-controls={openAnchorEl ? "fade-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={openAnchorEl ? "true" : undefined}
							onClick={handleOpenAnchor}>
							<MoreVertIcon
								sx={{ cursor: "pointer", width: "34px", height: "34px", color: "#168187" }}
							/>
						</Button>
						<Menu
							id="fade-menu"
							MenuListProps={{
								"aria-labelledby": "fade-button",
							}}
							anchorEl={anchorEl}
							open={openAnchorEl}
							onClose={handleCloseAnchor}
							TransitionComponent={Fade}
							className="blockReportMenu">
							<MenuItem onClick={handleDialogBlockUser}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
									}}>
									<img src={blockIcon} alt="" />
									<Box
										sx={{
											marginLeft: "10px",
											color: "#252427",
											fontSize: "18px",
											fontWeight: "600",
										}}>
										{i18next.t("Chat.blockUser")}
									</Box>
								</Box>
							</MenuItem>
							<MenuItem onClick={handleDialogReportUser}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
									}}>
									<img src={reportIcon} alt="" />
									<Box
										sx={{
											marginLeft: "10px",
											color: "#252427",
											fontSize: "18px",
											fontWeight: "600",
										}}>
										{i18next.t("Chat.reportUser")}
									</Box>
								</Box>
							</MenuItem>
						</Menu>
					</>
				)}
			</Box>
			<Box
				className="chatWrap"
				id="chatWrap"
				ref={elementRef}
				style={{ height: "calc(100vh - 120px)" }}>
				{messages &&
					!!messages?.length &&
					messages.map((item, index) => {
						return (
							<ConversationItem
								item={item}
								prevItem={messages[index - 1]}
								selectedChat={selectedChat}
								openUserDialog={openUserDialog}></ConversationItem>
						);
					})}
			</Box>
			<SendMessage
				onOpenKeyboard={handleOpenKeyboard}
				chatId={selectedChat.chatId}
				collegeUser={selectedChat.user}></SendMessage>

			<SwipeableTemporaryDrawer
				colleagueId={selectedChat?.user?.uid}
				handleDrawer={handleDrawer}
				closeDrawer={closeDrawer}
			/>
		</Box>
	);
};
