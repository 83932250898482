import { Box } from "@mui/material";
import AutocompleteSelect from "components/ui/AutocompleteSelect";
import useQuery from "hooks/useQuery";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getTags } from "redux/tags/selectors";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { addFiltersSelected } from "redux/search/actions";
import AutocompleteSearch from "components/ui/AutocompleteSearch";

export default ({ children, handleBlur = () => {}, handleFocus = () => {} }) => {
	const dispatch = useDispatch();
	const { params } = useQuery();
	const [tagsToDisplay, setTagsToDisplay] = useState([]);
	const [orderTags, setOrderTags] = useState([]);
	const [showOnlyPinned, setShowOnlyPinned] = useState(false);
	const [pageReady, setPageReady] = useState(false);
	const [tagsValue, setTagsValue] = useState([]);
	const [widthTags, setWidthTags] = useState(300);

	const tags = useSelector(getTags);

	const handleInputChange = (e) => {
		if (!e) return;
		if (!orderTags) return;
		if (e.target.value) return setShowOnlyPinned(false);
		return setShowOnlyPinned(true);
	};

	const changeTag = (values) => {
		//const tagsKeys = values.map((item) => item?.urlName);
		if (!!values && values?.length && values.length > 8) return;
		setTagsValue(values);
		dispatch(addFiltersSelected({ filters: { freeText: values } }));
	};

	const sortByPriority = (arr) => {
		if (_.isEmpty(arr)) return;
		const newArr = [...arr];
		return newArr.sort((a, b) => {
			return a.priority - b.priority;
		});
	};

	const getTagByUrl = (tags = [], paramsTags = "") => {
		paramsTags = paramsTags.split(",");
		return tags.filter((item) => paramsTags.includes(item.urlName));
	};

	useEffect(() => {
		setOrderTags(sortByPriority(tags));
	}, [tags]);

	useEffect(() => {
		if (orderTags && orderTags.length) {
			setPageReady(true);
			setShowOnlyPinned(true);
		}
	}, [orderTags]);

	useEffect(() => {
		if (showOnlyPinned) {
			setTagsToDisplay(orderTags.filter((tag) => tag.isPinned === true).map((item) => item.name));
		} else {
			setTagsToDisplay(orderTags.map((item) => item.name));
		}
	}, [showOnlyPinned]);

	useEffect(() => {
		if (tags && tags.length) {
			let freeText = params.freeText; // getTagByUrl(tags, params.freeText);
			if (freeText) {
				const arrFreeText = freeText.split(",");
				setTagsValue(arrFreeText);
				//const tagsKeys = dagsData.map((item) => item.urlName);
				dispatch(addFiltersSelected({ filters: { freeText: arrFreeText } }));
			} else {
				setTagsValue([]);
				dispatch(addFiltersSelected({ filters: { freeText: [] } }));
			}
		}
	}, [params.freeText, tags]);
	useEffect(() => {
		if (widthTags == window.outerWidth - 70) return;
		setWidthTags(window.outerWidth - 70);
	}, [window.outerWidth]);

	return (
		<>
			{pageReady && (
				<>
					<section style={{ display: "flex", alignItems: "end", padding: "0px" }}>
						<form
							id="search"
							className="center"
							style={{ width: "100%" }}
							onKeyPress={(e) => {
								e.key === "Enter" && e.preventDefault();
							}}>
							<Box
								className="searchPageBox"
								sx={{
									margin: "12px 0 0",
									width: `${widthTags}px`,
								}}>
								{
									<>
										<AutocompleteSearch
											data={tagsToDisplay}
											value={tagsValue}
											placeholder={
												!tagsValue || !tagsValue?.length ? i18next.t("Search.innerSearchTitle") : ""
											}
											onChangeHandler={(value) => changeTag(value)}
											onInputChange={handleInputChange}
											handleBlur={handleBlur}
											handleFocus={handleFocus}
											freeSolo={true}
										/>
									</>
								}
							</Box>
						</form>
						{children}
					</section>
				</>
			)}
		</>
	);
};
