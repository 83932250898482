import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class ViewsService {
	BASE_REF = "views";

	async fetchViews(uid) {
		return (
			(
				await firebase
					.database()
					.ref(this.BASE_REF)
					.child(uid)
					.orderByChild("timeStamp")
					.startAt(null)
					.once("value")
			).val() || {}
		);
	}

	view(uid, colleagueUid) {
		firebase.database().ref(this.BASE_REF).child(uid).child(colleagueUid).set({
			timeStamp: firebase.database.ServerValue.TIMESTAMP,
		});
	}

	listenViewsAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.orderByChild("timeStamp")
				.startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => subscriber.next({ [snapshot.key]: snapshot.val() }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}
}

export default new ViewsService();
