import { Box } from "@mui/material";
import Loader from "components/Loader";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RecommendationsService from "services/recommendations";
import RecommendationBox from "./RecommendationBox";
import SiteTalentBanner from "components/siteBunner/SiteTalentBanner";
import functionsService from "services/functionsService";
import httpsCallable from "services/httpsCallable";
import { getMyContactFriends } from "redux/contacts/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setMyContactsFriends } from "redux/contacts/actions";

export default ({
	talentId,
	setRecommendationsLength = () => {},
	myRef = null,
	isFromWeb = false,
	talentProfile = null,
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const { userProfile } = useUser();
	const { pathname } = useLocation();

	const [recommendations, setRecommendations] = useState([]);
	const [displayRecommendations, setDisplayRecommendations] = useState([]);
	const myContactFriends = useSelector(getMyContactFriends);
	// const [myContactFriends, setMyContactFriends] = useState(myContacts ?? []);

	const onDeleteHandle = (key) => {
		setRecommendations((prev) => {
			const newData = prev.filter((item) => item.key !== key);
			return newData;
		});
	};
	const talentUid = talentProfile?.uid ?? talentId;

	useEffect(() => {
		if (!talentUid) return;
		const fetchRecommendations = async () => {
			let talenUidFromInternalId = "";

			if (!talentProfile?.uid) {
				const result = await httpsCallable.post("getTalent", {
					extraData: {
						talentId,
					},
				});
				talenUidFromInternalId = result.data?.data?.items[0]?.uid;
			} else {
				talenUidFromInternalId = talentProfile?.uid;
			}

			if (!talenUidFromInternalId) return;
			const recommendationsList = await RecommendationsService.getRecommendations(
				talenUidFromInternalId
			);
			setRecommendations(recommendationsList);
			setLoading(false);
		};
		fetchRecommendations();
	}, [talentUid]);

	useEffect(() => {
		const listenRecommendationAdded = RecommendationsService.listenRecommendationAdded(
			talentUid
		).subscribe((data) => {
			setRecommendations((prevArr) => {
				return [data, ...prevArr];
			});
		});

		return () => {
			listenRecommendationAdded.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (!userProfile?.syncContactsToNeo4j || !!myContactFriends?.length) return;
		functionsService.getMyFriends().then((res) => {
			if (res?.data?.data && !!res.data.data?.length) {
				dispatch(setMyContactsFriends(res.data.data));
			}
		});
	}, []);

	useEffect(() => {
		setRecommendationsLength(recommendations.length);
		setDisplayRecommendations(recommendations);
	}, [recommendations]);

	useEffect(() => {
		//if the user has contact friends in common with the talent then they will be displayed above everyone else.
		if (!recommendations?.length || !myContactFriends?.length) return;
		const filterRecommendations = recommendations.map((item) => {
			if (myContactFriends.find((cFriend) => cFriend.uid == item.uid)) {
				return { ...item, priority: 2 }; //priority:2 ==> is my friend
			} else {
				return { ...item, priority: 1 };
			}
		});

		setDisplayRecommendations(
			filterRecommendations.sort((a, b) => {
				return b.priority - a.priority;
			})
		);
	}, [myContactFriends, recommendations]);

	return (
		<>
			<Box className={isFromWeb ? "recommendationList" : "recommendationListMobile"}>
				{talentProfile?.name && !!displayRecommendations.length && (
					<Box
						className="lastRecOn"
						style={{ display: "none" }}>{`המלצות אחרונות על ${talentProfile?.name}`}</Box>
				)}

				{!!loading ? (
					<Box className="center wrapLoader" sx={{ width: "100%", height: "90vh" }}>
						<Loader />
					</Box>
				) : !!displayRecommendations && !!displayRecommendations.length ? (
					displayRecommendations.map((item) => {
						return (
							<RecommendationBox
								myRef={userProfile?.uid === item?.uid ? myRef : null}
								onDelete={onDeleteHandle}
								key={item.key}
								talentId={talentId}
								isFromWeb={isFromWeb}
								item={item}
								talentProfileUid={talentProfile?.uid}></RecommendationBox>
						);
					})
				) : (
					pathname == "/profile/recommendations" && (
						<Box className="center" sx={{ width: "100%", marginTop: "50px" }}>
							<SiteTalentBanner />
						</Box>
					)
				)}
			</Box>
		</>
	);
};
