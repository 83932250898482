import * as Yup from "yup";
import { Box } from "@mui/material";
import i18next from "i18next";
import EditTextLine from "./editLineHelpers/EditTextLine";
import profileFields from "consts/profileFields";
import {
	fbRegExp,
	tikTokRegExp,
	linkedinRegExp,
	instagramRegExp,
	youtubeChanelRegExp,
} from "helpers/regex";

export default function SocialLinks({ user, handleSave = () => {} }) {
	return (
		<>
			{!!user?.uid && (
				<Box className="inner-section account-details">
					<h3 className="edit-title">{i18next.t("editProfile.socialLinks")}</h3>
					<EditTextLine
						keyField={profileFields.Site}
						valueField={user[profileFields.Site]}
						handleSave={handleSave}
						fieldName={i18next.t("register.site")}
						formikValidation={{
							site: Yup.string().url(i18next.t("validation.site")),
						}}
					/>
					<EditTextLine
						keyField={profileFields.Instagram}
						valueField={user[profileFields.Instagram]}
						handleSave={handleSave}
						fieldName={i18next.t("register.instagram")}
						formikValidation={{
							instagram: Yup.string().matches(instagramRegExp, i18next.t("validation.instagram")),
						}}
					/>
					<EditTextLine
						keyField={profileFields.Facebook}
						valueField={user[profileFields.Facebook]}
						handleSave={handleSave}
						fieldName={i18next.t("register.facebook")}
						formikValidation={{
							facebook: Yup.string().matches(fbRegExp, i18next.t("validation.facebook")),
						}}
					/>
					<EditTextLine
						keyField={profileFields.Linkedin}
						valueField={user[profileFields.Linkedin]}
						handleSave={handleSave}
						fieldName={i18next.t("register.linkedin")}
						formikValidation={{
							linkedin: Yup.string().matches(linkedinRegExp, i18next.t("validation.linkedin")),
						}}
					/>
					<EditTextLine
						keyField={profileFields.TikTok}
						valueField={user[profileFields.TikTok]}
						handleSave={handleSave}
						fieldName={i18next.t("register.tikTok")}
						formikValidation={{
							tikTok: Yup.string().matches(tikTokRegExp, i18next.t("validation.tikTok")),
						}}
					/>
					<EditTextLine
						keyField={profileFields.YoutubeChanel}
						valueField={user[profileFields.YoutubeChanel]}
						handleSave={handleSave}
						fieldName={i18next.t("register.youtubeChanel")}
						formikValidation={{
							youtubeChanel: Yup.string().matches(
								youtubeChanelRegExp,
								i18next.t("validation.youtube")
							),
						}}
					/>
				</Box>
			)}
		</>
	);
}
