//import { QueueEvents } from "../consts/queueEventwwww";
import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class HomePageService {
	BASE_REF = "system";
	CHILD_REF = "home_page";
	JOIN_NOW = "join_now";
	GOOGLE_PLAY = "google_play";
	APP_STORE = "app_store";
	WE_IN_MEDIA = "we_in_media";

	async getSystemHomePageData() {
		return (await firebase.database().ref(this.BASE_REF).child(this.CHILD_REF).once("value")).val();
	}

	listenToSystemHomePageData() {
		return new Observable((subscriber) => {
			let ref = firebase.database().ref(this.BASE_REF).child(this.CHILD_REF);

			ref.on("value", (snapshot) => {
				let data = snapshot.val();
				subscriber.next(data || {});
			});
			return () => ref.off("value");
		});
	}
}

export default new HomePageService();
