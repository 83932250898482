import { map, mergeMap, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";

import * as authTypes from "redux/auth/actionTypes";
import * as types from "./actionTypes";

import contactsService from "services/contactsService";
import { getLoggedInUserUID } from "../auth/selectors";
import ProfileService from "services/profile";

export const setContacts = (action$, store) => {
	return action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			try {
				const state = store.value;
				const uid = getLoggedInUserUID(state);
				const profile = await ProfileService.fetchUserProfileOnce(uid);
				const contacts = await contactsService.setContacts(uid, profile?.syncContactsToNeo4j);

				return {
					type: types.SEND_CONTACTS_LIST,
					payload: contacts,
				};
			} catch (error) {
				console.log(error);
				return {
					type: "error",
					error: error?.message,
				};
			}
		})
	);
};

export const listenContactsMatching = (action$, store) => {
	return action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			try {
				const state = store.value;
				const uid = getLoggedInUserUID(state);
				return contactsService.listenContactsMatching(uid).pipe(
					map((data) => {
						return {
							type: types.INIT_CONTACTS_SUCCESSFULLY,
							payload: data,
						};
					})
				);
			} catch (error) {
				return {
					type: "error",
					error: error?.message,
				};
			}
		}),
		repeat()
	);
};
