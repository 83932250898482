import { Observable } from "rxjs";
import firebase from "firebase/app";
import "firebase/database";

class LocationService {
	geoSuccess(position, subscriber) {
		const location = {
			lat: position.coords.latitude,
			lng: position.coords.longitude,
		};
		subscriber.next(location);
	}

	geoError(error, subscriber) {
		subscriber.error(error);
	}

	getCurrentLocation() {
		return new Observable((subscriber) => {
			navigator.geolocation.getCurrentPosition(
				(position) => this.geoSuccess(position, subscriber),
				(error) => this.geoError(error, subscriber),
				{ enableHighAccuracy: false, timeout: 20000 }
			);
		});
	}

	async getUserLastSearchLocations(uid, limit = 5) {
		return (
			await firebase.database().ref("users_locations").child(uid).limitToLast(limit).once("value")
		).val();
	}
}

export default new LocationService();
