import { map, mergeMap, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";

import * as authTypes from "redux/auth/actionTypes";
import * as types from "./actionTypes";
import TagsService from "services/tags";

export const fetchTags = (action$, store) => {
	return action$.pipe(
		ofType(authTypes.AUTH_APP_INIT),
		mergeMap(async () => {
			try {
				const tags = await TagsService.getTags();
				return {
					type: types.INIT_TAGS_SUCCESSFULLY,
					payload: tags,
				};
			} catch (error) {
				return {
					type: "error",
					error: error?.message,
				};
			}
		})
	);
};

export const listenTags = (action$, store) => {
	return action$.pipe(
		ofType(types.INIT_TAGS_SUCCESSFULLY),
		mergeMap(() => {
			try {
				return TagsService.listenTagAdded().pipe(
					map((add) => {
						return {
							type: types.TAG_ADDED,
							payload: add,
						};
					})
				);
			} catch (error) {
				return {
					type: "error",
					error: error?.message,
				};
			}
		}),
		repeat()
	);
};
