import { catchError, map, mergeMap, repeat, switchMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import _ from "lodash";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import chatService from "services/chatService";
import PublicProfileService from "services/publicProfile";

export const fetchChatList = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		switchMap(async () => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			try {
				let payload = await chatService.fetchChatsList(loggedInUserUID);
				await Promise.all(
					Object.entries(payload || {}).map(async ([key, val]) => {
						const user = await PublicProfileService.fetchProfile(key);
						payload[key] = { ...val, user, key };
					})
				);
				return {
					type: types.CHATS_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.CHATS_FETCH_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.CHATS_FETCH_FAILED,
				payload: error?.message,
			});
		})
	);

export const listenChatListChange = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return chatService.listenChatUpdates(uid).pipe(
				map((data) => {
					return {
						type: types.CHATS_CHANGED,
						payload: data,
					};
				})
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),

		repeat()
	);

export const listenChatDeleted = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return chatService.listenChatDeleted(uid).pipe(
				map((data) => {
					return {
						type: types.CHATS_REMOVED,
						payload: data,
					};
				})
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),

		repeat()
	);

export const listenChatAdded = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return chatService.listenChatAdded(uid).pipe(
				mergeMap(async (data) => {
					const user = await PublicProfileService.fetchProfile(data.key);
					data = { ...data, user };
					return {
						type: types.CHATS_ADDED,
						payload: data,
					};
				})
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),

		repeat()
	);
