import { Box } from "@mui/material";
import LoginAndRegisterPage from "./LoginPage";
import { isMobile } from "react-device-detect";
import SearchWebHeader from "components/searchWeb/SearchWebHeader";

export default function LoginTalent() {
	return (
		<>
			{!isMobile && <SearchWebHeader />}
			<LoginAndRegisterPage goToTalentRegister={true} />
		</>
	);
}
