import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import { Contacts } from "@capacitor-community/contacts";
import QueueService from "services/queue";
import StorageService from "services/storage";
import httpsCallable from "./httpsCallable";
import LocalStorageService from "services/localStorage";

const functions = firebase.functions();
const BASE_REF = "user_contacts_matching";

class ContactsService {
	listenContactsMatching(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(BASE_REF).child(uid);

			const callbackFn = ref.on(
				"value",
				(snapshot) => {
					return subscriber.next({ ...snapshot.val(), key: snapshot.key });
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
	getPermission = async () => {
		return await Contacts.requestPermissions();
	};

	retrieveListOfContacts = async () => {
		const projection = {
			// Specify which fields should be retrieved.
			name: true,
			phones: true,
			postalAddresses: true,
		};

		const result = await Contacts.getContacts({
			projection,
		});
		return result;
	};

	needToUpdateContacts() {
		const value = LocalStorageService.get("lastContactTime");

		if (!value || (!!value && parseInt(value) + 30 * 24 * 60 * 60 * 1000 < Date.now())) {
			// Adding 30 days.
			LocalStorageService.set("lastContactTime", Date.now(), 30);
			return true;
		}
		return false;
	}

	async setContacts(uid, syncContactsToNeo4j = false) {
		try {
			if (!!syncContactsToNeo4j && !this.needToUpdateContacts()) {
				return { contacts: [] };
			}

			const sendEvent = (data, numSteps, key) => {
				
				return QueueService.sendEvent(uid, "setContacts", {
					contacts: data,
					numSteps,
					key,
				});
			};
			const contactsList = await this.retrieveListOfContacts();
			if (contactsList && contactsList.contacts) {
				const contactsClean = contactsList.contacts
					.filter((item) => item.phones)
					.map((item) => {
						return {
							contactId: item.contactId ?? null,
							phones: item.phones ?? null,
							name: item?.name?.display ?? null,
						};
					});

				const promises = [];
				if (contactsClean.length) {
					const timeStamp = Date.now();
					const step = 1000;
					const numSteps = Math.ceil(contactsClean.length / step);
					for (let index = 0; index < contactsClean.length; index = index + step) {
						var slicedArr = contactsClean.slice(index, index + step);
						promises.push(sendEvent(slicedArr, numSteps, timeStamp));
					}
					await Promise.all(promises);
				}
			}

			return contactsList;
		} catch (error) {
			console.log(error);
		}
	}

	async addContactMatching(userUid, talentId) {
		try {
			const extraData = {};
			extraData.talentId = talentId;
			QueueService.sendEvent(userUid, "addContactMatching", extraData);
		} catch (error) {
			console.log(error);
		}
	}
	async contactUs(email, text, phone = null, image = null) {
		let imageUrl = image;
		if (!!imageUrl) {
			const now = new Date();
			const randomNum = Math.floor(Math.random() * 1000000);
			const randomNum2 = Math.floor(Math.random() * 1000);
			const imageName = `contact_pic${randomNum2}${now.getTime()}${randomNum}`;

			imageUrl = await StorageService.saveContactUsImage(imageName, image);
		}
		const result = await httpsCallable.post("contactUs", {
			email,
			text,
			phone,
			image: imageUrl,
		});
		return result;
	}
}

export default new ContactsService();
