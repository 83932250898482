import firebase from "firebase/app";
import ipHelper from "helpers/ip";
import AppService from "services/app";
import { isMobile } from "react-device-detect";
class HttpsCallable {
	getCookie(name) {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(";").shift();
	}
	async post(type, data) {
		let info = ipHelper.getInfoFromStore();
		if (!info?.platform) {
			//info = await ipHelper.getInfo();
			const platform = await AppService.getPlatform();
			info = { platform };
		}
		info = { ...info, isMobile };
		const functions = firebase.functions();
		const affId = this.getCookie("affId");
		try {
			data.type = type;
			data.ip = info ? info?.ip : "";
			data.info = info ?? "";
			data.affId = affId ?? "";

			const result = await functions.httpsCallable("httpsCall")(data);
			return result;
		} catch (e) {
			console.log(type, data);
			console.log(e);

			return { error: "error" };
		}
	}
}

export default new HttpsCallable();
