import SubscriptionLimits from "./subscriptionLimits";
import Subscription from "./subscription";

export default {
  hidePhoneLoginButton: false,
  showMaintenanceScreen: false,
  algoliaDefaultRadius: 5000000,
  showFeedbackBtn: false,
  massletterPeriodDays: 30,
  minAge: 18,
  minDistance: 5000,
  appDetails: {
    userMustUpdateFromStore: false,
    versions: {
      ios: null,
      android: null,
    },
  },
  subscriptionsIsOn: false,
  subscriptionLimits: {
    [SubscriptionLimits.ShowContacts]: {
      [Subscription.NONE]: false,
      [Subscription.FREE]: true,
      [Subscription.PAID]: true,
    },
    [SubscriptionLimits.SendLikeDislikePerDay]: {
      [Subscription.NONE]: { TYPE1: 5, TYPE2: 4 },
      [Subscription.FREE]: { TYPE1: 1000, TYPE2: 1000 },
      [Subscription.PAID]: { TYPE1: 1000, TYPE2: 1000 },
    },
  },
  getFreeSubAfterFillCreditDetails: {
    enabled: true,
    interfaces: ["popover"],
    periodDays: 30,
    subscriptionPlanID: "26",
    availableHourAtDayFrom: 20,
    availableHourAtDayTo: 4,
  },
  subscriptionCampaign: {
    enabled: false,
    campainKey: "",
    interfaces: ["popover", "chat"],
    subscriptionPlanID: "31",
    periodDays: 1,
    periodCancelTimes: 6,
    showDateFromTimeStamp: 0,
    showDateToTimeStamp: 0,
    profileFilters: {
      gender: [1],
      dobFrom: 34345,
      dobTo: 34345,
      maritalStatus: [1, 2],
    },
  },
  translationLastUpdatedTimeStamp: null,
  blockNewChatByAge: {
    maleMinPrecent: 16.5,
    maleMinNumber: 9, // years
    maleMaxPrecent: 16.5,
    maleMaxNumber: 0, // years
  },
  manualRatings: {
    defaultApproveImage: 2,
    defaultNewUser: 1,
    maxStars: 5,
    searchFilterBottom: 2,
    searchFilterUpper: 1,
    chatBlockUpper: 1,
  },
  showNativeAds: false,
  tags: {
    max: 50,
  },
};
