import { createSelector } from "reselect";

export const getDialogs = (state) => state.dialogs;

export const getDialog = (name) => {
	return createSelector(getDialogs, (dialogs) => dialogs.find((dialog) => dialog.type === name));
};

export const closeAllDialogs = () => ({
	type: "closeAllDialogs",
	payload: "",
});
export const closeDialogByType = (dialog = "") => ({
	type: "closeDialog",
	payload: dialog,
});
export const openDialog = (obj) => ({
	type: "openDialog",
	payload: obj,
});
