import { Box, CircularProgress, TextField } from "@mui/material";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useRef } from "react";
import { useState } from "react";
import functionsService from "services/functionsService";

export default function SendReport({ closeDrawer = () => {}, colleagueId, type }) {
	const [report, setReport] = useState();
	const [isSend, setIsSend] = useState(false);
	const textRef = useRef();

	const send = async () => {
		if (!colleagueId) return;
		let reportToSend = "";
		if (!!report) {
			reportToSend = report.trim();
		}
		textRef.current.focus();
		setIsSend(true);
		setReport("");
		await functionsService.reportUser(colleagueId, type, reportToSend);
		setIsSend(false);
		closeDrawer();
	};

	return (
		<Box>
			<Box className="">
				<TextField
					className="sendTxt"
					style={{ width: "100%", background: "#fff" }}
					value={report}
					placeholder={i18next.t("reportUser.detail")}
					variant="standard"
					onChange={(e) => setReport(e.target.value)}
					inputRef={textRef}
					multiline
					maxRows={5}
				/>
			</Box>
			<Box className="wrapBtn">
				<Button
					className="sendButton"
					onClick={send}
					sx={{
						background: isSend ? "#fff" : "#525bc2 !important",
					}}>
					{!isSend ? (
						<span style={{ color: "#fff", padding: "0 15px", fontSize: "18px" }}>
							{i18next.t("reportUser.sendReport")}
						</span>
					) : (
						<CircularProgress
							size=""
							sx={{
								color: "#525bc2",
								width: "30px",
								height: "30px",
							}}
						/>
					)}
				</Button>
			</Box>
		</Box>
	);
}
