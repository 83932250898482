import React, { useEffect } from "react";
import { getShowMassLetterPopup } from "redux/massLetter/selectors";
import { useSelector, useDispatch } from "react-redux";
import usePopups from "hooks/usePopups";

export default () => {
	const isShowMassLetterPopup = useSelector(getShowMassLetterPopup);
	const { showMassletterPopup } = usePopups();

	useEffect(() => {
		if (isShowMassLetterPopup) {
			showMassletterPopup();
		}
	}, [isShowMassLetterPopup]);

	return <></>;
};
