import "assets/scss/infoTalent/infoTalent.scss";
import { Box } from "@mui/material";
import docsIcon from "assets/images/svgs/info-docs.svg";
import locationIcon from "assets/images/svgs/info-location.svg";
import priceIcon from "assets/images/svgs/info-price.svg";
import { useEffect } from "react";
import { useState } from "react";
import i18next from "i18next";

export default function InfoTalent({ user = null, isViewTalent = false }) {
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		const userInfoObj = {};
		if (user?.city_he) {
			userInfoObj.city_he = user?.city_he;
		}
		if (user?.pricePerHour) {
			userInfoObj.pricePerHour = user?.pricePerHour;
		}
		setUserInfo(userInfoObj);
	}, [user?.city_he, user?.pricePerHour]);

	if (!user) return <></>;

	return (
		<>
			{!!Object.entries(userInfo || {})?.length && (
				<Box className={`wrapInfoDetails ${!!isViewTalent ? "columnInfo" : ""}`}>
					{!!userInfo &&
						Object.entries(userInfo || {}).map(([key, value], index) => (
							<>
								{!isViewTalent ? (
									<Box key={key} className={`wrapInfoData ${index >= 1 && "separator"}`}>
										{key == "city_he" ? <img src={locationIcon} /> : <img src={priceIcon} />}
										{key == "pricePerHour" ? (
											<span className="textInfoData">{`${i18next.t(
												"talent.pricePerHour"
											)}: ${value} ש"ח`}</span>
										) : (
											<span className="textInfoData">{value}</span>
										)}
									</Box>
								) : (
									<Box key={key} className={`wrapInfoData`}>
										{key == "city_he" ? <img src={locationIcon} /> : <img src={priceIcon} />}
										{key == "pricePerHour" ? (
											<span className="textInfoData">{`${i18next.t(
												"talent.pricePerHour"
											)}: ${value} ש"ח`}</span>
										) : (
											<span className="textInfoData">{value}</span>
										)}
									</Box>
								)}
							</>
						))}
				</Box>
			)}
		</>
	);
}
