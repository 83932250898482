import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CategoriesService from "services/categoriesService";
import TagsCategory from "./TagsCategory";
import useQuery from "hooks/useQuery";
import backToCategoriesIcon from "assets/images/svgs/category/backToCategories.svg";
import heartPin from "assets/images/talent/searchCategory/heartPin.png";
import heartPinWhite from "assets/images/talent/searchCategory/heartPinWhite.png";

import { FreeMode, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { addFiltersSelected } from "redux/search/actions";
import { getPopularTags } from "redux/tags/selectors";
import { getCategoryImg } from "helpers/getCategoryImg";
import { isMobile } from "react-device-detect";

export default ({
	webPage = false,
	tagsOpen = false,
	handleCategoryChange = () => {},
	setTagsOpen = () => {},
}) => {
	const dispatch = useDispatch();

	const popularTags = useSelector(getPopularTags());

	const { params } = useQuery();
	const [categories, setCategories] = useState();
	const [categorySelected, setCategorySelected] = useState();
	const [sortedCategory, setSortedCategory] = useState([]);
	const [defaultTagsCategory, setDefaultTagsCategory] = useState([]);

	const getImageByKey = (key) => {
		return getCategoryImg(key, !isMobile);
	};
	const handleSelectCategory = (category) => {
		setDefaultTagsCategory([]);
		if (category.key == categorySelected) {
			setCategorySelected(null);
			return dispatch(
				addFiltersSelected({ filters: { category: "", freeText: [] }, research: true })
			);
		}

		setCategorySelected(category.key);
		dispatch(
			addFiltersSelected({
				filters: { category: category.urlName, freeText: [] },
				research: true,
			})
		);
	};

	const handleTagSelected = (tags = []) => {
		dispatch(addFiltersSelected({ filters: { freeText: tags }, research: true }));
	};

	const getCategoryKeyByUrl = (categories = [], urlParam) => {
		if (categories && categories.length && urlParam) {
			return categories
				.filter((cat) => {
					return cat.urlName === urlParam;
				})
				.map((cat) => cat.key)[0];
		}
	};

	useEffect(() => {
		const getCategories = async () => {
			const categoryData = await CategoriesService.getCategories();
			setCategories(categoryData);
		};
		getCategories().catch((error) => console.error(error));
	}, []);

	useEffect(() => {
		if (categories && categories.length) {
			const categoryUrl = params.category ?? "";
			const categoryKey = getCategoryKeyByUrl(categories, categoryUrl);
			if (!categoryKey || !categorySelected) setCategorySelected(categoryKey);
		}
	}, [params.category, categories]);

	useEffect(() => {
		if (categories) {
			if (params?.category) {
				let find = [categories.find((category) => category?.urlName === params?.category)];
				if (!find[0]) find = [];
				const filteredCategories = categories
					.filter(
						(category) => category?.urlName !== params?.category && category?.deleted !== true
					)
					.sort((a, b) => {
						return a.priority - b.priority;
					});
				setSortedCategory([...find, ...filteredCategories]);
			} else {
				setSortedCategory(
					categories
						.filter((category) => category?.deleted !== true)
						.sort((a, b) => {
							return a.priority - b.priority;
						})
				);
			}
		}
	}, [categories]);

	useEffect(() => {
		handleCategoryChange();
	}, [categorySelected]);

	useEffect(() => {
		if (params?.freeText) {
			const tagsUrl = params.freeText ?? "";
			const tags = tagsUrl.split(",");
			if (tags) setDefaultTagsCategory(tags);
		}
	}, [params.freeText]);

	return (
		<>
			<Box className="searchCategory">
				{!webPage ? (
					<>
						<Swiper
							slidesPerView={5.5}
							spaceBetween={0}
							pagination={{
								clickable: true,
							}}
							modules={[FreeMode, Pagination]}
							className="mySwiper">
							{!!sortedCategory?.length &&
								sortedCategory.map((category) => {
									return (
										<SwiperSlide>
											<button
												key={category.key}
												onClick={() => handleSelectCategory(category)}
												className={`wrapCategoryBtn ${
													category.key !== categorySelected ? "wrapUnpicCategory" : ""
												}`}
												outline
												style={{ border: "0", background: "#ffffff00" }}
												color="primary">
												<Box
													className="center "
													sx={{
														textAlign: "center",
														flexDirection: "column",
														gap: "8px",
													}}>
													<Box className="center imgCategory">
														<img
															key={category.key}
															src={getImageByKey(category.key)}
															alt=""
															height="34px"
														/>
													</Box>

													{category.key !== categorySelected ? (
														<span>{category.name}</span>
													) : (
														<h1>{category.name}</h1>
													)}
												</Box>
											</button>
										</SwiperSlide>
									);
								})}
						</Swiper>
						{categorySelected && (
							<Box className="tagsCategory">
								<TagsCategory
									setTagsOpen={setTagsOpen}
									defaultTagsCategory={defaultTagsCategory}
									webPage={webPage}
									tagsOpen={tagsOpen}
									category={categorySelected}
									onTagSelected={handleTagSelected}></TagsCategory>
							</Box>
						)}
					</>
				) : (
					<>
						{!!categorySelected && (
							<>
								<Box
									onClick={() => {
										setCategorySelected(null);
										setDefaultTagsCategory([]);
										dispatch(
											addFiltersSelected({
												filters: { category: "", freeText: [] },
												research: true,
											})
										);
									}}
									className="center backToCategories">
									<img src={backToCategoriesIcon} />
									<span>{i18next.t("Search.returnToCategories")}</span>
								</Box>
							</>
						)}

						{!!sortedCategory?.length &&
							sortedCategory.map((category) => {
								return (
									<>
										{!categorySelected ? (
											<button
												key={category.key}
												onClick={() => handleSelectCategory(category)}
												className={`wrapCategoryBtn ${
													category.key !== categorySelected ? "wrapUnpicCategory" : ""
												}`}
												outline
												style={{ border: "0", background: "#ffffff00" }}
												color="primary">
												<Box
													className="center"
													sx={{
														textAlign: "center",
														flexDirection: "column",
														maxWidth: "56px",
														gap: "12px",
													}}>
													<Box className={`center imgCategory`}>
														<img key={category.key} src={getImageByKey(category.key)} alt="" />
													</Box>
													<span>{category.name}</span>
												</Box>
											</button>
										) : (
											<>
												{categorySelected == category.key && (
													<button
														key={category.key}
														onClick={() => handleSelectCategory(category)}
														className={`wrapCategoryBtn ${
															category.key !== categorySelected ? "wrapUnpicCategory" : ""
														}`}
														outline
														style={{ border: "0", background: "#ffffff00" }}
														color="primary">
														<Box
															className="center"
															sx={{
																textAlign: "center",
																flexDirection: "column",
																maxWidth: "56px",
																gap: "12px",
															}}>
															<Box className={`center imgCategory`}>
																<img key={category.key} src={getImageByKey(category.key)} alt="" />
															</Box>
															<h1>{category.name}</h1>
														</Box>
													</button>
												)}
											</>
										)}
									</>
								);
							})}

						{categorySelected && (
							<Box className="tagsCategory">
								<TagsCategory
									defaultTagsCategory={defaultTagsCategory}
									webPage={webPage}
									tagsOpen={tagsOpen}
									category={categorySelected}
									onTagSelected={handleTagSelected}></TagsCategory>
							</Box>
						)}
					</>
				)}
				{!!webPage && !categorySelected && (
					<Box className="popularTags">
						<h2>{i18next.t("Search.popularTagsInSite")}</h2>
						{!!popularTags && !!popularTags?.length && (
							<Box className="popularTagsContent">
								{popularTags.map((tag) => (
									<button
										className={`${defaultTagsCategory.includes(tag.name) ? "selectedTag" : ""}`}
										key={tag.key}
										type="button"
										onClick={() => handleTagSelected([tag.name])}>
										{tag.name}
									</button>
								))}
							</Box>
						)}
					</Box>
				)}
			</Box>
		</>
	);
};
