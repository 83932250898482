import "assets/scss/chat/chatWeb.scss";

import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import { useSelector } from "react-redux";
import ChatList from "components/chat/chatList/ChatList";
import ChatConversation from "components/chat/chatConversation/ChatConversation";
import ViewTalent from "components/talent/ViewTalent";
import UsersTypes from "consts/UsersTypes";
import { useEffect, useState } from "react";

export default () => {
	const { userProfile } = useUser();
	const selectedChat = useSelector((state) => state.chats.selectedChat);
	const [uid, setUid] = useState();

	useEffect(() => {
		setUid(selectedChat?.user?.uid);
	}, [selectedChat?.user?.uid]);

	return (
		<>
			<Box>
				{userProfile.uid && (
					<Box className="wrapChatWebPage">
						<Box className="chatWrapper borderColumns chatColl1">
							<ChatList></ChatList>
						</Box>
						{selectedChat?.user?.uid && (
							<>
								<Box
									className={
										selectedChat?.user?.type !== UsersTypes.Talent
											? "chatWrapper borderColumns chatColl2 fullWidthChat"
											: "chatWrapper borderColumns chatColl2"
									}>
									{selectedChat && (
										<ChatConversation selectedChat={selectedChat} isWeb={true}></ChatConversation>
									)}
								</Box>
								{selectedChat?.user?.type == UsersTypes.Talent && (
									<Box className="borderColumns viewTalentChat chatColl3">
										{uid == selectedChat.user.uid && (
											<ViewTalent talentId={selectedChat.user.uid} isChatWeb={true} />
										)}
									</Box>
								)}
							</>
						)}
					</Box>
				)}
			</Box>
		</>
	);
};
