import ChatListItem from "./ChatListItem";

export default ({ list, editChats }) => {
	return (
		<>
			{!!list &&
				!!list.length &&
				list
					.filter((item) => !item.deleted)
					.map((item) => (
						<ChatListItem editChats={editChats} key={item.key} item={item}></ChatListItem>
					))}
		</>
	);
};
