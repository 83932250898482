import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import MigrationStatusEnum from "consts/migrationStatus";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.MIGRATION_CHECK:
			return {
				status: MigrationStatusEnum.CHECKING,
			};
		case types.MIGRATION_NO_USER:
			return {
				status: MigrationStatusEnum.NOT_EXIST,
			};
		case types.MIGRATION_RUN_IN_PROGRESS:
			return {
				status: MigrationStatusEnum.IN_PROGRESS,
			};
		case types.MIGRATION_RUN_DONE:
			return {
				status: MigrationStatusEnum.DONE,
			};
		case authTypes.AUTH_LOG_OUT:
			return initialState;
		default:
			return state;
	}
}, initialState);
