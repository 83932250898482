import { combineReducers } from "redux";

import chat from "./chat/reducer";
import chatReceived from "./chatReceived/reducer";
import chatReceivedFiltered from "./chatReceivedFiltered/reducer";
import chatSent from "./chatSent/reducer";
import publicProfiles from "./publicProfiles/reducer";
import chatMeta from "./chatMeta/reducer";
import blocks from "./blocks/reducer";
import likeMatch from "./likeMatch/reducer";
import presence from "./presence/reducer";
import chatMessages from "./chatMessages/reducer";
import subscription from "./subscription/reducer";
import system from "./system/reducer";
import likes from "./likes/reducer";
import skips from "./skips/reducer";
import userProfile from "./profile/reducer";
import general from "./general/reducer";
import promotions from "./promotions/reducer";
import auth from "./auth/reducer";
import galleryModal from "./galleryModal/reducer";
import popups from "./popups/reducer";
import qrcode from "./qrcode/reducer";
import likedMe from "./likedMe/reducer";
import search from "./search/reducer";
import matches from "./matches/reducer";
import massletter from "./massLetter/reducer";
import viewedMe from "./viewedMe/reducer";
import views from "./views/reducer";
import letPictures from "./letPictures/reducer";
import letPicturesMe from "./letPicturesMe/reducer";
import migration from "./migration/reducer";
import init from "./init/reducer";
import tags from "./tags/reducer";
import notification from "./notification/reducers";
import chats from "./chats/reducer";
import dialogs from "./dialog/reducers";
import clientInfo from "./clientInfo/reducer";
import contacts from "./contacts/reducer";

export default combineReducers({
	dialogs,
	notification,
	auth,
	tags,
	chat,
	chatReceived,
	chatReceivedFiltered,
	chatSent,
	publicProfiles,
	chatMeta,
	blocks,
	likeMatch,
	presence,
	chatMessages,
	subscription,
	system,
	likes,
	skips,
	userProfile,
	general,
	promotions,
	galleryModal,
	popups,
	qrcode,
	likedMe,
	search,
	matches,
	massletter,
	viewedMe,
	views,
	letPictures,
	letPicturesMe,
	migration,
	init,
	chats,
	clientInfo,
	contacts,
});
