import { mergeMap, catchError, map, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import * as types from "./actionTypes";
import BaseRegisterEvent from "consts/baseRegisterEvents";
import AuthPhoneService from "services/authPhone";
import AuthAppleService from "services/authApple";
import ProfileService from "services/profile";
import QueueService from "services/queue";
import AuthEvents from "consts/authEvents";
import FacebookService from "services/authFacebook";
import AuthGoogleService from "services/authGoogle";
import HotjarService from "services/hotjar";
import { getLoggedInUserUID } from "./selectors";
import { getUserProfile } from "../profile/selectors";
import logsEvents, { AuthLogTypes } from "consts/logsEvents";
import httpsCallable from "services/httpsCallable";

export const loginWithFacebook = (action$) =>
	action$.pipe(
		ofType(types.AUTH_REGISTER_WITH_FACEBOOK),
		mergeMap(async () => {
			try {
				const payload = await FacebookService.login();
				return {
					type: types.AUTH_REGISTER_WITH_FACEBOOK_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.AUTH_LOGIN_WITH_FACEBOOK_FAILED,
					error: error?.message,
					email: error?.email,
				};
			}
		}),
		catchError(() => {
			return of({
				type: types.AUTH_MIGRATION_NO_USER,
			});
		}),
		repeat()
	);

export const loginWithToken = (action$) =>
	action$.pipe(
		ofType(types.AUTH_LOGIN_WITH_TOKEN),
		mergeMap(({ token }) => {
			return AuthPhoneService.linkWithToken(token).pipe(
				map(() => {
					return {
						type: types.AUTH_LOGIN_WITH_TOKEN_SUCCESSFULLY,
					};
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_LOGIN_WITH_PHONE_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);

export const loginWithPhone = (action$) =>
	action$.pipe(
		ofType(types.AUTH_LOGIN_WITH_PHONE),
		mergeMap(({ phone }) => {
			return AuthPhoneService.linkPhone(phone).pipe(
				map(() => {
					return {
						type: types.AUTH_LOGIN_WITH_PHONE_WAITING_FOR_VERIFICATION,
					};
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_LOGIN_WITH_PHONE_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);

export const verifyPhone = (action$, store) =>
	action$.pipe(
		ofType(types.AUTH_LOGIN_WITH_PHONE_VERIFICATION),
		mergeMap(async ({ verificationCode, typeUser }) => {
			try {
				const authUser = await AuthPhoneService.verifyCode(verificationCode);
				if (authUser.error) throw new Error(authUser.error);
				const userId = authUser.user.uid;
				const isUserExists = await ProfileService.isUserProfileExists(userId);
				console.log(typeUser);
				if (!isUserExists) {
					httpsCallable.post("register", {
						extraData: {
							method: "phone",
							phone: authUser.user.phoneNumber,
							typeUser,
						},
					});
				}
				return {
					type: types.AUTH_LOGIN_WITH_PHONE_VERIFICATION_SUCCESSFULLY,
				};
			} catch (error) {
				return {
					type: types.AUTH_LOGIN_WITH_PHONE_VERIFICATION_FAILED,
					error: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_LOGIN_WITH_PHONE_VERIFICATION_FAILED,
				payload: error?.message,
			});
		})
	);

export const resendCode = (action$) => {
	return action$.pipe(
		ofType(types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE),
		mergeMap(({ phone }) => {
			return AuthPhoneService.linkPhone(phone).pipe(
				map(() => {
					return {
						type: types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE_SUCCESSFULLY,
					};
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE_FAILED,
				error: error?.message,
			});
		})
	);
};

export const listenToAuthChange = (action$, store) => {
	return action$.pipe(
		ofType(types.AUTH_APP_INIT),
		mergeMap(() => {
			return AuthPhoneService.listenAuthChange().pipe(
				map((user) => {
					const state = store.value;
					const storedUser = state.auth.user;
					if (!user) {
						return !storedUser
							? { type: types.AUTH_LOGGED_OUT, user }
							: { type: types.AUTH_LOG_OUT, user: storedUser };
					} else if (!storedUser || storedUser.uid !== user.uid) {
						QueueService.sendEvent(user.uid, AuthEvents.Connect);
						return { type: types.AUTH_LOGGED_IN, user };
					} else {
						return { type: types.AUTH_ALREADY_LOGGED_IN };
					}
				})
			);
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_CHANGE_FAILED,
				error: error?.message,
			});
		})
	);
};

export const initHotjar = (action$, store) => {
	return action$.pipe(
		ofType(types.AUTH_LOGGED_IN, types.AUTH_ALREADY_LOGGED_IN),
		map(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			HotjarService.init(uid);
			return { type: types.AUTH_HOTJAR_INIT_SUCCESSFULLY };
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_HOTJAR_INIT_FAILED,
				error: error?.message,
			});
		})
	);
};

export const loginWithGoogle = (action$) =>
	action$.pipe(
		ofType(types.AUTH_REGISTER_WITH_GOOGLE),
		mergeMap(async () => {
			try {
				const res = await AuthGoogleService.login();
				const user = {
					name: res.googleData.given_name,
					email: res.googleData.email,
					auth_userId: res.googleToken,
				};
				return {
					type: types.AUTH_REGISTER_WITH_GOOGLE_SUCCESSFULLY,
					user,
				};
			} catch (error) {
				return {
					type: types.AUTH_REGISTER_WITH_GOOGLE_FAILED,
					error: error?.message,
					email: error?.email,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_REGISTER_WITH_GOOGLE_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);

export const logAuthErrors = (action$, store) => {
	return action$.pipe(
		ofType(
			types.AUTH_LOGIN_WITH_FACEBOOK_FAILED,
			types.AUTH_LOGIN_WITH_PHONE_FAILED,
			types.AUTH_REGISTER_WITH_GOOGLE_FAILED,
			types.AUTH_LOGIN_WITH_PASSWORD_FAILED
		),
		map(({ error, type, credentials, email }) => {
			const state = store.value;
			const userUid = getLoggedInUserUID(state) || null;
			const profile = getUserProfile(state);
			let logType;
			switch (type) {
				case types.AUTH_LOGIN_WITH_FACEBOOK_FAILED:
					logType = AuthLogTypes.FacebookAuthError;
					break;
				case types.AUTH_REGISTER_WITH_GOOGLE_FAILED:
					logType = AuthLogTypes.GoogleAuthError;
					break;
				case types.AUTH_LOGIN_WITH_PHONE_FAILED:
					logType = AuthLogTypes.PhoneAuthError;
					break;
				case types.AUTH_LOGIN_WITH_PASSWORD_FAILED:
					logType = AuthLogTypes.PasswordAuthError;
					break;
			}
			const extraData = { errMsg: error, type: logType };
			if (credentials) {
				extraData.credentials = credentials;
			}
			if (email) {
				extraData.email = email;
			}
			if (profile && profile.phone) {
				extraData.phone = profile.phone;
			}
			QueueService.sendEvent(userUid, logsEvents.SaveToLog, extraData);
			return {
				type: types.AUTH_LOG_AUTH_ERROR_SENT_SUCCESSFULLY,
			};
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_LOG_AUTH_ERROR_SENT_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);
};

export const loginWithApple = (action$) =>
	action$.pipe(
		ofType(types.AUTH_REGISTER_WITH_APPLE),
		mergeMap(async () => {
			try {
				const res = await AuthAppleService.login();
				const user = {
					name: res.data.given_name,
					email: res.data.email,
					auth_userId: res.token,
				};
				return {
					type: types.AUTH_REGISTER_WITH_APPLE_SUCCESSFULLY,
					user,
				};
			} catch (error) {
				return {
					type: types.AUTH_REGISTER_WITH_APPLE_FAILED,
					error: error?.message,
					email: error?.email,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.AUTH_REGISTER_WITH_APPLE_FAILED,
				error: error?.message,
			});
		}),
		repeat()
	);
