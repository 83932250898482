import { Box } from "@mui/material";
import "assets/scss/landing/landingColorizedTitle.scss";
import i18next from "i18next";
import daysFlatLogo from "assets/images/svgs/landing/6daysFlatLogo.svg";

export default function LandingColorizedTitle({ text = "", colorText = "" }) {
	return (
		<Box className="landingColorizedTitle">
			{/* <Box className="sixDays">{i18next.t("landingPage.sixDays")}</Box> */}
			<Box className="sixDays">
				<img src={daysFlatLogo} />
			</Box>
			<Box>
				{text ? `${text} ` : ""}
				<span className="colorText">{`${colorText}`}</span>
			</Box>
		</Box>
	);
}
