import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn, getIsAuthChecked } from "redux/auth/selectors";
import { fetchPublicProfile, reFetchPublicProfile } from "redux/publicProfiles/actions";
import { getUserProfile, getIsCompleteRegister } from "redux/profile/selectors";
import ProfileService from "services/profile";
import UserGeneralService from "services/userGeneral";
import registerSteps from "constants/registerSteps";
import { getUserGeneral } from "redux/general/selectors";
import httpsCallable from "services/httpsCallable";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default function useUser() {
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isCompleteRegister = useSelector(getIsCompleteRegister);

	const isAuthChecked = useSelector(getIsAuthChecked);
	const userProfile = useSelector(getUserProfile);
	const userGeneral = useSelector(getUserGeneral);
	const user = { ...userProfile, ...userGeneral };

	const saveAccountProfile = async (name, emailAccount) => {
		const updateName = ProfileService.updateProfile(userProfile.uid, { name });
		const updateEmail = UserGeneralService.updateGeneral(userProfile.uid, { emailAccount });
		await Promise.all([updateName, updateEmail]);
	};
	const setUserType = async (type, registerStep = registerSteps[2]) => {
		const userUid = userProfile.uid;
		await ProfileService.updateProfile(userUid, { type, registerStep });
		dispatch(reFetchPublicProfile(userUid));
	};

	const sendEmailCode = async (email) => {
		try {
			const extraData = {
				name: "email",
				email,
			};
			const result = await httpsCallable.post("sendVerificationEmailCode", {
				extraData,
			});

			return result;
		} catch (error) {
			console.log(error);
		}
	};
	const verifyCode = async (code) => {
		try {
			const extraData = {
				code,
			};
			const result = await httpsCallable.post("verifyEmailCode", {
				extraData,
			});

			return result;
		} catch (error) {
			console.log(error);
		}
	};

	const registerStep = async (data, step, type = "register") => {
		const userUid = userProfile.uid;
		if (type === "register") {
			data.registerStep = registerSteps[step + 1];
		}

		const extraData = {
			data: data,
			step,
			mode: type,
		};
		const result = await httpsCallable.post("registerStep", {
			extraData,
		});

		//await ProfileService.updateProfile(userUid, data);
		dispatch(reFetchPublicProfile(userUid));
		return result;
	};

	const uploadResume = async (fileData) => {
		const userUid = userProfile.uid;
		return await ProfileService.addResume(userUid, fileData, false);
	};

	const uploadFileField = async (fileData, fieldName) => {
		const userUid = userProfile.uid;
		return await ProfileService.addFile(userUid, fileData, false, fieldName);
	};

	const deleteFileField = async (uid, name, fieldName) => {
		const userUid = userProfile.uid;
		return await ProfileService.deleteFile(uid, name, fieldName);
	};

	const deleteResume = async (name) => {
		const userUid = userProfile.uid;
		return await ProfileService.deleteResume(userUid, name);
	};

	const getProfileImage = (profile) => {
		const url = profile?.profileImage?.url ?? getFirstCharImg(profile);
		return url;
	};

	return {
		uploadFileField,
		deleteFileField,
		isLoggedIn,
		isCompleteRegister,
		user,
		isAuthChecked,
		userProfile,
		userGeneral,
		setUserType,
		deleteResume,
		registerStep,
		uploadResume,
		getProfileImage,
		saveAccountProfile,
		sendEmailCode,
		verifyCode,
	};
}
