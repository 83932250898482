import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "redux/auth/selectors";
import LoginEnterPhonePage from "./LoginEnterPhonePage";
import LoginPhoneVerificationPage from "./LoginPhoneVerificationPage";
import Register from "./Register";
import useUser from "hooks/useUser";
import _ from "lodash";

import { closeDialogByType, getDialogs } from "redux/dialog/selectors";
import useNavigator from "hooks/useNavigator";
import dialogsTypes from "consts/dialogsTypes";
import { Box } from "@mui/system";

export default function LoginAndRegisterPage({ goToTalentRegister = false, platform = "web" }) {
	const dispatch = useDispatch();
	const navigator = useNavigator();
	const { isCompleteRegister } = useUser();

	const isLoggedIn = useSelector(isUserLoggedIn);
	const { dialogs } = useSelector(getDialogs);

	const [loginStep, setLoginStep] = useState(1);

	useEffect(() => {
		if (isCompleteRegister) {
			const loginDialogData = dialogs.find((element) => element.dialog === dialogsTypes.Login);
			if (dialogs && !!loginDialogData) {
				if (loginDialogData?.actAfterLogin) loginDialogData.actAfterLogin();

				dispatch(closeDialogByType(dialogsTypes.Login));
			} else {
				navigator.navigateToHomePage();
			}
		}
	}, [isCompleteRegister]);

	useEffect(() => {
		if (!!isLoggedIn && loginStep > 1 && platform !== "web") {
			navigator.navigateToPath("/openBusinessCardAppPage");
		}
	}, [isLoggedIn]);

	return (
		<>
			{isLoggedIn && <Register goToTalentRegister={goToTalentRegister} platform={platform} />}
			{!isLoggedIn && loginStep == 1 && (
				<LoginEnterPhonePage goToTalentRegister={goToTalentRegister} setLoginStep={setLoginStep} />
			)}
			{!isLoggedIn && loginStep == 2 && (
				<LoginPhoneVerificationPage
					goToTalentRegister={goToTalentRegister}
					setLoginStep={setLoginStep}
				/>
			)}
		</>
	);
}
