import { Box } from "@mui/system";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useNavigator from "hooks/useNavigator";
import recommendations from "services/recommendations";
import _ from "lodash";
import RecommendationBox from "components/talent/RecommendationBox";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";
import publicProfile from "services/publicProfile";
import UserImage from "components/talent/userImage";
import { useDispatch, useSelector } from "react-redux";
import dialogsTypes from "consts/dialogsTypes";
import { openDialog } from "redux/dialog/selectors";
import { getUserProfile } from "redux/profile/selectors";
import BaseHelmet from "components/seo/BaseHelmet";

export default function UserRecomendationIframePage({}) {
	const { id } = useParams();
	const [listMyRecomendation, setListMyRecomendation] = useState([]);
	const [recomendUser, setRecomendUser] = useState({});
	const currentUser = useSelector(getUserProfile) || {};

	const handleDelete = (key) => {
		setListMyRecomendation((prev) => {
			const newData = prev.filter((item) => item.key !== key);
			return newData;
		});
	};

	useEffect(async () => {
		if (id) {
			await publicProfile.fetchProfile(id).then((user) => setRecomendUser(user));
		}
	}, []);

	useEffect(async () => {
		const result = await recommendations.getMyRecommendations(id);
		if (_.isEmpty(result)) {
		} else {
			setListMyRecomendation(Object.values(result));
		}
	}, [id]);

	return (
		<>
			<BaseHelmet
				title={i18next.t("talent.recommendations")}
				description={
					i18next.t("talent.recomendationOf") + ` ${recomendUser.name ? recomendUser.name : ""}`
				}></BaseHelmet>
			<Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
				<Box
					sx={{
						width: "100%",
						color: "#168187",
						fontSize: "22px",
						fontWeight: "700",
						textAlign: "center",
						margin: "10px 0 ",
					}}>
					{currentUser?.uid == recomendUser?.uid ? (
						<Box>{i18next.t("talent.myRecomendation")}</Box>
					) : (
						<Box>
							{i18next.t("talent.recomendationOf")}
							{` ${recomendUser.name ?? ""}`}
						</Box>
					)}
				</Box>

				<Box className="recommendationWrapper" sx={{ margin: "20px" }}>
					<Box className="recommendationList">
						{!!listMyRecomendation?.length &&
							listMyRecomendation.map((item) => (
								<>
									<RecomendTo talentId={item.talentId} />
									<RecommendationBox
										onDelete={handleDelete}
										key={item.key}
										talentId={item.talentId}
										item={item}></RecommendationBox>
								</>
							))}
					</Box>
				</Box>
			</Box>
		</>
	);
}

export function RecomendTo({ talentId }) {
	const [user, setUser] = useState({});
	const dispatch = useDispatch();

	const openTalentPopup = () => {
		dispatch(openDialog({ dialog: dialogsTypes.ViewTalent, talentId }));
	};

	useEffect(async () => {
		await publicProfile.fetchProfile(talentId).then((user) => setUser(user));
	}, []);

	return (
		<Box className="center wrapIframe" sx={{ margin: "0", flexWrap: "wrap" }}>
			<Box
				sx={{ marginRight: "10px", fontSize: "17px", fontWeight: "500", cursor: "pointer" }}
				onClick={openTalentPopup}>
				<span>{i18next.t("talent.recomendOn")}</span>
				<span style={{ color: "#525bc2" }}>{` ${user?.name ?? ""}`}</span>
			</Box>
			<Box style={{ borderRadius: "50%", overflow: "hidden" }}>
				{!user?.uid ? (
					<CircularProgress size={20} sx={{ color: "#168187" }} />
				) : (
					<UserImage user={user} key={user.uid} onClick={openTalentPopup} />
				)}
			</Box>
			{!!user?.title && (
				<Box style={{ borderRadius: "20px", margin: "0 5px" }}>
					<span
						style={{
							margin: "2px 5px",
							border: 0,
							width: " max-content",
							color: "#5a5a5a",
							fontWeight: 400,
							fontSize: "16px",
							lineHeight: "24px",
							background: "rgba(56, 107, 168, 0.15)",
							borderRadius: "4px",
							textAlign: "right",
							padding: "0px 8px",
						}}>
						{user.title}
					</span>
				</Box>
			)}
		</Box>
	);
}
