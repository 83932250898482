import { Box } from "@mui/material";
import UploadImage from "components/login/UploadImage";
import profileFields from "consts/profileFields";
import useProfileImages from "hooks/useProfileImages";
import { useEffect } from "react";
import { useState } from "react";

export default ({}) => {
	const { saveNewImage } = useProfileImages();
	const [imageValue, setImageValue] = useState();
	const [loading, setLoading] = useState(false);

	const handleOnChangeImage = (file) => {
		setImageValue(file);
	};
	const handleOnValidityChange = () => {};

	const deleteImage = () => {};
	useEffect(async () => {
		if (imageValue) {
			setLoading(true);
			await saveNewImage(imageValue, true);
			setLoading(false);
		}
	}, [imageValue]);

	return (
		<Box className="wrapUploadImage" style={{ display: "flex", justifyContent: "center" }}>
			<UploadImage
				settingsPage={true}
				onChange={handleOnChangeImage}
				onValidityChange={handleOnValidityChange}
				profilePic={true}
				onDeleteImage={() => deleteImage()}
				loading={loading}
			/>
		</Box>
	);
};
