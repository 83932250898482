import "firebase/database";
import HttpsCallable from "./httpsCallable";

class StatisticsService {
	getTimestampSevenDaysAgo() {
		const currentDate = new Date();
		const sevenDaysAgo = new Date(currentDate);
		sevenDaysAgo.setDate(currentDate.getDate() - 7);

		return sevenDaysAgo.getTime();
	}

	async searchTalentActions(lastWeek = false) {
		let lastWeekObj = {};
		if (!!lastWeek) {
			lastWeekObj["fromCreatedDate"] = this.getTimestampSevenDaysAgo();
			lastWeekObj["toCreatedDate"] = Date.now();
		}

		return await HttpsCallable.post("searchTalentActions", {
			extraData: {
				...lastWeekObj,
			},
		});
	}
}

export default new StatisticsService();
