import firebase from "firebase/app";
import "firebase/database";

class ProfileExtraFieldsService {
	BASE_REF = "profile_extra_fields";

	async fetchExtraFields() {
		return (await firebase.database().ref(this.BASE_REF).once("value")).val() || {};
	}
}

export default new ProfileExtraFieldsService();
