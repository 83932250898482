import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class UserChatSentService {
	BASE_REF = "chat_sent";

	async fetchChatSent(uid) {
		return (await firebase.database().ref(this.BASE_REF).child(uid).once("value")).val() || {};
	}

	listenChatSentUpdates(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => {
					return subscriber.next({ [snapshot.key]: snapshot.val() });
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenChatSentAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => {
					return subscriber.next({ [snapshot.key]: snapshot.val() });
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	listenChatSentRemoved(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => subscriber.next(snapshot.key),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}
}

export default new UserChatSentService();
