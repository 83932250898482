import randomstring from "randomstring";

import "assets/scss/editImages/editImages.scss";
import { Box, CircularProgress } from "@mui/material";
import Button from "components/ui/Button";
import i18next from "i18next";

import { useEffect, useRef, useState } from "react";
import usePopups from "hooks/usePopups";
import CropImageTypes from "consts/cropImageTypes";
import getImageBase64FromURl from "helpers/getImageBase64FromURl";

import defaultProfile from "assets/images/svgs/darkAvatar.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";

import useProfileImages from "hooks/useProfileImages";
import { async } from "validate.js";
import profileFields from "consts/profileFields";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ProfileService from "services/profile";
import { useDispatch } from "react-redux";
import dialogsTypes from "consts/dialogsTypes";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import { TransformImg } from "helpers/cloudinary";
import { updateQRCodeHeaderText } from "redux/qrcode/actions";
import Loader from "components/Loader";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";

export default function EditImages({ user, handleopenLightBox, handleArrImages, handleImgIndex }) {
	const refUploadInput = useRef();
	const [inProgress, setInProgress] = useState(false);
	const [arrImages, setArrImages] = useState([]);
	const [codes, setCodes] = useState([]);

	const [arrImagesLightBox, setArrImagesLightBox] = useState([]);
	const { saveNewImage } = useProfileImages();
	const { showCropImagePopup } = usePopups();
	const dispatch = useDispatch();
	const MAX_IMAGES = 9;

	const reSizeImages = (imgUrl) => {
		return TransformImg(imgUrl, 370, 370);
	};
	const openPopupImg = (data) => {
		if (arrImagesLightBox.length) {
			handleArrImages(arrImagesLightBox);
			handleImgIndex(data.index);
			handleopenLightBox(true);
		}
	};
	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};
	const deleteImage = (e, imgKey) => {
		e.stopPropagation();
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: `האם אתה רוצה למחוק את התמונה?`,
				onYes: async () => {
					toggelClose();
					await ProfileService.deleteImage(user.uid, imgKey);
				},
				onNo: toggelClose,
			})
		);
	};
	const editImage = async (e, data) => {
		e.stopPropagation();
		let img = data.sourceUrl
			? data.sourceUrl.includes("http")
				? data.sourceUrl
				: codes[data.sourceUrl]
			: "";
		showCropImagePopup({
			image: img,
			cropImageType: CropImageTypes.EditedImage,
			cropImageName: data.name,
		});
	};
	const addImage = async (e) => {
		e.stopPropagation();
		refUploadInput.current.click();
	};
	const handleSaveNewImage = async (e) => {
		setInProgress(true);
		await saveNewImage(e);
		setInProgress(false);
	};

	useEffect(() => {
		if (user && user.pictures) {
			const newArr = [];
			Object.values(user.pictures || {}).map((item, index) => {
				let obj = { ...item };
				obj.url = reSizeImages(item.url);
				obj.sourceUrl = item.url;
				obj.index = index;
				newArr.push(obj);
			});
			setArrImages(newArr.filter((img) => img.status !== 2)); //imgStatus == 2 (=reject)
		} else {
			setArrImages([]);
		}
	}, [user?.pictures]);

	useEffect(() => {
		if (user && user.pictures) {
			const newArr = [];
			Object.values(user.pictures).map((item, index) => {
				const resizeUrl = reSizeImages(item.url);
				newArr.push(resizeUrl);
			});
			setArrImagesLightBox(newArr);
		}
	}, [arrImages]);

	const handleOnInProgress = (value) => {
		setInProgress(value);
	};

	const setCodesFiles = (codes) => {
		setCodes((oldValue) => {
			return { ...oldValue, ...codes };
		});
	};

	// const gridAddImgWrapper =
	// 	arrImages?.length % 3 == 0 ? "gridAll" : arrImages?.length % 3 == 1 ? "gridTwoOfAll" : "";

	return (
		<>
			{arrImages?.length < MAX_IMAGES &&
				(!inProgress ? (
					<Box onClick={addImage} className="addTxtImgWrapper">
						<Box className="center addIcon">
							<AddIcon sx={{ color: "#313030", fontSize: "20px" }} />
						</Box>
						<u>{i18next.t("register.addingImg")}</u>
						<ImgInput
							refInput={refUploadInput}
							handleSaveNewImage={handleSaveNewImage}
							onInProgress={handleOnInProgress}
							user={user}
							setCodesFiles={setCodesFiles}
						/>
					</Box>
				) : (
					<Box className="addTxtImgWrapper">
						<Loader />
					</Box>
				))}

			<Box className="imagesWrapper">
				{arrImages?.length !== 0 &&
					arrImages &&
					arrImages.map((data, index) => {
						return (
							<ImgWrapper
								imgKey={data.name}
								imgUrl={data.url}
								onDeleteImage={deleteImage}
								onEditImage={editImage}
								key={index}
								data={data}
								codes={codes}
								openPopupImg={openPopupImg}
							/>
						);
					})}

				{/* {arrImages?.length < MAX_IMAGES &&
				(!inProgress ? (
					<Box className={`addImgWrapper ${gridAddImgWrapper}`}>
						<Box onClick={addImage} className="center imgContent">
							<span>{i18next.t("register.addImg")}</span>
							<Box className="center addIcon">
								{<AddIcon sx={{ color: "#313030", fontSize: "20px" }} />}
							</Box>
							<ImgInput
								refInput={refUploadInput}
								handleSaveNewImage={handleSaveNewImage}
								onInProgress={handleOnInProgress}
								user={user}
								setCodesFiles={setCodesFiles}
							/>
						</Box>
					</Box>
				) : (
					<Box className={`addImgWrapper ${gridAddImgWrapper}`}>
						<Loader />
					</Box>
				))} */}
			</Box>
		</>
	);
}

function ImgWrapper({
	imgUrl,
	onDeleteImage,
	onEditImage,
	imgKey,
	data,
	openPopupImg,
	codes = {},
}) {
	let srcImg = imgUrl ? (imgUrl.includes("http") ? imgUrl : codes[imgUrl]) : "";

	const [inProgress, setInProgress] = useState(false);

	const editImage = (e) => {
		setInProgress(true);
		onEditImage(e, data);
		setInProgress(false);
	};
	const deleteImage = (e, key) => {
		setInProgress(true);
		onDeleteImage(e, key);
		setInProgress(false);
	};
	return !inProgress ? (
		<Box className="imgBox" onClick={() => openPopupImg(data)}>
			<img src={srcImg} alt="" width={80} />
			{imgUrl && (
				<Box className="deleteIcon center">
					<CloseIcon
						onClick={(e) => deleteImage(e, imgKey)}
						sx={{ color: "#717171", fontSize: "18px" }}
					/>
				</Box>
			)}
			{/* {imgUrl && (
				<Box className="icon editIcon">
					{<EditIcon onClick={(e) => editImage(e)} sx={{ color: "#525bc2", fontSize: "20px" }} />}
				</Box>
			)} */}
			{!imgUrl && (
				<Box className="addCircleIcon">
					<AddCircleIcon sx={{ color: "#168187", fontSize: "20px" }} />
				</Box>
			)}
		</Box>
	) : (
		<Box className="imgBox">
			<CircularProgress size={40} sx={{ color: "#168187" }} />
		</Box>
	);
}

function ImgInput({ refInput, handleSaveNewImage, onInProgress, user, setCodesFiles }) {
	const [totalAllowUploadImages, setTotalAllowUploadImages] = useState(9);

	useEffect(() => {
		const totalImages = user?.pictures ? Object.keys(user?.pictures || {}).length : 0;
		setTotalAllowUploadImages(9 - totalImages);
	}, [user]);

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const resize = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => {
				let count = 1;
				let img = new Image();
				img.src = reader.result;
				img.onload = function () {
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					const { width, height } = calculateWidth(1200, 1200, img.width, img.height);
					canvas.width = width;
					canvas.height = height;
					img.crossOrigin = "anonymous";
					ctx.drawImage(img, 0, 0, width, height);
					const res = canvas.toDataURL(file.type ?? "image/jpeg");
					ctx = null;
					canvas = null;
					if (!res.includes("iiigAooooAKKKKACiiigAooooAKKKKA")) {
						resolve(res);
					}
				};
			};

			/* 		let canvas = document.createElement("canvas");
			let ctx = canvas.getContext("2d");
			const { width, height } = calculateWidth(1200, 1200, img.width, img.height);
			canvas.width = width;
			canvas.height = height;
			img.crossOrigin = "anonymous";
			ctx.drawImage(img, 0, 0, width, height);
			const res = canvas.toDataURL("image/jpeg");
			ctx = null;
			canvas = null;
			resolve(res); */
		});

	const calculateWidth = (maxWidth, maxHeight, imageWidth, imageHeight) => {
		const conf = Math.max(imageWidth / maxWidth, imageHeight / maxHeight);
		return {
			width: imageWidth / conf,
			height: imageHeight / conf,
		};
	};

	const onSelectFileInput = async (e) => {
		try {
			if (!e.target.files) return;
			onInProgress(true);
			if (!(e.target.files && e.target.files.length > 0)) {
				onInProgress(false);
				return;
			}

			const codeObj = {};
			const arrImagesName = {};
			let totalError = 0;
			let arrFiles = Object.values(e.target.files);

			if (arrFiles.length > totalAllowUploadImages) {
				arrFiles = arrFiles.slice(0, totalAllowUploadImages);
			}

			await Promise.all(
				arrFiles.map(async (file, index) => {
					if (file.type === "image/png" && file.size > 3000000) {
						totalError++;
						//	base64 = await toBase64(file);
					} else {
						let base64;
						if (file.type === "image/png") {
							base64 = await toBase64(file);
						} else {
							base64 = await resize(file);
						}

						const randString = randomstring.generate(6);
						const { data } = await ProfileService.addImage(base64, null, null, randString);
						codeObj[randString] = base64;
						setCodesFiles(codeObj);
						arrImagesName[index] = data?.data.name;
					}
				})
			);
			onInProgress(false);
			if (totalError > 0) {
				let msg = "";
				if (totalError == 1) {
					msg = `תמונה אחת לא  עלתה כיון ששקלה מעל 3 מגה`;
				} else {
					msg = `${totalError} תמונות לא עלו כיון ששקלו מעל 3 מגה`;
				}
				alert(msg);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<input
			style={{ display: "none" }}
			ref={refInput}
			multiple={true}
			accept="image/*"
			type="file"
			onChange={onSelectFileInput}
		/>
	);
}
