export const CHATS_FETCH_SUCCESSFULLY = "chats.CHATS_FETCH_SUCCESSFULLY";
export const CHATS_FETCH_FAILED = "chats.CHATS_FETCH_FAILED";
export const CHATS_UPDATED = "chats.CHATS_UPDATED";
export const CHATS_UPDATED_ERROR = "chats.CHATS_UPDATED_ERROR";
export const CHATS_ADDED = "chats.CHATS_ADDED";
export const CHATS_ADDED_ERROR = "chats.CHATS_ADDED_ERROR";
export const CHATS_REMOVED = "chats.CHATS_REMOVED";
export const CHATS_REMOVED_ERROR = "chats.CHATS_REMOVED_ERROR";
export const CHATS_VIRTUAL_DELETE_CHAT = "chats.CHATS_VIRTUAL_DELETE_CHAT";
export const CHATS_CANCEL_DELETE_CHAT = "chats.CHAT_RECEIVED_CANCEL_DELETE_CHAT";
export const CHATS_SET_SELECTED = "chats.CHATS_SET_SELECTED";
export const CHATS_CLEAN_SELECTED = "chats.CHATS_CLEAN_SELECTED";
export const CHATS_SET_SELECTED_LIST = "chats.CHATS_SET_SELECTED_LIST";
export const CHATS_RESET_SELECTED_LIST = "chats.CHATS_RESET_SELECTED_LIST";
export const CHATS_UPDATE_PIN_CHAT = "chats.CHATS_UPDATE_PIN_CHAT";
export const CHATS_UPDATE_UNPIN_CHAT = "chats.CHATS_UPDATE_UNPIN_CHAT";
export const CHATS_CHANGED = "chats.CHATS_CHANGED";
