import useNavigator from "hooks/useNavigator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loginWithToken } from "redux/auth/actions";

export default function ImpersonateUser() {
	let { id } = useParams();
	const dispatch = useDispatch();
	const loginWithTokenStatus = useSelector((state) => state.auth.loginWithTokenStatus);
	const login = () => {
		dispatch(loginWithToken(id));
	};
	const navigator = useNavigator();

	useEffect(() => {
		console.log(id);
		if (id) {
			login();
		}
	}, [id]);

	useEffect(() => {
		if (loginWithTokenStatus) {
			navigator.navigateToHomePage();
		}
	}, [loginWithTokenStatus]);

	return <></>;
}
