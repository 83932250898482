import "assets/scss/chat/chat.scss";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import ChatConversation from "./chatConversation/ChatConversation";
import ChatList from "./chatList/ChatList";

export default () => {
	const selectedChat = useSelector((state) => state.chats.selectedChat);

	return (
		<Box className="chatWrapper">
			{!selectedChat && <ChatList></ChatList>}
			{selectedChat && <ChatConversation selectedChat={selectedChat}></ChatConversation>}
		</Box>
	);
};
