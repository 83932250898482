export default {
	CollectivelyAppeal: "CollectivelyAppeal",
	SendMessageToTalentPopup: "SendMessageToTalentPopup",
	TalentPage: "TalentPage",
	YesNo: "YesNo",
	None: "None",
	Login: "Login",
	ViewTalent: "ViewTalent",
	filterSearch: "filterSearch",
	OrderBy: "orderBy",
	LeaveDetails: "LeaveDetails",
	Terms: "Terms",
	ReportUser: "ReportUser",
	UserRecomendationDialog: "UserRecomendationDialog",
	Countdown: "Countdown",
	TagsSuggestions: "TagsSuggestions",
	DownloadApp: "DownloadApp",
	RegisterClientDialog: "RegisterClientDialog",
};
