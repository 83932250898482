import { useFormik } from "formik";
import * as Yup from "yup";
import i18next from "i18next";

import ErrorLabel from "components/ui/ErrorLabel";
import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import TextComponent from "components/ui/TextComponent";
import editIcon from "assets/images/profile/editIcon.png";
import phoneIcon from "assets/images/profile/phoneIcon.png";
import freezeIcon from "assets/images/profile/freezeIcon.png";
import userNumberIcon from "assets/images/profile/userNumberIcon.png";
import deleteIcon from "assets/images/profile/deleteIcon.png";
import Button from "components/ui/Button";
import IconsAndContents from "./IconsAndContents";
import { useDispatch } from "react-redux";
import { openNotification } from "redux/notification/actions";
import functionsService from "services/functionsService";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default ({ user, logOutUser = () => {} }) => {
	const dispatch = useDispatch();
	// const { saveAccountProfile } = useUser();
	let phone = user.phone.replace("+", "");
	phone = phone.replace(" ", "");
	phone = phone.replace("972", "0");
	const initialValues = {
		name: user?.name,
		email: user?.email,
		phone: phone ?? "",
		internalID: user?.internalID,
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().max(20).required(i18next.t("validation.required")),
		email: Yup.string().email(i18next.t("validation.email")),
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				// await saveAccountProfile(values.name, values.email);
				// dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
			} catch (error) {
				console.log(error);
				alert("error");
			}
		},
	});

	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};

	const handleUnFreezeCard = async () => {
		await functionsService.unFreezeMe().then((res) => console.log(res));
	};

	const handleFreezeCard = async () => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: i18next.t("freezeMe.areUSure"),
				onYes: async () => {
					await functionsService.freezeMe().then((res) => console.log(res));
					toggelClose();
				},
				onNo: toggelClose,
				yesBtn: "תקפיא אותי",
			})
		);
	};
	const handleDeleteAccount = async () => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: i18next.t("deleteMe.areUSure"),
				onYes: async () => {
					await functionsService.deleteMe().then((res) => console.log(res));
					logOutUser();
					toggelClose();
				},
				onNo: toggelClose,
				yesBtn: "מחק אותי",
			})
		);
	};

	// const iconsAndContents = [
	// 	{
	// 		icon: phoneIcon,
	// 		iconContent: i18next.t("profile.phone"),
	// 		value: user?.phone,
	// 	},
	// 	{
	// 		icon: userNumberIcon,
	// 		iconContent: i18next.t("profile.usersNumber"),
	// 		value: user?.placeid,
	// 	},
	// 	{
	// 		icon: freezeIcon,
	// 		iconContent: i18next.t("profile.freezeCard"),
	// 		value: "",
	// 		onclick: () => {
	// 			alert("1");
	// 		},
	// 	},
	// 	{
	// 		icon: deleteIcon,
	// 		iconContent: i18next.t("profile.deleteAccount"),
	// 		value: "",
	// 		onclick: () => {
	// 			alert("1");
	// 		},
	// 	},
	// ];

	return (
		<>
			<Box>
				<form id="account-settings" onSubmit={formik.handleSubmit}>
					<Box className="boxWrapper">
						<span>{i18next.t("register.name")}</span>
						<TextComponent disabled id="name" value={"name"} {...formik.getFieldProps("name")} />
					</Box>
					{user?.email && (
						<Box className="boxWrapper">
							<span>{i18next.t("register.email")}</span>
							<TextComponent
								disabled
								id="email-account"
								value={"email"}
								{...formik.getFieldProps("email")}
							/>
						</Box>
					)}
					<Box className="boxWrapper">
						<span>{i18next.t("profile.phone")}</span>
						<TextComponent disabled id="phone" value={"phone"} {...formik.getFieldProps("phone")} />
					</Box>
					<Box className="boxWrapper">
						<span>{i18next.t("profile.usersNumber")}</span>
						<TextComponent
							disabled
							id="internalID"
							value={"internalID"}
							{...formik.getFieldProps("internalID")}
						/>
					</Box>
					{
						<Box
							className="boxWrapper freezeCard"
							onClick={user?.statusTalent !== "freezed" ? handleFreezeCard : () => {}}>
							{user?.statusTalent !== "freezed" ? (
								<span>{i18next.t("profile.freezeCard")}</span>
							) : (
								<>
									<span>{i18next.t("profile.freezedCard")}</span>{" "}
									<Box className="unFreezeCard">
										<span onClick={handleUnFreezeCard}>{i18next.t("profile.unfreezeCard")}</span>
									</Box>
								</>
							)}
						</Box>
					}
					<Box
						className="boxWrapper deleteAccount"
						onClick={user?.statusTalent !== "deleted" ? handleDeleteAccount : () => {}}>
						{user?.statusTalent !== "deleted" ? (
							<span>{i18next.t("profile.deleteAccount")}</span>
						) : (
							<span>{i18next.t("profile.deletedAccount")}</span>
						)}
					</Box>

					{/* {iconsAndContents &&
						iconsAndContents.length > 0 &&
						iconsAndContents.map((value, i) => (
							<Box className="boxWrapper">
								<IconsAndContents
									onclick={value.onclick ? value.onclick : false}
									key={i}
									icon={value.icon}
									iconContent={value.iconContent}
									content={value.value}></IconsAndContents>
							</Box>
						))} */}
					{/* <Box sx={{ display: "flex", justifyContent: "end" }}>
						<Button type="submit" fontWeight={500} variant="" size="14px">
							{i18next.t("profile.save")}
						</Button>
					</Box> */}
				</form>
			</Box>
		</>
	);
};
