import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

export default ({ updated, ...prop }) => {
	const [dateFormatted, setDateFormatted] = useState();
	const calcDate = () => {
		const days = moment(Date.now()).diff(moment(updated), "days");
		if (days === 0) {
			setDateFormatted(moment(updated).format("kk:mm"));
		} else if (days === 1) {
			setDateFormatted("אתמול");
		} else {
			setDateFormatted(moment(updated).format("DD.MM.YYYY"));
		}
	};

	useEffect(() => {
		if (updated) {
			calcDate();
		}
	}, [updated]);

	return <Box {...prop}>{dateFormatted}</Box>;
};
