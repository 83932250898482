import "assets/scss/searchHeader/searchHeader.scss";
import { Box, Button } from "@mui/material";
import i18next from "i18next";
import _ from "lodash";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useDispatch, useSelector } from "react-redux";
import sixDaysWedLogo from "assets/images/talent/sixDaysWedLogo.png";
import useNavigator from "hooks/useNavigator";
import useUser from "hooks/useUser";
import { TransformImg } from "helpers/cloudinary";

import mainLogo from "assets/images/svgs/descHeader/mainLOGO1.svg";
import mainLogo2 from "assets/images/svgs/descHeader/mainLOGO2.svg";
import profileExpandHeaderIcon from "assets/images/svgs/descHeader/profile-expand.svg";
import searchHeader from "assets/images/svgs/descHeader/search-desktop-header.svg";
import chatHeader from "assets/images/svgs/descHeader/messages-desktop-icon.svg";
import lastRecHeader from "assets/images/svgs/descHeader/saved-desktop-icon.svg";
import heartHeader from "assets/images/svgs/descHeader/recommendation-desktop-header.svg";
import suitcaseHeader from "assets/images/talent/suitcaseHeader.png";
import { useEffect, useState } from "react";
import UsersTypes from "consts/UsersTypes";
import RecommendationsService from "services/recommendations";
import chatService from "services/chatService";
import { useLocation } from "react-router-dom";
import SearchTags from "components/searchTalent/SearchTags";
import SearchIcon from "@mui/icons-material/Search";
import { getFiltersSelected } from "redux/search/selectors";
import useSearch from "hooks/useSearch";

export default () => {
	const dispatch = useDispatch();
	const { isLoggedIn, getProfileImage, userProfile } = useUser();
	const navigator = useNavigator();
	const [chatIndicator, setChatIndicator] = useState(0);
	// const [profileIndicator, setProfileIndicator] = useState(0);
	const [focusFilter, setFocusFilter] = useState(false);
	const { pathname } = useLocation();
	const filters = useSelector(getFiltersSelected);
	const search = useSearch();

	const openLoginPopup = () => {
		dispatch(openDialog({ dialog: dialogsTypes.Login }));
	};
	const openProfilePage = () => {
		navigator.navigateToPath("/profile");
	};
	const handleProfileExpand = () => {
		if (isLoggedIn && !!userProfile.name) {
			openProfilePage();
		} else {
			openLoginPopup();
		}
	};

	const handleSubmit = async () => {
		if (pathname !== "/search") {
			let filtersWithoutUids = { ...filters };
			if (filtersWithoutUids?.uids) filtersWithoutUids["uids"] = "";

			search.search(filtersWithoutUids);

			const searchParams = new URLSearchParams(filtersWithoutUids).toString();
			navigator.navigateToPath(`/search?${searchParams}`);
		} else {
			const obj = { category: "" };
			search.search(obj);
		}
	};

	const profileImage = TransformImg(getProfileImage(userProfile), 200, 200);
	// const textButton = [
	// 	i18next.t("register.registerSupplierHeader"),
	// 	<img style={{ margin: "0 15px", height: "19px" }} src={suitcaseHeader} />,
	// ];

	// useEffect(() => {
	// 	if (userProfile && userProfile?.type == UsersTypes.Talent) {
	// 		const listenRecommendationAdded = RecommendationsService.listenRecommendationUnread(
	// 			userProfile.uid
	// 		).subscribe((data) => {
	// 			const count = Object.values(data || {}).length;
	// 			setProfileIndicator(count);
	// 		});

	// 		return () => {
	// 			listenRecommendationAdded.unsubscribe();
	// 		};
	// 	}
	// }, [userProfile]);

	useEffect(() => {
		if (userProfile && userProfile.type == UsersTypes.Talent) {
			const listenRecommendationAdded = chatService
				.listenChatsUnread(userProfile.uid)
				.subscribe((data) => {
					setChatIndicator(data);
				});

			return () => {
				listenRecommendationAdded.unsubscribe();
			};
		}
	}, [userProfile]);

	return (
		<>
			<Box className="wrapSearchWebHeader">
				<Box className="wrapInnerSearchWebHeader" style={{ gap: "24px" }}>
					<Box
						className="wrapSiteHeaderImg"
						onClick={() => {
							window.scrollTo(0, 0);
							if (pathname !== "/") navigator.navigateToPath("/?cfs=t");
						}}>
						<img src={mainLogo2} alt="" />
					</Box>
					<Box className="wrapSearchAndNav">
						<Box className={`webSearch ${!focusFilter ? "focusSearch" : ""}`}>
							<SearchTags
								handleFocus={() => setFocusFilter(true)}
								handleBlur={() => setFocusFilter(false)}>
								<Box className="wrapSearchAndFilter">
									<Button onClick={handleSubmit} className="center searchBtn">
										<SearchIcon style={{ color: "#386BA8", width: "25px" }} />
									</Button>
								</Box>
							</SearchTags>
						</Box>
					</Box>
				</Box>

				<Box className="wrapInnerSearchWebHeader">
					<Box className="wrapInnerSearchWebHeader">
						{/* <Box className="center wrapButtonRegister">
							{true && (
								<Button
									onClick={() => {
										navigator.navigateToPath("/login/supplier");
									}}
									className="wrapInnerButton"
									style={{
										color: "#fff",
										background: "#000",
										borderRadius: "20px",
										textAlign: "center",
									}}
									variant="contained">
									{textButton}
								</Button>
							)}
						</Box> */}
						<Box className="wrapNav">
							<Box
								className={`center innerNav ${pathname === "/search" ? "boldNav" : ""}`}
								onClick={() => navigator.navigateToPath("/search")}>
								<img src={searchHeader} />
								<span>{i18next.t("headerInnerNav.allExperts")}</span>
							</Box>

							<Box
								className={`center innerNav ${
									pathname === "/latestRecommendations" ? "boldNav" : ""
								}`}
								onClick={() => {
									navigator.navigateToPath("/latestRecommendations");
								}}>
								<img src={heartHeader} />
								<span>{i18next.t("headerInnerNav.lestRec")}</span>
							</Box>
							{!!isLoggedIn && (
								<>
									<Box
										className={`center innerNav ${pathname === "/chat" ? "boldNav" : ""}`}
										onClick={() => {
											navigator.navigateToPath("/chat");
										}}>
										<img src={chatHeader} />
										<span>{i18next.t("headerInnerNav.massage")}</span>
										{chatIndicator >= 1 && <span className="indicator">{chatIndicator}</span>}
									</Box>
									<Box
										className={`center innerNav ${
											pathname === "/profile/favorites" ? "boldNav" : ""
										}`}
										onClick={() => {
											navigator.navigateToPath("/profile/favorites");
										}}>
										<img src={lastRecHeader} />
										<span>{i18next.t("headerInnerNav.favorites")}</span>
									</Box>
								</>
							)}
						</Box>
					</Box>
					{!isLoggedIn ? (
						<>
							<Button className="connect" onClick={openLoginPopup}>
								{"התחברות"}
							</Button>
						</>
					) : (
						<Box className="center wrapImgAndName">
							<Box className="wrapImg" onClick={isLoggedIn ? openProfilePage : openLoginPopup}>
								{<img src={profileImage} />}
							</Box>
							<Box className="wrapProfileExpand" onClick={handleProfileExpand}>
								{<img src={profileExpandHeaderIcon} />}
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};
