import firebase from 'firebase/app';
import "firebase/database";
import { Observable } from 'rxjs';

class Like2LikeService {
  BASE_REF = 'like2like';

  async fetchLike2Like(uid) {
    return (
      (
        await firebase.database()
          .ref(this.BASE_REF)
          .child(uid)
          .once('value')
      ).val() || {}
    );
  }

  listenLike2LikeAdded(uid) {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(uid)
        .orderByChild('timeStamp')
        .limitToLast(1);

      const callbackFn = ref.on(
        'child_added',
        snapshot => subscriber.next({ [snapshot.key]: snapshot.val() }),
        error => subscriber.error(error)
      );
      return () => ref.off('child_added', callbackFn);
    });
  }

  listenLike2LikeUpdate(uid) {
    return new Observable(subscriber => {
      const ref = firebase.database()
        .ref(this.BASE_REF)
        .child(uid);

      const callbackFn = ref.on(
        'child_changed',
        snapshot => subscriber.next({ ...snapshot.val(), uid: snapshot.key }),
        error => subscriber.error(error)
      );
      return () => ref.off('child_changed', callbackFn);
    });
  }
}

export default new Like2LikeService();
