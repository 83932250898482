import { createSelector } from "reselect";
import { orderBy } from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

export const getLikedMe = (state) => state.likedMe;

export const getLikedMeData = createSelector(getLikedMe, (likedMe) => likedMe.data || {});

export const getLikedMeSortByDate = createSelector(
	getLikedMeData,
	getBlocks,
	(likedMeData, blocks) => {
		const likedMeArray = Object.entries(likedMeData).map(([key, value]) => ({
			...value,
			uid: key,
		}));
		const likedMeFilteredDeleted = likedMeArray
			.filter(({ deleted }) => deleted !== true)
			.filter((user) => !blocks[user.uid]);
		return orderBy(likedMeFilteredDeleted, "timeStamp", "desc");
	}
);

export const getUnViewLikedMeUsers = createSelector(getLikedMeSortByDate, (likedMe) => {
	return likedMe.filter(({ unView }) => unView);
});

export const unViewedLikedMe = createSelector(
	getUnViewLikedMeUsers,
	(unViewLikedMeUsers) => unViewLikedMeUsers.length
);

export const getPitId = createSelector(getLikedMe, (likedMe) => likedMe.pitId);

export const getSearchAfter = createSelector(getLikedMe, (likes) => likes.searchAfter);
export const getLikedMeUsers = createSelector(
	getLikedMe,
	getLikedMeSortByDate,
	(likedMe, likedMeFilteredDeleted) => {
		const users = Object.keys(likedMe.users)
			.sort(
				(uidA, uidB) => likedMe.users[uidB].likes_timeStamp - likedMe.users[uidA].likes_timeStamp
			)
			.map((uid) => likedMe.users[uid]);
		return users.filter(
			(user) =>
				!user.deleted && likedMeFilteredDeleted.findIndex(({ uid }) => uid === user.uid) !== -1
		);
	}
);

export const getIsLoadingMore = createSelector(
	getLikedMe,
	(likedMe) => likedMe.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getLikedMe, (likedMe) => likedMe.hasMoreResults);

export const getLikedMeStatus = createSelector(getLikedMe, (likedMe) => likedMe.apiStatus);
