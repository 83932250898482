import React, { useState, useEffect } from "react";
import i18next from "i18next";
import Question from "./Question";
import PhoneVerificationInput from "../PhoneVerificationInput";
import Button from "../Button";
import LoginHeader from "../LoginHeader";

export default ({
	title,
	subTitle,
	onSubmit,
	onResendCode,
	hasError,
	setHasError,
	errMsg,
	onBack,
	setDidntGetCode,
	setCountToSendCode,
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();
	const [ticks, setTicks] = useState(60);

	const handleSubmit = () => {
		onSubmit(value);
	};

	const handleResendCode = () => {
		onResendCode(value);
	};

	const handleChange = (val) => {
		setValue(val);
	};

	const handleValidityChange = (valid) => {
		setIsValid(valid);
		if (valid) {
			handleSubmit();
		}
	};

	useEffect(() => {
		if (ticks > 0) {
			setTimeout(() => {
				setTicks((value) => value - 1);
				setCountToSendCode(ticks - 1);
			}, 1000);
		} else {
			setDidntGetCode(true);
		}
	}, [ticks]);

	return (
		<>
			<Question
				variant=""
				isValid={isValid}
				title={title}
				subTitle={subTitle}
				onSubmit={() => {}}
				showBackButton={true}
				btnTxt={i18next.t("Login.confirm")}>
				<PhoneVerificationInput
					setDidntGetCode={setDidntGetCode}
					onChange={handleChange}
					onValidityChange={handleValidityChange}
					hasError={hasError}
					setHasError={setHasError}
					errMsg={errMsg}
					onEnterPressed={handleSubmit}
				/>
			</Question>
		</>
	);
};
