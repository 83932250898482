import "assets/scss/popups/sendMessage/sendMessage.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18next from "i18next";
import Button from "components/Button";
import Text from "components/ui/Text";
import ErrorLabel from "components/ui/ErrorLabel";
import { Box, CircularProgress } from "@mui/material";
import useUser from "hooks/useUser";
import chatService from "services/chatService";
import RegisterBtnWrap from "components/register/RegisterBtnWrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openNotification } from "redux/notification/actions";
import { useEffect } from "react";
import chatTemplates from "constants/chatTemplates";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import _ from "lodash";
import functionsService from "services/functionsService";
import { phoneRegExp } from "helpers/regex";
import { ScrollToFieldError } from "helpers/FormikErrors";
import TextComponent from "components/ui/TextComponent";
import { getFirstCharImg } from "helpers/getFirstCharImg";
import SiteButton from "components/ui/SiteButton";
import UsersTypes from "consts/UsersTypes";

export default ({ talent }) => {
	const dispatch = useDispatch();
	const { userProfile, isLoggedIn } = useUser();
	const [inProgress, setInProgress] = useState(false);

	const initialValues = {
		eventsDate: "",
		name: userProfile.name ?? "",
		phone: userProfile?.phoneToConnect
			? userProfile.phoneToConnect
			: userProfile?.phone
			? userProfile.phone
			: "",
		noteForTalent: "",
	};

	const validationSchema = Yup.object().shape({
		eventsDate: Yup.string().required(i18next.t("validation.required")),
		name: Yup.string().required(i18next.t("validation.required")),
		phone: Yup.string()
			.matches(phoneRegExp, i18next.t("validation.phone"))
			.required(i18next.t("validation.required")),
	});

	const openRegClientDialog = () => {
		if (!userProfile?.completeClientRegister && userProfile?.type === UsersTypes.Client)
			return dispatch(
				openDialog({
					dialog: dialogsTypes.RegisterClientDialog,
					actAfterRegister: () => {
						sendMessage({ ...formik.values, skipRegClient: true });
					},
					isFromMessageTalent: true,
					talentName: talent?.name,
				})
			);
		return;
	};

	const sendMessage = async ({ eventsDate, name, phone, noteForTalent, skipRegClient = false }) => {
		if (!isLoggedIn || (userProfile?.type === UsersTypes.Talent && !userProfile?.name))
			return dispatch(
				openDialog({
					dialog: dialogsTypes.Login,
					isFromMsgToTalent: true,
					msgTalentName: talent.name,
					actAfterLogin: () => formik.submitForm(),
				})
			);
		if (
			!skipRegClient &&
			!userProfile?.completeClientRegister &&
			userProfile?.type === UsersTypes.Client
		)
			return openRegClientDialog();

		if (!eventsDate || !name || !phone) return;
		const message = { eventsDate, name, phone, noteForTalent, template: chatTemplates.Offer };
		const sender = userProfile.uid;
		const receiver = talent.uid;
		const returnData = await functionsService.sendLead(receiver, message);

		if (returnData && returnData?.data?.success) {
			dispatch(openNotification(i18next.t("notificationActions.messageSentSuccessfuly")));
			dispatch(closeDialogByType(dialogsTypes.SendMessageToTalentPopup));
		} else {
			dispatch(openNotification("failed"));
		}
	};

	const handleSendMessageToTalent = (e) => {
		// if (!userProfile || _.isEmpty(userProfile) || !userProfile.name) {
		// 	e.preventDefault();
		// 	dispatch(openDialog({ dialog: dialogsTypes.Login }));
		// }
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setInProgress(true);
			await sendMessage(values);
			setInProgress(false);
		},
	});

	return (
		<Box className="wrapSendMessageTo">
			<div className="wrapProfileImg">
				<img
					style={{ borderRadius: "50%" }}
					width={150}
					height={150}
					src={talent?.profileImage?.url ? talent.profileImage.url : getFirstCharImg(talent)}
				/>
			</div>
			<Box className=" center sendMessageTo">
				<span>{`${i18next.t("work.sendPersonalMessage")}${talent?.name}`}</span>
			</Box>
			<form
				id="starter"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "10px" }}>
				<Box className="boxWrapper">
					<span>
						{i18next.t("work.essence of reference")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<TextComponent
						placeholder={i18next.t("work.describe inquire about")}
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={4}
						id="eventsDate"
						value={"eventsDate"}
						{...formik.getFieldProps("eventsDate")}
					/>
					<ErrorLabel>{formik.touched.eventsDate && formik.errors.eventsDate}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>
						{i18next.t("work.name")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={2}
						id="name"
						value={"name"}
						{...formik.getFieldProps("name")}
					/>
					<ErrorLabel>{formik.touched.name && formik.errors.name}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>
						{i18next.t("work.phone")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={2}
						id="phone"
						value={"phone"}
						{...formik.getFieldProps("phone")}
					/>
					<ErrorLabel>{formik.touched.phone && formik.errors.phone}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>{i18next.t("work.budgetIfRelevant")}</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={2}
						id="noteForTalent"
						value={"noteForTalent"}
						{...formik.getFieldProps("noteForTalent")}
					/>
					<ErrorLabel>{formik.touched.noteForTalent && formik.errors.noteForTalent}</ErrorLabel>
				</Box>

				<Box className=" center sendWrapper">
					<span onClick={handleSendMessageToTalent}>
						{inProgress ? (
							<div
								style={{
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
									margin: "0 30px",
								}}>
								<CircularProgress size={40} sx={{ color: "#386BA8" }} />
							</div>
						) : (
							<SiteButton styledButton={{ type: "edit" }} text={i18next.t("work.getBackToMe")} />
						)}
					</span>
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
		</Box>
	);
};
