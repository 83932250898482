import useUser from "hooks/useUser";
import LoginAndRegisterPage from "./LoginPage";
import MainPage from "./MainPage";
import { useState } from "react";
import AppEnterPage from "./AppEnterPage";

export default ({ platform }) => {
	const { isCompleteRegister } = useUser();
	const [showAppEnterPage, setShowAppEnterPage] = useState(true);

	const goToLoginPage = () => setShowAppEnterPage(false);

	return (
		<>
			{isCompleteRegister && <MainPage></MainPage>}
			{!isCompleteRegister && (
				<>
					{showAppEnterPage && window.location.search !== "?cfas=t" ? (
						<AppEnterPage goToLoginPage={goToLoginPage} />
					) : (
						<LoginAndRegisterPage platform={platform} />
					)}
				</>
			)}
		</>
	);
};
