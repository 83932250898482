import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default function Countdown({ data }) {
	const dispatch = useDispatch();
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			dispatch(closeDialogByType(dialogsTypes.Countdown));
		}, 4000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, []);
	return (
		<Box
			style={{
				padding: "40px",
				minWidth: "300px",
			}}>
			<Box
				style={{
					margin: "20px 0",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontWeight: 700,
				}}>
				{data.text}
			</Box>
		</Box>
	);
}
