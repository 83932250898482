import { Box } from "@mui/material";
import Button from "components/ui/Button";
import dialogsTypes from "consts/dialogsTypes";
import useNavigator from "hooks/useNavigator";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import { openNotification } from "redux/notification/actions";
import shareIcon from "../../assets/images/shareIcon.png";
import SiteButton from "components/ui/SiteButton";
import useUser from "hooks/useUser";

export default ({ user }) => {
	const dispatch = useDispatch();
	const navigate = useNavigator();
	const { userProfile } = useUser();

	const handleShareTalent = async () => {
		if (user.internalID) {
			const message = `היי, ראיתי את ${user?.name} ב"ששת ימים" - פלטפורמת ספקים ונותני שירותים מבוססת המלצות חברים משותפים ובינה מלאכותית. מה דעתך?🙏`;

			try {
				return await navigator.share({
					title: `מה דעתך על ${user?.name}`,
					text: message,
					url: `${window.location.origin}/talent/${user?.internalID}?affId=${userProfile?.internalID}`,
				});
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	const openNavigateToEditPage = () => {
		navigate.navigateToPath("/profile/editAccount");
		dispatch(closeDialogByType(dialogsTypes.ViewTalent));
	};

	return (
		<>
			<Box sx={{ display: "block", minHeight: "70px", width: "100%", position: "relative" }}>
				<Box className="center getBackToMe">
					<SiteButton
						text={i18next.t("register.share your business card")}
						onClickBtn={handleShareTalent}
					/>
					{/* <SiteButton text={i18next.t("work.editCard")} onClickBtn={openNavigateToEditPage} /> */}
					{/* <Button
						variant=""
						onClick={openNavigateToEditPage}
						sx={{
							background: "#7496bf !important",
							borderRadius: "25px",
							padding: "10px 15px",
						}}
						size="16px"
						className="freelancer-btn-yes"
						fontWeight={600}>
						{i18next.t("work.editCard")}
					</Button> */}
				</Box>
			</Box>
		</>
	);
};
