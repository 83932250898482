import "assets/scss/landing/landingWhySixDays.scss";
import { Box } from "@mui/material";
import LandingColorizedTitle from "./LandingColorizedTitle";
import i18next from "i18next";
import whySixDays from "assets/images/svgs/landing/whySixDays.svg";
import whySixDaysMob from "assets/images/svgs/landing/why6daysmob.png";
import bid from "assets/images/svgs/landing/bid.png";
import search from "assets/images/svgs/landing/search.png";
import interfaceImg from "assets/images/svgs/landing/interface.png";

export default function LandingWhySixDays({ isMobile = false }) {
	return (
		<Box className="landingWhySixDays">
			<LandingColorizedTitle text={"למה"} colorText={"ששת ימים?"} />

			{!!isMobile ? (
				<WhySixDaysMobile />
			) : (
				<img src={whySixDays} width={"50%"} style={{ minWidth: "700px" }} />
			)}
		</Box>
	);
}

function WhySixDaysMobile() {
	const titleImages = [
		{
			title: "חיפוש לפי מכרים משותפים",
			subTitle: [
				<Box className="wrapTxt">
					<span className="">{"מה טוב יותר מלקוח שמגיע דרך "}</span>
					<span className="bolderTxt">{"חבר משותף "}</span>
					<span className="coloredTxt">{"שהמליץ "}</span>
					<span className="bolderTxt">{"עליך?"}</span>
				</Box>,
			],
			img: search,
		},
		{
			title: "אפשרות לשליחת הצעות מחיר במערכת בינה מלאכותית",
			subTitle: [
				<Box className="wrapTxt">
					<span className="bolderTxt">{"נלמד אותך, "}</span>
					<span className="">{"נלמד את הלקוחות שלך.."}</span>
				</Box>,
				<Box className="wrapTxt">
					<span className="">{"ונייצר חיבורים שפשוט עושים "}</span>
					<span className="coloredTxt">{"win-win "}</span>
					<span className="bolderTxt">{"לשני הצדדים."}</span>
				</Box>,
			],
			img: bid,
		},
		{
			title: "ממשק חדשני ונוח לקבלת פניות מלקוחות",
			subTitle: [
				<Box className="wrapTxt">
					<span className="">{"אפשרויות לקבלת "}</span>
					<span className="bolderTxt">{"פניות "}</span>
					<span className="">{"מלקוחות חדשים ישירות "}</span>
					<span className="coloredTxt">{"לוואטסאפ שלכם"}</span>
				</Box>,
			],
			img: interfaceImg,
			addStyle: "noBorder",
		},
	];

	return (
		<Box className="wrapWhySixDaysMobile">
			{!!titleImages &&
				titleImages.map((item, index) => (
					<TitleAndImgMobile
						key={index}
						title={item?.title}
						subTitle={item?.subTitle}
						img={item?.img}
						addStyle={item?.addStyle}
					/>
				))}
		</Box>
	);
}

function TitleAndImgMobile({ title = "", subTitle = "", img = "", addStyle = "" }) {
	return (
		<Box className={`wrapTitleSubAndImage ${addStyle ? addStyle : ""}`}>
			<Box className={`innerTitle`}>
				<h3>{title}</h3>
				<h4>{subTitle}</h4>
				<img src={img} width={"100%"} />
			</Box>
		</Box>
	);
}
