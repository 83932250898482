import store from "redux/store";
import { getInfo } from "redux/clientInfo/selectors";
import { getPlatform } from "redux/init/selectors";

class IpHelper {
	token = "e3074a78a8ef1d";
	async getInfo() {
		try {
			/* 	let response = await fetch(`https://ipinfo.io/json?token=${this.token}`);

			if (response.status === 200) {
				const data = await response.json();
				return data;
			} */
			return null;
		} catch (error) {
			return null;
		}
	}

	getInfoFromStore() {
		const state = store.getState();
		const platform = getPlatform(state);
		let info = { platform: platform ?? "" };
		return info;
	}
}

export default new IpHelper();
