import "assets/scss/appEnterPage/headerEnterPage.scss";
import { Box } from "@mui/material";
import sheshetBlue from "assets/images/svgs/enterPageApp/sixDaysBlue.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType, getDialogs } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import backIcon from "assets/images/svgs/back.svg";

export default function HeaderEnterPage() {
	const dispatch = useDispatch();
	const { dialogs } = useSelector(getDialogs);
	const loginDialogData = dialogs.find((element) => element?.dialog === dialogsTypes.Login);

	const closePopup = () => {
		if (loginDialogData) return dispatch(closeDialogByType(dialogsTypes.Login));
	};

	return (
		<Box className="headerEnterPageWrapper">
			{!!loginDialogData && (
				<Box className="closePopupBtn" onClick={closePopup}>
					<img src={backIcon} />
				</Box>
			)}
			<img alt="sheshetBlue" src={sheshetBlue} />
		</Box>
	);
}
