import React, { useState } from "react";
import Question from "./Question";
import PhoneInput from "../PhoneInput";
import Recaptcha from "components/recaptchaContainer";
import Loader from "components/Loader";
import i18next from "i18next";

export default ({
	title,
	subTitle,
	onSubmit,
	hasError,
	errMsg,
	showLoader = false,
	showSubmitButton = true,
	defaultInputValue = "",
	handleShowSubtitle = () => {},
	handleChangeValidation = () => {},
	setPhoneValue = () => {},
}) => {
	const [value, setValue] = useState();
	const [isValid, setIsValid] = useState();
	const handleOnChange = (val) => {
		setValue(val);
		setPhoneValue(val);
	};
	const handleOnSubmit = async () => {
		setIsValid(false);
		const isConfirm = await onSubmit(value);
		if (isConfirm === false) {
			setIsValid(true);
		} else {
			setTimeout(() => {
				setIsValid(true);
			}, 8000);
		}
	};
	const handleOnValidityChange = (_isValid) => {
		setIsValid(_isValid);
		handleChangeValidation(_isValid);
	};

	return (
		<>
			<Question
				variant=""
				title={title}
				subTitle={subTitle}
				onSubmit={handleOnSubmit}
				showSubmitButton={showSubmitButton}
				isValid={isValid}
				showBackButton={true}
				btnTxt={i18next.t("Login.Sent verification code")}>
				{showLoader && <Loader />}
				<PhoneInput
					defaultValue={defaultInputValue}
					handleShowSubtitle={handleShowSubtitle}
					onChange={handleOnChange}
					hasError={hasError}
					errMsg={errMsg}
					onValidityChange={handleOnValidityChange}
					onEnterPressed={handleOnSubmit}
				/>
			</Question>
			<Recaptcha />
		</>
	);
};
