const registerSteps = {
	1: 1,
	2: 2,
	3: 3,
	4: 4,
	5: 5,
};

Object.freeze(registerSteps);

export default registerSteps;
