import "assets/scss/dialog/dialog.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { closeDialogByType, getDialogs } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import DDialog from "./DDialog/DDialog";
import YesNoPopupModal from "components/modals/YesNoPopupModal";
import LoginAndRegisterPage from "pages/LoginPage";
import ViewTalent from "components/talent/ViewTalent";
import CollectivelyAppeal from "components/talent/CollectivelyAppeal/CollectivelyAppeal";
import SendMessageToTalent from "components/talent/SendMessageToTalent";
import YesNoPopup from "components/dialogs/YesNoPopup";
import SearchFilterDialog from "components/searchTalent/SearchFilterDialog";
import OrderByLocation from "components/searchTalent/OrderByLocation";
import useUser from "hooks/useUser";
import LeaveDetailsDialog from "components/LeaveDetails/LeaveDetailsDialog";
import TermsDialog from "components/terms/TermsDialog";
import ReportUserDialog from "components/reportUser/ReportUserDialog";
import UserRecomendationDialog from "components/userRecomendation/UserRecomendationDialog";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import UserWebPopup from "components/searchTalent/UserWebPopup";
import Countdown from "components/dialogs/Countdown";
import TagsSuggestions from "components/dialogs/TagsSuggestions";
import DownloadApp from "components/dialogs/DownloadApp";
import RegisterClientDialog from "components/dialogs/RegisterClientDialog";

function Dialogs() {
	const { dialogs } = useSelector(getDialogs);
	const { isLoggedIn } = useUser();

	const history = useHistory();
	const dispatch = useDispatch();
	const handleClose = () => {
		dispatch(closeDialogByType(""));
	};

	useEffect(() => {
		const unblock = history.block((location, action) => {
			if (action === "POP") {
				if (dialogs.length) {
					handleClose();
					return false;
				} else {
					return true;
				}
			}
		});

		return () => {
			//	unblock();
		};
	}, [dialogs]);

	return (
		<>
			{dialogs &&
				dialogs
					.filter((element) => element.dialog === dialogsTypes.ViewTalent)
					.map((item) => {
						return (
							<DDialog
								showGoBack={false}
								withHeaderColor={false}
								noPopupStyle={true}
								isFullScreen={true}
								talentId={item.talentId}
								talent={item.talent}
								type={dialogsTypes.ViewTalent}
								noBack={!!item?.noBack}
								component={isMobile ? ViewTalent : UserWebPopup}></DDialog>
						);
					})}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.Login) && (
				<DDialog
					withHeaderColor={false}
					noPopupStyle={true}
					isFullScreen={!!isMobile ? true : false}
					showGoBack={false}
					type={dialogsTypes.Login}
					component={LoginAndRegisterPage}></DDialog>
			)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.YesNo) && (
				<DDialog
					withHeaderColor={true}
					data={dialogs[dialogs.length - 1]}
					type={dialogsTypes.YesNo}
					showGoBack={false}
					component={YesNoPopup}></DDialog>
			)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.Countdown) && (
				<DDialog
					withHeaderColor={true}
					showGoBack={false}
					data={dialogs[dialogs.length - 1]}
					type={dialogsTypes.Countdown}
					component={Countdown}></DDialog>
			)}
			{dialogs &&
				!!dialogs.find((element) => element.dialog === dialogsTypes.CollectivelyAppeal) && (
					<DDialog
						withHeaderColor={true}
						isFullScreen={true}
						noPopupStyle={true}
						type={dialogsTypes.CollectivelyAppeal}
						component={CollectivelyAppeal}></DDialog>
				)}
			{dialogs &&
				!!dialogs.find((element) => element.dialog === dialogsTypes.SendMessageToTalentPopup) && (
					<DDialog
						withHeaderColor={true}
						talent={
							dialogs.find((element) => element.dialog === dialogsTypes.SendMessageToTalentPopup)
								?.talent
						}
						isFullScreen={!!isMobile ? true : false}
						noPopupStyle={true}
						type={dialogsTypes.SendMessageToTalentPopup}
						component={SendMessageToTalent}></DDialog>
				)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.LeaveDetails) && (
				<DDialog
					withHeaderColor={true}
					isFullScreen={true}
					showGoBack={false}
					type={dialogsTypes.LeaveDetails}
					component={LeaveDetailsDialog}></DDialog>
			)}

			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.filterSearch) && (
				<DDialog
					withHeaderColor={true}
					isFullScreen={true}
					type={dialogsTypes.filterSearch}
					component={SearchFilterDialog}></DDialog>
			)}

			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.OrderBy) && (
				<DDialog
					withHeaderColor={true}
					isFullScreen={true}
					type={dialogsTypes.OrderBy}
					component={OrderByLocation}></DDialog>
			)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.Terms) && (
				<DDialog
					withHeaderColor={true}
					isFullScreen={true}
					type={dialogsTypes.Terms}
					component={TermsDialog}></DDialog>
			)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.ReportUser) && (
				<DDialog
					withHeaderColor={true}
					isFullScreen={true}
					type={dialogsTypes.ReportUser}
					component={ReportUserDialog}></DDialog>
			)}
			{dialogs &&
				!!dialogs.find((element) => element.dialog === dialogsTypes.UserRecomendationDialog) && (
					<DDialog
						withHeaderColor={false}
						showGoBack={false}
						noPopupStyle={!!isMobile ? true : false}
						isFullScreen={!!isMobile ? true : false}
						talentId={dialogs[dialogs.length - 1].talentId}
						type={dialogsTypes.UserRecomendationDialog}
						component={UserRecomendationDialog}></DDialog>
				)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.TagsSuggestions) && (
				<DDialog
					withHeaderColor={true}
					type={dialogsTypes.TagsSuggestions}
					showGoBack={false}
					component={TagsSuggestions}></DDialog>
			)}
			{dialogs && !!dialogs.find((element) => element.dialog === dialogsTypes.DownloadApp) && (
				<DDialog
					withHeaderColor={true}
					type={dialogsTypes.DownloadApp}
					showGoBack={false}
					component={DownloadApp}></DDialog>
			)}
			{dialogs &&
				!!dialogs.find((element) => element.dialog === dialogsTypes.RegisterClientDialog) && (
					<DDialog
						withHeaderColor={false}
						isFullScreen={false}
						type={dialogsTypes.RegisterClientDialog}
						showGoBack={false}
						component={RegisterClientDialog}></DDialog>
				)}
		</>
	);
}

export default Dialogs;
