import React from "react";
import moment from "moment";
import i18next from "i18next";

import { isSameDay } from "helpers/date";
import { dateFormat } from "consts/date";

export default ({ updated, classDay }) => {
	const isToday = moment(updated).isSame(new Date(), "day");
	const isYesterday = moment(updated).isSame(moment().add(-1, "days"), "day");

	return (
		<>
			<div className={classDay ? `${classDay} dateWrapper` : "chat-day dateWrapper"}>
				<span>
					{isToday
						? moment(updated).format("kk:mm")
						: isYesterday
						? i18next.t("Yesterday")
						: moment(updated).format("DD.MM.YYYY")}
				</span>
			</div>
		</>
	);
};
