import React from "react";
import { useHistory } from "react-router-dom";

const useNavigator = () => {
	const history = useHistory();

	const redirectToPath = (path) => {
		history.push(path);
	};

	const redirectWithRefreshTo = (path) => {
		window.location = path;
	};

	const redirectWithReload = (path) => {
		redirectToPath("/empty");
		setTimeout(() => {
			redirectToPath(path);
		}, 1);
	};

	const redirectToPreviousPath = () => {
		history.goBack();
	};

	const navigateToProfilePage = () => redirectToPath("/profile");
	const navigateToSearchPage = () => redirectToPath("/search");
	const navigateToFinishRegister = () => redirectToPath("/finishRegister");
	const navigateToMatchesPage = () => redirectToPath("/matches");
	const navigateToLoginEnterPhonePage = () => redirectToPath("/login/phone");
	const navigateToLoginPhoneVerificationPage = () => redirectToPath("/login/phoneverify");
	const navigateToLogoutPage = () => redirectToPath("/logout");
	const navigateToLoginQuestionsPage = () => redirectToPath("/login/questions");
	const navigateToRegisterPage = () => redirectToPath("/register");
	const navigateToHomePage = () => redirectToPath("/");
	const redirectToHomePage = () => redirectWithRefreshTo("/");
	const navigateToMassagesReceived = () => redirectToPath("/messages/received");
	const navigateToMassagesSent = () => redirectToPath("/messages/sent");
	const navigateToMassagesFiltered = () => redirectToPath("/messages/filtered");
	const navigateToPreviousPage = () => redirectToPreviousPath();
	const navigateToContactPage = () => redirectToPath("/contact");

	const navigateToPath = (path) => redirectToPath(path);

	return {
		navigateToSearchPage,
		navigateToRegisterPage,
		navigateToMatchesPage,
		navigateToLoginEnterPhonePage,
		navigateToLoginPhoneVerificationPage,
		navigateToLogoutPage,
		navigateToLoginQuestionsPage,
		navigateToHomePage,
		redirectToHomePage,
		navigateToFinishRegister,
		navigateToMassagesReceived,
		navigateToMassagesSent,
		navigateToMassagesFiltered,
		navigateToPreviousPage,
		navigateToPath,
		redirectWithReload,
		navigateToProfilePage,
		navigateToContactPage,
	};
};

export default useNavigator;
