import produce from "immer";

import * as types from "./actionTypes";
import * as blocksTypes from "../blocks/actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.LIKE_MATCH_FETCH_SUCCESSFULLY:
			return {
				...state,
				data: action.payload,
				apiStatus: ApiRequestStatus.SUCCESSFULLY,
			};
		case types.LIKE_MATCH_START_CHAT:
			return {
				...state,
				send: action.payload,
				data: {
					...state.data,
					[action.colleagueUid]: {
						...state.data[action.colleagueUid],
						startChat: true,
					},
				},
			};
		case types.LIKE_MATCH_ADDED:
			const uid = Object.keys(action.payload)[0];
			state.data[uid] = action.payload[uid];
			break;
		case types.LIKE_MATCH_UPDATE:
			state.data[action.payload.uid] = action.payload;
			break;
		case blocksTypes.BLOCKS_BLOCK_USER:
			delete state.data[action.user];
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
