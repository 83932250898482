import {
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextareaAutosize,
} from "@mui/material";
import UploadImage from "components/login/UploadImage";
import AutocompleteCity from "components/ui/AutocompleteCity";
import AutocompleteSelect from "components/ui/AutocompleteSelect";
import { Button } from "@mui/material";
import ErrorLabel from "components/ui/ErrorLabel";
import Text from "components/ui/Text";
import TextComponent from "components/ui/TextComponent";
import profileFields from "consts/profileFields";
import { useFormik } from "formik";
import useProfileImages from "hooks/useProfileImages";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import { getTags } from "redux/tags/selectors";
import TagsService from "services/tags";
import * as Yup from "yup";
import RegisterBtnWrap from "./RegisterBtnWrap";
import RegisterTalent from "./RegisterTalent";
import _ from "lodash";
import UsersTypes from "consts/UsersTypes";
import serviceArea from "consts/serviceArea";
import LoginHeader from "components/login/LoginHeader";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import useNavigator from "hooks/useNavigator";
import { ScrollToFieldError } from "helpers/FormikErrors";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default ({ nextStep, user, type = "register", goBack, currentStep = 1, pathType }) => {
	const dispatch = useDispatch();
	const tags = useSelector(getTags);
	const { userProfile, registerStep } = useUser();
	const { saveNewImage } = useProfileImages();
	const [imageValue, setImageValue] = useState();
	const [isImageValid, setIsImageValid] = useState();
	const [inProgress, setInProgress] = useState(false);
	const [tagsToDisplay, setTagsToDisplay] = useState([]);
	// const [tagsCategory, setTagsCategory] = useState([]);
	const [showOnlyPinned, setShowOnlyPinned] = useState(false);
	const [orderTags, setOrderTags] = useState([]);
	const [titleCategory, setTitleCategory] = useState("");
	// const [serviceAreaTags, setServiceAreaTags] = useState([]);
	const [pickMainTag, setPickMainTag] = useState(false);
	const navigate = useNavigator();

	const btnText = type === "register" ? i18next.t("register.next") : i18next.t("register.save");

	// const onSelectServiceArea = (value) => {
	// 	if (titleCategory == "") return setPickMainTag(true);
	// 	setPickMainTag(false);
	// 	if (serviceAreaTags.includes(value)) {
	// 		//if tag is in list = remove
	// 		setServiceAreaTags(serviceAreaTags.filter((tag) => tag !== value));
	// 	} else {
	// 		//if tag is not in list = add
	// 		const tagsData = tagsCategory.find((tag) => tag.key == titleCategory.key);
	// 		if (tagsData?.isEventsHall) {
	// 			setServiceAreaTags([value]);
	// 		} else {
	// 			setServiceAreaTags([...serviceAreaTags, value]);
	// 		}
	// 	}
	// };

	const handleOnChangeImage = (value) => {
		setImageValue(value);
	};

	const handleOnValidityChange = (_isValid) => {
		setIsImageValid(_isValid);
	};

	const initialValues = {
		name: user?.name ?? "",
		title: user?.title ?? "",
		// mainTag: user?.mainTag ? user.mainTag : {},
		tags:
			!!user && !!user?.tags
				? user.tags.map((tag) => {
						return tags.find((itam) => itam.key == tag.key);
				  })
				: [],
		city: {
			lng: user?.city_loc?.lng || "",
			lat: user?.city_loc?.lat || "",
			placeId: user?.placeid || "",
			city_he: user?.city_he || "",
			address: user?.address || "",
		},
		// serviceArea: user?.serviceArea ?? [],
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.max(50, i18next.t("validation.longName"))
			.required(i18next.t("validation.required")),
		title: Yup.string()
			.max(50, i18next.t("validation.longText"))
			.required(i18next.t("validation.required")),
		// mainTag: Yup.object().required(i18next.t("validation.required")),
		city: Yup.object().shape({
			address: Yup.string().required(i18next.t("validation.required")),
		}),

		// mainTag: Yup.object()
		// 	.shape({
		// 		key: Yup.string().required(i18next.t("validation.required")),
		// 	})
		// 	.required(i18next.t("validation.required")),
		//!make an error
	});

	const submitForm = async (values) => {
		if (
			!values?.tags ||
			!values.tags?.length ||
			!values?.name ||
			!values?.title ||
			(!values?.city?.address && !user?.address)
		)
			return setInProgress(false);
		setInProgress(true);
		if (imageValue && !isImageValid) {
			alert("the image is invalid");
		} else {
			const valuesObj = { ...values };
			valuesObj.city_loc = {
				lat: values.city.lat,
				lng: values.city.lng,
			};
			valuesObj.city_he = values.city.city_he;
			valuesObj.placeid = values.city.placeId;
			valuesObj.address = values.city.address;
			delete valuesObj.city;

			await registerStep(valuesObj, 1, type);
			if (imageValue) {
				await saveNewImage(imageValue, true);
			}
			if (type === "register") {
				window.scrollTo(0, 0);
				nextStep();
			} else {
				dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
			}
			setInProgress(false);
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			await submitForm(values);
		},
	});

	const changeTag = (values) => {
		const MAX_TAGS = 5;
		if (!values || values?.length > MAX_TAGS) return;
		const listTags = values.filter((tag) => !!tag?.key);
		formik.setFieldValue("tags", listTags);
	};

	const handleChangeAutoComplete = (event) => {
		let cityObj = {};
		if (!event.geometry) return;
		if (!!event.address_components) {
			const findCityObjFromAllList = event.address_components.map((add) => {
				if (add.types && add.types.includes("locality")) return add;
			});
			cityObj = findCityObjFromAllList.find((obj) => obj !== undefined);
			if (!cityObj) {
				//when no "locality" found
				const findCityObjFromAllList = event.address_components.map((add) => {
					if (add.types && add.types.includes("administrative_area_level_2")) return add;
				});
				cityObj = findCityObjFromAllList.find((obj) => obj !== undefined);
			}
		}
		const city = {
			lng: event.geometry.location.lng(),
			lat: event.geometry.location.lat(),
			placeId: event.place_id,
			city_he: cityObj?.long_name || "",
			address: event.formatted_address,
		};
		formik.setFieldValue("city", city);
	};

	const handleInputChange = (e) => {
		if (!orderTags) return;
		if (e.target.value) return setShowOnlyPinned(false);
		return setShowOnlyPinned(true);
	};

	const sortByPriority = (arr) => {
		if (_.isEmpty(arr)) return;
		const newArr = [...arr];
		return newArr.sort((a, b) => {
			return a.priority - b.priority;
		});
	};

	// useEffect(() => {
	// 	formik.setFieldValue("serviceArea", serviceAreaTags);
	// }, [serviceAreaTags]);

	useEffect(() => {
		if (userProfile?.uid && userProfile.type !== UsersTypes.Talent) {
			//navigator.redirectToHomePage();
		}
	}, [userProfile]);

	useEffect(() => {
		if (showOnlyPinned) {
			setTagsToDisplay(orderTags.filter((tag) => tag.isPinned === true));
		} else {
			setTagsToDisplay(orderTags);
		}
	}, [showOnlyPinned]);
	useEffect(() => {
		setOrderTags(sortByPriority(tags));
	}, [tags]);

	useEffect(() => {
		if (orderTags && orderTags.length) {
			setShowOnlyPinned(true);
		}
	}, [orderTags]);

	// useEffect(async () => {
	// 	await TagsService.getTagsCategory().then((data) => {
	// 		if (!data) return;
	// 		setTagsCategory(Object.values(data));
	// 	});
	// 	setTagsCategory(tags.filter((tag) => tag?.isMainTag == true));
	// }, []);

	// useEffect(() => {
	// 	// if (!!user && user?.serviceArea) {
	// 	// 	setServiceAreaTags(user?.serviceArea);
	// 	// }
	// 	if (!!user && user?.mainTag) {
	// 		const tag = tagsCategory.find((tag) => tag.key == user.mainTag.key);
	// 		setTitleCategory(tag);
	// 		formik.setFieldValue("mainTag", tag);
	// 	}
	// }, [tagsCategory]);

	return (
		<>
			<LoginHeader step={1} textHeader={i18next.t("register.accountDetails")} />
			<form
				className="reg-step1"
				id="register-step-1"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "10px" }}>
				<Box>
					<Box className="reg-title">
						{i18next.t("register.NameServiceProviderAndBusiness")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</Box>
					<TextComponent value={"name"} {...formik.getFieldProps("name")} id="name" />

					<ErrorLabel>{formik.touched.name && formik.errors.name}</ErrorLabel>
				</Box>
				<Box>
					<Box className="reg-title">
						{i18next.t("register.title")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</Box>
					<Box className="skills">{i18next.t("register.underNameDescribeExpert")}</Box>
					<TextComponent value={"title"} {...formik.getFieldProps("title")} id="title" />
					<ErrorLabel>{formik.touched.title && formik.errors.title}</ErrorLabel>
				</Box>

				<Box className="wrapTagsInReg" sx={{ marginTop: "10px" }}>
					<Box className="reg-title">
						{i18next.t("register.areasSpecializationAndRelevantTags")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</Box>
					<Box className="skills" sx={{ fontSize: "14px" }}>
						{`${i18next.t(
							"register.choose the tags that customers can use to find you"
						)} ${i18next.t("register.until 5 tags")}`}
					</Box>
					{tagsToDisplay && (
						<AutocompleteSelect
							data={tagsToDisplay}
							placeholder={!formik.getFieldProps("tags")?.value?.length ? "הוסף..." : ""}
							onChangeHandler={(value) => changeTag(value)}
							onInputChange={handleInputChange}
							freeSolo={false}
							{...formik.getFieldProps("tags")}
						/>
					)}
				</Box>
				<Box
					className="skills"
					sx={{ fontSize: "14px", marginBottom: "0 !important", margin: "10px 0 0" }}>
					{`לא מצאת את התגיות שמתארות את השירות שלך?`}
				</Box>
				<Button
					variant="text"
					sx={{
						background: "#fff !important",
						padding: "0px",
						color: "#717171",
						lineHeight: 1,
						margin: "0 0 10px",
						minWidth: 0,
						textDecoration: "underline",
					}}
					size="13px"
					className=""
					onClick={() => {
						dispatch(openDialog({ dialog: dialogsTypes.TagsSuggestions }));
					}}
					fontWeight={500}>{`ניתן לשלוח הצעה לתגיות נוספות`}</Button>

				<Box className="reg-title" sx={{ margin: "20px 0 -7px" }}>
					{i18next.t("register.addressServiceProviderAndBusiness")}
					<span style={{ color: "#ef2e2e" }}>{" *"}</span>
				</Box>
				<Box className="wrapAutocompleteCityInReg" sx={{ margin: "10px 0" }}>
					<AutocompleteCity
						placeholder={i18next.t("register.businessLocation")}
						defaultValue={user?.address}
						onChangeAutoComplete={handleChangeAutoComplete}></AutocompleteCity>
					<ErrorLabel>
						{formik.touched.city && formik.errors.city && i18next.t("validation.required")}
					</ErrorLabel>
				</Box>

				{/* <Box className="reg-title" sx={{ marginTop: "20px" }}>
					{i18next.t("register.serviceArea")}
				</Box>
				<Box className="skills center" sx={{ textAlign: "center" }}>
					{i18next.t("register.choose the perfect area")}
				</Box>
				<Box
					sx={{
						overflow: "hidden",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
					}}>
					{Object.entries(serviceArea).map(([key, area]) => (
						<TagComponent
							keyName={key}
							tagName={i18next.t(`serviceArea.${area}`)}
							onSelect={onSelectServiceArea}
							serviceAreaTags={serviceAreaTags}
						/>
					))}
				</Box> */}
				{type === "register" && (
					<>
						<Box className="center title" sx={{ marginBottom: "10px" }}>
							{i18next.t("register.addProfilePicOrLogo")}
						</Box>

						<UploadImage
							onChange={handleOnChangeImage}
							onValidityChange={handleOnValidityChange}
							profilePic={true}
							value={imageValue}
						/>
					</>
				)}

				<Box sx={{ marginBottom: "10px" }}>
					<RegisterBtnWrap
						type={type}
						inProgress={inProgress}
						btnText={btnText}
						currentStep={currentStep}
						goBack={goBack}></RegisterBtnWrap>
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
		</>
	);
};

// function TagComponent({ tagName, onSelect, keyName, serviceAreaTags = [], user }) {
// 	const [selected, setSelected] = useState(false);
// 	const handleSelected = () => {
// 		onSelect(keyName);
// 	};

// 	useEffect(() => {
// 		if (serviceAreaTags.includes(keyName)) {
// 			setSelected(true);
// 		} else {
// 			setSelected(false);
// 		}
// 	}, [serviceAreaTags]);

// 	return (
// 		<button
// 			type="button"
// 			onClick={handleSelected}
// 			style={{
// 				border: "#fff 0px solid",
// 				margin: "5px",
// 				borderRadius: "10px",
// 				background: selected ? "#525bc2" : "#f2f3f7",
// 				padding: "5px 20px",
// 				minHeight: " 15px",
// 				fontSize: "13px",
// 				color: selected ? "#fff" : "#171717",
// 			}}>
// 			{tagName}
// 		</button>
// 	);
// }
