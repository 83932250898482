import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import "assets/scss/profile/profileWrapper/profileWrapper.scss";
import useNavigator from "hooks/useNavigator";
import TabsLayout from "layouts/tabsLayout";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import arrowBlueProfile from "assets/images/svgs/arrowBlueProfile.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import useUser from "hooks/useUser";
import UsersTypes from "consts/UsersTypes";
import recommendations from "services/recommendations";
import functionsService from "services/functionsService";

export default function ProfileWrapper({
	component: Component,
	showGoBack = true,
	goBack = () => {},
	icon = "",
	headerTitle = "",
	userProfile = {},
	...prop
}) {
	const [showArrowBack, setShowArrowBack] = useState(showGoBack);
	const navigator = useNavigator();
	const { pathname } = useLocation();

	const redirectToProfile = () => {
		navigator.navigateToProfilePage();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		functionsService.setOnline();
		// if (
		// 	pathname == "/profile/editAccount" &&
		// 	userProfile.type == UsersTypes.Talent &&
		// 	userProfile?.registerStep < 6
		// ) {
		// 	setShowArrowBack(false);
		// }
	}, []);

	return (
		<TabsLayout>
			<Box className="profileWrapper" sx={{ minWidth: "100%" }}>
				<Box className="profileHeaderAndTitle">
					{showArrowBack && (
						<span className="arrowBlueProfile" onClick={redirectToProfile}>
							<img src={arrowBlueProfile} alt="" />
						</span>
					)}
					<Box className="profileTitle" onClick={redirectToProfile}>
						{/* <img src={icon} alt="" /> */}
						<span>{headerTitle}</span>
					</Box>
				</Box>
				<Component {...prop}></Component>
			</Box>
		</TabsLayout>
	);
}
