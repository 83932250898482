import { Box } from "@mui/material";
import useNavigator from "hooks/useNavigator";
import i18next from "i18next";

export default function UsefulLinks() {
	const { navigateToPath } = useNavigator();
	const handleBlogLink = (e) => {
		e.stopPropagation();
		navigateToPath("blog");
	};
	return (
		<Box className="boxWrapper usefulLinks">
			<h4>{i18next.t("landingPage.usefulLinks")}</h4>
			<Box className="usefulContent">
				<a onClick={handleBlogLink} className="">
					{"בלוג"}
				</a>
			</Box>
		</Box>
	);
}
