import { mergeMap, map, takeUntil, catchError, repeat, switchMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";

import ChatSentService from "services/chatSent";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchChatSent = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    switchMap(async () => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      try {
        const payload = await ChatSentService.fetchChatSent(uid);
        return {
          type: types.CHAT_SENT_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.CHAT_SENT_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.CHAT_SENT_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchChatSentAdded = (action$, store) =>
  action$.pipe(
    ofType(types.CHAT_SENT_FETCH_SUCCESSFULLY),
    switchMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      console.log("listenChatSentAdded");

      return ChatSentService.listenChatSentAdded(uid).pipe(
        map((change) => ({
          type: types.CHAT_SENT_ADDED_SUCCESSFULLY,
          payload: change,
        }))
      );
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.CHAT_SENT_ADDED_ERROR,
        payload: error?.message,
      });
    }),
    repeat()
  );

export const fetchChatSentUpdate = (action$, store) =>
  action$.pipe(
    ofType(types.CHAT_SENT_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return ChatSentService.listenChatSentUpdates(uid).pipe(
        map((change) => ({
          type: types.CHAT_SENT_UPDATED_SUCCESSFULLY,
          payload: change,
        }))
      );
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.CHAT_SENT_UPDATED_ERROR,
        payload: error?.message,
      });
    }),
    repeat()
  );

export const fetchChatSentRemoved = (action$, store) =>
  action$.pipe(
    ofType(types.CHAT_SENT_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return ChatSentService.listenChatSentRemoved(uid).pipe(
        map((change) => ({
          type: types.CHAT_SENT_REMOVED_SUCCESSFULLY,
          payload: change,
        }))
      );
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.CHAT_SENT_REMOVED_ERROR,
        payload: error?.message,
      });
    }),
    repeat()
  );
