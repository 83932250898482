import firebase from "firebase/app";
import "firebase/functions";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";
const functions = firebase.functions();

class SearchService {
	async search(
		pitId,
		searchAfter,
		extraData,
		sortOption,
		builderType = SearchQueryBuilderType.Search
	) {
		const result = await functions.httpsCallable("searchCall")({
			pitId,
			searchAfter,
			extraData,
			sortOption,
			builderType,
		});
		return result.data;
	}
}

export default new SearchService();
