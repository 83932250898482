export default Object.freeze({
	viewOut: "viewOut",
	viewIn: "viewIn",
	clickSendMessage: "clickSendMessage",
	clickWhatsapp: "clickWhatsapp",
	clickShare: "clickShare",
	clickShareFacebook: "clickShareFacebook",
	clickShareWhatsapp: "clickShareWhatsapp",
	clickShareTwitter: "clickShareTwitter",
	clickAddFavorite: "clickAddFavorite",
	clickPhone: "clickPhone",
	clickWaze: "clickWaze",
	clickLinks: "clickLinks",
	clickYoutubeLink: "clickYoutubeLink",
	clickInstagramLink: "clickInstagramLink",
	clickTiktokLink: "clickTiktokLink",
	clickFacebookLink: "clickFacebookLink",
  clickLinkedinLink: "clickLinkedinLink",
  clickWebLink: "clickWebLink",
	clickPhotos: "clickPhotos",
	clickFriends: "clickFriends",
	clickPhoneFriend: "clickPhoneFriend",
	userSendMessage: "userSendMessage",
	recommendation: "recommendation",
});
