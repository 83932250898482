import "assets/scss/landing/landingCategories.scss";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import categoriesService from "services/categoriesService";
import { useDispatch, useSelector } from "react-redux";
import { FreeMode, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import i18next from "i18next";

import advertising from "assets/images/svgs/category/advertising.svg";
import architect from "assets/images/svgs/category/architect.svg";
import beauty from "assets/images/svgs/category/beauty.svg";
import coaching from "assets/images/svgs/category/coaching.svg";
import coding from "assets/images/svgs/category/coding.svg";
import constructions from "assets/images/svgs/category/constructions.svg";
import design from "assets/images/svgs/category/design.svg";
import events from "assets/images/svgs/category/events.svg";
import finance from "assets/images/svgs/category/finance.svg";
import fitness from "assets/images/svgs/category/fitness.svg";
import internetSocial from "assets/images/svgs/category/internet-social.svg";
import home from "assets/images/svgs/category/key.svg";
import lecturer from "assets/images/svgs/category/lecturer.svg";
import managing from "assets/images/svgs/category/managing.svg";
import music from "assets/images/svgs/category/music.svg";
import teachers from "assets/images/svgs/category/teachers.svg";
import video from "assets/images/svgs/category/video.svg";
import genery from "assets/images/svgs/category/genery.svg";
import { search } from "redux/search/actions";
import useNavigator from "hooks/useNavigator";

export default function LandingCategories({ isFreelancers = false, isMobile = false }) {
	const dispatch = useDispatch();
	const [categories, setCategories] = useState([]);
	const { navigateToPath } = useNavigator();

	const getImageByKey = (key) => {
		switch (key) {
			case "-NWplWYMmwC9i9jrd7Nv":
				return advertising;
			case "-NWplAzmHJSp314fIfar":
				return architect;
			case "-NXdTsnUOcN3GIUHFayG":
				return beauty;
			case "-NWpkbeWZmwsTL4SPhbE":
				return coaching;
			case "-NWplDXWeZ4D1HOHkfKX":
				return coding;
			case "-NWpjc_HoYKh8aw_yxQB":
				return constructions;
			case "-NXdUPcCU7ztFVsugQLa":
				return design;
			case "-NWpl0ZE_JCxIBiMQ4kk":
				return events;
			case "-NWpk7x0SXQ761FP5Pzn":
				return finance;
			case "-NWpjrdVOzo2Vmg8wgLa":
				return fitness;
			case "-NWpj6rdDsqaeadybmsY":
				return internetSocial;
			case "-NWplRysl1c8e0vwV-cr":
				return home;
			case "-NWplHRsSxHkprSh1Fts":
				return lecturer;
			case "-NXdVDuOCsgtgKIWX7Yd":
				return managing;
			case "-NWplKiFlu6jgJJ5vlcj":
				return music;
			case "-NXdVo-5kubMr6YRhWLC":
				return teachers;
			case "-NWpl5ibrkYqsfuBAsFP":
				return video;

			default:
				return genery;
		}
	};

	const handleCategory = (urlName) => {
		if (!!isFreelancers) return;
		navigateToPath(`/search?category=${urlName}`);
	};

	useEffect(() => {
		dispatch(search({ category: "" }));

		const getCategories = async () => {
			const categoryData = await categoriesService.getCategories();
			if (!categoryData?.length) return;
			setCategories(categoryData.filter((c) => !c?.deleted));
		};
		getCategories().catch((error) => console.error(error));
	}, []);

	return (
		<Box className={`landingCategoriesWrapper ${!isFreelancers ? "isUsers" : ""}`}>
			<Box className="wrapCategoryText">
				<h4>
					{`${i18next.t("landingPage.advertiseProfessionalsFrom")} `}
					<span style={{ fontWeight: 700 }}>{`${i18next.t(
						"landingPage.varietyCategories"
					)}, `}</span>
					{`${i18next.t("landingPage.suchAs")}:`}
				</h4>
			</Box>
			<Box className="categoriesList">
				{!!categories && (
					<Swiper
						id="categoriesSwiper"
						slidesPerView={"auto"}
						spaceBetween={!isMobile ? 50 : 10}
						pagination={{
							clickable: true,
						}}
						// loop={true}
						// autoplay={{
						// 	delay: 2000,
						// 	disableOnInteraction: false,
						// }}
						navigation={true}
						modules={[Autoplay, FreeMode]}
						className="mySwiper">
						{!!categories &&
							!!categories?.length &&
							categories.map((item) => (
								<SwiperSlide key={item.key} style={{ width: "min-content" }}>
									<Box className="imgAndName" onClick={() => handleCategory(item?.urlName)}>
										<img src={getImageByKey(item.key)} />
										<span style={{ paddingLeft: "5px" }}>{item?.name ?? ""}</span>
									</Box>
								</SwiperSlide>
							))}
					</Swiper>
				)}
			</Box>
		</Box>
	);
}
