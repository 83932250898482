import "assets/scss/siteBunner/siteBunner.scss";
import i18next from "i18next";
import { Box } from "@mui/system";
import wrapImgBunner from "assets/images/leaveDetailsImg.png";
import shareYourCard from "assets/images/shareYourCard.png";
import { useDispatch } from "react-redux";
import useUser from "hooks/useUser";
import UsersTypes from "consts/UsersTypes";
import Button from "components/ui/Button";
import { openNotification } from "redux/notification/actions";
import useNavigator from "hooks/useNavigator";

export default function SiteTalentBanner() {
	const dispatch = useDispatch();
	const { userProfile } = useUser();
	const { navigateToPath } = useNavigator();

	const handleCopyUrl = async (isTalent) => {
		if (userProfile.internalID) {
			const message = `היי, ראיתי את ${userProfile?.name} ב"ששת ימים" - פלטפורמת ספקים ונותני שירותים מבוססת המלצות חברים משותפים ובינה מלאכותית. מה דעתך?🙏`;
			try {
				await navigator.share({
					title: `מה דעתך על ${userProfile?.name}`,
					text: message,
					url: !!isTalent
						? navigator.clipboard.writeText(
								`${window.location.origin}/talent/${userProfile?.internalID}?${
									userProfile.internalID ? `affId=${userProfile.internalID}` : ""
								}`
						  )
						: navigator.clipboard.writeText(
								`${window.location.origin}/?${
									userProfile.internalID ? `affId=${userProfile.internalID}` : ""
								}`
						  ),
				});
				return navigateToPath("/profile/myBusinessCard");
			} catch (error) {
				console.log(error.message);
			}
		}
	};

	const shareTxt = [
		i18next.t("siteBunner.shareYourCard"),
		<span style={{ margin: "0 10px 0 0" }}>
			<img src={shareYourCard} />
		</span>,
	];

	return (
		<Box className="wrapSiteBunner">
			<Box className="wrapImgBunner">
				<img src={wrapImgBunner} alt="" />
			</Box>
			<Box className="wrapCardBunner">
				<h2 className="cardTitle">{i18next.t("siteBunner.recomendationItsAllStory")}</h2>
				<Box className="cardContent">
					{i18next.t("siteBunner.weSureYouHaveLotsOfSatisfiedCustomers")}
				</Box>
				<Box className="center">
					<Button
						variant=""
						onClick={() => handleCopyUrl(userProfile.type == UsersTypes.Talent)}
						sx={{
							background: "#386BA8 !important",
							borderRadius: "25px",
							padding: "4px 20px",
							margin: "10px 0",
							lineHeight: "1",
						}}
						size="19px"
						fontWeight={600}>
						{shareTxt}
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
