// import React from 'react'

import { Box } from "@mui/material";
import InfoLine from "./info/InfoLine";
import resumeTalent from "assets/images/talent/resumeTalent.png";
import i18next from "i18next";
import { useEffect, useState } from "react";

import waze from "assets/images/talent/viewTalent/waze.png";
import facebook from "assets/images/talent/viewTalent/facebook.png";
import youtube from "assets/images/talent/viewTalent/youtube.png";
import linkedin from "assets/images/talent/viewTalent/linkedin.png";
import instagram from "assets/images/talent/viewTalent/instagram.png";
import computer from "assets/images/talent/viewTalent/computer.png";
import facebookGray from "assets/images/talent/viewTalent/facebook-gray.png";
import youtubeGray from "assets/images/talent/viewTalent/youtube-gray.png";
import linkedinGray from "assets/images/talent/viewTalent/linkedin-gray.png";
import instagramGray from "assets/images/talent/viewTalent/instagram-gray.png";
import computerGray from "assets/images/talent/viewTalent/computer-gray.png";
import tiktok from "assets/images/talent/viewTalent/tiktok.png";
import tiktokGray from "assets/images/talent/viewTalent/tiktokGray.png";
import { useDispatch } from "react-redux";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";

export default function InfoAndSocial({ user, userProfile: myUserProfile = null }) {
	const [infoValues, setInfoValues] = useState([]);
	const [icons, setIcons] = useState([]);
	const dispatch = useDispatch();

	const openLinkBnt = (openLink) => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: i18next.t("talent.openLinkQuestion"),
				onYes: async () => {
					openLink();
					toggelClose();
				},
				onNo: toggelClose,
				yesBtn: "כן",
			})
		);
	};
	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};

	useEffect(() => {
		setInfoValues([
			{
				key: "location",
				value: [
					<span style={{ padding: "0 0 0 10px " }}>{i18next.t("talent.waze")}</span>,
					<img style={{ cursor: "pointer" }} src={waze} />,
				],
				textValue: user.address ?? "",
				onClick: async () => {
					if (user?.uid) functionsService.addTalentAction(user?.uid, talentAction.clickWaze);
					if (!user?.city_loc) return;
					window.open(
						`https://waze.com/ul?ll=${user.city_loc.lat},${user.city_loc.lng}&navigate=yes`,
						"_blank"
					);
				},
			},

			// {
			// 	key: "maximumNumberOfGuests",
			// 	value: i18next.t("talent.maximumNumberOfGuests"),
			// 	textValue: user.maximumNumberOfGuests ?? "",
			// },

			// {
			// 	key: "mealPrice",
			// 	value: i18next.t("talent.mealPrice"),
			// 	textValue: user.mealPrice ?? "",
			// },
			// {
			// 	key: "kosherType",
			// 	value: i18next.t("talent.kosherType"),
			// 	textValue: user.kosherType ?? "",
			// },
		]);
		setIcons([
			{
				type: "site",
				icon: user?.site ? (
					<img style={{ cursor: "pointer" }} src={computer} />
				) : (
					<img src={computerGray} />
				),
				onclick: user?.site
					? async () => {
							if (user?.uid)
								await functionsService.addTalentAction(user?.uid, talentAction.clickWebLink);

							window.open(user?.site);
					  }
					: () => {},
			},
			{
				type: "facebook",
				icon: user?.facebook ? (
					<img style={{ cursor: "pointer" }} src={facebook} />
				) : (
					<img src={facebookGray} />
				),
				onclick: user?.facebook
					? async () => {
							if (user?.uid)
								await functionsService.addTalentAction(user?.uid, talentAction.clickFacebookLink);

							window.open(user?.facebook);
					  }
					: () => {},
			},
			{
				type: "tiktok",
				icon: user?.tikTok ? (
					<img style={{ cursor: "pointer" }} src={tiktok} />
				) : (
					<img src={tiktokGray} />
				),
				onclick: user?.tikTok
					? async () => {
							if (user?.uid)
								await functionsService.addTalentAction(user?.uid, talentAction.clickTiktokLink);

							window.open(user?.tikTok);
					  }
					: () => {},
			},
			{
				type: "instagram",
				icon: user?.instagram ? (
					<img style={{ cursor: "pointer" }} src={instagram} />
				) : (
					<img src={instagramGray} />
				),
				onclick: user?.instagram
					? async () => {
							if (user?.uid)
								await functionsService.addTalentAction(user?.uid, talentAction.clickInstagramLink);

							window.open(user?.instagram);
					  }
					: () => {},
			},
			{
				type: "youtube",
				icon: user?.youtube ? (
					<img style={{ cursor: "pointer" }} src={youtube} />
				) : (
					<img src={youtubeGray} />
				),
				onclick: user?.youtube
					? async () => {
							if (user?.uid)
								await functionsService.addTalentAction(user?.uid, talentAction.clickYoutubeLink);

							window.open(user?.youtube);
					  }
					: () => {},
			},
			// {
			// 	type: "linkedin",
			// 	icon: user?.linkedin ? (
			// 		<img style={{ cursor: "pointer" }} src={youtube} />
			// 	) : (
			// 		<img src={youtubeGray} />
			// 	),
			// 	onclick: user?.linkedin
			// 		? async () => {
			// 				if (user?.uid)
			// 					await functionsService.addTalentAction(user?.uid, talentAction.clickLinkedinLink);

			// 				window.open(user?.linkedin);
			// 		  }
			// 		: () => {},
			// },
		]);
	}, [user]);

	return (
		<Box className="wrapInfoAndSocial">
			{/* {infoValues &&
				infoValues.length > 0 &&
				(user?.talentType == "supplier"
					? infoValues.map(
							(item) =>
								item.textValue && (
									<InfoLine
										onClick={item.onClick}
										key={item.key}
										item={item}
										value={item.value}
										textValue={item.textValue}
									/>
								)
					  )
					: infoValues.map((item) =>
							user?.uid !== myUserProfile?.uid ? (
								item.textValue && (
									<InfoLine
										onClick={item.onClick}
										key={item.key}
										item={item}
										value={item.value}
										textValue={item.textValue}
									/>
								)
							) : (
								<InfoLine
									onClick={item.onClick}
									key={item.key}
									item={item}
									value={item.value}
									textValue={item.textValue}
								/>
							)
					  ))} */}

			<Box className="socialIcons center">
				{icons &&
					icons.length > 0 &&
					icons.map((icon) => (
						<span onClick={icon.onclick} key={icon.type}>
							{icon.icon}
						</span>
					))}
			</Box>
		</Box>
	);
}
