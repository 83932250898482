import "assets/scss/profile/editAccount.scss";
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import penEdit from "assets/images/svgs/penEdit.svg";
import i18next from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorLabel from "components/ui/ErrorLabel";

export default function EditTextLine({
	keyField = "",
	valueField = "",
	fieldName = "",
	handleSave = () => {},
	showEdit = false,
	isMulti = false,
	subField = "",
	formikValidation = {},
}) {
	const [value, setValue] = useState(valueField || "");
	const [showEditField, setShowEditField] = useState(showEdit);

	const initialValues = {
		[keyField]: valueField ?? "",
	};

	const validationSchema = Yup.object().shape({
		...formikValidation,
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			saveField();
		},
	});

	const handleChange = (e) => {
		setValue(e.target.value);
		formik.setFieldValue(keyField, e.target.value);
	};

	const validYouTube = () => {
		let newYoutubeLink = value;
		const splitUrlLink = value.split("/");
		if (splitUrlLink.includes("youtu.be")) {
			const linkId = splitUrlLink.slice(-1)[0];
			newYoutubeLink = `https://www.youtube.com/watch?v=${linkId}`;
		}
		setValue(newYoutubeLink);
		handleSave(keyField, newYoutubeLink);
		return setShowEditField(false);
	};

	const saveField = () => {
		if (value === valueField) return setShowEditField(false);
		if (keyField === "youTubeUrlVideo" || keyField === "youtubeChanel") return validYouTube();

		handleSave(keyField, value);
		setShowEditField(false);
	};

	const cencelField = () => {
		setValue(valueField);
		formik.setFieldValue(keyField, valueField);
		setShowEditField(false);
	};

	return (
		<form className="edit-text-line" onSubmit={formik.handleSubmit}>
			<Box className="edit-text-title-field">
				<Box className="noSelection field-name">
					<Box className="">{fieldName}</Box>
					{subField && <Box className="sub-title">{subField}</Box>}
				</Box>
				<Box className="field-value">
					{!showEditField ? (
						<Box className="fixed-text" onClick={() => setShowEditField(true)}>
							{value}
						</Box>
					) : (
						<TextField
							{...formik.getFieldProps(keyField)}
							multiline={isMulti}
							autoFocus
							fullWidth
							onKeyPress={(e) => {
								e.key === "Enter" && !isMulti && e.preventDefault();
							}}
							className="text-edit-field"
							value={keyField}
							{...formik.getFieldProps(keyField)}
							onChange={handleChange}
							id={keyField}></TextField>
					)}
					{showEditField && (
						<ErrorLabel>{formik.touched[keyField] && formik.errors[keyField]}</ErrorLabel>
					)}
				</Box>
			</Box>
			<Box className="edit-text-btns-field">
				{!showEditField ? (
					<button onClick={() => setShowEditField(true)}>
						<img src={penEdit} alt="edit" />
					</button>
				) : (
					<Box className="save-edit">
						<Button className="editBtn saveBtn" onClick={formik.handleSubmit} variant="outlined">
							<span>{i18next.t("editProfile.save")}</span>
						</Button>
						<Button className="editBtn cancelBtn" onClick={cencelField} variant="outlined">
							<span>{i18next.t("editProfile.cancel")}</span>
						</Button>
					</Box>
				)}
			</Box>
		</form>
	);
}
