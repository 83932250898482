import { Box } from "@mui/system";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import dialogsTypes from "consts/dialogsTypes";
import useNavigator from "hooks/useNavigator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType, getDialogs } from "redux/dialog/selectors";

export default ({
	pathType = "register",
	component: Component,
	goToStep,
	currentStep,
	showGoBack,
	setCurrentStep,
	withHeader = false,
	platform = "web",
	...rest
}) => {
	const dispatch = useDispatch();
	const { dialogs } = useSelector(getDialogs);
	const { navigateToPath } = useNavigator();
	const loginDialogData = dialogs.find((element) => element.dialog === dialogsTypes.Login);

	const goBack = () => {
		if (currentStep === 1) {
			dispatch(closeDialogByType(dialogsTypes.Login));
			if (!loginDialogData && platform !== "web") navigateToPath("/openBusinessCardAppPage");
		} else {
			goToStep(currentStep - 1);
		}
	};
	const handleCurrentStep = (step = 1) => {
		setCurrentStep(step);
	};

	useEffect(() => {
		handleCurrentStep(currentStep);
	}, [currentStep]);

	return (
		<>
			{withHeader && (
				<Box>
					<Box
						sx={{
							position: "relative",
							minHeight: "18px",
							background: "#7496bf",
							width: "100%",
						}}></Box>
				</Box>
			)}
			<Component
				pathType={pathType}
				currentStep={currentStep}
				goBack={goBack}
				{...rest}></Component>
		</>
	);
};
