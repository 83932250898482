import { createSelector } from "reselect";

export const getContactsMatching = (state) => state.contacts.contactsMatching;

export const getContactsMatchingByTalentId = (uid) => {
	return createSelector(getContactsMatching, (contacts) => {
		return contacts[uid];
	});
};

export const getMyContactFriends = (state) => state?.contacts?.myContactFriends;
