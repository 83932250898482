import "assets/scss/landing/landingExposure.scss";
import { Box } from "@mui/material";
import { useState } from "react";
import i18next from "i18next";
import { Button } from "reactstrap";
import man from "assets/images/svgs/landing/men-caracter.png";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default function LandingExposure({ isMobile = false }) {
	const dispatch = useDispatch();
	const [countUsers, setCountUsers] = useState("250,000 ");

	const openLoginPopup = () => {
		return dispatch(openDialog({ dialog: dialogsTypes.Login, registerType: "talent" }));
	};
	return (
		<Box className="landingExposure">
			<img src={man} alt="img" />
			<Box className="exposureContent">
				<Box className="wrapText">
					<Box>{`${i18next.t("landingPage.SignupGetExposure")} `}</Box>
					<Box className="countText">
						<b style={{ color: "#5F6EF8" }}>{countUsers}</b>
						<b>{`${i18next.t("landingPage.thousandUsers")}`}</b>
					</Box>
					<Button className="btnAbsolute" onClick={openLoginPopup}>
						{!isMobile
							? i18next.t("landingPage.joinNowOutFreelancersTeam")
							: i18next.t("landingPage.joinNow")}
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
