export const CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_FETCH_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_FETCH_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_FETCH_FAILED";

export const CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_ADDED_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_ADDED_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_ADDED_FAILED";

export const CHAT_RECEIVED_FILTERED_UPDATED_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_UPDATED_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_UPDATED_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_UPDATED_FAILED";

export const CHAT_RECEIVED_FILTERED_REMOVED_SUCCESSFULLY =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_REMOVED_SUCCESSFULLY";
export const CHAT_RECEIVED_FILTERED_REMOVED_FAILED =
	"chatReceivedFiltered.CHAT_RECEIVED_FILTERED_REMOVED_FAILED";

export const UPDATE_MESSAGES_FILTER_SUCCESFULLY =
	"chatReceivedFiltered.UPDATE_MESSAGES_FILTER_SUCCESFULLY";
