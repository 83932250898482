export const CHAT_SENT_FETCH_SUCCESSFULLY = "chatSent.CHAT_SENT_FETCH_SUCCESSFULLY";
export const CHAT_SENT_FETCH_FAILED = "chatSent.CHAT_SENT_FETCH_FAILED";

export const CHAT_SENT_UPDATED_SUCCESSFULLY = "chatSent.CHAT_SENT_UPDATED_SUCCESSFULLY";
export const CHAT_SENT_ADDED_SUCCESSFULLY = "chatSent.CHAT_SENT_ADDED_SUCCESSFULLY";
export const CHAT_SENT_REMOVED_SUCCESSFULLY = "chatSent.CHAT_SENT_REMOVED_SUCCESSFULLY";
export const CHAT_SENT_UPDATED_ERROR = "chatSent.CHAT_SENT_UPDATED_ERROR";
export const CHAT_SENT_ADDED_ERROR = "chatSent.CHAT_SENT_ADDED_ERROR";
export const CHAT_SENT_REMOVED_ERROR = "chatSent.CHAT_SENT_REMOVED_ERROR";

export const CHAT_SENT_SEND_MESSAGE = "chatSent.SEND_MESSAGE";
