import _ from "lodash";
import moment from "moment";

import * as types from "./actionTypes";
import { getLoggedInUserUID } from "redux/auth/selectors";
import QueueService from "services/queue";
import ViewsEvents from "consts/viewEvents";
import ViewsService from "services/views";
import LocalStorageService from "services/localStorage";
import { HEIGHT_MIN, HEIGHT_MAX } from "consts/fillProfileDetailsValidations";
import FilterLimits from "services/fiterLimits";

export const addFiltersSelected = (data) => {
	return (dispatch, getState) => {
		dispatch({
			type: types.SEARCH_ADD_FILTERS_SELECTED,
			payload: data,
		});
	};
};

export const search = (cleanFilter, sortOption) => {
	return (dispatch, getState) => {
		dispatch({
			type: types.SEARCH_FETCH,
			freeText: "",
			cleanFilter,
			selectedSortOption: sortOption,
		});
	};
};

export const searchLoadMore = (freeText) => {
	return (dispatch, getState) => {
		const filters = getSearchFilters();
		return dispatch({ type: types.SEARCH_FETCH_LOAD_MORE, freeText, filters });
	};
};

export const searchLike = (userUid) => {
	return { type: types.SEARCH_LIKE, user: userUid };
};

export const viewUser = (userUid) => {
	return (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		QueueService.sendEvent(uid, ViewsEvents.View, userUid);
		ViewsService.view(uid, userUid);
		return dispatch({ type: types.SEARCH_VIEW, user: userUid });
	};
};

export const setSearchType = (searchType) => {
	return { type: types.SEARCH_TYPE, payload: searchType };
};

export const updateRedPoint = () => {
	return { type: types.SEARCH_UPDATE_RED_POINT };
};

export const getSearchFilters = () => JSON.parse(LocalStorageService.get("currentFilters") || "{}");

export const setSearchFilters = (filters) =>
	LocalStorageService.set("currentFilters", JSON.stringify(filters));

export const getFiltersIsEmpty = (profile) => {
	const filters = getSearchFilters();
	return _.isEmpty(filters) || isDefaultValuesFilters(filters, profile);
};

const isDefaultValuesFilters = (filters, profile) => {
	const { min, max } = FilterLimits.getDefaultAgeRange(profile.gender, profile.dob);
	const heightIsDefault =
		!filters.Height || (filters.Height.from == HEIGHT_MIN && filters.Height.to == HEIGHT_MAX);
	const ageIsDefault =
		!filters.Age ||
		(moment()
			.endOf("year")
			.diff(filters.Age.from * 1000, "year") == max &&
			moment()
				.startOf("year")
				.diff(filters.Age.to * 1000, "year") == min);
	const filterHasOtherKeys = Object.keys(filters).find((key) => key !== "Height" && key != "Age");
	return heightIsDefault && ageIsDefault && !filterHasOtherKeys;
};
