import produce from "immer";

import * as types from "./actionTypes";
import * as skipsTypes from "../skips/actionTypes";
import * as blocksTypes from "../blocks/actionTypes";

const initialState = {};

export default produce((state, action) => {
  switch (action.type) {
    case types.ACTIVE_USER_ID:
      state.active_userId = action.payload.userId;
      break;
    case skipsTypes.SKIPPED_ADD:
    case blocksTypes.BLOCKS_BLOCK_USER:
      if (state.active_userId === action.user) {
        state.active_userId = null;
      }
      break;
    case types.CANCEL_ACTIVE_USER:
      state.active_userId = null;
      break;
  }
}, initialState);
