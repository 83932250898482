import { useDispatch, useSelector } from "react-redux";

import { closePopupUnderPopup, closeSecondPopupUnderPopup, showPopup } from "redux/popups/actions";
import PopupsTypes from "consts/popupsTypes";
import { updateQRCodeHeaderText } from "redux/qrcode/actions";
import useGalleryModal from "./useGalleryModal";
import { getPopups } from "redux/popups/selectors";

const usePopups = () => {
	const dispatch = useDispatch();

	const { currentUserPromotedProfile } = useSelector(getPopups);

	const { setActiveIndex, setActiveIndexMainPicture, setGalleryType } = useGalleryModal();

	const closePopup = () => dispatch(showPopup(PopupsTypes.None));

	const _closePopupUnderPopup = () => dispatch(closePopupUnderPopup());

	const _closeSecondPopupUnderPopup = () => dispatch(closeSecondPopupUnderPopup());

	const showPopupHandler = (popupType, infoObj) => dispatch(showPopup(popupType, infoObj));

	const showUnmatchUserPopup = (user) =>
		showPopupHandler(PopupsTypes.UnmatchUser, { currentUserProfile: user });

	const showBlockUserPopup = (user) =>
		showPopupHandler(
			PopupsTypes.BlockUser,
			currentUserPromotedProfile
				? { currentUserPromotedProfile: user }
				: { currentUserProfile: user }
		);

	const showReportUserPopup = (user) =>
		showPopupHandler(
			PopupsTypes.ReportUser,
			user
				? currentUserPromotedProfile
					? { currentUserPromotedProfile: user }
					: { currentUserProfile: user }
				: {}
		);

	const showReportQuestionPopup = () => showPopupHandler(PopupsTypes.QuestionReport);

	const showUserSuccessfullyBlockedPopup = () =>
		showPopupHandler(PopupsTypes.UserSuccessfullyBlocked);

	const showThanksReportUserPopup = () => showPopupHandler(PopupsTypes.ThanksReportUser);

	const showUpdatesSavedSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.UpdatesSavedSuccessfully);

	const showContactFormSentSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.ContactFormSentSuccessfully);

	const showFreezeAccountPopup = () => showPopupHandler(PopupsTypes.FreezeAccountPopup);

	const showFreezeAccountSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.FreezeAccountSuccessfullyPopup);

	const showDeleteAccountSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.DeleteAccountSuccessfullyPopup);

	const showDeleteAccountPopup = () => showPopupHandler(PopupsTypes.DeleteAccountPopup);

	const showDeleteAccountSelectReasonPopup = () =>
		showPopupHandler(PopupsTypes.DeleteAccountSelectReasonPopup);

	const showDeleteAccountAddMessagePopup = (infoObj) =>
		showPopupHandler(PopupsTypes.DeleteAccountAddMessagePopup, infoObj);

	const showLanguageSwitchPopup = () => showPopupHandler(PopupsTypes.LanguageSwitchPopup);

	const showDeleteImagePopup = (key) =>
		showPopupHandler(PopupsTypes.DeleteImagePopup, { imageKey: key });

	const showCropImagePopup = (infoObj) => showPopupHandler(PopupsTypes.CropImagePopup, infoObj);

	const showUserProfileSideBar = (user) => {
		showPopupHandler(PopupsTypes.UserProfileSideBar, {
			currentUserProfile: user,
			popupUnderPopup: true,
		});
	};

	const showUserCardPopup = (user, secondPopupUnderPopup = false) => {
		secondPopupUnderPopup
			? showPopupHandler(PopupsTypes.CardUserModal, {
					currentUserPromotedProfile: user,
					secondPopupUnderPopup: true,
			  })
			: showPopupHandler(PopupsTypes.CardUserModal, {
					currentUserProfile: user,
					popupUnderPopup: true,
			  });
	};

	const showQrcodePopup = (text) => {
		dispatch(updateQRCodeHeaderText(text));
		showPopupHandler(PopupsTypes.QRCode);
	};

	const showGalleryPopup = (galleryType, index) => {
		typeof index === "number" ? setActiveIndex(index) : setActiveIndexMainPicture(galleryType);
		dispatch(setGalleryType(galleryType));
		showPopupHandler(PopupsTypes.Gallery);
	};

	const showSubscriptionPopup = (limitSource, colleagueName) =>
		showPopupHandler(PopupsTypes.SubscriptionModal, {
			limitSource,
			colleagueName,
		});

	const showSubscriptionSuccessfullyPopup = () =>
		showPopupHandler(PopupsTypes.SubscriptionSuccessfullyPopup);

	const showUnblockQuestionPopup = (unblockUserProfile) =>
		showPopupHandler(PopupsTypes.UnblockUserPopup, { unblockUserProfile });

	const showDeleteLikedMeUsersPopup = (infoObj) =>
		showPopupHandler(PopupsTypes.DeleteLikedMeUsersPopup, infoObj);

	const showDeleteChatsPopup = (infoObj) => showPopupHandler(PopupsTypes.DeleteChatsPopup, infoObj);

	const showMassletterPopup = () => {
		showPopupHandler(PopupsTypes.MassletterPopup);
	};

	const showMassletterSentPopup = () => {
		showPopupHandler(PopupsTypes.MassletterSentPopup);
	};

	const showChatRecievedFilterPopup = () => {
		showPopupHandler(PopupsTypes.ChatReceivedFilterPopup);
	};

	const showSendMessagePopup = (user) =>
		showPopupHandler(PopupsTypes.SendMessagePopup, {
			currentUserProfile: user,
		});

	const showSendTemplateMessagePopup = (user) =>
		showPopupHandler(PopupsTypes.SendTemplateMessagePopup, {
			currentUserProfile: user,
		});

	const showBlurMessagePopup = () => showPopupHandler(PopupsTypes.BlurMessagePopup);

	const showNonSubscriberToSubscriberPopup = (user) =>
		showPopupHandler(
			PopupsTypes.NonSubscriberToSubscriberPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);

	const showNonSubscriberToNonSubscriberPopup = (user) =>
		showPopupHandler(
			PopupsTypes.NonSubscriberToNonSubscriberPopup,
			user
				? {
						currentUserProfile: user,
				  }
				: {}
		);

	const showMessageSuccessfullySentPopup = () =>
		showPopupHandler(PopupsTypes.MessageSuccessfullySentPopup);

	const showNotApprovedMessagePopup = () => showPopupHandler(PopupsTypes.NotApprovedMessagePopup);

	return {
		showUnmatchUserPopup,
		showBlockUserPopup,
		showReportUserPopup,
		showReportQuestionPopup,
		showUserSuccessfullyBlockedPopup,
		showThanksReportUserPopup,
		showUpdatesSavedSuccessfullyPopup,
		showQrcodePopup,
		showGalleryPopup,
		showContactFormSentSuccessfullyPopup,
		showFreezeAccountPopup,
		showFreezeAccountSuccessfullyPopup,
		showDeleteAccountPopup,
		showDeleteAccountSelectReasonPopup,
		showDeleteAccountAddMessagePopup,
		showDeleteAccountSuccessfullyPopup,
		showLanguageSwitchPopup,
		showDeleteImagePopup,
		showCropImagePopup,
		showUserCardPopup,
		_closePopupUnderPopup,
		_closeSecondPopupUnderPopup,
		showUserProfileSideBar,
		showSubscriptionPopup,
		showSubscriptionSuccessfullyPopup,
		showUnblockQuestionPopup,
		showDeleteLikedMeUsersPopup,
		showDeleteChatsPopup,
		showMassletterPopup,
		showMassletterSentPopup,
		showChatRecievedFilterPopup,
		showSendMessagePopup,
		showSendTemplateMessagePopup,
		showBlurMessagePopup,
		showNonSubscriberToSubscriberPopup,
		showNonSubscriberToNonSubscriberPopup,
		showNotApprovedMessagePopup,
		showMessageSuccessfullySentPopup,
		showPopupHandler,
		closePopup,
	};
};

export default usePopups;
