export const PROFILE_FETCH_PROFILE_SUCCESSFULLY = "profile.PROFILE_FETCH_PROFILE_SUCCESSFULLY";
export const PROFILE_FETCH_PROFILE_FAILED = "profile.PROFILE_FETCH_PROFILE_FAILED";
export const PROFILE_UPDATE_PROFILE = "profile.PROFILE_UPDATE_PROFILE";
export const PROFILE_UPDATE_PROFILE_FAILED = "profile.PROFILE_UPDATE_PROFILE_FAILED";
export const PROFILE_UPDATE_PROFILE_SUCCESSFULLY = "profile.PROFILE_UPDATE_PROFILE_SUCCESSFULLY";
export const UPDATE_MAIN_PICTURE = "profile.UPDATE_MAIN_PICTURE";
export const PROFILE_DELETE_IMAGE = "profile.PROFILE_DELETE_IMAGE";
export const PROFILE_DELETE_IMAGE_SUCCESSFULLY = "profile.PROFILE_DELETE_IMAGE_SUCCESSFULLY";
export const PROFILE_DELETE_IMAGE_FAILED = "profile.PROFILE_DELETE_IMAGE_FAILED";
export const PROFILE_UPDATE_IMAGE = "profile.PROFILE_UPDATE_IMAGE";
export const PROFILE_UPDATE_IMAGE_SUCCESSFULLY = "profile.PROFILE_UPDATE_IMAGE_SUCCESSFULLY";
export const PROFILE_UPDATE_IMAGE_FAILED = "profile.PROFILE_UPDATE_IMAGE_FAILED";
export const PROFILE_SAVE_EDITED_IMAGE = "profile.PROFILE_SAVE_EDITED_IMAGE";
export const PROFILE_SAVE_EDITED_IMAGE_SUCCESSFULLY =
	"profile.PROFILE_SAVE_EDITED_IMAGE_SUCCESSFULLY";
export const PROFILE_SAVE_EDITED_IMAGE_FAILED = "profile.PROFILE_SAVE_EDITED_IMAGE_FAILED";
export const PROFILE_LOCATION_EXISTS = "profile.PROFILE_LOCATION_EXISTS";
export const UPDATE_USER_PROVIDERS_FAILED = "profile.UPDATE_USER_PROVIDERS_FAILED";
export const PROFILE_GET_FACEBOOK_PROFILE_DATA_SUCCESSFULLY =
	"profile.PROFILE_GET_FACEBOOK_PROFILE_DATA_SUCCESSFULLY";
export const PROFILE_GET_FACEBOOK_PROFILE_DATA_FAILED =
	"profile.PROFILE_GET_FACEBOOK_PROFILE_DATA_FAILED";
export const PROFILE_IMPORT_FACEBOOK_IMAGE_SUCCESSFULLY =
	"profile.PROFILE_IMPORT_FACEBOOK_IMAGE_SUCCESSFULLY";
export const PROFILE_IMPORT_FACEBOOK_IMAGE_FAILED = "profile.PROFILE_IMPORT_FACEBOOK_IMAGE_FAILED";
export const PROFILE_UPDATE_PROFILE_MAIN_PICTURE_SUCCESSFULLY =
	"profile.PROFILE_UPDATE_PROFILE_MAIN_PICTURE_SUCCESSFULLY";
export const PROFILE_UPDATE_PROFILE_MAIN_PICTURE_FAILED =
	"profile.PROFILE_UPDATE_PROFILE_MAIN_PICTURE_FAILED";
export const PROFILE_COMPLETE_REGISTRATION_UPDATED_SUCCESSFULLY =
	"profile.PROFILE_COMPLETE_REGISTRATION_UPDATED_SUCCESSFULLY";
