import { Button } from "@mui/material";

export default ({ fontWeight = 500, variant = "outlined", size = "18px", ...prop }) => {
	return (
		<Button
			className="siteBtn"
			sx={{
				background: variant == "outlined" ? "" : "#386BA8 !important",

				border: variant == "outlined" ? "2px solid #386BA8 !important" : "",
			}}
			{...prop}
			variant={variant}>
			<span
				style={{
					letterSpacing: "0",
					color: variant == "outlined" ? "#386BA8" : "#ffffff",
					fontSize: size,
					fontWeight: fontWeight,
				}}>
				{prop.children}
			</span>
		</Button>
	);
};
