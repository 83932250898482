import { createSelector } from "reselect";

export const getSystemAppSettings = (state) => state.system.data;

export const getSubscriptionLimits = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings?.subscriptionLimits
);

export const getSubscriptionsIsOn = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings?.subscriptionsIsOn
);

export const getMassletterPeriodDays = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings?.massletterPeriodDays
);

export const getIsPasswordLoginHidden = createSelector(
	getSystemAppSettings,
	(appSettings) => appSettings?.passwordLoginHidden === true
);
