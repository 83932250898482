import { mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import SearchService from "services/search";
import { getUserProfile } from "../profile/selectors";
import * as types from "./actionTypes";
import { getPitId, getSearchAfter, getLastSortOption } from "./selectors";
import ProfileFields from "consts/profileFields";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";

export const fetchMatches = (action$, store) =>
	action$.pipe(
		ofType(types.MATCHES_FETCH),
		mergeMap(async ({ selectedSortOption }) => {
			const state = store.value;
			const profile = getUserProfile(state);

			let sortOption;
			if (!selectedSortOption) {
				const sortOptions = [
					ElasticSortOptions.SortByLastLoginTimeDESC,
					ElasticSortOptions.SortByCreateTimeDESC,
					ElasticSortOptions.SortByRankAndLastLoginDESC,
				];

				const lastOption = getLastSortOption(state);
				sortOption = !!lastOption
					? sortOptions[sortOptions.indexOf(lastOption) + (1 % sortOptions.length)]
					: sortOptions[Math.floor(Math.random() * sortOptions.length)];
			} else {
				sortOption = selectedSortOption;
			}

			try {
				const payload = await SearchService.search(
					null,
					null,
					{
						userLocation: profile.location || profile[ProfileFields.CityLoc],
					},
					sortOption,
					SearchQueryBuilderType.Matches
				);

				payload.lastSortOption = sortOption;
				payload.users = {};
				payload.items.map((user) => (payload.users[user.uid] = user));

				return {
					type: types.MATCHES_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.MATCHES_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);
export const fetchMatchesLoadMore = (action$, store) =>
	action$.pipe(
		ofType(types.MATCHES_FETCH_LOAD_MORE),
		mergeMap(async () => {
			const state = store.value;
			const profile = getUserProfile(state);
			const pitId = getPitId(state);
			const searchAfter = getSearchAfter(state);
			const sortOption = getLastSortOption(state);
			try {
				const payload = await SearchService.search(
					pitId,
					searchAfter,
					{
						userLocation: profile.location || profile[ProfileFields.CityLoc],
					},
					sortOption,
					SearchQueryBuilderType.Matches
				);

				payload.lastSortOption = sortOption;

				return {
					type: types.MATCHES_FETCH_LOAD_MORE_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.MATCHES_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);
