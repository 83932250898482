import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

export default function LightBox({ images, imgIndex = 0, isOpen = false, setUpdateOpenLightBox }) {
	const [openLightBox, setOpenLightBox] = useState(isOpen);
	const [imgDisplayIndex, setImgDisplayIndex] = useState(imgIndex);

	const nextImg = () => {
		setImgDisplayIndex((imgDisplayIndex + 1) % images.length);
	};
	const prevImg = () => {
		setImgDisplayIndex((imgDisplayIndex + images.length - 1) % images.length);
	};

	useEffect(() => {
		setOpenLightBox(isOpen);
		setImgDisplayIndex(imgIndex);
	}, [imgIndex]);
	useEffect(() => {
		setUpdateOpenLightBox(openLightBox);
	}, [openLightBox]);

	return (
		<>
			{openLightBox && (
				<Lightbox
					mainSrc={images[imgDisplayIndex] ? images[imgDisplayIndex] : images[0]}
					nextSrc={images[(imgDisplayIndex + 1) % images.length]}
					prevSrc={images[(imgDisplayIndex + images.length - 1) % images.length]}
					onCloseRequest={() => setOpenLightBox(false)}
					onMovePrevRequest={nextImg}
					onMoveNextRequest={prevImg}
				/>
			)}
		</>
	);
}
