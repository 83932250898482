import { Box, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useState, useEffect } from "react";
import i18next from "i18next";
import SiteButton from "components/ui/SiteButton";
import googlePlay from "assets/images/googlePlay.png";
import appStore from "assets/images/appStore.png";
import homePageService from "services/homePage.service";
import logoSixDays from "assets/images/svgs/landing/logoLogin.svg";

export default function DownloadApp({}) {
	const [data, setData] = useState({});

	const handleClick = (link) => {
		if (!link) return;
		if (data[link]) window.open(data[link]);
	};

	useEffect(async () => {
		homePageService.getSystemHomePageData().then((data) => {
			if (!data) return;
			setData(data);
		});
	}, []);

	return (
		<Box
			style={{
				padding: "0 40px 40px",
				minWidth: "300px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<img
				style={{ cursor: "pointer", margin: "20px 0", alignSelf: "start" }}
				src={logoSixDays}
				alt="SixDays"
				onClick={() => {}}
			/>
			<h3 style={{ textAlign: "center", margin: "0", fontSize: "18px" }}>
				{"על מנת לראות אנשי קשר משותפים והמלצות של חברים"}
			</h3>

			<Box
				style={{
					margin: "10px 0",
					width: "100%",
					display: "flex",
					justifyContent: "space-around",
					alignItems: "center",
					gap: "10px",
				}}>
				<img
					style={{
						cursor: "pointer",
						padding: "8px",
						borderRadius: "15px",
						boxShadow: "#646464 1px 1px 7px 0px",
					}}
					src={googlePlay}
					alt="googlePlay"
					onClick={() => handleClick("google_play")}
				/>
				<img
					style={{
						cursor: "pointer",
						padding: "8px",
						borderRadius: "15px",
						boxShadow: "#646464 1px 1px 7px 0px",
					}}
					src={appStore}
					alt="appStore"
					onClick={() => handleClick("app_store")}
				/>
			</Box>
			<h2 style={{ textAlign: "center", margin: "0", fontSize: "24px" }}>{"הורד את האפליקציה"}</h2>
		</Box>
	);
}
