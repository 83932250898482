import { Box } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect } from "react";

export default function LoginHeader({ step = 1, textHeader = "" }) {
	return (
		<Box className="wrapLoginHeader">
			<Box className="header-wrapper">
				<Box className="center header-content-wrapper">
					<NumberBox num={1} step={step} />
					<Box className={`separator ${step > 1 ? "sepBlue" : ""}`} />
					<NumberBox num={2} step={step} />
					<Box className={`separator ${step > 2 ? "sepBlue" : ""}`} />
					<NumberBox num={3} step={step} />
					<Box className={`separator ${step > 3 ? "sepBlue" : ""}`} />
					<NumberBox num={4} step={step} />
				</Box>
				{textHeader && <Box className="center textHeader">{textHeader}</Box>}
			</Box>
		</Box>
	);
}

function NumberBox({ num, step }) {
	return (
		<Box className={`numBox ${num <= step ? "blueNum" : ""}`}>
			{step > num ? <CheckIcon sx={{ color: "#fff", fontSize: "15px" }} /> : num}
		</Box>
	);
}
