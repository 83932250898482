import * as Yup from "yup";
import { Box } from "@mui/material";
import i18next from "i18next";
import EditTextLine from "./editLineHelpers/EditTextLine";
import profileFields from "consts/profileFields";
import { youtubeRegExp } from "helpers/regex";
import profileExtraFieldsService from "services/profileExtraFieldsService";
import { useEffect, useState } from "react";

export default function MoreDetails({ user, handleSave = () => {} }) {
	const [extraFields, setExtraFields] = useState([]);

	const getExtraFields = async () => {
		const extraFieldsObj = {};
		const fields = await profileExtraFieldsService.fetchExtraFields();

		if (!!user && !!user?.tags) {
			user.tags.map((tag) => {
				Object.values(fields).map((field) => {
					if (field.tags) {
						if (field.tags[tag.key]) {
							extraFieldsObj[field.key] = field;
						}
					}
				});
			});
			setExtraFields(Object.values(extraFieldsObj || {}).filter((ef) => ef?.type === "text"));
		}
	};

	useEffect(() => getExtraFields(), []);

	return (
		<>
			{!!user?.uid && (
				<Box className="inner-section account-details">
					<h3 className="edit-title">{i18next.t("editProfile.moreDetails")}</h3>
					<EditTextLine
						keyField={profileFields.AboutMe}
						valueField={user[profileFields.AboutMe]}
						handleSave={handleSave}
						isMulti={true}
						fieldName={i18next.t("register.thingsShouldKnow")}
					/>
					<EditTextLine
						keyField={profileFields.Experience}
						valueField={user[profileFields.Experience]}
						handleSave={handleSave}
						fieldName={i18next.t("register.experience")}
					/>
					<EditTextLine
						keyField={profileFields.Education}
						valueField={user[profileFields.Education]}
						handleSave={handleSave}
						fieldName={i18next.t("register.education")}
					/>
					<EditTextLine
						keyField={profileFields.Skills}
						valueField={user[profileFields.Skills]}
						handleSave={handleSave}
						fieldName={i18next.t("register.skills")}
					/>
					<EditTextLine
						keyField={profileFields.YouTubeUrlVideo}
						valueField={user[profileFields.YouTubeUrlVideo]}
						handleSave={handleSave}
						fieldName={i18next.t("register.uploadYouTubeVideo")}
						subField={i18next.t("register.uploadOnlyYouTubeVideo")}
						formikValidation={{
							youTubeUrlVideo: Yup.string().matches(youtubeRegExp, i18next.t("validation.youtube")),
						}}
					/>
					{!!extraFields &&
						!!extraFields?.length &&
						extraFields.map((ef) => (
							<EditTextLine
								keyField={ef?.name}
								valueField={user[ef?.name]}
								handleSave={handleSave}
								fieldName={ef?.title}
								formikValidation={
									ef?.required
										? { [ef?.name]: Yup.string().required(i18next.t("validation.required")) }
										: {}
								}
							/>
						))}
				</Box>
			)}
		</>
	);
}
