import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import AddRecommendation from "./AddRecommendation";
import LoginBtn from "./LoginBtn";
import RecommendationList from "./RecommendationList";
import "../../assets/scss/addRecommendation/addRecommendation.scss";
import { useRef, useState } from "react";
import recLike from "assets/images/svgs/newRec/newRecIcon.svg";
import i18next from "i18next";

export default ({
	talentId,
	talentPage = false,
	talentProfile,
	isFromWeb = false,
	showRecLike = true,
}) => {
	const myRef = useRef();

	const [recommendationsLength, setRecommendationsLength] = useState(0);
	const { userProfile } = useUser();

	const recText = () => {
		if (isFromWeb && userProfile.uid !== talentId)
			return (
				<>
					<span>{`מכירים את ${talentProfile?.name || ""}?`}</span>
					<span>{` תרצו להשאיר לו המלצה?`}</span>
				</>
			);
		if (userProfile.uid !== talentId)
			return <span>{`${i18next.t("talent.recomendationsOn")} ${talentProfile?.name || ""}`}</span>;

		return <></>;
	};

	return (
		<Box className={`recommendationWrapper ${!isFromWeb ? "recommendationMobileWrapper" : ""}`}>
			<Box className="recommendationTitle center">
				{!!showRecLike && (
					<Box className="center recLikeWrapper">
						<Box className="center wrapInnerRecLike">
							<img src={recLike} />
						</Box>
						<Box className="recOn">{recText()}</Box>
					</Box>
				)}
			</Box>
			{!(userProfile.uid == talentId) && talentPage && (
				<AddRecommendation
					isFromWeb={isFromWeb}
					myRef={myRef}
					talentProfile={talentProfile}
					recommendationsLength={recommendationsLength}
					talentId={talentId}></AddRecommendation>
			)}
			<RecommendationList
				talentProfile={talentProfile}
				isFromWeb={isFromWeb}
				myRef={myRef}
				setRecommendationsLength={setRecommendationsLength}
				talentId={talentId}></RecommendationList>
		</Box>
	);
};
