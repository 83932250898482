import { catchError, map, mergeMap, of, repeat, takeUntil } from "rxjs";
import { ofType } from "redux-observable";

import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import Like2LikeService from "services/like2like";
import { getLoggedInUserUID } from "../auth/selectors";

export const fetchLikeMatch = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(async () => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      try {
        const payload = await Like2LikeService.fetchLike2Like(loggedInUserUID);
        return {
          type: types.LIKE_MATCH_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.LIKE_MATCH_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.LIKE_MATCH_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchLikeMatchAdded = (action$, store) =>
  action$.pipe(
    ofType(types.LIKE_MATCH_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return Like2LikeService.listenLike2LikeAdded(uid).pipe(
        map((payload) => {
          return {
            type: types.LIKE_MATCH_ADDED,
            payload,
          };
        })
      );
    }),
    catchError((error) => {
      return of({
        type: types.LIKE_MATCH_LISTEN_ERROR,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );

export const fetchLikeMatchUpdate = (action$, store) =>
  action$.pipe(
    ofType(types.LIKE_MATCH_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return Like2LikeService.listenLike2LikeUpdate(uid).pipe(
        map((payload) => {
          return {
            type: types.LIKE_MATCH_UPDATE,
            payload,
          };
        })
      );
    }),
    catchError((error) => {
      return of({
        type: types.LIKE_MATCH_LISTEN_ERROR,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );
