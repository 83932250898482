import { Box, Button } from "@mui/material";
import SearchContent from "components/searchTalent/SearchContent";
import SearchFilter from "components/searchTalent/SearchFilter";
import useSearch from "hooks/useSearch";
import "assets/scss/search/search.scss";
import { useEffect, useRef, useState } from "react";
import Content from "views/Content";
import Tester from "components/capacitor/Tester";
import { useDispatch } from "react-redux";
import { addFiltersSelected } from "redux/search/actions";
import { getFilters, isInit } from "redux/search/selectors";
import useQuery from "hooks/useQuery";
import { useSelector } from "react-redux";
import PreviewSitePage from "./PreviewSitePage";
import FilterSearch from "components/searchWeb/FilterSearch";
import UserWebPopup from "components/searchTalent/UserWebPopup";

export default () => {
	const search = useSearch();
	const didMount = useRef(false);
	const dispatch = useDispatch();
	const { pushQueryParams } = useQuery();
	const filters = useSelector(getFilters);
	const isInitSearchData = useSelector(isInit);
	const [favoriteList, setFavoriteList] = useState([]);
	const [isUserWebPopupOpen, setIsUserWebPopupOpen] = useState(false);
	const [userWebPopup, setUserWebPopup] = useState({});

	useEffect(() => {
		const dataFromParams = search.getObjectSearchByParams();
		dispatch(addFiltersSelected({ filters: dataFromParams }));
	}, []);

	useEffect(() => {
		if (isInitSearchData) {
			search.search();
		}
	}, [isInitSearchData]);

	useEffect(() => {
		if (didMount.current && filters) {
			pushQueryParams(filters);
		}
		didMount.current = true;
	}, [filters]);

	useEffect(() => {
		if (userWebPopup?.uid) setIsUserWebPopupOpen(true);
	}, [userWebPopup]);

	return (
		<>
			<Box className="pageColl1">
				<FilterSearch />
			</Box>
			<Box className="pageColl2" style={{ overflow: isUserWebPopupOpen ? "hidden" : "" }}>
				<Content>
					<Box className={isUserWebPopupOpen ? "disableSearch" : ""}>
						<SearchContent
							webPage={true}
							setUserWebPopup={setUserWebPopup}
							setFavoriteList={setFavoriteList}></SearchContent>
					</Box>
					{isUserWebPopupOpen && (
						<UserWebPopup
							setIsUserWebPopupOpen={setIsUserWebPopupOpen}
							setTalent={setUserWebPopup}
							talent={userWebPopup}
							favoriteList={favoriteList}
						/>
					)}
				</Content>
			</Box>
		</>
	);
};
