import "assets/scss/search/searchFilterDialog.scss";
import { Box } from "@mui/system";
import dialogsTypes from "consts/dialogsTypes";
import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import { addFiltersSelected } from "redux/search/actions";
import AreaFilter from "./advanceFilter/AreaFilter";
import MaxGuestsFilter from "./advanceFilter/MaxGuestsFilter";
import { Badge, Button } from "@mui/material";
import i18next from "i18next";
import { getFiltersSelected } from "redux/search/selectors";
import searchFilter from "assets/images/talent/searchFilter.png";
import useQuery from "hooks/useQuery";
import _ from "lodash";

export default ({ webPage = false }) => {
	const dispatch = useDispatch();
	const [extraFilters, setExtraFilters] = useState({});
	const [invisible, setInvisible] = useState(false);
	const advanceSearchData = useSelector(getFiltersSelected);
	const [prevExtraFilters, setPrevExtraFilters] = useState(advanceSearchData);
	const { params } = useQuery();

	const memoObj = useMemo(() => {
		return { ...prevExtraFilters };
	}, [prevExtraFilters]);

	const clean = () => {
		if (!!webPage) {
			setExtraFilters({});
			dispatch(
				addFiltersSelected({
					filters: { category: "" },
					research: true,
				})
			);
		} else {
			dispatch(addFiltersSelected({ filters: {}, research: true }));
			dispatch(closeDialogByType(dialogsTypes.filterSearch));
		}
	};
	const filter = () => {
		if (!!webPage) {
			dispatch(addFiltersSelected({ filters: extraFilters, research: true }));
		} else {
			dispatch(addFiltersSelected({ filters: extraFilters, research: true }));
			dispatch(closeDialogByType(dialogsTypes.filterSearch));
		}
	};

	const handleOnChange = (newData) => {
		setExtraFilters((oldData) => {
			return { ...oldData, ...newData };
		});
	};

	const textButton = [
		i18next.t("Search.filter"),
		<img style={{ margin: "3px 10px 0 0", height: "16px" }} src={searchFilter} />,
	];

	useEffect(() => {
		if (!!advanceSearchData?.area && !!advanceSearchData?.area?.length) {
			setInvisible(false);
		} else if (!!webPage && !!advanceSearchData?.category) {
			setInvisible(false);
		} else {
			setInvisible(true);
		}
	}, [advanceSearchData]);

	useEffect(() => {
		if (!!webPage) {
			if (_.isEqual(memoObj, extraFilters)) return;
			setPrevExtraFilters(extraFilters);
			filter();
		}
	}, [extraFilters]);

	useEffect(() => {
		dispatch(addFiltersSelected({ filters: params }));
	}, []);

	return (
		<Box className="searchFilterDialogWrapper">
			{!webPage && (
				<>
					<Box className="filter">
						<span onClick={clean} style={{ position: "relative" }}>
							<Badge
								sx={{ position: "absolute", top: "0", left: "0" }}
								color="error"
								variant="dot"
								invisible={invisible}></Badge>
							{i18next.t("Search.clearFilter")}
						</span>
					</Box>

					<Box className="mbBox">
						<Box
							className="filterTitle filterHeader"
							sx={{ margin: "0 0 10px", fontWeight: "600" }}>
							<h4>{i18next.t("Search.filterBy")}</h4>
						</Box>
					</Box>

					<Box className="mbBox">
						<Box className="filterTitle">
							<h4>{i18next.t("Search.areaFilter")}</h4>
						</Box>
						<AreaFilter onChange={handleOnChange}></AreaFilter>
					</Box>

					{/* <Box className="mbBox">
						<Box className="filterTitle">
							<h4>{i18next.t("Search.maxGuestsFilter")}</h4>
						</Box>
						<MaxGuestsFilter onChange={handleOnChange}></MaxGuestsFilter>
					</Box> */}

					<Box className="filterResults" sx={{ marginTop: "60px" }}>
						<Button
							variant=""
							onClick={filter}
							sx={{
								color: "#fff",
								background: "#386ba8 !important",
								padding: "5px 70px",
								borderRadius: "20px",
								fontSize: "20px",
							}}>
							{i18next.t("Search.filterResults")}
						</Button>
					</Box>
				</>
			)}
			{webPage && (
				<Box className="wrapFilterWeb">
					<Box className="center">
						<Button
							variant=""
							onClick={filter}
							sx={{
								color: "#fff",
								background: "#000 !important",
								padding: "5px 20px",
								borderRadius: "20px",
								fontSize: "18px",
								lineHeight: "1",
								fontWeight: "700",
								whiteSpace: "1",
							}}>
							{textButton}
						</Button>
					</Box>
					<Box className="filter">
						<span onClick={clean} style={{ position: "relative" }}>
							<Badge
								sx={{ position: "absolute", top: "0", left: "0" }}
								color="error"
								variant="dot"
								invisible={invisible}></Badge>
							{i18next.t("Search.clearFilter")}
						</span>
					</Box>
				</Box>
			)}
		</Box>
	);
};
