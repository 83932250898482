import "assets/scss/popups/leaveDetails/leaveDetails.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18next from "i18next";
import ErrorLabel from "components/ui/ErrorLabel";
import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import RegisterBtnWrap from "components/register/RegisterBtnWrap";
import { useState } from "react";
import leaveDetailsImg from "assets/images/leaveDetailsImg.png";
import { useDispatch } from "react-redux";
import _ from "lodash";
import functionsService from "services/functionsService";
import { phoneRegExp } from "helpers/regex";
import { ScrollToFieldError } from "helpers/FormikErrors";
import TextComponent from "components/ui/TextComponent";
import siteHeader from "assets/images/talent/siteHeader.png";
import { closeDialogByType } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import CloseIcon from "@mui/icons-material/Close";
import leadsType from "consts/leadsType";
import { openNotification } from "redux/notification/actions";
import localStorage from "services/localStorage";

export default ({ notFromDialog = false, setShowOffer = () => {} }) => {
	const dispatch = useDispatch();
	const { userProfile } = useUser();
	const [inProgress, setInProgress] = useState(false);

	const initialValues = {
		name: userProfile.name ?? "",
		phone: userProfile?.phoneToConnect
			? userProfile.phoneToConnect
			: userProfile?.phone
			? userProfile.phone
			: "",
		typeOfEvent: "",
		closeHall: null,
	};

	const validationSchema = Yup.object().shape({
		phone: Yup.string()
			.matches(phoneRegExp, i18next.t("validation.phone"))
			.required(i18next.t("validation.required")),
	});

	const sendMessage = async ({ name, phone, typeOfEvent, closeHall }) => {
		if (!phone) return;
		const message = { name, phone, typeOfEvent, closeHall };

		const returnData = await functionsService.sendLead(null, message, leadsType.general);

		if (returnData && returnData?.data?.success) {
			dispatch(openNotification(i18next.t("notificationActions.messageSentSuccessfuly")));
			dispatch(closeDialogByType(dialogsTypes.LeaveDetails));
			if (notFromDialog) setShowOffer(false);
			localStorage.set("ldp", true, 30);
		} else {
			dispatch(openNotification("failed"));
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setInProgress(true);
			await sendMessage(values);
			setTimeout(() => {
				setInProgress(false);
			}, 2000);
		},
	});

	return (
		<Box className="leaveDetailsPopupWrapper">
			<Box className="siteHeader">
				<Box
					className="closePopup"
					onClick={() => {
						if (notFromDialog) {
							setShowOffer(false);
						} else {
							dispatch(closeDialogByType(dialogsTypes.LeaveDetails));
						}
					}}>
					<CloseIcon sx={{ color: "#C6C6C6" }} />
				</Box>
				<img src={siteHeader} alt="" />
			</Box>
			<Box className="center wrapHeader">
				<Box className="center titleHeader">{i18next.t("leaveDetailsPopup.knowsShlish")}</Box>
				<Box className="center wrapImg">
					<img src={leaveDetailsImg} />
				</Box>
			</Box>
			<Box className="center ">
				<span className="saveTimeAndMoney">{i18next.t("leaveDetailsPopup.saveTimeAndMoney")}</span>
			</Box>
			<form
				id="starter"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "0 20px" }}>
				<Box className="boxWrapper">
					<span>{i18next.t("work.name")}</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						id="name"
						value={"name"}
						{...formik.getFieldProps("name")}
					/>
				</Box>
				<Box className="boxWrapper">
					<span>{i18next.t("work.phone")}</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						id="phone"
						value={"phone"}
						{...formik.getFieldProps("phone")}
					/>
					<ErrorLabel>{formik.touched.phone && formik.errors.phone}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>{i18next.t("leaveDetailsPopup.typeOfEvent")}</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						id="typeOfEvent"
						value={"typeOfEvent"}
						{...formik.getFieldProps("typeOfEvent")}
					/>
				</Box>
				<Box className="boxWrapper">
					<span>{i18next.t("leaveDetailsPopup.closeHall")}</span>
					<Box className="closeHallWrapper">
						<Box
							sx={{
								marginRight: "10px",
								background:
									formik.getFieldProps("closeHall").value === true ? "#cccccc !important" : "",
							}}
							onClick={() => {
								if (formik.getFieldProps("closeHall").value === true) return;
								formik.setFieldValue("closeHall", true);
							}}>
							{i18next.t("leaveDetailsPopup.weClose")}
						</Box>
						<Box
							sx={{
								marginLeft: "10px",
								background:
									formik.getFieldProps("closeHall").value === false ? "#cccccc !important" : "",
							}}
							onClick={() => {
								if (formik.getFieldProps("closeHall").value === false) return;
								formik.setFieldValue("closeHall", false);
							}}>
							{i18next.t("leaveDetailsPopup.noClose")}
						</Box>
					</Box>
				</Box>

				<Box className=" center sendWrapper">
					<span onClick={() => formik.submitForm()}>
						<RegisterBtnWrap
							inProgress={inProgress}
							btnText={i18next.t("leaveDetailsPopup.continue")}></RegisterBtnWrap>
					</span>
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
			<Box className="center wrapTalkWithUs">
				<span>{i18next.t("leaveDetailsPopup.talkWithUs")}</span>
				<a href="tel:0539688444" style={{ color: "#525bc2" }}>
					{i18next.t("leaveDetailsPopup.phoneTalkWithUs")}
				</a>
			</Box>
		</Box>
	);
};
