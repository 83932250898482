import "assets/scss/register/register.scss";
import BaseRegister from "components/register/BaseRegister";
import RegisterTalentStep1 from "components/register/RegisterTalentStep1";
import RegisterTalentStep2 from "components/register/RegisterTalentStep2";
import RegisterTalentStep3 from "components/register/RegisterTalentStep3";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import _ from "lodash";
import StepWizard from "react-step-wizard";
import registerSteps from "constants/registerSteps";
import useNavigator from "hooks/useNavigator";
import RegisterFinish from "components/register/RegisterFinish";
import RegisterClient from "components/register/RegisterClient";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import { openNotification } from "redux/notification/actions";
import dialogsTypes from "consts/dialogsTypes";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import UsersTypes from "consts/UsersTypes";
import RegisterTalentStep4 from "components/register/RegisterTalentStep4";
import RegisterTalentStep5 from "components/register/RegisterTalentStep5";
import { useLocation } from "react-router";
import Loader from "components/Loader";

export default ({ goToTalentRegister = false, platform = "web" }) => {
	const [currentStep, setCurrentStep] = useState(null);
	const [registerStep, setRegisterStep] = useState(null);
	const [isClient, setIsClient] = useState(true);
	const [registerType, setRegisterType] = useState(null); //from global state - Client / Talent
	const { isLoggedIn, user } = useUser();
	const dispatch = useDispatch();
	const navigator = useNavigator();
	let { pathname } = useLocation();

	let pathType = "register";
	if (pathname == "/profile/editAccount") pathType = "edit";

	useEffect(() => {
		if (user?.uid && user.type) {
			setIsClient(user.type !== UsersTypes.Talent);
			setRegisterType(user.type);
		}
	}, [user.type]);

	useEffect(() => {
		if (!registerStep) {
			setRegisterStep(user?.registerStep);
		}
		if (user?.completeTalentRegister) navigator.navigateToSearchPage();
	}, [user?.registerStep]);

	useEffect(() => {
		if (goToTalentRegister) {
			setRegisterType(UsersTypes.Talent);
		}
	}, []);

	return (
		<>
			{!!user && user.uid ? (
				<>
					{user?.registerStep >= 0 && user?.registerStep < 6 && isLoggedIn && (
						<StepWizard initialStep={1}>
							{registerStep <= registerSteps[1] && registerType === UsersTypes.Client && (
								<BaseRegister
									platform={platform}
									setCurrentStep={setCurrentStep}
									user={user}
									component={RegisterClient}></BaseRegister>
							)}
							{registerStep <= registerSteps[1] && registerType === UsersTypes.Talent && (
								<BaseRegister
									platform={platform}
									pathType={pathType}
									setCurrentStep={setCurrentStep}
									user={user}
									component={RegisterTalentStep1}></BaseRegister>
							)}
							{registerStep <= registerSteps[2] && registerType === UsersTypes.Talent && (
								<BaseRegister
									platform={platform}
									pathType={pathType}
									setCurrentStep={setCurrentStep}
									user={user}
									component={RegisterTalentStep3}></BaseRegister>
							)}
							{registerStep <= registerSteps[3] && registerType === UsersTypes.Talent && (
								<BaseRegister
									platform={platform}
									pathType={pathType}
									setCurrentStep={setCurrentStep}
									user={user}
									component={RegisterTalentStep2}></BaseRegister>
							)}
							{(registerStep <= registerSteps[4] || registerStep <= registerSteps[5]) &&
								registerType === UsersTypes.Talent && (
									<BaseRegister
										platform={platform}
										pathType={pathType}
										setCurrentStep={setCurrentStep}
										user={user}
										component={RegisterTalentStep4}></BaseRegister>
								)}
							{/* {registerStep <= registerSteps[5] && registerType === UsersTypes.Talent && (
										<BaseRegister
											platform={platform}
											pathType={pathType}
											setCurrentStep={setCurrentStep}
											user={user}
											component={RegisterTalentStep5}></BaseRegister>
									)} */}
						</StepWizard>
					)}
				</>
			) : (
				<Box className="center" sx={{ width: "100%", height: "90vh" }}>
					<Loader />
				</Box>
			)}
		</>
	);
};
