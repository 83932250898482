import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import Button from "components/ui/Button";
import i18next from "i18next";
import { Box } from "@mui/material";

export default ({ children, onSetFileBase64, onDelete, btnTxt, file, ...prop }) => {
	const [fileData, setFileData] = useState();
	const [fileName, setFileName] = useState();
	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleChange = async (event) => {
		const fileBase64 = await toBase64(event.target.files[0]);
		setFileData(fileBase64);
		setFileName(event.target.files[0].name);
		onSetFileBase64(fileBase64);
	};

	const handleDelete = async () => {
		setFileName("");
		setFileData("");
		onDelete();
	};

	return (
		<Box {...prop}>
			{!fileData && !file && (
				<Button variant="" component="label" size="13px">
					{i18next.t(btnTxt)}
					<input
						hidden
						accept="application/pdf,application/msword, application/vnd.ms-excel"
						type="file"
						onChange={handleChange}
					/>
				</Button>
			)}

			{(fileData || file) && (
				<Box sx={{ display: "flex", overflow: "hidden" }}>
					<DeleteIcon onClick={handleDelete}></DeleteIcon>

					<span
						style={{
							textOverflow: "ellipsis",
							textAlign: "left",
							maxWidth: "210px",
						}}>
						{fileName}
					</span>
					{file && (
						<a target={"blank"} href={file} download>
							{i18next.t("talent.resume")}
						</a>
					)}
				</Box>
			)}
		</Box>
	);
};
