import { createSelector } from "reselect";
import _ from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

export const getSearch = (state) => state.search;

export const getIsLoadingSearch = createSelector(
	getSearch,
	(search) => search.users.length === 0 && search.apiStatus === API_REQUESTS_STATUS.PENDING
);

export const showFetchUsersErrorMessage = createSelector(
	getSearch,
	(search) => search.apiStatus === API_REQUESTS_STATUS.FAILED
);

export const getSearchUsers = createSelector(getSearch, (search) =>
	Object.keys(search.users).map((uid) => search.users[uid])
);

export const getStatus = createSelector(getSearch, (search) => search.apiStatus);
export const getTotal = createSelector(getSearch, (search) => search?.total?.value ?? 0);

export const getUpdateRedPoint = createSelector(getSearch, (search) => search.updateRedPoint);

export const getIsLoadingMore = createSelector(
	getSearch,
	(search) => search.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getSearch, (search) => search.hasMoreResults);
export const getFilters = createSelector(getSearch, (search) => search.filters);

export const getSearchFreeTextFilter = createSelector(getSearch, (search) => search.freeTextFilter);

export const getPitId = createSelector(getSearch, (search) => search.pitId);
export const getSearchAfter = createSelector(getSearch, (search) => search.searchAfter);

export const getLastSortOption = createSelector(getSearch, (search) => search.lastSortOption);

export const getFiltersSelected = createSelector(getSearch, (search) => search.filtersSelected);
export const hasMoreFilter = createSelector(getSearch, (search) => {
	return (
		!_.isEmpty(search.filtersSelected?.maximumNumberOfGuests) ||
		!_.isEmpty(search.filtersSelected?.area)
	);
});
export const isInit = createSelector(getSearch, (search) => search.isInit);
