import "assets/scss/landing/landingHowWork.scss";
import { Box } from "@mui/material";
import LandingColorizedTitle from "./LandingColorizedTitle";
import i18next from "i18next";
import woman from "assets/images/svgs/landing/wonan-caracter.png";
import howWork from "assets/images/svgs/landing/how-it-works.svg";
import howWorkMob from "assets/images/svgs/landing/howItsWorkMobile.png";

export default function LandingHowWork({ isMobile = false }) {
	return (
		<Box className="landingHowWork">
			<LandingColorizedTitle text={"אז איך זה "} colorText={"עובד"} />
			{isMobile ? <img src={howWorkMob} width={"100%"} /> : <img src={howWork} width={"50%"} />}

			<img className="presentorImg" src={woman} width={"20%"} />
		</Box>
	);
}
