import i18next from "i18next";
import Autocomplete from "react-google-autocomplete";
export default function AutocompleteCity({ onChangeAutoComplete, defaultValue = "", ...rest }) {
	const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

	const onTextChange = (e) => {
		const value = e.target.value;
	};

	const onBlur = (e) => {
		// console.log("onBlur");
	};
	return (
		<Autocomplete
			options={{
				types: ["geocode", "establishment"],
			}}
			apiKey={googleMapsKey}
			language="he"
			defaultValue={defaultValue}
			onPlaceSelected={onChangeAutoComplete}
			placeholder={i18next.t("register.location")}
			onChange={onTextChange}
			onBlur={onBlur}
			key={"key"}
			style={{
				border: "0",
				borderRadius: "0",
				borderBottom: "1px solid #949494",
				color: "#313030",
				fontSize: "18px",
				width: "100%",
				borderInline: "",
				borderInlineStyle: "1px solid red",
				outline: "none",
				padding: "5px 0",
			}}
			{...rest}
		/>
	);
}
