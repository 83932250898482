import { mergeMap, map, takeUntil, catchError, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import moment from "moment";

import LikedMeService from "services/likedMe";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import SearchService from "services/search";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";
import { getUserProfile } from "redux/profile/selectors";
import ProfileFields from "consts/profileFields";
import { getPitId, getSearchAfter } from "./selectors";
import PublicProfileService from "services/publicProfile";
import { getMessagesFilter } from "redux/general/selectors";

export const fetchLikedMe = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			try {
				const payload = await LikedMeService.fetchLikedMe(uid);
				return {
					type: types.LIKED_ME_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.LIKED_ME_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);

export const fetchLikedMeUsers = (action$, store) =>
	action$.pipe(
		ofType(types.LIKED_ME_USERS_FETCH, types.LIKED_ME_USERS_FETCH_LOAD_MORE),
		mergeMap(async () => {
			const state = store.value;
			const profile = getUserProfile(state);
			const pitId = getPitId(state);
			const searchAfter = getSearchAfter(state);
			const messagesFilter = getMessagesFilter(state);

			let filter = {};

			if (messagesFilter.ageFrom && messagesFilter.ageTo) {
				filter.Age = { from: messagesFilter.ageFrom, to: messagesFilter.ageTo };
			}
			if (messagesFilter.maritalStatus) {
				filter.MaritalStatus = messagesFilter.maritalStatus;
			}
			if (messagesFilter.stance) {
				filter.Stance = messagesFilter.stance;
			}

			try {
				const payload = await SearchService.search(
					pitId,
					searchAfter,
					{
						userFilters: filter,
						userLocation: profile.location || profile[ProfileFields.CityLoc],
					},
					ElasticSortOptions.SortByLikeTimeStampDESC,
					SearchQueryBuilderType.LikedMe
				);

				payload.users = payload.items;

				return {
					type: !pitId
						? types.LIKED_ME_USERS_FETCH_SUCCESSFULLY
						: types.LIKED_ME_USERS_FETCH_LOAD_MORE_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.LIKED_ME_USERS_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);

export const fetchLikedMeAdded = (action$, store) =>
	action$.pipe(
		ofType(types.LIKED_ME_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return LikedMeService.listenLikedMeAdded(uid).pipe(
				map((change) => ({
					type: types.LIKED_ME_ADDED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.LIKED_ME_ADDED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchUserProfileForLikedMeAdded = (action$, store) =>
	action$.pipe(
		ofType(types.LIKED_ME_ADDED),
		mergeMap(async ({ payload }) => {
			const uid = Object.keys(payload)[0];
			const user = await PublicProfileService.fetchProfile(uid);
			user.likes_timeStamp = moment.utc(payload[uid].timeStamp).format("YYYYMMDDhhmmss");
			user.likes_unviewed = true;

			return {
				type: types.LIKED_ME_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY,
				user,
			};
		}),
		catchError((error) => {
			return of({
				type: types.LIKED_ME_ADDED_FETCH_USER_PROFILE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenToLikedMeUpdate = (action$, store) =>
	action$.pipe(
		ofType(types.LIKED_ME_FETCH_SUCCESSFULLY),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return LikedMeService.listenChildChanged(uid).pipe(
				map((change) => ({
					type: types.LIKED_ME_CHANGED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.LIKED_ME_CHANGED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchLikedMeRemoved = (action$, store) =>
	action$.pipe(
		ofType(authTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const uid = getLoggedInUserUID(state);
			return LikedMeService.listenLikedMeRemoved(uid).pipe(
				map((change) => ({
					type: types.LIKED_ME_REMOVED,
					payload: change,
				}))
			);
		}),
		takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
		catchError((error) => {
			return of({
				type: types.LIKED_ME_REMOVED_ERROR,
				payload: error?.message,
			});
		}),
		repeat()
	);
