import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import HttpsCallable from "./httpsCallable";

const functions = firebase.functions();

class ChatService {
	BASE_REF = "chats_list";
	MESSAGES_REF = "chat_messages";

	async fetchChatMessages(chatID) {
		const messages =
			(await firebase.database().ref(this.MESSAGES_REF).child(chatID).once("value")).val() || {};
		const arr = [];
		Object.entries(messages || {}).map(([key, value]) => {
			arr.push({ ...value, key });
		});
		return Object.values(arr);
	}

	listenToChatMessages(chatID) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.MESSAGES_REF).child(chatID);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => {
					return subscriber.next(this.buildMessage(snapshot.key, snapshot.val()));
				},
				(error) => {
					return subscriber.error(error);
				}
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenNewMessagesAdded(chatID) {
		return new Observable((subscriber) => {
			const ref = firebase
				.database()
				.ref(this.MESSAGES_REF)
				.child(chatID)
				.orderByChild("updated")
				.startAt(Date.now());
			const callbackFn = ref.on(
				"child_added",
				(snapshot) =>
					subscriber.next({
						...snapshot.val(),
						key: snapshot.key,
					}),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	async fetchChatsList(uid) {
		return (
			(
				await firebase.database().ref(this.BASE_REF).child(uid).orderByChild("order").once("value")
			).val() || {}
		);
	}

	listenChatList(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid).orderByChild("order");

			const callbackFn = ref.on(
				"value",
				(snapshot) => {
					return subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	listenChatUpdates(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid).orderByChild("order");

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => {
					return subscriber.next({ ...snapshot.val(), key: snapshot.key });
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenNewMessagesRecived(chatID) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(chatID);

			const callbackFn = ref.on(
				"child_changed",
				(snapshot) => subscriber.next({ ...snapshot.val(), key: snapshot.key }),
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_changed", callbackFn);
		});
	}

	listenChatDeleted(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid).orderByChild("order");

			const callbackFn = ref.on(
				"child_removed",
				(snapshot) => {
					return subscriber.next(snapshot.key);
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_removed", callbackFn);
		});
	}
	listenChatAdded(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => {
					return subscriber.next({ ...snapshot.val(), key: snapshot.key });
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}

	async deleteChat(colleagueIds) {
		const result = await HttpsCallable.post("deleteChat", {
			colleagueIds,
		});
		return result;
	}

	async pinChat(colleagueIds) {
		const result = await HttpsCallable.post("pinChat", {
			colleagueIds,
		});
		return result;
	}

	async unpinChat(colleagueIds) {
		const result = await HttpsCallable.post("unpinChat", {
			colleagueIds,
		});
		return result;
	}

	async addMessage(sender, receiver, message, chatId = null) {
		const result = await HttpsCallable.post("sendChatMessage", {
			sender,
			receiver,
			message,
			chatId,
		});

		return result.data;
	}
	async addMassLetter(data) {
		const result = await HttpsCallable.post("addMassLetter", data);
		return result.data;
	}

	async markChatAsRead(colleagueId) {
		const result = await HttpsCallable.post("markChatAsRead", {
			colleagueId,
		});

		return result.data;
	}

	listenChatsUnread(id) {
		return new Observable((subscriber) => {
			console.log("listen");
			if (!id) return;

			const ref = firebase.database().ref("chat_total_unread").child(id);

			const callbackFn = ref.on(
				"value",
				(snapshot) => {
					subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}
}

export default new ChatService();
