export const SEARCH_FETCH = "search.SEARCH_FETCH";
export const SEARCH_FETCH_FAILED = "search.SEARCH_FETCH_FAILED";
export const SEARCH_FETCH_SUCCESSFULLY = "search.SEARCH_FETCH_SUCCESSFULLY";

export const SEARCH_FETCH_LOAD_MORE = "search.SEARCH_FETCH_LOAD_MORE";
export const SEARCH_FETCH_LOAD_MORE_FAILED = "search.SEARCH_FETCH_LOAD_MORE_FAILED";
export const SEARCH_FETCH_LOAD_MORE_SUCCESSFULLY = "search.SEARCH_FETCH_LOAD_MORE_SUCCESSFULLY";

export const SEARCH_LIKE = "search.SWIPE_LIKE";
export const SEARCH_VIEW = "search.SWIPE_VIEW";
export const SEARCH_TYPE = "search.SEARCH_TYPE";
export const SEARCH_UPDATE_RED_POINT = "search.SEARCH_UPDATE_RED_POINT";
export const SEARCH_ADD_FILTERS_SELECTED = "search.SEARCH_ADD_FILTERS_SELECTED";
export const SEARCH_ADD_SORT_OPTION = "search.SEARCH_ADD_SORT_OPTION";
