import { Box } from "@mui/material";
import { useState } from "react";
import LightBox from "components/lightBox/LightBox";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";

import Masonry from "@mui/lab/Masonry";

const heights = [
	350, 170, 210, 260, 270, 330, 330, 220, 180, 350, 170, 210, 260, 270, 330, 330, 220, 180,
];

export default ({ user }) => {
	const [openLightBox, setOpenLightBox] = useState(false);
	const [imgIndex, setImgIndex] = useState(0);
	const userImagesUrls = Object.entries(user?.pictures ?? {}).map((arr) => arr[1].url) ?? [];
	const [images, setImages] = useState(userImagesUrls);

	const handleImgClick = async (index) => {
		if (user?.uid) functionsService.addTalentAction(user?.uid, talentAction.clickPhotos);
		setImgIndex(index);
		setOpenLightBox(true);
	};

	const createIframe = () => {
		if (!user?.youTubeUrlVideo) return;
		const urlParams = new URL(user?.youTubeUrlVideo);
		const videoId = urlParams.searchParams.get("v");
		const iframe = `<iframe width="100%"  style="height:350px;" src="https://www.youtube.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		if (!iframe) return <span></span>;
		return iframe;
	};
	const listGalery = [];

	if (!!user?.youTubeUrlVideo) {
		listGalery.push(
			<Box
				className="wrapYouTubeVideo"
				sx={{
					overflow: "hidden",
					borderRadius: "20px",
				}}
				dangerouslySetInnerHTML={{
					__html: createIframe(),
				}}
			/>
		);
	}
	if (user.pictures) {
		Object.values(user.pictures).map((item, index) => {
			listGalery.push(
				<img
					onClick={() => handleImgClick(index)}
					width={"100%"}
					height={"100%"}
					// height={!!user?.youTubeUrlVideo ? heights[index + 1] : heights[index]}
					src={item.url}
					key={index}
				/>
			);
		});
	}

	return (
		<Box className="wrapImages">
			<Box className="wrapGalery">
				<BasicMasonry listGalery={listGalery} />
			</Box>
			{openLightBox && (
				<LightBox
					images={images}
					imgIndex={imgIndex}
					isOpen={openLightBox}
					setUpdateOpenLightBox={setOpenLightBox}
				/>
			)}
		</Box>
	);
};

function BasicMasonry({ listGalery }) {
	return (
		<Box sx={{ width: "100%" }}>
			<Masonry columns={3} spacing={2}>
				{listGalery.map((item, index) => (
					<Box key={index} sx={{ borderRadius: "20px" }}>
						{item}
					</Box>
				))}
			</Masonry>
		</Box>
	);
}
{
	/* <Box key={index} sx={{ height: heights[index], borderRadius: "20px" }}> */
}
