import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

export default () => {
	const history = useHistory();
	const currentPath = history.location.pathname;
	const isCurrentPathProfileBlocked = currentPath === "/profileBlocked";
	const [goToBlockPage, setGoToBlockPage] = useState(false);
	const { userProfile } = useUser();

	useEffect(() => {
		setGoToBlockPage(userProfile?.status && userProfile?.status === "reject");
	}, [userProfile?.status]);

	if (goToBlockPage) {
		return <Redirect to="/profileBlocked" />;
	}
	if (isCurrentPathProfileBlocked) {
		return <Redirect to="/" />;
	}
	return <></>;
};
