import "assets/scss/searchWeb/searchWeb.scss";
import i18next from "i18next";
import { Box, Button } from "@mui/material";
import BaseHelmet from "../components/seo/BaseHelmet";
import SearchTalentWebPage from "./SearchTalentWebPage";
import siteHeader from "assets/images/talent/siteHeader.png";
import { useState } from "react";
import LeaveDetailsDialog from "components/LeaveDetails/LeaveDetailsDialog";

export default () => {
	const [showOffer, setShowOffer] = useState(false);
	return (
		<Box className="wrapSearchWebPage">
			<BaseHelmet
				title={i18next.t("seo.homePage.title")}
				description={i18next.t("seo.homePage.description")}
				url={`search${window.location.search}`}></BaseHelmet>

			<Box className="wrapInnerColumns">
				<SearchTalentWebPage></SearchTalentWebPage>
			</Box>
			{/* <Box className="stickyQuickLeaveDetails">
				<Box className={showOffer ? "innerSticky changeHeight" : "innerSticky"}>
					<Box
						sx={{
							position: "relative",
							minHeight: "18px",
							background: "#7496bf",
							width: "100%",
						}}></Box>
					{!showOffer ? (
						<>
							<Box className="center siteImgAndBtnOffer" onClick={() => setShowOffer(!showOffer)}>
								<img src={siteHeader} alt="" />
								<Button
									className="wrapInnerButton"
									style={{
										color: "#fff",
										background: "#000",
										borderRadius: "20px",
										textAlign: "center",
									}}
									variant="contained">
									{i18next.t("stickySearch.getGreatOffer")}
								</Button>
							</Box>
						</>
					) : (
						<Box className="wrapLeaveDetailsSticky">
							<LeaveDetailsDialog notFromDialog={true} setShowOffer={setShowOffer} />
						</Box>
					)}
				</Box>
			</Box> */}
		</Box>
	);
};
