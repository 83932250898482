import { Box } from "@mui/material";
import UserChatItem from "components/chatContent/UserChatItem";
import chatTemplates from "constants/chatTemplates";

import useChat from "hooks/useChat";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ChatDate from "../chatList/ChatDate";
import DayDivider from "../DayDivider";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default ({ item, prevItem, selectedChat, openUserDialog }) => {
	const [text, setText] = useState();
	const { getTextChat } = useChat();
	const { userProfile } = useUser();

	useEffect(() => {
		if (
			(item && item?.text) ||
			item?.template == chatTemplates.Offer ||
			item?.template == chatTemplates.MassLettr
		) {
			setText(getTextChat(item));
		}
	}, [item]);

	return (
		<>
			<DayDivider currentMessage={item} previousMessage={prevItem}></DayDivider>
			{userProfile.uid == item?.uid ? (
				<Box className="wrapTxtImg">
					<Box className="myTxt">
						<Box className="myChatTxt">{text}</Box>
						<ChatDate
							sx={{ textAlign: "start", alignSelf: "end" }}
							updated={item?.updated}></ChatDate>
					</Box>
					<Box className="userImg">
						{prevItem?.uid !== userProfile.uid && (
							<img
								src={
									userProfile?.profileImage?.url
										? userProfile?.profileImage?.url
										: getFirstCharImg(userProfile)
								}
								alt=""
							/>
						)}
					</Box>
				</Box>
			) : (
				<Box className="wrapTxtImg" sx={{ display: "flex" }}>
					<Box className="talentImg" onClick={openUserDialog}>
						{prevItem?.uid !== item?.uid && (
							<img
								src={
									!!selectedChat?.user.profileImage?.url
										? selectedChat?.user.profileImage?.url
										: getFirstCharImg(selectedChat?.user)
								}
								alt=""
							/>
						)}
					</Box>
					<Box className="wrapTxtMsg">
						<Box className="chatTxt">{text}</Box>
						<ChatDate updated={item?.updated}></ChatDate>
					</Box>
				</Box>
			)}
		</>
	);
};
