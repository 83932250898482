import { TextField } from "@mui/material";
import "assets/scss/textComponent/textComponent.scss";

export default ({ value = "", ...prop }) => {
	return (
		<TextField
			onKeyPress={(e) => {
				e.key === "Enter" && e.preventDefault();
			}}
			className="textComponent required"
			value={value}
			{...prop}></TextField>
	);
};
