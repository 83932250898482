import "assets/scss/talentPage/talentPage.scss";
import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import Friends from "./Friends";
import Header from "./Header";
import Images from "./Images";
import Recommendations from "./Recommendations";
import InfoAndSocial from "./InfoAndSocial";
import useUser from "hooks/useUser";
import _ from "lodash";
import LinkBtns from "components/searchTalent/linkBtns/LinkBtns";
import i18next from "i18next";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useDispatch, useSelector } from "react-redux";
import httpsCallable from "services/httpsCallable";
import Loader from "components/Loader";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import InfoTalent from "components/searchTalent/infoTalent/InfoTalent";
import Experience from "./Experience";

export default ({ talentId, talent, telentFromOutSide = {}, isChatWeb = false }) => {
	const [talentProfile, setTalentProfile] = useState();
	const { userProfile } = useUser();
	const dispatch = useDispatch();

	const createIframe = () => {
		if (!talentProfile?.youTubeUrlVideo) return;
		const urlParams = new URL(talentProfile?.youTubeUrlVideo);
		const videoId = urlParams.searchParams.get("v");
		const iframe = `<iframe width="100%"  height="250" style="margin-bottom:-7px; " src="https://www.youtube.com/embed/${videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		if (!iframe) return <span></span>;
		return iframe;
	};

	useEffect(async () => {
		if (!talentId) return;
		if (talent) {
			setTalentProfile(talent);
		} else if (!!telentFromOutSide && telentFromOutSide?.uid) {
			setTalentProfile(telentFromOutSide);
		} else {
			const result = await httpsCallable.post("getTalent", {
				extraData: {
					talentId,
				},
			});

			if (!result?.data?.data?.items?.length) {
				dispatch(closeDialogByType(dialogsTypes.ViewTalent));
				return dispatch(
					openDialog({
						dialog: dialogsTypes.Countdown,
						text: "המשתמש לא קיים יותר במערכת",
					})
				);
			}
			setTalentProfile(result?.data?.data?.items[0]);
		}
	}, [talentId]);

	// const openSendMessagePopup = () => {
	// 	dispatch(openDialog({ dialog: dialogsTypes.SendMessageToTalentPopup, talent: talentProfile }));
	// };
	// const whatsappLink = !talentProfile?.whatsappDisplay
	// 	? ""
	// 	: toString(talentProfile.whatsappDisplay).includes("972")
	// 	? `https://api.whatsapp.com/send?phone=${toString(talentProfile.whatsappDisplay).replace(
	// 			"+",
	// 			""
	// 	  )}`
	// 	: `https://api.whatsapp.com/send?phone=972${talentProfile.whatsappDisplay}`;

	useEffect(async () => {
		await functionsService.setOnline();
		if (talentId) await functionsService.addTalentAction(talentId, talentAction.viewIn);
	}, []);

	return (
		<Box className="wrapTalentPage">
			{talentProfile ? (
				<>
					{/*
					//! share icons
					<Box className="wrapIconsMenu" sx={{ position: "absolute", right: "10px" }}>
						<IconsMenu
							sx={{ position: "absolute", right: "-10px", top: "20px" }}
							user={talentProfile}
						/>
					</Box> */}
					<Header talentProfile={talentProfile} userProfile={userProfile} isChatWeb={isChatWeb} />
					<Box sx={{ padding: "0 20px" }}>
						{talentProfile?.aboutMe && (
							<Box className="talentAboutMe">
								<h3 style={{ color: "#313030", fontSize: "16px" }}>{talentProfile.aboutMe}</h3>
							</Box>
						)}
						{talentProfile?.tagsObj && (
							<>
								{/* <h3 className="subheading">{i18next.t("talent.areasOfExpertise")}</h3> */}
								<section className="tags">
									{!!talentProfile.tagsObj?.length &&
										talentProfile.tagsObj.map((item) => (
											<button key={item.key}>{item.name}</button>
										))}
								</section>
							</>
						)}

						<InfoTalent user={talentProfile} isViewTalent={true} />

						<Separator styles={{ margin: "50px 0 15px !important" }} />
						<InfoAndSocial user={talentProfile} userProfile={userProfile} />
						<Separator />

						<Experience user={talentProfile} />
						{(talentProfile?.education || talentProfile?.skills || talentProfile?.experience) && (
							<Separator styles={{ margin: "40px 0 !important" }} />
						)}
						{userProfile.uid !== talentId && (
							<>
								<Friends talentId={talentId}></Friends>
								<Separator styles={{ margin: "25px 0 !important" }} />
							</>
						)}
						{talentProfile && !_.isEmpty(talentProfile.pictures) && (
							<>
								<h3 className="subheading">{i18next.t("talent.images")}</h3>
								<Images user={talentProfile}></Images>
							</>
						)}
						{!!talentProfile?.youTubeUrlVideo && (
							<Box
								className="wrapYtVideo"
								sx={{
									overflow: "hidden",
									borderRadius: "20px",
								}}
								dangerouslySetInnerHTML={{
									__html: createIframe(),
								}}
							/>
						)}
					</Box>
					<Recommendations
						talentProfile={talentProfile}
						talentPage={true}
						talentId={talentId}></Recommendations>
					{userProfile.uid == talentId && <Footer user={talentProfile}></Footer>}
					{userProfile.uid !== talentId && (
						<Box
							sx={{
								display: "block",
								minHeight: "70px",
								width: "100%",
								position: "relative",
								background: "#f8f8f8",
							}}>
							<Box className="center getBackToMe">
								<LinkBtns user={talentProfile} />
							</Box>
						</Box>
					)}
				</>
			) : (
				<Box className="wrapSkeletons" sx={{ width: "100%", height: "90vh" }}>
					<Skeleton className="skeleton" animation="pulse" height="135px" />
					<Skeleton className="skeleton" animation="pulse" height="35px" width="65%" />
					<Skeleton
						className="skeleton"
						animation="pulse"
						height="180px"
						width="80%"
						style={{
							alignSelf: "start",
							margin: "20px 20px 0",
						}}
					/>
					<Box className="innerSkeletonsTags">
						<Skeleton className="skeleton" animation="pulse" height="26px" width="180px" />
						<Skeleton className="skeleton" animation="pulse" height="26px" width="80px" />
						<Skeleton className="skeleton" animation="pulse" height="26px" width="120px" />
					</Box>
				</Box>
			)}
		</Box>
	);
};

function Separator({ styles }) {
	return <Box className="separatorViewTalent" sx={{ ...styles }}></Box>;
}
