const countGuests = {
	100: 100,
	150: 150,
	200: 200,
	300: 300,
	400: 400,
	500: 500,
	500: 500,
	500: 500,
	600: 600,
	700: 700,
	900: 900,
	"1000 +": 1000,
};

Object.freeze(countGuests);

export default countGuests;
