import { Box } from "@mui/material";
import publicProfileService from "services/publicProfile";
import { useEffect, useState } from "react";
import useUser from "hooks/useUser";
import recommendations from "services/recommendations";
import Date from "components/ui/Date";
import UserImage from "./userImage";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import deleteBtn from "../../assets/images/talent/deleteBtn.png";
import quoteIcon from "assets/images/svgs/quoteIcon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import i18next from "i18next";
import popupsTypes from "consts/popupsTypes";
import usePopups from "hooks/usePopups";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import useNavigator from "hooks/useNavigator";
import reccomendIcon from "assets/images/svgs/reccomendations-icon.svg";
import months from "consts/months";
import RecommendationHeader from "./RecommendationHeader";

export default ({
	talentId,
	item,
	onDelete,
	myRef = null,
	isFromWeb = false,
	talentProfileUid = "",
}) => {
	const [user, setUser] = useState();
	const { userProfile } = useUser();
	const dispatch = useDispatch();
	const talentUid = talentProfileUid ?? talentId;

	const dialogsArr = useSelector((state) => state.dialogs.dialogs);
	const recomendDialog = dialogsArr?.length ? dialogsArr[dialogsArr.length - 1] : null;

	const deleteRecommendation = async (isMyComment = false) => {
		if (isMyComment) {
			await recommendations.deleteRecommendation(item.key, talentUid);
		} else {
			await recommendations.deleteRecommendation(item.key);
		}
		onDelete(item.key);
	};

	const goToUserProfile = () => {
		//in the future
	};

	useEffect(() => {
		const getUser = async () => {
			if (item.uid) {
				const userProfile = await publicProfileService.fetchProfile(item.uid);
				setUser(userProfile);
			} else {
				setUser({});
			}
		};
		getUser();
	}, []);

	const deleteRecommendationPopup = (isMyComment = false) => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: isMyComment
					? "האם למחוק את התגובה שלך?"
					: `האם למחוק את התגובה של ${user?.name ? user.name : item?.name} - "${item.text}"`,
				onYes: () => {
					deleteRecommendation(isMyComment);
					toggelClose();
				},
				onNo: toggelClose,
			})
		);
	};

	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};
	const openUserRecomendationPage = () => {
		if (!user.uid || user.uid == recomendDialog?.recId) return;
		//don't open the popup if the popup is already open

		dispatch(
			openDialog({ dialog: dialogsTypes.UserRecomendationDialog, recId: user.uid, userProfile })
		);
	};

	return (
		<Box
			className={`animate__animated ${item?.animate ? item?.animate : ""} recommendationBox ${
				userProfile.uid == talentUid && item?.status == 0 ? "unReadRecomandation" : ""
			}`}
			ref={myRef}>
			{!!user && (
				<>
					<Box className="innerRecommendationBox">
						<RecommendationHeader
							user={user}
							item={item}
							displayDeleteBtn={userProfile.uid == talentUid || userProfile.uid == item?.uid}
							openUserRecomendationPage={openUserRecomendationPage}
							deleteRecommendationPopup={deleteRecommendationPopup}
						/>

						<Box className="wrapTxtRec">
							<span>{item.text}</span>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};
