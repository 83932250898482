import firebase from "firebase/app";

firebase.initializeApp({
	apiKey: "AIzaSyC6K1jNJqPsxVJ87KkgFXLtnl2Jkfd_LGA",
	authDomain: "sixdays-wedding.firebaseapp.com",
	databaseURL: "https://sixdays-wedding-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "sixdays-wedding",
	storageBucket: "sixdays-wedding.appspot.com",
	messagingSenderId: "103151145477",
	appId: "1:103151145477:web:9d401554b5c1607773026c",
	measurementId: "G-809KC2RJNY",
});
