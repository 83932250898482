import "assets/scss/statistics/statistics.scss";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import useUser from "hooks/useUser";
import i18next from "i18next";
import SiteButton from "components/ui/SiteButton";
import shareProfile from "assets/images/svgs/profile/shareProfile.svg";
import statisticsService from "services/statistics.service";
import functionsService from "services/functionsService";
import { merge } from "lodash";
import Loader from "components/Loader";

export default function StatisticsPage({}) {
	const { userProfile } = useUser();
	const [loading, setLoading] = useState(true);

	const statisticsToDisplay = {
		viewIn: { count: 0, lastWeek: 0 },
		clickPhone: { count: 0, lastWeek: 0 },
		clickWhatsapp: { count: 0, lastWeek: 0 },
		clickSendMessage: { count: 0, lastWeek: 0 },
		clickAddFavorite: { count: 0, lastWeek: 0 },
		clickPhotos: { count: 0, lastWeek: 0 },
		clickWebLink: { count: 0, lastWeek: 0 },
		clickInstagramLink: { count: 0, lastWeek: 0 },
		clickYoutubeLink: { count: 0, lastWeek: 0 },
		clickTiktokLink: { count: 0, lastWeek: 0 },
		clickLinkedinLink: { count: 0, lastWeek: 0 },
		clickFacebookLink: { count: 0, lastWeek: 0 },
		clickFriends: { count: 0, lastWeek: 0 },
		// viewOut: { count: 0, lastWeek: 0 },
		// recommendation: { count: 0, lastWeek: 0 },
	};
	const [statistics, setStatistics] = useState(statisticsToDisplay);

	const message = `היי, ראיתי את ${userProfile?.name} ב"ששת ימים" - פלטפורמת ספקים ונותני שירותים מבוססת המלצות חברים משותפים ובינה מלאכותית. מה דעתך?🙏`;
	const shareTxt = [
		<span style={{ margin: "0 0 0 10px" }}>
			<img src={shareProfile} />
		</span>,
		i18next.t("register.shareMyCard"),
	];

	const handleCopyUrl = async () => {
		if (userProfile.internalID) {
			return await navigator.share({
				title: `מה דעתך על ${userProfile?.name}`,
				text: message,
				url: `${window.location.origin}/talent/${userProfile?.internalID}?affId=${userProfile.internalID}`,
			});
		}
	};

	useEffect(async () => {
		functionsService.resetUnreadCount("talentActions");

		const [generalStatistic, lastWeekStatistics] = await Promise.all([
			statisticsService.searchTalentActions(),
			statisticsService.searchTalentActions(true),
		]).catch((e) => console.log(e));

		const genAggArr = generalStatistic.data?.data?.aggregations?.byAction?.buckets;
		const lastWeekAggArr = lastWeekStatistics.data?.data?.aggregations?.byAction?.buckets;

		let objStatistic = {};
		genAggArr.map((item) => {
			if (!statistics[item?.key]) return;

			return (objStatistic[item?.key] = { count: item?.doc_count || 0 });
		});
		lastWeekAggArr.map((item) => {
			if (!statistics[item?.key]) return;
			return (objStatistic[item?.key] = {
				...objStatistic[item?.key],
				lastWeek: item?.doc_count || 0,
			});
		});

		setStatistics(merge(statistics, objStatistic));
		setLoading(false);
	}, []);

	return (
		<Box className="editAccountStatistics noSelection">
			<Box className="wrap-statistic-cards">
				{!loading &&
					!!statistics &&
					Object.entries(statistics || {}).map(([key, value], index) => (
						<StatisticCard key={index} item={value} text={i18next.t(`statistics.actions.${key}`)} />
					))}

				{!!loading && <Loader />}
			</Box>
			<Box className="wrap-explain">
				<Box className="wrap-title">
					<Box>{`${i18next.t("statistics.hey")}${
						userProfile?.name ? ` ${userProfile?.name}` : ""
					},`}</Box>
					<Box>{i18next.t("statistics.someInsights")}</Box>
				</Box>
				<Box className="wrap-subtitle">{i18next.t("statistics.subTitle")}</Box>
				<Box className="wrap-share-btn">
					<SiteButton
						text={shareTxt}
						onClickBtn={handleCopyUrl}
						styledText={{ style: { fontSize: "18px" } }}
					/>
				</Box>
			</Box>
		</Box>
	);
}

function StatisticCard({ item, text = "הקליקו על כפתור" }) {
	return (
		<Box className={`statistic-card  ${!!item?.lastWeek ? "with-moovment" : ""}`}>
			<Box className="statistic-header">{text}</Box>
			<Box className="statistic-content">{item?.count || 0}</Box>
			<Box className="statistic-footer">
				{item?.lastWeek ? (
					<Box className="moovment-text">
						<span className="moovment-number">
							{item?.lastWeek > 99 ? "99+" : `${item?.lastWeek}+`}
						</span>
						<span>{i18next.t("statistics.lastWeek")}</span>
					</Box>
				) : (
					i18next.t("statistics.noMovementLastWeek")
				)}
			</Box>
		</Box>
	);
}
