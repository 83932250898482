import { Box, TextField } from "@mui/material";
import { Button } from "@mui/material";

import Text from "components/ui/Text";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useState } from "react";
import chatService from "services/chatService";
import sendBtn from "assets/images/chat/sendBtn.png";
import CircularProgress from "@mui/material/CircularProgress";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { openNotification } from "redux/notification/actions";

export default ({ collegeUser, chatId, onOpenKeyboard }) => {
	const [message, setMessage] = useState();
	const [isSend, setIsSend] = useState(false);
	const { userProfile } = useUser();
	const textRef = useRef();
	const dispatch = useDispatch();

	const openKeyboard = () => {
		onOpenKeyboard();
	};

	const send = async () => {
		let messageToSend = "";
		if (!!message) {
			messageToSend = message.trim();
		}
		if (messageToSend) {
			textRef.current.focus();
			setIsSend(true);
			setMessage("");
			const returnData = await chatService.addMessage(
				userProfile.uid,
				collegeUser.uid,
				{ text: messageToSend },
				chatId
			);
			if (returnData.msg == "unAllow send") handleCantSendMsg();
			setIsSend(false);
		}
	};

	const handleCantSendMsg = () => {
		dispatch(
			openNotification(
				`${i18next.t("notificationActions.chat.cantSendMsg1")}${collegeUser?.name}${i18next.t(
					"notificationActions.chat.cantSendMsg2"
				)}`
			)
		);
	};

	return (
		<Box className="sendMessage">
			<Box className="wrapTxt">
				<TextField
					className="sendTxt"
					style={{ width: "100%" }}
					id="outlined-search"
					value={message}
					placeholder={i18next.t("Chat.writeYourMessage")}
					variant="standard"
					onChange={(e) => setMessage(e.target.value)}
					inputRef={textRef}
					onClick={(e) => openKeyboard(e)}
					multiline
					maxRows={5}
				/>
			</Box>
			<Box>
				<Button className="sendButton" onClick={send} sx={{}}>
					{!isSend ? (
						<span
							style={{
								color: "#fff",
								textAlign: "center",
								lineHeight: "1",
								height: "35px",
								width: "50px",
							}}>
							{i18next.t("sendMessage")}
						</span>
					) : (
						<CircularProgress
							size=""
							sx={{
								color: "#fff",
								width: "35px",
								height: "35px",
								margin: "0 7.5px",
							}}
						/>
					)}
				</Button>
			</Box>
		</Box>
	);
};
