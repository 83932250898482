import "assets/scss/AutocompleteSelect/AutocompleteSelect.scss";
import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { getFiltersSelected } from "redux/search/selectors";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";
import { useState } from "react";
import { isEqual } from "lodash";
export default function AutocompleteSearch({
	placeholder = "הוסף...",
	data,
	value,
	label,
	onChangeHandler,
	popupIcon = "",
	disableCloseOnSelect = true,
	onInputChange = (data) => {},
	handleBlur = () => {},
	handleFocus = () => {},
	freeSolo = false,
}) {
	const [tagValue, setTagValue] = useState(value);
	const advanceSearchData = useSelector(getFiltersSelected);
	const baseHandleBlur = () => {
		if (!freeSolo) return;
		const input = document.getElementById("tags-standard");
		//alert(3);
		if (input && input.value) {
			console.log(advanceSearchData);
			const freeText = advanceSearchData.freeText || [];
			const arr = [...freeText, ...[input.value]];
			onChangeHandler(arr);
			input.value = "";
		}
		handleBlur();
	};

	const onChangeHandle = (value) => {
		setTagValue(value);
	};

	const handleTagClose = (val) => {
		const newValue = tagValue.filter((item) => item != val);
		setTagValue(newValue);
	};

	const renderTags = (value, getTagProps) => {
		const tags = value.slice(-2);
		const tagsDisplay = tags.map((option, index) => (
			<Chip
				key={option}
				label={option}
				{...getTagProps({ index })}
				onDelete={() => handleTagClose(option)}
				deleteIcon={<CancelIcon />}
				style={{ margin: "4px" }}
			/>
		));

		return (
			<>
				{tagsDisplay}
				<div style={{ background: "none" }}>
					{value.length - 2 > 0 ? `+${value.length - 2}` : ""}
				</div>
			</>
		);
	};

	useEffect(() => {
		onChangeHandler(tagValue);
	}, [tagValue]);

	useEffect(() => {
		if (!isEqual(value, tagValue)) {
			setTagValue(value);
			window.scrollTo(0, 0);
		}
	}, [value]);

	return (
		<Stack spacing={3} className="autocompleteSelectWrapper">
			<Autocomplete
				onBlur={baseHandleBlur}
				limitTags={4}
				renderTags={renderTags}
				onFocus={handleFocus}
				disableClearable={true}
				popupIcon={popupIcon}
				disableCloseOnSelect={disableCloseOnSelect}
				autoComplete
				multiple
				onInputChange={onInputChange}
				onChange={(e, value) => onChangeHandle(value)}
				id="tags-standard"
				options={data}
				clearOnBlur={true}
				getOptionLabel={(option) => {
					if (typeof option === "string") {
						return option;
					} else {
						return option?.name;
					}
				}}
				value={tagValue}
				freeSolo={freeSolo}
				renderInput={(params) => (
					<TextField {...params} variant="standard" label={label} placeholder={placeholder} />
				)}
			/>
		</Stack>
	);
}
