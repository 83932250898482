import { Box } from "@mui/material";
import { TransformImg } from "helpers/cloudinary";
import { useDispatch, useSelector } from "react-redux";
import { addRemoveToSelectedList, setSelectedChat } from "redux/chats/actions";

import { useLongPress } from "use-long-press";
import { useEffect, useState } from "react";
import { getChatListSelected } from "redux/chats/selectors";

import ChatListDate from "./ChatListDate";
import chatService from "services/chatService";
import personalMessage from "assets/images/chat/personalMessage.png";
import pinImg from "assets/images/svgs/pin.svg";
import DoneIcon from "@mui/icons-material/Done";
import i18next from "i18next";
import useUser from "hooks/useUser";

export default ({
	item: { user, chatId, updated, lastMessage, pin, unReadCount },
	editChats = false,
}) => {
	const dispatch = useDispatch();
	const chatListSelected = useSelector(getChatListSelected);
	const [selectedClass, setSelectedClass] = useState(false);
	const { getProfileImage } = useUser();
	const selectedChat = useSelector((state) => state.chats.selectedChat);

	const bind = useLongPress(
		() => {
			handleAddRemoveToSelectedList(user.uid);
			setSelectedClass(false);
		},
		{
			onStart: (event) => setSelectedClass(true),
			onFinish: (event) => setSelectedClass(true),
			onCancel: (event) => {
				setTimeout(() => {
					setSelectedClass(false);
				}, 1);
			},
			onMove: (event) => {
				setSelectedClass(false);
			},
			//	filterEvents: (event) => true, // All events can potentially trigger long press
			threshold: 300,
			captureEvent: true,
			cancelOnMovement: false,
			detect: "both",
		}
	);

	const handleAddRemoveToSelectedList = (id) => {
		dispatch(addRemoveToSelectedList(id, pin));
	};

	const profileImage = TransformImg(getProfileImage(user), 200, 200);

	const goToChat = () => {
		chatService.markChatAsRead(user.uid);
		dispatch(setSelectedChat({ chatId, user }));
	};

	const clickChat = () => {
		if (editChats || (chatListSelected && Object.values(chatListSelected).length)) {
			handleAddRemoveToSelectedList(user.uid);
		} else {
			goToChat();
		}
	};

	return (
		<>
			{user && (
				<Box
					className={
						chatListSelected[user.uid] || selectedClass || selectedChat?.user?.uid == user?.uid
							? "chatListItem chat-selected"
							: "chatListItem"
					}
					onClick={clickChat}
					// {...bind()}
				>
					<Box className="chatImgWrapper">
						{!!editChats && (
							<Box
								className={
									chatListSelected[user.uid] || selectedClass
										? "countWrapper chat-selected"
										: "countWrapper"
								}>
								<DoneIcon />
							</Box>
						)}
						<img src={profileImage} width={60} />
					</Box>
					<Box className="chatItemWrapper">
						<Box className="chatItemContent" sx={{ width: `${window.innerWidth - 140}px` }}>
							<Box className="chatItemName">{user.name}</Box>
							{!!user?.title && <Box className="chatItemTitle">{user.title}</Box>}

							{lastMessage?.text && (
								<Box sx={{ width: "100%" }}>
									<Box className="chatItemLastMessage">{`${lastMessage?.text}`}</Box>
								</Box>
							)}
						</Box>
						{lastMessage?.type !== "private" && (
							<Box className="generalAddress">{i18next.t("Chat.generalAddress")}</Box>
						)}
						<Box className="chatUnreadPinWrapper">
							<Box className="unreadAndPin">
								{!!unReadCount && (
									<Box className="chatUnread">
										<span>{unReadCount}</span>
									</Box>
								)}

								<Box className="chatPin">{pin ? <img src={pinImg} alt="" /> : ""}</Box>
							</Box>
							<ChatListDate updated={updated}></ChatListDate>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
