import { Box } from "@mui/material";
import Terms from "components/account/ProfileInputs/Terms";

export default ({}) => {
	return (
		<Box sx={{ padding: "20px" }}>
			<Terms />
		</Box>
	);
};
