import "assets/scss/latestRecommendations/latestRecommendations.scss";
import { Box, CircularProgress } from "@mui/material";
import BaseHelmet from "components/seo/BaseHelmet";
import i18next from "i18next";
import { useEffect, useState } from "react";
import functionsService from "services/functionsService";
import { RecommendOn } from "pages/UserRecomendationPage";
import recImg from "assets/images/svgs/newRec/newRecIcon.svg";

export default ({}) => {
	const [lastRecomendation, setLastRecomendation] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(async () => {
		await functionsService.getLastRecommendations().then((res) => {
			if (res?.data?.data && !!res.data.data?.length) setLastRecomendation(res.data.data);
		});
		setLoading(false);
	}, []);

	return (
		<Box className="latestRecommendations" sx={{ overflow: "scroll" }}>
			<BaseHelmet
				title={i18next.t("seo.latestRec.title")}
				description={i18next.t("seo.latestRec.description")}
				url={`latestRecommendations`}></BaseHelmet>

			<h4 className="center">
				<Box className="imgWrapper">
					<Box className="wrapRecImg">
						<img src={recImg} alt="sheshet" />
					</Box>
				</Box>
				<span>{"המלצות אחרונות על"}</span>
				<span style={{ fontWeight: "700" }}>{"המומחים של ששת"}</span>
			</h4>

			<Box className="recommendationWrapper" sx={{ margin: "20px 100px" }}>
				<Box className="recommendationList">
					{loading ? (
						<Box className="center">
							<CircularProgress size={40} sx={{ color: "#168187" }} />
						</Box>
					) : (
						<>
							{lastRecomendation.map((item, index) => (
								<RecommendOn item={item} key={index} talentId={item.talentId} userId={item.uid} />
							))}
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};
