import MassLetter from "components/chat/chatTemplates/MassLetter";
import Offer from "components/chat/chatTemplates/Offer";
import chatTemplates from "constants/chatTemplates";

export default function useChat() {
	const getTextChat = (item) => {
		if (item) {
			if (item.template) {
				return generateTextByTemplate(item);
			} else {
				return item.text;
			}
		}
	};

	const generateTextByTemplate = (item) => {
		let text = "";
		const templateName = item.template;
		switch (templateName) {
			case chatTemplates.Offer:
				text = <Offer item={item}></Offer>;
				break;
			case chatTemplates.MassLettr:
				text = <MassLetter item={item}></MassLetter>;
				break;
		}
		return text;
	};
	return {
		getTextChat,
	};
}
