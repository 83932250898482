import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import defaultProfile from "assets/images/svgs/darkAvatar.svg";

import { TransformImg } from "helpers/cloudinary";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default ({ user, item, ...prop }) => {
	const { getProfileImage } = useUser();
	const [profileImageUrl, setProfileImageUrl] = useState();

	useEffect(() => {
		if (profileImageUrl === undefined || !profileImageUrl)
			setProfileImageUrl(getFirstCharImg(item));
	}, [profileImageUrl]);

	useEffect(() => {
		if (user?.uid) {
			const url = getProfileImage(user);
			const tarnsImg = TransformImg(url, 200, 200);
			setProfileImageUrl(tarnsImg);
		}
	}, [user]);

	return (
		<img
			width={"50px"}
			height={"50px"}
			src={profileImageUrl}
			style={{ background: profileImageUrl === defaultProfile && "#fff" }}
			{...prop}
		/>
	);
};
