import "assets/scss/landing/landingFooter.scss";
import { Box } from "@mui/material";
import { useState } from "react";
import i18next from "i18next";
import { Button } from "reactstrap";
import FooterLinkBtns from "./footer/FooterLinkBtns";
import ContactInfo from "./footer/ContactInfo";
import UsefulLinks from "./footer/UsefulLinks";
import SubscribeNewsletter from "./footer/SubscribeNewsletter";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default function LandingFooter() {
	const [countUsers, setCountUsers] = useState("250,000 ");
	const dispatch = useDispatch();

	return (
		<Box className="landingFooter">
			<Box className="footerContent">
				<FooterLinkBtns />
				<ContactInfo />
				<UsefulLinks />
				{/* <SubscribeNewsletter /> */}
			</Box>
			<Box className="bottomBackground">
				<Box className="rightsReserved noSelection">
					{i18next.t("landingPage.allRightsReserved")}
				</Box>
				<Box className="privacy">
					<span
						onClick={() => {
							dispatch(openDialog({ dialog: dialogsTypes.Terms }));
						}}>
						{i18next.t("landingPage.termsAndPrivecy")}
					</span>
					{/* <span
						onClick={() => {
							dispatch(openDialog({ dialog: dialogsTypes.Terms }));
						}}>
						{i18next.t("landingPage.terms")}
					</span>
					<span>|</span>
					<span
						onClick={() => {
							dispatch(openDialog({ dialog: dialogsTypes.Terms }));
						}}>
						{i18next.t("landingPage.privacy")}
					</span> */}
				</Box>
			</Box>
		</Box>
	);
}
