import Notification from "./Notification";
import { useDispatch } from "react-redux";
import { openNotification, closeNotification } from "redux/notification/actions";
import i18next from "i18next";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import publicProfile from "services/publicProfile";
import { Link } from "react-router-dom";
import useUser from "hooks/useUser";
import chatService from "services/chatService";
import { setSelectedChat } from "redux/chats/actions";

export default function NotificationWrapper() {
	const dispatch = useDispatch();
	const { userProfile } = useUser();

	const onClickUser = (chatId, user) => {
		dispatch(closeNotification());
		chatService.markChatAsRead(user.uid);
		dispatch(setSelectedChat({ chatId, user }));
	};

	useEffect(() => {
		//new msg

		if (!isEmpty(userProfile) && userProfile?.uid) {
			const listenViewMeAdded = chatService
				.listenNewMessagesRecived(userProfile.uid)
				.subscribe(async (data) => {
					if (!isEmpty(data) && !!data.key) {
						if (window.location.pathname === "/chat") return;
						await publicProfile.fetchProfile(data.key).then((userSendMeMsg) => {
							if (!isEmpty(userSendMeMsg)) {
								dispatch(
									openNotification(
										<Link
											to="/chat"
											className="wrapNotificationMsg"
											onClick={() => onClickUser(data.chatId, userSendMeMsg)}>
											{i18next.t("notificationActions.newMsg")}
											{userSendMeMsg?.name}
											{!!userSendMeMsg?.profileImage?.url && (
												<img src={`${userSendMeMsg.profileImage.url}`} alt="img" />
											)}
										</Link>
									)
								);
							}
						});
					}
				});

			return () => {
				listenViewMeAdded.unsubscribe();
			};
		}
	}, [userProfile]);

	return <Notification />;
}
