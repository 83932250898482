import { useEffect, useRef, useState } from "react";
import serviceArea from "consts/serviceArea";
import { getFiltersSelected } from "redux/search/selectors";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import i18next from "i18next";

export default ({ onChange }) => {
	const advanceSearchData = useSelector(getFiltersSelected);
	const [selectedVal, setSelectedVal] = useState([]);
	const didMount = useRef(false);

	const selectArea = (data) => {
		setSelectedVal((oldVal) => {
			if (oldVal?.includes(data) ?? false) {
				return oldVal.filter((item) => item !== data);
			} else {
				return [...oldVal, data];
			}
		});
	};

	useEffect(() => {
		if (!advanceSearchData?.area?.length) return setSelectedVal([]);

		if (advanceSearchData && advanceSearchData.area && !didMount.current) {
			didMount.current = true;
			setSelectedVal(advanceSearchData.area);
		}
	}, [advanceSearchData?.area]);

	useEffect(() => {
		onChange({ area: selectedVal });
	}, [selectedVal]);
	return (
		<>
			<Box className="center areaBtns" sx={{ flexWrap: "wrap" }}>
				{Object.entries(serviceArea || {}).map(([key, val]) => {
					const isSelected = selectedVal?.includes(val) ?? false;
					return (
						<button
							key={key}
							className={isSelected ? "selected" : ""}
							style={{
								border: "0",
								boxShadow: "0px 2px 3px 0px #ccc",
								margin: "5px",
								borderRadius: "10px",
								background: isSelected ? "#525bc2" : "#fff",
								padding: "3px 25px",
								minHeight: " 15px",
								fontSize: "13px",
								color: isSelected ? "#fff" : "#313030",
								fontWeight: "600",
							}}
							onClick={(e) => selectArea(val)}>
							{i18next.t(`serviceArea.${key}`)}
						</button>
					);
				})}
			</Box>
		</>
	);
};
