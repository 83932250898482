import "assets/scss/uploadImg/uploadImg.scss";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import defaultProfile from "assets/images/svgs/darkAvatar.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import UploadImageInput from "components/account/EditImages/UploadImageInput";
import imgUserEmpty from "assets/images/profilesImages/empty.png";
import { getFacebookPictureUrl } from "redux/profile/selectors";
import useUser from "hooks/useUser";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, CircularProgress } from "@mui/material";
import multyNoImg from "assets/images/noImg.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation } from "react-router";
import { TransformImg } from "helpers/cloudinary";
import penEdit from "assets/images/svgs/penEdit.svg";

export default ({
	loading = false,
	onChange,
	onValidityChange,
	onDeleteImage,
	value,
	profilePic = true,
	settingsPage = false,
	multiUploadImages = false,
}) => {
	const [file, setFile] = useState();
	const [inProgress, setInProgress] = useState(false);
	const refUploadInput = useRef();
	// const facebookPictureUrl = useSelector(getFacebookPictureUrl);
	const { userProfile } = useUser();

	const handleFileSize = (file) => {
		if (!file) return file;
		return TransformImg(file, 200, 200);
	};

	const handleSaveNewImage = async (file) => {
		setInProgress(true);
		onValidityChange(true);
		if (!!file) {
			setFile(handleFileSize(file));
			await onChange(file);
		}
		setInProgress(false);
	};

	const deleteImage = (e) => {
		onDeleteImage(e);
	};

	const onImageClick = () => {
		refUploadInput.current.click();
	};

	useEffect(() => {
		setFile(handleFileSize(value));
	}, [value]);

	useEffect(() => {
		setInProgress(loading);
	}, [loading]);

	// useEffect(() => {
	// 	setFile(userProfile?.profileImage?.url);
	// 	setInProgress(false);
	// }, [userProfile?.profileImage?.url]);

	useEffect(() => {
		if (userProfile?.profileImage?.url && !multiUploadImages)
			setFile(handleFileSize(userProfile.profileImage?.url));
	}, [userProfile]);
	useEffect(() => {
		if (multiUploadImages) setFile(handleFileSize(multyNoImg));
	}, []);

	return (
		<>
			<Box className="center wrapImgContent" onClick={onImageClick} sx={{ width: "min-content" }}>
				<Box
					className="wrapDefaultImg"
					style={{
						border: file == multyNoImg && "0",
						borderRadius: profilePic ? "50%" : "10px",
						padding: "0",
						zIndex: 2,
						cursor: "pointer",
					}}>
					{!inProgress ? (
						<img
							style={{ borderRadius: profilePic ? "50%" : "10px" }}
							width={multiUploadImages ? 90 : 100}
							height={multiUploadImages ? 90 : 100}
							src={file ? file : defaultProfile}
						/>
					) : (
						<Box className="center" sx={{ padding: "30px" }}>
							<CircularProgress size={40} sx={{ color: "#000000" }} />
						</Box>
					)}
					{/* {(!file || file == multyNoImg) && (
						<Box className="addCircleIcon">
							{<AddCircleIcon sx={{ color: "#000000", fontSize: "50px" }} />}
						</Box>
					)} */}
					{(!file || file === multyNoImg || (file && !multiUploadImages)) && (
						<Box className="editIcon" style={{ bottom: "0px" }}>
							{<img src={penEdit} alt="pen" style={{ margin: "5px" }} />}
						</Box>
					)}
					{file && file !== multyNoImg && multiUploadImages && (
						<Box onClick={deleteImage} className="addCircleIcon" sx={{ background: "none" }}>
							{<CancelIcon sx={{ color: "#ef2e2e", fontSize: "40px" }} />}
						</Box>
					)}
				</Box>
			</Box>

			<Box>
				<UploadImageInput refInput={refUploadInput} handleSaveNewImage={handleSaveNewImage} />
			</Box>
		</>
	);
};
