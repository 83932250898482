import produce from "immer";

import * as types from "./actionTypes";

const initialState = {
	platform: undefined,
};

export default produce((state, action) => {
	switch (action.type) {
		case types.INIT_PLATFORM_SUCCESSFULLY:
			state.platform = action.payload.platform;
	}
}, initialState);
