import { Box, TextField } from "@mui/material";
import i18next from "i18next";
import { useState } from "react";
import searchIcon from "assets/images/tabs/searchIcon.png";
import useNavigator from "hooks/useNavigator";

export default ({ onSearch, setEditChats = () => {} }) => {
	const [value, setValue] = useState("");
	const navigate = useNavigator();

	const editChats = () => {
		setEditChats(true);
	};
	const handleSearchChange = (event) => {
		setValue(event.target.value);
		onSearch(event.target.value);
	};
	return (
		<>
			<Box className="chatEdit" onClick={editChats}>
				{i18next.t("Chat.edit")}
			</Box>
			<Box className="headerMessage" sx={{ display: "none" }}>
				{i18next.t("tabs.message")}
			</Box>
			<Box className="searchChatWrapper" style={{ width: "100%" }}>
				<TextField
					className="searchChatText"
					style={{ width: "100%" }}
					id="outlined-search"
					value={value}
					placeholder={i18next.t("Search.search")}
					variant="standard"
					onChange={handleSearchChange}
				/>
				<Box className="searchIcon">
					<img src={searchIcon} alt="" width={17} onClick={(e) => e.stopPropagation()} />
				</Box>
			</Box>
		</>
	);
};
