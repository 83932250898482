import { Box } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
	fbRegExp,
	tikTokRegExp,
	youtubeRegExp,
	linkedinRegExp,
	instagramRegExp,
	youtubeChanelRegExp,
} from "helpers/regex";
import useUser from "hooks/useUser";
import ErrorLabel from "components/ui/ErrorLabel";
import TextComponent from "components/ui/TextComponent";
import RegisterBtnWrap from "./RegisterBtnWrap";
import { useDispatch } from "react-redux";
import { openNotification } from "redux/notification/actions";
import LoginHeader from "components/login/LoginHeader";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import useNavigator from "hooks/useNavigator";
import { ScrollToFieldError } from "helpers/FormikErrors";

export default ({ nextStep, user, type = "register", goBack, currentStep = 1, pathType }) => {
	const [inProgress, setInProgress] = useState(false);
	const btnText = type === "register" ? i18next.t("register.next") : i18next.t("register.save");
	const dispatch = useDispatch();
	const { registerStep } = useUser();
	const navigate = useNavigator();

	const initialValues = {
		linkedin: user.linkedin ?? "",
		facebook: user.facebook ?? "",
		tikTok: user.tikTok ?? "",
		instagram: user.instagram ?? "",
		youtube: user.youtube ?? "",
		site: user.site ?? "",
	};

	const validationSchema = Yup.object().shape({
		site: Yup.string().url(i18next.t("validation.site")),
		tikTok: Yup.string().matches(tikTokRegExp, i18next.t("validation.tikTok")),
		linkedin: Yup.string().matches(linkedinRegExp, i18next.t("validation.linkedin")),
		facebook: Yup.string().matches(fbRegExp, i18next.t("validation.facebook")),
		instagram: Yup.string().matches(instagramRegExp, i18next.t("validation.instagram")),
		youtube: Yup.string().matches(youtubeChanelRegExp, i18next.t("validation.youtube")),
	});

	const validYouTube = (youtubeLink) => {
		let newYoutubeLink = youtubeLink;
		const splitUrlLink = youtubeLink.split("/");
		if (splitUrlLink.includes("youtu.be")) {
			const linkId = splitUrlLink.slice(-1)[0];
			newYoutubeLink = `https://www.youtube.com/watch?v=${linkId}`;
		}
		return newYoutubeLink;
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setInProgress(true);

			if (values.youtube) {
				const youtube = validYouTube(values.youtube);
				values.youtube = youtube;
			}

			const returnData = await registerStep(values, 3, type);

			if (returnData?.data?.success) {
				if (type === "register") {
					window.scrollTo(0, 0);
					nextStep();
				} else {
					dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
				}
			} else {
				dispatch(openNotification(i18next.t(returnData?.data?.msg)));
			}
			setInProgress(false);
		},
	});

	return (
		<>
			<LoginHeader step={3} textHeader={i18next.t("register.siteAndSocialLinks")} />

			<form
				className="reg-step1 reg-step2"
				id="register-step-22"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "10px" }}>
				<Box className="skills center ">{i18next.t("register.addLinkToSiteAndSocialMedia")}</Box>

				<Box sx={{ margin: "10px 0" }}>
					<Box className="reg-title">{i18next.t("register.site")}</Box>
					<TextComponent
						id="site"
						{...formik.getFieldProps("site")}
						helperText={
							!!formik?.values?.site && !user?.site
								? i18next.t("register.exampleHttps")
								: i18next.t("register.withHttps")
						}
					/>
					<ErrorLabel>{formik.touched.site && formik.errors.site}</ErrorLabel>
				</Box>

				<Box sx={{ margin: "10px 0" }}>
					<Box className="reg-title">{i18next.t("register.instagram")}</Box>
					<TextComponent id="instagram" {...formik.getFieldProps("instagram")} />
					<ErrorLabel>{formik.touched.instagram && formik.errors.instagram}</ErrorLabel>
				</Box>

				<Box sx={{ margin: "10px 0" }}>
					<Box className="reg-title">{i18next.t("register.facebook")}</Box>
					<TextComponent id="facebook" {...formik.getFieldProps("facebook")} />
					<ErrorLabel>{formik.touched.facebook && formik.errors.facebook}</ErrorLabel>
				</Box>

				<Box sx={{ margin: "10px 0" }}>
					<Box className="reg-title">{i18next.t("register.linkedin")}</Box>
					<TextComponent id="linkedin" {...formik.getFieldProps("linkedin")} />
					<ErrorLabel>{formik.touched.linkedin && formik.errors.linkedin}</ErrorLabel>
				</Box>

				<Box sx={{ margin: "10px 0" }}>
					<Box className="reg-title">{i18next.t("register.tikTok")}</Box>
					<TextComponent id="tikTok" {...formik.getFieldProps("tikTok")} />
					<ErrorLabel>{formik.touched.tikTok && formik.errors.tikTok}</ErrorLabel>
				</Box>

				<Box sx={{ margin: "10px 0" }}>
					<Box className="reg-title">{i18next.t("register.youtubeChanel")}</Box>
					<TextComponent id="youtube" {...formik.getFieldProps("youtube")} />
					<ErrorLabel>{formik.touched.youtube && formik.errors.youtube}</ErrorLabel>
				</Box>

				<Box sx={{ marginBottom: "80px" }}>
					<RegisterBtnWrap
						type={type}
						inProgress={inProgress}
						btnText={btnText}
						currentStep={currentStep}
						goBack={goBack}></RegisterBtnWrap>
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
		</>
	);
};
