import { createSelector } from "reselect";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

export const getViewedMe = (state) => state.viewedMe;

export const getPitId = createSelector(getViewedMe, (viewedMe) => viewedMe.pitId);

export const getSearchAfter = createSelector(getViewedMe, (viewed) => viewed.searchAfter);

export const getViewedMeUsers = createSelector(
	getViewedMe,
	getBlocks,
	(viewedMe, blocks) =>
		Object.keys(viewedMe.users)
			.sort(
				(uidA, uidB) => viewedMe.users[uidB].views_timeStamp - viewedMe.users[uidA].views_timeStamp
			)
			.map((uid) => viewedMe.users[uid])
			.filter((user) => !blocks[user.uid])
);

export const getIsLoadingMore = createSelector(
	getViewedMe,
	(viewedMe) => viewedMe.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getViewedMe, (viewedMe) => viewedMe.hasMoreResults);

export const getUnViewedViewedMeCount = createSelector(
	getViewedMe,
	(viewedMe) => viewedMe.unViewedViewedMeCount
);

export const getViewedMeStatus = createSelector(getViewedMe, (viewedMe) => viewedMe.apiStatus);