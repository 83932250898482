import { Box } from "@mui/material";
import "assets/scss/shabat/shabat.scss";
import useUser from "hooks/useUser";
import moment from "moment";
import { useEffect } from "react";

export default function Shabat({ parashat = "", havdalah = "" }) {
	const { userProfile } = useUser();

	// useEffect(() => {
	// 	document.getElementById("root").style.overflow = "hidden";
	// 	return () => {
	// 		return (document.getElementById("root").style.overflow = "initial");
	// 	};
	// }, []);
	return (
		<Box className="shabatPageWrapper">
			<Box className="shabatContent">
				<h1 className="innerText" style={{ fontWeight: !userProfile?.name && "400" }}>{`היי${
					userProfile?.name ? ` ${userProfile.name}, ` : ", "
				}`}</h1>
				<h2 className="innerText isPera">{`בשביל שהמומחים שלנו יוכלו לתת לך את השירות הכי טוב שיש, גם הם צריכים יום מנוחה אחד בשבוע :)`}</h2>

				<Box className="innerText isPera">{`נחזור לפעילות מלאה מיד בצאת השבת`}</Box>
				{havdalah && (
					<Box className="innerText isPera">{`${moment(havdalah).format(
						"DD.MM.YYYY בשעה HH:MM"
					)}`}</Box>
				)}
				<Box className="innerText isPera">
					{`ולבינתיים - שתהיה לכולנו `} <span className="colorText">{`שבת שלום!`}</span>
				</Box>
			</Box>
		</Box>
	);
}
