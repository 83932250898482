import { createSelector } from "reselect";

export const getChatMeta = (state) => state.chatMeta;

export const getAllUnreadMessages = createSelector(getChatMeta, (chatMeta) => {
  const res = Object.keys(chatMeta)
    .map((chat) => chatMeta[chat].newMessages || 0)
    .reduce((a, b) => a + b, 0);
  return res;
});

export const hasTyping = (chatId) =>
  createSelector(getChatMeta, (chatMeta) => chatMeta[chatId]?.typing);
