import { useState, useEffect } from "react";

import usePopups from "hooks/usePopups";
import CropImageTypes from "consts/cropImageTypes";
import getImageBase64FromURl from "helpers/getImageBase64FromURl";

export default ({ onClick, refInput, handleSaveNewImage, style = {}, urlForAutoOpening }) => {
	const [upImg, setUpImg] = useState(null);
	const [openCrop, setOpenCrop] = useState(1);
	const { showCropImagePopup } = usePopups();

	const resize = (base64Image) => {
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.onload = () => {
				if (img.width > 1200 || img.height > 1200) {
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					const { width, height } = calculateWidth(1200, 1200, img.width, img.height);
					canvas.width = width;
					canvas.height = height;
					img.crossOrigin = "anonymous";
					ctx.drawImage(img, 0, 0, width, height);
					const res = canvas.toDataURL("image/jpeg");
					ctx = null;
					canvas = null;
					// in iphone is upload in first time black image , we check if is black image and don't resolve it
					if (!res.includes("iiigAooooAKKKKACiiigAooooAKKKKA")) {
						resolve(res);
					}
				} else {
					if (base64Image.includes("http")) {
						resolve(getBase64FromUrl(base64Image));
					} else resolve(base64Image);
				}
			};
			img.src = base64Image;
		});
	};

	const calculateWidth = (maxWidth, maxHeight, imageWidth, imageHeight) => {
		const conf = Math.max(imageWidth / maxWidth, imageHeight / maxHeight);
		return {
			width: imageWidth / conf,
			height: imageHeight / conf,
		};
	};

	const getBase64FromUrl = async (url) => {
		const data = await fetch(url);
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data);
			};
		});
	};

	useEffect(() => {
		if (!upImg) return;
		resize(upImg).then((imgUrl) => handleSaveNewImage(imgUrl));

		// showCropImagePopup({
		// 	image: upImg,
		// 	cropImageType: CropImageTypes.NewImage,
		// 	handleSaveNewImage,
		// });
	}, [openCrop]);

	useEffect(async () => {
		if (!urlForAutoOpening) return;
		setUpImg(await getImageBase64FromURl(urlForAutoOpening));
		setOpenCrop(openCrop + 1);
	}, []);

	const onSelectFileInput = (e) => {
		if (!(e.target.files && e.target.files.length > 0)) return;
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.addEventListener("load", () => {
			setUpImg(reader.result);
			setOpenCrop(openCrop + 1);
			e.target.value = "";
		});
		/*	if (!(e.target.files && e.target.files.length > 0)) return;
		const data = URL.createObjectURL(e.target.files[0]);
		setUpImg(data);
		setOpenCrop(openCrop + 1);
		e.target.value = "";*/
	};

	return (
		<input
			style={{ display: "none", style }}
			ref={refInput}
			className=" empty"
			accept="image/*"
			id="contained-button-file"
			type="file"
			onChange={onSelectFileInput}
			onClick={onClick}
		/>
	);
};
