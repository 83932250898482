import moment from "moment";
import { createSelector } from "reselect";
import _ from "lodash";

import { getUserGeneralStatus, getAllowSendMassLetterMessage } from "redux/general/selectors";
import { getMassletterPeriodDays } from "redux/system/selectors";
import API_REQUESTS_STATUS from "consts/apiRequestStatus";

export const getMassletter = (state) => state.massletter;

export const getStatus = createSelector(getMassletter, (massLetter) => massLetter.apiStatus);

export const getLastMassLetterCreateDate = createSelector(
	getMassletter,
	(massLetter) => massLetter.last_massletter_create_date
);

export const getOpenMassletterModal = createSelector(
	getMassletter,
	(massLetter) => massLetter.open_massletter_modal
);

export const getShowMassLetterPopup = createSelector(
	getStatus,
	getUserGeneralStatus,
	getAllowSendMassLetterMessage,
	getMassletterPeriodDays,
	getLastMassLetterCreateDate,
	getOpenMassletterModal,
	(
		apiStatus,
		userGeneralStatus,
		allowSendMassLetterMessage,
		massletterPeriodDays,
		lastMassLetterCreateDate,
		openMassletterModal
	) => {
		const managedOrHadPeriodFromLastSend = openMassletterModal; // || moment().diff(lastMassLetterCreateDate, "days") > massletterPeriodDays;

		return (
			apiStatus === API_REQUESTS_STATUS.SUCCESSFULLY &&
			userGeneralStatus &&
			allowSendMassLetterMessage &&
			managedOrHadPeriodFromLastSend
		);
	}
);
