import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

import StorageService from "services/storage";
import randomstring from "randomstring";
import PendingStatus from "consts/pendingStatus";
import UploadImagesService from "./UploadImagesService";
import httpsCallable from "./httpsCallable";

class ProfileService {
	BASE_REF = "users_profile";

	updateProfile(uid, details) {
		return firebase.database().ref(this.BASE_REF).child(uid).update(details);
	}

	fetchUserProfile(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid);
			console.log("u.p fetch");
			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	listenUserGeneralReadOnly(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref("users_general").child(uid).child("readOnly");

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	async fetchUserProfileOnce(id) {
		return (await firebase.database().ref(this.BASE_REF).child(id).once("value")).val();
	}
	async deleteResume(userAuth, name) {
		await StorageService.deleteResume(userAuth, name);
		return firebase.database().ref(this.BASE_REF).child(userAuth).child("resume").set(null);
	}
	async deleteImage(userAuth, imageName, imageData) {
		try {
			const result = await httpsCallable.post("deleteImage", {
				extraData: {
					imageName,
				},
			});
			return result.success;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	setMainImage(imagekey) {
		const uid = firebase.auth().currentUser?.uid;
		return this.updateProfile(uid, {
			mainPictureName: imagekey,
		});
	}

	async saveEditImage(uid, key, path) {
		try {
			const result = await this.addImage(path, false, key);
			return result.success;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	async addImageOld(uid, file, isExternalLink, isProfileImage = false) {
		const rand = randomstring.generate(12);
		const imageName = `profilePic_${uid}_${rand}_${Date.now()}`;
		const url = await UploadImagesService.upload(uid, imageName, file);

		//const { url, size } = await StorageService.saveImage(uid, imageName, file);
		const image = {
			url,
			isExternalLink,
			name: imageName,
			status: PendingStatus.Wait,
			file_size: 10,
		};
		if (isProfileImage) {
			await firebase.database().ref(this.BASE_REF).child(uid).child("profileImage").set(image);
		} else {
			await firebase
				.database()
				.ref(this.BASE_REF)
				.child(uid)
				.child("pictures")
				.child(image.name)
				.set(image);
		}

		return image;
	}

	async addImage(file, isProfileImage = false, imageName = null, code = null) {
		try {
			const returnData = await UploadImagesService.addImage(file, isProfileImage, imageName, code);
			return returnData;
		} catch (error) {
			console.error(error);
		}
	}

	async uploadResumeToStorage(uid, file) {
		const rand = randomstring.generate(12);
		const fileName = `resume_${uid}_${rand}_${Date.now()}`;
		const savedFile = await StorageService.saveResume(uid, fileName, file);
		return {
			fileName,
			url: savedFile.url,
			size: savedFile.size,
		};
	}

	async uploadFileToStorage(uid, file, fieldName) {
		const rand = randomstring.generate(12);
		const fileName = `resume_${uid}_${rand}_${Date.now()}`;
		const savedFile = await StorageService.saveResume(uid, fileName, file, fieldName);
		return {
			fileName,
			url: savedFile.url,
			size: savedFile.size,
		};
	}

	async addResume(uid, file, isExternalLink) {
		const { url, size, fileName } = await this.uploadResumeToStorage(uid, file);
		const fileResume = {
			url,
			isExternalLink,
			name: fileName,
			status: PendingStatus.Wait,
			file_size: size,
		};

		await firebase.database().ref(this.BASE_REF).child(uid).child("resume").set(fileResume);
		return fileResume;
	}

	async addFile(uid, file, isExternalLink, fieldName) {
		const { url, size, fileName } = await this.uploadFileToStorage(uid, file, fieldName);
		const fileData = {
			url,
			isExternalLink,
			name: fileName,
			status: PendingStatus.Wait,
			file_size: size,
		};

		await firebase.database().ref(this.BASE_REF).child(uid).child(fieldName).set(fileData);
		return fileData;
	}

	async deleteFile(userAuth, name, fieldName) {
		await StorageService.deleteResume(userAuth, name);
		return firebase.database().ref(this.BASE_REF).child(userAuth).child(fieldName).set(null);
	}

	async isUserProfileExists(uid) {
		const val = await firebase.database().ref(this.BASE_REF).child(uid).once("value");
		return val.exists();
	}
	async updateCompleteRegistration(uid) {
		await firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.update({ hasCompleteRegistration: true });
	}
}

export default new ProfileService();
