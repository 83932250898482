import firebase from "firebase/app";
import "firebase/database";

const FIREBASE_HTTP_FUNCTION_URL = process.env.REACT_APP_FIREBASE_HTTP_FUNCTION_URL;

class HTTPFunctionService {
	APIS = { SUBSCRIPTION_PAY: "subscription/pay", MIGRATION: "migrate" };

	async GetUrl(url) {
		try {
			let response = await fetch(url);
			let responseJson = await response.text();
			return responseJson;
		} catch (err) {
			console.log("err GetUrl", err);
			throw err;
		}
	}

	async getCardcomPageLink(planId) {
		try {
			let response = await fetch(
				`${FIREBASE_HTTP_FUNCTION_URL}/${this.APIS.SUBSCRIPTION_PAY}?planId=${planId}`,
				{
					headers: {
						Authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}`,
					},
					referrerPolicy: "no-referrer-when-downgrade",
				}
			);
			let responseJson = await response.json();
			return responseJson;
		} catch (err) {
			return Promise.reject();
		}
	}

	async migrateUser() {
		try {
			let response = await fetch(`${FIREBASE_HTTP_FUNCTION_URL}/${this.APIS.MIGRATION}`, {
				headers: {
					Authorization: `Bearer ${await firebase.auth().currentUser?.getIdToken()}`,
				},
				referrerPolicy: "no-referrer-when-downgrade",
			});
			let responseJson = await response.json();
			return responseJson;
		} catch (err) {
			return Promise.reject();
		}
	}
}

export default new HTTPFunctionService();
