import { createSelector } from "reselect";

import { getLoggedInUserUID } from "../auth/selectors";
import { getUserPublicProfile } from "../publicProfiles/selectors";
import { canReadMessages, getReadMessagesLimits } from "../subscription/selectors";

const getChatMessages = (state) => state.chatMessages.data;

export const getMessages = (chatID, userID) =>
	createSelector(
		getChatMessages,
		getLoggedInUserUID,
		canReadMessages,
		getReadMessagesLimits,
		getUserPublicProfile(userID),

		(chatMessages, uid, canReadMessagesSubscription, readMessagesLimits, user) =>
			chatMessages[chatID]?.status !== "FAILED" &&
			chatMessages[chatID]?.messages.map((message) => {
				if (
					message.uid === uid ||
					canReadMessagesSubscription ||
					readMessagesLimits?.[user?.subscription] === true ||
					message.isTemplate ||
					message.system ||
					message.isRecommendation
				) {
					return message;
				} else if (message.isMassletter) {
					return {
						...message,
						generalBlur: true,
					};
				} else {
					return {
						...message,
						privateBlur: true,
					};
				}
			})
	);

export const getLastMessageId = (chatID) =>
	createSelector(getChatMessages, (chatMessages) => {
		const lastIndex = chatMessages[chatID]?.messages.length - 1;
		return chatMessages[chatID]?.messages?.[lastIndex]?.id;
	});
