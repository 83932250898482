import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";
import httpsCallable from "./httpsCallable";

class TagsService {
	BASE_REF = "tags";

	async fetchTags() {
		return (await firebase.database().ref(this.BASE_REF).once("value")).val();
	}

	async getTags() {
		const tags = await this.fetchTags();
		return Object.values(tags);
	}
	async getTagsCategory() {
		const tags = await firebase.database().ref("tags_categories").get();
		return tags.val();
	}

	async addTagsSuggestions(name) {
		const result = await httpsCallable.post("addTagsSuggestions", {
			extraData: { name },
		});
		return result;
	}

	listenTagAdded() {
		return new Observable((subscriber) => {
			console.log("listen");
			const ref = firebase.database().ref(this.BASE_REF).limitToLast(1);
			let first = true;
			const callbackFn = ref.on(
				"child_added",
				(snapshot) => {
					if (first) {
						first = false;
					} else subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}
}

export default new TagsService();
