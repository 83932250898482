import { Box } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";
import { useState } from "react";

export default function Experience({ user = null }) {
	const [infoValues, setInfoValues] = useState({});

	useEffect(() => {
		if (!user) return;

		const userInfoObj = {};
		if (user?.education) userInfoObj.education = user.education;
		if (user?.skills) userInfoObj.skills = user.skills;
		if (user?.experience) userInfoObj.experience = user.experience;

		setInfoValues(userInfoObj);
	}, []);

	if (!user) return <></>;
	return (
		<>
			{!!Object.entries(infoValues || {})?.length && (
				<Box className="experienceWrapper">
					{Object.entries(infoValues || {}).map(([key, value]) => (
						<Box key={key} className="experienceItem">
							<span className="expKey">{i18next.t(`talent.${key}`)}:</span>
							<span className="expVal">{value}</span>
						</Box>
					))}
				</Box>
			)}
		</>
	);
}
