import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import PhoneVerificationQuestion from "components/login/Questions/PhoneVerificationQuestion";
import { verifyPhone, resendCode, resetLoginWithPhone } from "redux/auth/actions";
import {
	getLoginWithPhoneStatus,
	getResendCodeStatus,
	getLoginPhone,
	isUserLoggedIn,
	getIsAuthChecked,
} from "redux/auth/selectors";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import RESEND_CODE_STATUS from "consts/resendCodeStatus";
import phone2Image from "assets/images/birdsLogin.svg";
import TermsLink from "components/login/TermsLink";
import UsersTypes from "consts/UsersTypes";
import HeaderEnterPage from "components/enterPageApp/HeaderEnterPage";

export default ({ setLoginStep = () => {}, goToTalentRegister = false }) => {
	const navigator = useNavigator();
	const dispatch = useDispatch();
	const loginPhone = useSelector(getLoginPhone);
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const resendCodeStatus = useSelector(getResendCodeStatus);
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const [isCodeResent, setIsCodeResent] = useState();
	const [didntGetCode, setDidntGetCode] = useState(false);
	const [countToSendCode, setCountToSendCode] = useState(60);

	let userRegisterType = useSelector((state) => state.dialogs.dialogs)[0];
	userRegisterType = userRegisterType?.registerType
		? userRegisterType.registerType
		: goToTalentRegister
		? UsersTypes.Talent
		: null;

	const handleSubmit = (value) => {
		dispatch(verifyPhone(value, userRegisterType));
	};
	const handleBack = () => {
		dispatch(resetLoginWithPhone());
	};

	const handleResendCode = () => {
		dispatch(resendCode(loginPhone));
	};

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.LOGIN_SUCCESSFULLY:
				// navigator.navigateToRegisterPage();
				break;
			case LOGIN_WITH_PHONE_STATUS.INIT:
				setLoginStep(1);
				break;
			case LOGIN_WITH_PHONE_STATUS.VERIFICATION_FAILED:
				setHasError(true);
				setErrMsg(i18next.t("Phone Error.Wrong Code"));
				break;
			default:
				break;
		}
	}, [loginStatus]);

	useEffect(() => {
		if (!loginPhone) {
			setLoginStep(1);
		}
	}, [loginPhone]);

	useEffect(() => {
		switch (resendCodeStatus) {
			case RESEND_CODE_STATUS.RESEND_CODE_PENDING:
				break;
			case RESEND_CODE_STATUS.RESEND_CODE_FAILED:
				setHasError(true);
				setErrMsg(i18next.t("Login.Resend code failed"));
				break;
			case RESEND_CODE_STATUS.RESEND_CODE_SUCCESSFULLY:
				setIsCodeResent(true);
			default:
				break;
		}
	}, [resendCodeStatus]);

	return (
		<div className="loginPagesWrapper">
			<div className="loginPopup">
				<div className="loginHolder verifyHolder">
					<HeaderEnterPage />

					<PhoneVerificationQuestion
						title={i18next.t("Login.sent you code sms")}
						subTitle={i18next.t("Login.Enter the code")}
						hasError={hasError}
						setHasError={setHasError}
						errMsg={errMsg}
						onSubmit={handleSubmit}
						onBack={handleBack}
						onResendCode={handleResendCode}
						setDidntGetCode={setDidntGetCode}
						setCountToSendCode={setCountToSendCode}
					/>
					<span style={{ marginTop: "10px", color: "#5A5A5A", fontWeight: 600 }}>
						{i18next.t("Login.didnt receive a code")}
					</span>
					{didntGetCode ? (
						<a
							onClick={handleResendCode}
							style={{
								marginTop: "5px",
								color: "#5A5A5A",
								fontWeight: 600,
								borderBottom: "1px solid #5A5A5A",
							}}>
							{i18next.t("Login.sendMeCode")}
						</a>
					) : (
						<span style={{ marginTop: "5px", color: "#5A5A5A", fontWeight: 600 }}>
							{countToSendCode} {i18next.t("Login.toGetCode")}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};
