import { createSelector } from "reselect";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

const getViews = (state) => state.views;

export const getPitId = createSelector(getViews, (views) => views.pitId);

export const getSearchAfter = createSelector(getViews, (views) => views.searchAfter);

export const getViewsUsers = createSelector(
	getViews,
	getBlocks,
	(views, blocks) =>
		Object.keys(views.users)
			.sort(
				(uidA, uidB) =>
					views.users[uidB].viewed_me_timeStamp - views.users[uidA].viewed_me_timeStamp
			)
			.map((uid) => views.users[uid])
			.filter((user) => !blocks[user.uid])
);

export const getIsLoadingMore = createSelector(
	getViews,
	(views) => views.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getViews, (views) => views.hasMoreResults);

export const getViewsStatus = createSelector(getViews, (views) => views.apiStatus);