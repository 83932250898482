import { createSelector } from "reselect";

export const getChatList = (state) => state.chats.data;
export const getChatSelected = (state) => state.chats.selectedChat;
export const getChatListSelected = (state) => state.chats.selectedListForAction;

export const getChatListSelectedCount = createSelector(getChatListSelected, (chatSelected) => {
	return Object.keys(chatSelected || {}).length;
});
/* 
export const getChatReceivedSortByDate = createSelector(
	getChatReceived,
	getPublicProfiles,
	getBlocksUIDs,
	(chatReceived, publicProfiles, blockedUsers) => {
		if (chatReceived.status === ApiRequestStatus.INIT) {
			return [];
		}
		const chatReceiveArray = Object.entries(chatReceived).map(([key, value]) => ({
			...value,
			uid: key,
		}));
		const chatFilteredDeleted = chatReceiveArray.filter((chat) => {
			const res =
				!chat.deleted && publicProfiles[chat.uid]?.approved && isNil(blockedUsers[chat.uid]);

			return res;
		});
		return orderBy(chatFilteredDeleted, "timeStamp", "desc");
	}
);

export const getChatReceivedUsers = createSelector(
	getChatReceivedSortByDate,
	getChatMeta,
	(chatReceivedSortByDate, chatMeta) => {
		const res = chatReceivedSortByDate.map((chat) => ({
			...chat,
			unread: chatMeta[chat.chatID]?.newMessages,
		}));
		return res;
	}
);

export const getMatchesChatUsers = createSelector(getChatReceivedUsers, (chatUsers) => {
	return chatUsers.filter((chatUser) => chatUser);
});

export const getMatchesUnreadMessages = createSelector(getMatchesChatUsers, (matchesChatUsers) => {
	const res = matchesChatUsers
		.map((matchesChatUser) => matchesChatUser.unread || 0)
		.reduce((a, b) => a + b, 0);
	return res;
});

export const getAllChatUsers = createSelector(
	getChatReceivedUsers,
	getManualyPromotedUsers,
	(chatUsers, manualyPromotedUsers) => {
		if (!manualyPromotedUsers || !manualyPromotedUsers.length) {
			return chatUsers;
		}
		const res = orderBy(
			concat(
				chatUsers,
				manualyPromotedUsers.map((user) => ({ ...user, promotion: true }))
			),
			"timeStamp",
			"desc"
		);
		return res;
	}
);

export const getChatReceivedUser = (userUid) =>
	createSelector(getChatReceived, (chatReceived) => chatReceived[userUid]);

export const getChat = (userUid) =>
	createSelector(
		getChatReceivedUser(userUid),
		getMatchUser(userUid),
		getChatSentUser(userUid),
		getChatReceivedFilteredUser(userUid),
		(chatReceivedUser, matchUser, chatSentUser, chatReceivedFilteredUser) =>
			chatReceivedUser?.chatID ||
			matchUser?.chatID ||
			chatSentUser?.chatID ||
			chatReceivedFilteredUser?.chatID
	);

export const getIsUserHasChatButFrozen = (userUid) =>
	createSelector(
		getChat(userUid),
		getPublicProfiles,
		(chatId, publicProfiles) =>
			chatId && publicProfiles[userUid] && !publicProfiles[userUid]?.approved
	);

export const getIsNewChat = (userUid) =>
	createSelector(
		getChatReceivedUser(userUid),
		getChatSentUser(userUid),
		getChatReceivedFilteredUser(userUid),
		(chatReceivedUser, chatSent, chatReceivedFilteredUser) =>
			isNil(chatReceivedUser?.chatID) &&
			isNil(chatSent?.chatID) &&
			isNil(chatReceivedFilteredUser?.chatID)
	);

export const getIsChatReceivedLoaded = createSelector(
	getChatReceived,
	(chatReceived) => chatReceived.status !== ApiRequestStatus.INIT
);
 */
