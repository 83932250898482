import "assets/scss/profile/profileContent/profileContent.scss";
import { Box } from "@mui/material";
import i18next from "i18next";
import Loader from "components/Loader";
import defaultProfile from "assets/images/svgs/darkAvatar.svg";
import { TransformImg } from "helpers/cloudinary";
import locationIcon from "assets/images/locationIcon.png";
import { getTags } from "redux/tags/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getDialogs, openDialog } from "redux/dialog/selectors";
import { useEffect, useState } from "react";
import useUser from "hooks/useUser";
import LightBox from "components/lightBox/LightBox";
import Button from "components/ui/Button";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import dialogsTypes from "consts/dialogsTypes";
import heartIcon from "assets/images/svgs/save-active.svg";
import heartGrayIcon from "assets/images/svgs/save-default.svg";
import reccomendIcon from "assets/images/svgs/reccomendations-icon.svg";

export default function ProfileContent({ user, viewTalentPage = false, favoriteList = [] }) {
	const dispatch = useDispatch();
	const { getProfileImage, isLoggedIn } = useUser();
	const profileImage = TransformImg(getProfileImage(user), 200, 200);
	const { dialogs } = useSelector(getDialogs);
	// const tags = useSelector(getTags);
	// const tagCategory = tags.find((tag) => tag.key == user?.mainTag?.key);

	const [showPhone, setShowPhone] = useState(false);
	const [images, setImages] = useState([]);
	const [selected, setSelected] = useState(false);
	const [selectedAnimation, setSelectedAnimation] = useState(false);
	const [openLightBox, setOpenLightBox] = useState(false);

	const handleSelected = async (e) => {
		e.stopPropagation();
		if (!isLoggedIn) return dispatch(openDialog({ dialog: dialogsTypes.Login }));
		setSelected(!selected);
		setSelectedAnimation(!selected);
		if (selected) {
			await functionsService.removeFavorite(user.uid);
		} else {
			await functionsService.addFavorite(user.uid);
			await functionsService.addTalentAction(user.uid, talentAction.clickAddFavorite);
		}
	};

	const handleImgClick = () => {
		setOpenLightBox(true);
	};

	const generatePhone = (pNumber) => {
		if (!pNumber) return "";
		if (typeof pNumber == "number") pNumber = `${pNumber}`;

		if (pNumber.includes("972")) {
			pNumber = `${pNumber.replace("972", "0")}`;
		}
		if (pNumber.includes("+")) {
			pNumber = `${pNumber.replace("+", "")}`;
		}
		const start = pNumber.slice(0, 3);
		const end = pNumber.slice(3);
		return `${start}-${end}`;
	};

	useEffect(() => {
		if (dialogs[0]?.dialog == "ViewTalent") {
			setShowPhone(true);
		} else {
			setShowPhone(true);
		}
		if (user?.profileImage) {
			setImages([user.profileImage?.url]);
		}
	}, []);

	useEffect(() => {
		setSelected(favoriteList.includes(user.uid));
	}, [favoriteList]);

	return (
		<>
			{user == undefined ? (
				<Loader />
			) : (
				<Box className="wrapProfileContent">
					<Box
						className="wrapImgAndName"
						sx={{ fontSize: "18px", fontWeight: "500", minWidth: "80px" }}>
						<Box
							className={viewTalentPage ? "wrapImg viewTalentImg" : "wrapImg"}
							onClick={viewTalentPage ? handleImgClick : () => {}}>
							{<img src={profileImage} />}
						</Box>
					</Box>
					<Box className="flexColumn">
						<span className="wrapUserName">{user.name}</span>
						{user?.title && <span className="wrapUserTitle">{user?.title}</span>}
						{!!user?.countRecommendations && (
							<span className="wrapRecommend">
								<img src={reccomendIcon} />
								{`${user?.countRecommendations || 0} ${i18next.t("talent.recommendations")}`}
							</span>
						)}
					</Box>

					<Box
						className={`heartSelected ${!!selectedAnimation ? "heartSelectedAnimation" : ""}`}
						onClick={handleSelected}>
						{selected ? <img src={heartIcon} /> : <img src={heartGrayIcon} />}
					</Box>
					{openLightBox && (
						<LightBox
							images={images}
							imgIndex={1}
							isOpen={openLightBox}
							setUpdateOpenLightBox={setOpenLightBox}
						/>
					)}
				</Box>
			)}
		</>
	);
}
