import produce from "immer";
import tags from "services/tags";

import * as types from "./actionTypes";

const initialState = {
	contactsMatching: {},
	myContactFriends: [],
};

export default produce((state, action) => {
	switch (action.type) {
		case types.INIT_CONTACTS_SUCCESSFULLY:
			return { ...state, contactsMatching: action.payload };

		case types.ADD_MY_CONTACTS:
			return { ...state, myContactFriends: action.payload };
	}
}, initialState);
