import { Box, Switch } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { phoneRegExp } from "helpers/regex";
import useUser from "hooks/useUser";
import ErrorLabel from "components/ui/ErrorLabel";
import TextComponent from "components/ui/TextComponent";
import RegisterBtnWrap from "./RegisterBtnWrap";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import LoginHeader from "components/login/LoginHeader";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import useNavigator from "hooks/useNavigator";
import { ScrollToFieldError } from "helpers/FormikErrors";
import { getIsCompleteRegister } from "redux/profile/selectors";

export default ({ nextStep, user, type = "register", goBack, currentStep = 1, pathType }) => {
	const dispatch = useDispatch();
	const btnText = type === "register" ? i18next.t("register.next") : i18next.t("register.save");
	const [inProgress, setInProgress] = useState(false);
	const navigate = useNavigator();
	const isCompleteRegister = useSelector(getIsCompleteRegister);

	const { registerStep } = useUser();
	const initialValues = {
		whatsapp: user?.whatsapp ?? "",
		phoneToConnect: user?.phoneToConnect ?? "",
		showWhatsapp: user?.showWhatsapp ?? true,
		showPhone: user?.showPhone ?? true,
		email: user?.email ?? "",
	};
	const [showWhatsappChecked, setShowWhatsappChecked] = useState(initialValues.showWhatsapp);
	const [showPhoneChecked, setShowPhoneChecked] = useState(initialValues.showPhone);

	const validationSchema = Yup.object().shape({
		whatsapp: Yup.string().matches(phoneRegExp, i18next.t("validation.phone")),
		phoneToConnect: Yup.string().matches(phoneRegExp, i18next.t("validation.phone")),
		showWhatsapp: Yup.boolean(),
		showPhone: Yup.boolean(),
		email: Yup.string().email(i18next.t("validation.email")),
	});

	const handleShowWhatsappChange = (event) => {
		setShowWhatsappChecked(event.target.checked);
		formik.setFieldValue("showWhatsapp", event.target.checked);
	};

	const handleShowPhoneChange = (event) => {
		setShowPhoneChecked(event.target.checked);
		formik.setFieldValue("showPhone", event.target.checked);
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setInProgress(true);
			const returnData = await registerStep(values, 5, type);

			if (returnData?.data?.success) {
				if (type === "register") {
					window.scrollTo(0, 0);
					navigate.navigateToPath("/thanksSuppliers");
				} else {
					dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
				}
			} else {
				dispatch(openNotification(i18next.t(returnData?.data?.msg)));
			}
			setInProgress(false);
		},
	});

	useEffect(() => {
		if (!isCompleteRegister && user?.phone) {
			let phone = user.phone;
			if (user?.phone.includes("+972")) phone = user.phone.replace("+972", "0");

			if (!user?.whatsapp) formik.setFieldValue("whatsapp", phone);
			if (!user?.phoneToConnect) formik.setFieldValue("phoneToConnect", phone);
		}
	}, []);

	return (
		<>
			<LoginHeader step={4} textHeader={i18next.t("register.howWillCustomersContactMe")} />

			<form
				className="reg-step1 reg-step4"
				id="register-step-22"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "10px" }}>
				<Box sx={{ position: "relative" }}>
					<Box className="reg-title">{i18next.t("register.phoneToConnect")}</Box>

					<TextComponent
						placeholder="המספר שיוצג ללקוחות"
						id="phoneToConnect"
						{...formik.getFieldProps("phoneToConnect")}
						onBlur={() => {
							if (formik.values?.phoneToConnect && !formik.values?.whatsapp) {
								formik.setFieldValue("whatsapp", formik.values?.phoneToConnect);
							}
						}}
						sx={{ margin: "10px 0 0" }}
					/>
					<ErrorLabel>{formik.touched.phoneToConnect && formik.errors.phoneToConnect}</ErrorLabel>
					<Box className="center wrapShowCustomer">
						<span
							style={{
								color: "#5A5A5A",
								fontSize: "18px",
								fontWeight: "400",
							}}>
							{i18next.t("register.showClient")}
						</span>
						<Switch
							checked={showPhoneChecked}
							onChange={handleShowPhoneChange}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</Box>
				</Box>

				<Box sx={{ position: "relative" }}>
					<Box className="reg-title">{i18next.t("register.applicationToWhatsapp")}</Box>

					<TextComponent
						placeholder="המספר שאליו הלקוחות ישלחו הודעות"
						id="whatsapp"
						{...formik.getFieldProps("whatsapp")}
						sx={{ margin: "10px 0 0" }}
					/>
					<ErrorLabel>{formik.touched.whatsapp && formik.errors.whatsapp}</ErrorLabel>
					<Box className="center wrapShowCustomer" sx={{}}>
						<span
							style={{
								color: "#5A5A5A",
								fontSize: "18px",
								fontWeight: "400",
							}}>
							{i18next.t("register.showClient")}
						</span>
						<Switch
							checked={showWhatsappChecked}
							onChange={handleShowWhatsappChange}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</Box>
				</Box>

				<Box sx={{ margin: "10px 0 0" }}>
					<Box className="reg-title">{i18next.t("register.myEmail")}</Box>
					<Box className="skills">{i18next.t("register.myEmailContent")}</Box>

					<TextComponent
						sx={{ margin: "10px 0 0" }}
						id="email"
						{...formik.getFieldProps("email")}
					/>
					<ErrorLabel>{formik.touched.email && formik.errors.email}</ErrorLabel>
				</Box>

				<Box sx={{ marginBottom: "80px" }}>
					<RegisterBtnWrap
						type={type}
						inProgress={inProgress}
						btnText={btnText}
						currentStep={currentStep}
						goBack={goBack}></RegisterBtnWrap>
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
		</>
	);
};
