import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import { useEffect } from "react";
import Settings from "./settings/Settings";
import Favorite from "components/favorite/Favorite";
import UserRecomendationPage from "pages/UserRecomendationPage";
import Recommendation from "./Recommendation";
import MyBusinessCard from "./settings/MyBusinessCard";
import EditAccountPage from "./EditAccountPage";
import MyImages from "./settings/MyImages";
import Register from "pages/Register";
import UsersTypes from "consts/UsersTypes";
import StatisticsPage from "pages/StatisticsPage";

export default ({ innerPage }) => {
	const { userProfile } = useUser();

	const innerComponent = () => {
		switch (innerPage) {
			case "settings":
				return <Settings />;
			case "favorites":
				return <Favorite />;
			case "myRecomendationOnSuppliers":
				return <UserRecomendationPage idFromOut={userProfile?.uid} withoutHeader={true} />;
			case "recommendations":
				return <Recommendation isFromWeb={true} />;
			case "myBusinessCard":
				return <MyBusinessCard />;
			case "editAccount":
				return userProfile?.type === UsersTypes.Talent && userProfile.registerStep < 6 ? (
					<Register />
				) : (
					<EditAccountPage />
				);

			case "myImages":
				return <MyImages />;
			case "statistics":
				return <StatisticsPage />;
			default:
				return <></>;
		}
	};

	return <>{innerComponent()}</>;
};
