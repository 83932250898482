import { Box, CircularProgress } from "@mui/material";
import UploadImage from "components/login/UploadImage";
import Button from "components/ui/Button";
import ErrorLabel from "components/ui/ErrorLabel";
import TextComponent from "components/ui/TextComponent";
import { useFormik } from "formik";
import { ScrollToFieldError } from "helpers/FormikErrors";
import useNavigator from "hooks/useNavigator";
import useProfileImages from "hooks/useProfileImages";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import * as Yup from "yup";

export default function RegisterClient({ user = {}, type = "" }) {
	const dispatch = useDispatch();
	const navigator = useNavigator();
	const { registerStep } = useUser();
	const { saveNewImage } = useProfileImages();
	const [isLoading, setIsLoading] = useState(false);
	const [isImageLoading, setIsImageLoading] = useState(false);
	const [imageValue, setImageValue] = useState();
	const [isImageValid, setIsImageValid] = useState();

	const handleOnChangeImage = (value) => {
		setImageValue(value);
	};

	const handleOnValidityChange = (_isValid) => {
		setIsImageValid(_isValid);
	};

	const initialValues = {
		name: user ? user.name : "",
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.max(25, i18next.t("validation.longName"))
			.required(i18next.t("validation.required")),
	});

	const submitForm = async (values) => {
		setIsLoading(true);
		if (imageValue && !isImageValid) {
			alert("the image is invalid");
			setIsLoading(false);
		} else {
			if (imageValue) {
				await saveNewImage(imageValue, true);
			}
			await registerStep(values, 1);

			if (type == "edit") {
				dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
			} else {
				navigator.navigateToHomePage();
			}
			setIsLoading(false);
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			await submitForm(values);
		},
	});

	// useEffect(async () => {
	// 	if (!!imageValue) {
	// 		setIsImageLoading(true);
	// 		await saveNewImage(imageValue, true);
	// 		setIsImageLoading(false);
	// 	}
	// }, [imageValue]);

	return (
		<>
			<form
				className="reg-step1"
				id="register-client"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "10px" }}>
				<Box className="center reg-header">{i18next.t("register.headerClient")}</Box>

				<Box>
					<Box className="reg-title">
						{`${i18next.t("register.fullName")}`}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</Box>
					<TextComponent
						id="name"
						sx={{ width: "100%" }}
						value={"name"}
						{...formik.getFieldProps("name")}
					/>
					<ErrorLabel>{formik.touched.name && formik.errors.name}</ErrorLabel>
				</Box>

				{type !== "edit" && (
					<>
						<Box className="center title" sx={{ margin: "20px 0" }}>
							{i18next.t("register.addProfilePic")}
						</Box>

						{!isImageLoading ? (
							<UploadImage
								onChange={handleOnChangeImage}
								onValidityChange={handleOnValidityChange}
								profilePic={true}
								value={imageValue}
							/>
						) : (
							<Box className="center">
								<CircularProgress size={40} sx={{ color: "#7496bf" }} />
							</Box>
						)}
					</>
				)}
				<Box className="center" style={{ margin: "50px 0" }}>
					{isLoading ? (
						<Box className="center">
							<CircularProgress size={40} sx={{ color: "#7496bf" }} />
						</Box>
					) : (
						<Button
							type="submit"
							fontWeight={400}
							variant=""
							sx={{
								borderRadius: "20px",
								padding: "0px 45px",
								background: "#386BA8 !important",
							}}>
							{i18next.t("register.end")}
						</Button>
					)}
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
		</>
	);
}
