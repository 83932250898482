import Button from "components/ui/Button";
import Title from "../Title";
import SiteButton from "components/ui/SiteButton";

export default ({
	children,
	isValid,
	title,
	subTitle,
	onSubmit,
	variant = "outlined",
	showSubmitButton = true,
	btnTxt = "Continue",
}) => {
	return (
		<>
			<div className="innerLoginWrapper">
				<Title {...{ title, subTitle }} />
				{children}
				{showSubmitButton && (
					<Button
						variant={variant}
						disabled={!isValid}
						onClick={onSubmit}
						sx={{
							color: "#fff",
							background: !isValid
								? "linear-gradient(90deg, #5956c9cc -0.81%, #386ba8cc 52.96%, #168187cc 102.44%)"
								: "linear-gradient(90deg, #5956c9 -0.81%, #386ba8 52.96%, #168187 102.44%)",
						}}>
						{btnTxt}
					</Button>
				)}
			</div>
		</>
	);
};
