import firebase from "firebase/app";
import "firebase/database";
import { Observable } from "rxjs";

class UserGeneralService {
	BASE_REF = "users_general";

	fetchGeneral(uid) {
		return new Observable((subscriber) => {
			const ref = firebase.database().ref(this.BASE_REF).child(uid).child("public");

			const callbackFn = ref.on(
				"value",
				(snapshot) => subscriber.next(snapshot.val()),
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	updateGeneral(uid, details) {
		return firebase.database().ref(this.BASE_REF).child(uid).child("public").update(details);
	}

	updateCommunicationSettings(uid, key, newVal) {
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child("public/settings/communication")
			.child(key)
			.set(newVal);
	}

	updateNotificationsSettings(uid, key, newVal) {
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child("public/settings/notifications")
			.child(key)
			.set(newVal);
	}

	updateMessagesFilter(value) {
		const uid = firebase.auth().currentUser?.uid;
		return firebase
			.database()
			.ref(this.BASE_REF)
			.child(uid)
			.child("public/messagesFilter")
			.set(value);
	}

	clearPendingFields(fields) {
		const uid = firebase.auth().currentUser?.uid;
		return Promise.all(
			fields.map((field) =>
				firebase.database().ref(this.BASE_REF).child(uid).child("pendings").child(field).set(null)
			)
		);
	}
}

export default new UserGeneralService();
