import { Box } from "@mui/material";
import i18next from "i18next";

export default function ContactInfo() {
	return (
		<Box className="boxWrapper contactInfo">
			<h4>{i18next.t("landingPage.contactInfo")}</h4>
			<Box className="contantContent">
				<Box className="">
					<span>{"מייל: "}</span>
					<a href={`mailto:info@sheshet.co.il`} style={{ color: "#fff", textDecoration: "none" }}>
						info@sheshet.co.il
					</a>
				</Box>
				<Box className="separator" />
				{/* <Box className="">{i18next.t("text")}</Box>
				<Box className="separator" />
				<Box className="">{i18next.t("text")}</Box> */}
			</Box>
		</Box>
	);
}
