import { Box, TextareaAutosize } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import useUser from "hooks/useUser";
import TextComponent from "components/ui/TextComponent";
import ErrorLabel from "components/ui/ErrorLabel";
import UploadFile from "components/ui/UploadFile";
import useNavigator from "hooks/useNavigator";
import i18next from "i18next";
import RegisterBtnWrap from "./RegisterBtnWrap";
import { openNotification } from "redux/notification/actions";
import { useDispatch, useSelector } from "react-redux";
import { youtubeRegExp } from "helpers/regex";
import LoginHeader from "components/login/LoginHeader";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import MyImages from "components/profile/settings/MyImages";
import { ScrollToFieldError } from "helpers/FormikErrors";
import { getTags } from "redux/tags/selectors";
import profileExtraFieldsService from "../../services/profileExtraFieldsService";

export default ({ nextStep, user, type = "register", goBack, currentStep = 1, pathType }) => {
	const baseValidationSchemaObj = {};
	const dispatch = useDispatch();
	const btnText = type === "register" ? i18next.t("register.next") : i18next.t("register.save");
	const [fieldFile, setFieldFile] = useState({});
	const [extraFields, setExtraFields] = useState(null);
	const [inProgress, setInProgress] = useState(false);
	const { registerStep, userProfile } = useUser();
	const navigate = useNavigator();
	const tags = useSelector(getTags);
	const { uploadFileField, deleteFileField } = useUser();
	const [initialValues, setInitialValues] = useState({});
	const [validationSchema, setValidationSchema] = useState(
		Yup.object().shape(baseValidationSchemaObj)
	);
	//find the tagCategory & check if it eventsHall (return true\false\undefined)
	const isEventsHallUser = !(user?.talentType === "supplier");

	const getExtraFields = async () => {
		const extraFieldsObj = {};
		const fields = await profileExtraFieldsService.fetchExtraFields();
		if (!!user && !!user?.tags) {
			user.tags.map((tag) => {
				Object.values(fields).map((field) => {
					if (field.tags) {
						if (field.tags[tag.key]) {
							extraFieldsObj[field.key] = field;
						}
					}
				});
			});
			setExtraFields(extraFieldsObj);
		}
	};

	const validYouTube = (youtubeLink) => {
		let newYoutubeLink = youtubeLink;
		const splitUrlLink = youtubeLink.split("/");
		if (splitUrlLink.includes("youtu.be")) {
			const linkId = splitUrlLink.slice(-1)[0];
			newYoutubeLink = `https://www.youtube.com/watch?v=${linkId}`;
		}
		return newYoutubeLink;
	};

	const updateFieldFile = (fileData, fieldName) => {
		setFieldFile((prev) => ({ ...prev, [fieldName]: fileData }));
	};

	const deleteFileHandler = async (fieldName) => {
		if (user && user[fieldName] && user[fieldName]?.url) {
			await deleteFileField(user.uid, user[fieldName].name, fieldName);
		}
		setFieldFile((prev) => {
			return { ...prev, [fieldName]: null };
		});
	};

	const validationSchema1 = Yup.object().shape({
		// pricePerHour: Yup.string()
		// 	.max(50, i18next.t("validation.longName"))
		// 	.required(i18next.t("validation.required")),
		youTubeUrlVideo: Yup.string().matches(youtubeRegExp, i18next.t("validation.youtube")),
	});
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema1 || validationSchema,
		onSubmit: async (values) => {
			setInProgress(true);
			if (values?.youTubeUrlVideo) {
				const youtube = validYouTube(values.youTubeUrlVideo);
				values.youTubeUrlVideo = youtube;
			}
			if (!_.isEmpty(fieldFile)) {
				Promise.all(
					Object.entries(fieldFile).map(async ([key, value]) => {
						await uploadFileField(value, key);
					})
				);
			}

			await registerStep(values, 2, type);
			if (type === "register") {
				window.scrollTo(0, 0);
				nextStep();
			} else {
				dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
			}
			setInProgress(false);
		},
	});

	useEffect(() => {
		getExtraFields();
	}, []);

	useEffect(() => {
		formik.setValues({ ...initialValues });
	}, [initialValues]);

	useEffect(() => {
		const initialValuesObj = {
			// pricePerHour: user.pricePerHour ?? "",
			education: user.education ?? "",
			skills: user.skills ?? "",
			experience: user.experience ?? "",
			aboutMe: user.aboutMe ?? "",
			youTubeUrlVideo: user.youTubeUrlVideo ?? "",

			// resume: user.resume ?? "",
			// maximumNumberOfGuests: user.maximumNumberOfGuests ?? "",
			// mealPrice: user.mealPrice ?? "",
			// kosherType: user.kosherType ?? "",
		};

		if (!_.isEmpty(extraFields)) {
			const extraInitialValues = Object.keys(extraFields).reduce((acc, key) => {
				acc[extraFields[key].name] = user[extraFields[key].name] ?? ""; // You can assign any value you want here
				return acc;
			}, {});
			setInitialValues({ ...initialValuesObj, ...extraInitialValues });
		} else {
			setInitialValues({ ...initialValuesObj });
		}
		if (!_.isEmpty(extraFields)) {
			const extraValidationSchemaObj = Object.keys(extraFields).reduce((acc, key) => {
				if (extraFields[key].required)
					acc[extraFields[key].name] = Yup.string().required(i18next.t("validation.required")); // You can assign any value you want here
				return acc;
			}, {});
			const vs = { ...baseValidationSchemaObj, ...extraValidationSchemaObj };

			setValidationSchema(Yup.object().shape(vs));
		}
	}, [extraFields]);

	return (
		<>
			<LoginHeader step={2} textHeader={i18next.t("register.moreOnTheSupplier")} />

			{!_.isEmpty(initialValues) && (
				<form
					className="reg-step1 reg-step3"
					id="register-step-22"
					onSubmit={formik.handleSubmit}
					style={{ overflow: "hidden", padding: "10px" }}>
					<Box style={{ fontSize: "16px" }}>{i18next.t("register.weNoteRecognized")}</Box>
					<Box sx={{ margin: "10px 0 0", overflow: "hidden" }}>
						<Box className="reg-title">{i18next.t("register.thingsShouldKnow")}</Box>
						<TextareaAutosize
							id="aboutMe"
							value={"aboutMe"}
							name="aboutMe"
							minRows={5}
							variant="outlined"
							style={{
								width: "100%",
								resize: "none",
								outline: "none",
								background: "#fff",
								borderRadius: "5px",
								border: "1px solid #ddd",
							}}
							{...formik.getFieldProps("aboutMe")}
						/>
					</Box>
					{/* {isEventsHallUser && (
						<>
							<Box sx={{ margin: "10px 0 0" }}>
								<Box className="reg-title">{i18next.t("register.maximumNumberOfGuests")}</Box>

								<TextComponent
									sx={{ margin: "5px 0 0" }}
									id="maximumNumberOfGuests"
									{...formik.getFieldProps("maximumNumberOfGuests")}
								/>
								<ErrorLabel>
									{formik.touched.maximumNumberOfGuests && formik.errors.maximumNumberOfGuests}
								</ErrorLabel>
							</Box>
							<Box sx={{ margin: "10px 0 0" }}>
								<Box className="reg-title">{i18next.t("register.mealPrice")}</Box>

								<TextComponent
									sx={{ margin: "5px 0 0" }}
									id="mealPrice"
									{...formik.getFieldProps("mealPrice")}
								/>
								<ErrorLabel>{formik.touched.mealPrice && formik.errors.mealPrice}</ErrorLabel>
							</Box>
							<Box sx={{ margin: "10px 0 0" }}>
								<Box className="reg-title">{i18next.t("register.kosherType")}</Box>

								<TextComponent
									sx={{ margin: "5px 0" }}
									id="kosherType"
									{...formik.getFieldProps("kosherType")}
								/>
								<ErrorLabel>{formik.touched.kosherType && formik.errors.kosherType}</ErrorLabel>
							</Box>
						</>
					)} */}
					<Box sx={{ margin: "10px 0 0" }}>
						<Box className="reg-title">{i18next.t("register.experience")}</Box>

						<TextComponent
							sx={{ margin: "5px 0 0" }}
							id="experience"
							{...formik.getFieldProps("experience")}
						/>
						<ErrorLabel>{formik.touched.experience && formik.errors.experience}</ErrorLabel>
					</Box>
					<Box sx={{ margin: "10px 0 0" }}>
						<Box className="reg-title">{i18next.t("register.education")}</Box>

						<TextComponent
							sx={{ margin: "5px 0 0" }}
							id="education"
							{...formik.getFieldProps("education")}
						/>
						<ErrorLabel>{formik.touched.education && formik.errors.education}</ErrorLabel>
					</Box>
					<Box sx={{ margin: "10px 0 0" }}>
						<Box className="reg-title">{i18next.t("register.skills")}</Box>

						<TextComponent
							sx={{ margin: "5px 0 0" }}
							id="skills"
							{...formik.getFieldProps("skills")}
						/>
						<ErrorLabel>{formik.touched.skills && formik.errors.skills}</ErrorLabel>
					</Box>
					{/* <Box sx={{ margin: "10px 0 0" }}>
						<Box className="reg-title">{i18next.t("register.pricePerHour")}</Box>

						<TextComponent
							sx={{ margin: "5px 0 0" }}
							id="pricePerHour"
							{...formik.getFieldProps("pricePerHour")}
						/>
						<ErrorLabel>{formik.touched.pricePerHour && formik.errors.pricePerHour}</ErrorLabel>
					</Box> */}

					<Box sx={{ margin: "10px 0 0px" }}>
						<Box className="reg-title">{i18next.t("register.uploadYouTubeVideo")}</Box>
						<Box className="skills" sx={{ margin: "10px 0" }}>
							{i18next.t("register.uploadOnlyYouTubeVideo")}
						</Box>

						<TextComponent
							sx={{ margin: "5px 0" }}
							id="youTubeUrlVideo"
							{...formik.getFieldProps("youTubeUrlVideo")}
						/>
						<ErrorLabel>
							{formik.touched.youTubeUrlVideo && formik.errors.youTubeUrlVideo}
						</ErrorLabel>
					</Box>
					{Object.values(extraFields || {}).map((item) => {
						return (
							<>
								{item.type === "text" && (
									<Box sx={{ margin: "10px 0" }}>
										<Box className="reg-title">{item.title}</Box>
										<TextComponent id={item.name} {...formik.getFieldProps(item.name)} />
										<ErrorLabel>{formik.touched[item.name] && formik.errors[item.name]}</ErrorLabel>
									</Box>
								)}
								{item.type === "file" && (
									<Box sx={{ position: "relative", overflow: "hidden" }}>
										<TextComponent
											readOnly
											sx={{ margin: "30px 0 0" }}
											id={item.name}
											InputProps={{
												readOnly: true,
											}}
											label={item.title}
											value=""
										/>

										<UploadFile
											sx={{
												position: "relative",
												top: "-40px",
												display: "flex",
												justifyContent: "end",
												alignItems: "center",
											}}
											file={user[item.name]?.url}
											onSetFileBase64={(data) => updateFieldFile(data, item.name)}
											onDelete={() => deleteFileHandler(item.name)}
											btnTxt={item.title}></UploadFile>
									</Box>
								)}
							</>
						);
					})}

					{type === "register" && (
						<>
							<Box
								className="center"
								sx={{
									fontSize: "17px",
									fontWeight: "600",
									margin: "30px 0 5px",
									justifyContent: "start !important",
								}}>
								<span className="reg-title">
									{i18next.t("register.addPhotosToTheBusinessPageGallery")}
								</span>
							</Box>
							<MyImages withoutHeader={true} user={userProfile}></MyImages>
						</>
					)}
					<Box sx={{ mb: 15 }}>
						<RegisterBtnWrap
							type={type}
							inProgress={inProgress}
							btnText={btnText}
							currentStep={currentStep}
							goBack={goBack}></RegisterBtnWrap>
					</Box>
					<ScrollToFieldError errors={formik.errors} />
				</form>
			)}
		</>
	);
};
