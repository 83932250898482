import "assets/scss/landing/landingUsersHeader.scss";
import { Box } from "@mui/material";
import i18next from "i18next";
import { Button } from "@mui/material";
import useNavigator from "hooks/useNavigator";
import SearchTags from "components/searchTalent/SearchTags";
import SearchIcon from "@mui/icons-material/Search";
import { getFiltersSelected } from "redux/search/selectors";
import { useSelector } from "react-redux";
import presentor from "assets/images/svgs/landing/presentor.png";
import BaseHelmet from "components/seo/BaseHelmet";

export default function LandingUsersHeader() {
	const navigate = useNavigator();
	const filters = useSelector(getFiltersSelected);

	const handleSubmit = async () => {
		const searchParams = new URLSearchParams(filters).toString();
		navigate.navigateToPath(`/search?${searchParams}`);
	};
	return (
		<>
			<BaseHelmet
				structuredData={`{
					  "@context": "https://schema.org",
					  "@type": "WebSite",
					  "name": "ששת ימים - אינדקס עסקים מומחים ובעלי מקצוע",
					  "url": "http://sheshet.co.il",
					  "description": "הפלטפורמה היחידה בישראל שמחברת בין עסקים ונותני שירותים ללקוחות על בסיס חברים ואנשי קשר משותפים ובאמצעות בינה מלאכותית",
					   "potentialAction": {
					        "@type": "SearchAction",
					        "target": {
					          "@type": "EntryPoint",
					          "urlTemplate": "https://sheshet.co.il/search?freeText=q={search_term_string}"
					        },
					        "query-input": "required name=search_term_string"
					      }
					}`}
				title={i18next.t("landingPage.baseHelmet.usersTitle")}
				description={i18next.t("landingPage.baseHelmet.usersDesc")}
				url={`/`}></BaseHelmet>
			<Box className="landingUsersHeader">
				<Box className="usersContent">
					<h1 className="noSelection userTitle">
						<Box className="firstLine">
							{`${i18next.t("landingPage.chooseProfessionals")} `}
							{`${i18next.t("landingPage.professionalsBy")} `}
						</Box>

						<Box>
							<span className="customTextPrimary">{`${i18next.t(
								"landingPage.recommendationsFromFriends"
							)}`}</span>
						</Box>
					</h1>
					<h2 className="noSelection userSubTitle">
						<Box>
							{`${i18next.t("landingPage.thePlatform")} `}
							<span className="bolder">{`${i18next.t("landingPage.onlyIsrael")} `}</span>
							{`${i18next.t("landingPage.thatConnects")} `}
						</Box>
						<Box>{`${i18next.t("landingPage.businessesToCustomers")} `}</Box>
						<Box>
							{`${i18next.t("landingPage.andContacts")} `}
							<span className="bolder">{`${i18next.t("landingPage.Ai")}`}</span>
						</Box>
					</h2>
					<Box className="textInput">
						<SearchTags>
							<Box className="wrapSearchAndFilter">
								<Button onClick={handleSubmit} className="center">
									<SearchIcon style={{ color: "#5156a2", width: "36px" }} />
								</Button>
							</Box>
						</SearchTags>
					</Box>
				</Box>
				<Box className="wrapHeaderImg">
					<img src={presentor} />
				</Box>
			</Box>
		</>
	);
}
