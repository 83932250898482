import i18next from "i18next";
import { Box } from "@mui/material";
import BaseHelmet from "../components/seo/BaseHelmet";

export default () => {
	return (
		<Box sx={{ overflow: "scroll" }}>
			<BaseHelmet
				title={i18next.t("seo.homePage.title")}
				description={i18next.t("seo.homePage.description")}></BaseHelmet>
		</Box>
	);
};
