import "assets/scss/masonry/masonry.scss";
import Masonry from "react-masonry-css";

const DynamicMasonry = ({ children, breakpointColumns = { default: 3, 1580: 2, 1180: 1 } }) => {
	const breakpointColumnsObj = {
		...breakpointColumns,
	};

	return (
		<Masonry
			breakpointCols={breakpointColumnsObj}
			className="myMasonryGrid"
			columnClassName="myMasonryGridColumn">
			{children}
		</Masonry>
	);
};

export default DynamicMasonry;
