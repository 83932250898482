import { Button } from "reactstrap";
import { useState } from "react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Lock from "assets/images/icons/locked.svg";
import Block from "assets/images/icons/block.svg";
import { getUserProfile } from "redux/profile/selectors";
import { getUserGeneralStatus } from "redux/general/selectors";
import UserStatus from "consts/userStatus";
import { unFreeze } from "redux/general/actions";
import functionsService from "services/functionsService";

export default () => {
	const dispatch = useDispatch();
	const profile = useSelector(getUserProfile);
	const status = profile?.status;
	const history = useHistory();
	const [isUnfreezeClicked, setIsUnfreezeClicked] = useState(false);

	const _onLogout = () => {
		history.push("/logout");
	};

	const _unFreeze = async () => {
		await functionsService.unFreezeMe();
		setIsUnfreezeClicked(true);
	};

	return (
		<div className="freeze">
			<div className="wrapButtons">
				{status === "reject" ? (
					<div className="lockIcon blockedIcon">
						<img src={Block} />
					</div>
				) : (
					<div className="lockIcon">
						<img src={Lock} />
					</div>
				)}

				<div className="helloUser">
					<h1> {i18next.t("Blocks.Title", { name: profile.name || "" })}</h1>
					<h3> {i18next.t(`Blocks.${status}`)}</h3>
				</div>
			</div>
			<div className="wrapButtons">
				{status === "freezed" && (
					<Button
						onClick={_unFreeze}
						className="freezeBtn"
						outline
						color="primary"
						style={{ display: "" }}
						disabled={isUnfreezeClicked}>
						{isUnfreezeClicked === false
							? i18next.t("Blocks.unFreeze")
							: i18next.t("Blocks.unFreezing")}
					</Button>
				)}

				<Button
					onClick={_onLogout}
					className="logoutBtn"
					outline
					color="primary"
					style={{ color: "black" }}>
					{i18next.t("Blocks.Logout")}
				</Button>
			</div>
		</div>
	);
};
