import { Box, CircularProgress, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import tags from "services/tags";
import { useState } from "react";
import i18next from "i18next";
import SiteButton from "components/ui/SiteButton";
import { openNotification } from "redux/notification/actions";

export default function TagsSuggestions({}) {
	const dispatch = useDispatch();
	const [tagName, setTagName] = useState("");
	const [inProgress, setInProgress] = useState(false);

	const addTagsSuggestions = () => {
		if (!tagName || !tagName?.trim()) return;
		setInProgress(true);
		tags
			.addTagsSuggestions(tagName.trim())
			.then((data) => {
				if (data.data?.success) {
					dispatch(openNotification("הפעולה בוצעה בהצלחה"));
					return dispatch(closeDialogByType(dialogsTypes.TagsSuggestions));
				}
				dispatch(openNotification("הפעולה נכשלה"));
				setInProgress(false);
			})
			.catch((e) => {
				dispatch(openNotification("הפעולה נכשלה"));
				setInProgress(false);
			});
	};

	return (
		<Box
			style={{
				padding: "40px",
				minWidth: "300px",
			}}>
			<h1>{i18next.t("tagsSuggestions.suggest")}</h1>
			<Box>
				<TextField
					fullWidth
					placeholder="שם התגית"
					value={tagName}
					onChange={(e) => {
						setTagName(e.target.value);
					}}
					id="tagName"
				/>
			</Box>

			<Box
				style={{
					margin: "20px 0",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontWeight: 700,
				}}>
				{!!inProgress ? (
					<CircularProgress size={40} sx={{ color: "#386BA8" }} />
				) : (
					<SiteButton
						text={"שלח"}
						onClickBtn={addTagsSuggestions}
						styledButton={{ style: { padding: "4px 25px" } }}
					/>
				)}
			</Box>
		</Box>
	);
}
