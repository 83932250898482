import { Box, CircularProgress } from "@mui/material";
import Loader from "components/Loader";
import Button from "components/ui/Button";
import TextComponent from "components/ui/TextComponent";
import { useFormik } from "formik";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserUID } from "redux/auth/selectors";
import RecommendationsService from "services/recommendations";
import * as Yup from "yup";
import UserImage from "./userImage";
import SendIcon from "@mui/icons-material/Send";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import sendFull from "assets/images/svgs/send-reccomendation.svg";
import sendDisable from "assets/images/svgs/send-recemendation-disable.svg";
import sendWeb from "assets/images/svgs/newRec/newSendIcon.svg";
import UsersTypes from "consts/UsersTypes";

export default ({
	talentId,
	recommendationsLength,
	talentProfile,
	myRef = null,
	isFromWeb = false,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const uid = useSelector(getLoggedInUserUID);
	const { userProfile, isLoggedIn } = useUser();
	const dispatch = useDispatch();

	const initialValues = {
		text: "",
	};

	const validationSchema = Yup.object().shape({
		text: Yup.string().required(i18next.t("validation.required")),
	});

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => {
			await addRecommendation(values);
			resetForm();
		},
	});
	const openRegClientDialog = () => {
		return dispatch(
			openDialog({
				dialog: dialogsTypes.RegisterClientDialog,
				actAfterRegister: () => {
					addRecommendation({ ...formik.values, skipRegClient: true });
				},
				isFromMessageTalent: false,
				talentName: talentProfile?.name,
			})
		);
	};

	const addRecommendation = async (values) => {
		if (
			!userProfile?.completeClientRegister &&
			userProfile?.type === UsersTypes.Client &&
			!values?.skipRegClient
		)
			return openRegClientDialog();

		if (values.text && uid) {
			setIsLoading(true);
			if (talentProfile?.uid ?? talentId)
				await functionsService.addTalentAction(
					talentProfile?.uid ?? talentId,
					talentAction.recommendation
				);
			const trimText = `${values?.text ?? ""}`.trim();
			const result = await RecommendationsService.addRecommendation(
				uid,
				talentProfile?.uid ?? talentId,
				trimText
			).then((data) => {
				if (data?.data?.msg == "already have recommendation for this user") {
					myRef.current.scrollIntoView({
						behavior: "smooth",
						block: "center",
					});
					dispatch(
						openDialog({
							dialog: dialogsTypes.Countdown,
							text: "לא ניתן להגיב יותר מפעם אחת",
						})
					);
				}
			});
			setIsLoading(false);
		}
	};

	const handleSubmitRecommendation = (e) => {
		if (!isLoggedIn || (userProfile?.type === UsersTypes.Talent && !userProfile?.name)) {
			e.preventDefault();
			handleOpenLoginPopup();
		}
	};
	// const handleFocusRec = (e) => {
	// 	const elem = document.querySelector(".getBackToMe");
	// 	if (elem) elem.style.display = "none";
	// };
	// const handleBlurRec = (e) => {
	// 	const elem = document.querySelector(".getBackToMe");
	// 	if (elem) elem.style.display = "flex";
	// };
	// const handleSubmitRecommendationMobile = (e) => {
	// 	if (!userProfile?.name || !isLoggedIn) {
	// 		e.preventDefault();
	// 		return handleOpenLoginPopup();
	// 	}
	// 	formik.submitForm();
	// };
	const handleOpenLoginPopup = () => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.Login,
				isFromRecommend: true,
				recommendName: talentProfile.name,
				actAfterLogin: () => formik.submitForm(),
			})
		);
	};
	const sendRecomendationBtnTxt = [
		<img src={sendWeb} />,
		<span style={{ marginRight: "5px" }}>{i18next.t("talent.sendRecomendation")}</span>,
	];

	return (
		<>
			{!!userProfile && (
				<>
					<form
						className="addRecommendationForm"
						id="add-recommendation"
						onSubmit={formik.handleSubmit}>
						<UserImage user={userProfile} className="userProfileImg"></UserImage>
						<Box className="wrapTxtBtn">
							<TextComponent
								minRows={2}
								multiline={true}
								placeholder={`${i18next.t("talent.recommendationOnTalent")} ${talentProfile.name}`}
								{...formik.getFieldProps("text")}
								sx={{ width: "100%" }}></TextComponent>
							{isLoading ? (
								<Box>
									<CircularProgress sx={{ width: "50px", color: "#1B8386" }} />
								</Box>
							) : (
								<Button
									variant=""
									type="submit"
									width="50px"
									sx={{ background: "#1B8386 !important" }}
									onClick={handleSubmitRecommendation}>
									{sendRecomendationBtnTxt}
								</Button>
							)}
						</Box>
					</form>
					{/* {isFromWeb ? (
							<form
								className="addRecommendationForm"
								id="add-recommendation"
								onSubmit={formik.handleSubmit}>
								<UserImage user={userProfile} className="userProfileImg"></UserImage>
								<Box className="wrapTxtBtn">
									<TextComponent
										minRows={2}
										multiline={true}
										placeholder={`${i18next.t("talent.recommendationOnTalent")} ${
											talentProfile.name
										}`}
										{...formik.getFieldProps("text")}
										sx={{ width: "100%" }}></TextComponent>
									{isLoading ? (
										<Box>
											<CircularProgress sx={{ width: "50px", color: "#1B8386" }} />
										</Box>
									) : (
										<Button
											variant=""
											type="submit"
											width="50px"
											sx={{ background: "#1B8386 !important" }}
											onClick={handleSubmitRecommendation}>
											{sendRecomendationBtnTxt}
										</Button>
									)}
								</Box>
							</form>
						) : (
							<>
								<form
									className="addRecommendationFormMobile"
									id="add-recommendation"
									onSubmit={formik.handleSubmit}>
									<UserImage user={userProfile} className="userProfileImg"></UserImage>
									<Box className="wrapTxtBtn">
										<TextComponent
											minRows={2}
											multiline={true}
											placeholder={`${i18next.t("talent.recommendationOnTalent")} ${
												talentProfile.name
											}`}
											onFocus={handleFocusRec}
											{...formik.getFieldProps("text")}
											onBlur={handleBlurRec}
											sx={{ width: "100%" }}></TextComponent>
									</Box>
									{isLoading ? (
										<Box className="wrapSend">
											<CircularProgress
												sx={{
													width: "22px !important",
													height: "22px !important",
													color: "rgba(56, 106, 168, 0.15)",
												}}
											/>
										</Box>
									) : (
										<Box className="wrapSend">
											{!formik?.values?.text ? (
												<img src={sendDisable} />
											) : (
												<img src={sendFull} onClick={handleSubmitRecommendationMobile} />
											)}
										</Box>
									)}
								</form>
							</>
						)} */}
				</>
			)}
		</>
	);
};
