import { Box } from "@mui/material";
import SearchContent from "components/searchTalent/SearchContent";
import SearchFilter from "components/searchTalent/SearchFilter";
import useSearch from "hooks/useSearch";
import "assets/scss/search/search.scss";
import { useEffect, useRef, useState } from "react";
import Content from "views/Content";
import Tester from "components/capacitor/Tester";
import { useDispatch } from "react-redux";
import { addFiltersSelected } from "redux/search/actions";
import { getFilters, isInit } from "redux/search/selectors";
import useQuery from "hooks/useQuery";
import { useSelector } from "react-redux";
import PreviewSitePage from "./PreviewSitePage";
import contactsService from "services/contactsService";
import useUser from "hooks/useUser";

export default () => {
	const { user } = useUser();
	const search = useSearch();

	const dispatch = useDispatch();
	const { pushQueryParams, params } = useQuery();
	const filters = useSelector(getFilters);
	const isInitSearchData = useSelector(isInit);
	const didMount = useRef(false);
	const didMountContacts = useRef(false);
	useEffect(() => {
		const dataFromParams = search.getObjectSearchByParams();
		dispatch(addFiltersSelected({ filters: dataFromParams }));
	}, []);

	useEffect(() => {
		if (isInitSearchData) {
			search.search();
		}
	}, [isInitSearchData]);

	useEffect(async () => {
		// Only from second time run this code.
		if (didMount.current && filters) {
			pushQueryParams(filters);
		}
		didMount.current = true;
	}, [filters]);

	useEffect(async () => {
		if (!didMountContacts.current && user?.uid) {
			await contactsService.setContacts(user.uid, user.syncContactsToNeo4j);
			didMountContacts.current = true;
		}
	}, [user?.uid]);

	return (
		<>
			<Box sx={{ padding: "0px 0 80px" }} className="searchPageWrapper">
				<Box className="paddingSearchFilter">
					<SearchFilter></SearchFilter>
				</Box>
				{/* <Tester></Tester> */}
				<Content>
					{<SearchContent></SearchContent>}
					{/* 	{<PreviewSitePage />} */}
				</Content>
			</Box>
		</>
	);
};
