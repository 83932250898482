import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getTagsByCategoryKey } from "redux/tags/selectors";

export default ({
	category,
	onTagSelected,
	tagsOpen = false,
	webPage = false,
	defaultTagsCategory = [],
	setTagsOpen = () => {},
}) => {
	const tagsByCategory = useSelector(getTagsByCategoryKey(category));
	const MAX_TAGS_DISPLAY = 3;
	const [showAllTags, setShowAllTags] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);

	const handleSelectTag = (tagName) => {
		let tags = [];
		if (!!selectedTags?.includes(tagName)) {
			tags = selectedTags.filter((tag) => tag != tagName);
		} else {
			tags = [...selectedTags, tagName];
		}
		setSelectedTags(tags);
		onTagSelected(tags);
	};

	useEffect(() => {
		const element = document.querySelector(".paddingSearchFilter");
		if (!element) return;
		element.classList.add("boxShadowActive");
		return () => {
			element.classList.remove("boxShadowActive");
		};
	}, []);

	useEffect(() => {
		setShowAllTags(tagsOpen);
	}, [tagsOpen]);

	useEffect(() => {
		if (!defaultTagsCategory || !defaultTagsCategory?.length) return setSelectedTags([]);
		setSelectedTags(defaultTagsCategory);
	}, [defaultTagsCategory]);

	useEffect(() => {
		const element = document.querySelector(".moreTagsBtn");
		if (
			!(
				!!tagsByCategory &&
				!!tagsByCategory.length &&
				!!(tagsByCategory.length - MAX_TAGS_DISPLAY > 0)
			)
		) {
			if (element?.classList) return element.classList.remove("moreTagsBtnActive");
			return;
		}
		if (!element) return;
		element.classList.add("moreTagsBtnActive");

		return () => {
			if (element?.classList) element.classList.remove("moreTagsBtnActive");
		};
	}, [tagsByCategory]);

	return (
		<>
			{!!tagsByCategory &&
				!!tagsByCategory.length &&
				tagsByCategory.map((tag, index) => (
					<>
						{(webPage ||
							index < MAX_TAGS_DISPLAY ||
							(index >= MAX_TAGS_DISPLAY && showAllTags)) && (
							<button
								className={`${selectedTags.includes(tag.name) ? "selectedTag" : ""}`}
								key={tag.key}
								type="button"
								onClick={() => handleSelectTag(tag.name)}>
								{tag.name}
							</button>
						)}
					</>
				))}
			{!webPage &&
				!showAllTags &&
				!!tagsByCategory &&
				!!tagsByCategory.length &&
				!!(tagsByCategory.length - MAX_TAGS_DISPLAY > 0) && (
					<button
						type="button"
						onClick={() => {
							setTagsOpen(true);
						}}>
						{`+${tagsByCategory.length - MAX_TAGS_DISPLAY}`}
					</button>
				)}
		</>
	);
};
