import { map, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import SearchService from "services/search";
import { getUserProfile } from "../profile/selectors";
import * as types from "./actionTypes";
import { getPitId, getSearchAfter, getLastSortOption, getFilters } from "./selectors";
import ProfileFields from "consts/profileFields";
import _ from "lodash";
import searchTypes from "consts/typesSearch";

import { search } from "./actions";
import httpsCallable from "services/httpsCallable";

export const fetchFilterChange = (action$, store) =>
	action$.pipe(
		ofType(types.SEARCH_ADD_FILTERS_SELECTED),
		map(({ payload }) => {
			if (payload?.research) {
				return search();
			} else {
				return {
					type: "success",
					payload,
				};
			}
		})
	);

export const fetchSearch = (action$, store) =>
	action$.pipe(
		ofType(types.SEARCH_FETCH),
		mergeMap(async ({ cleanFilter = {}, freeText }) => {
			const state = store.value;
			const typeSearch = searchTypes.Search;
			const profile = getUserProfile(state);
			let sortOption = state.search.filtersSelected.sort;
			try {
				const filterToSearch = { ...state.search.filtersSelected, ...cleanFilter } ?? {};
				//const filterToSearch = { ...state.search.filtersSelected };
				if (!_.isEmpty(filterToSearch.tagsKeys)) {
					filterToSearch.category = "";
				}

				const result = await httpsCallable.post("search", {
					extraData: {
						extraData: {
							freeText,
							userFilters: filterToSearch,
							userLocation: filterToSearch?.location ?? "",
						},
						sortOption,
						builderType: typeSearch,
					},
				});

				let payload = result.data.data;

				payload.lastSortOption = sortOption;
				payload.users = {};
				payload.filters = filterToSearch;
				payload.items.map((user) => (payload.users[user.uid] = user));

				return {
					type: types.SEARCH_FETCH_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.SEARCH_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);
export const fetchSearchLoadMore = (action$, store) =>
	action$.pipe(
		ofType(types.SEARCH_FETCH_LOAD_MORE),
		mergeMap(async ({ freeText }) => {
			const state = store.value;
			const typeSearch = state.search.typeSearch ? state.search.typeSearch : searchTypes.Search;
			const profile = getUserProfile(state);
			const pitId = getPitId(state);
			const searchAfter = getSearchAfter(state);
			const sortOption = getLastSortOption(state);
			try {
				const filterToSearch = { ...state.search.filtersSelected } ?? {};
				if (!_.isEmpty(filterToSearch.tagsKeys)) {
					filterToSearch.category = "";
				}
				const result = await httpsCallable.post("search", {
					extraData: {
						searchAfter,
						extraData: {
							freeText,
							userFilters: filterToSearch,
							userLocation: filterToSearch?.location ?? "",
						},
						sortOption,
						builderType: typeSearch,
					},
				});

				let payload = result.data.data;

				payload.lastSortOption = sortOption;
				payload.users = {};
				payload.filters = filterToSearch;
				payload.items.map((user) => (payload.users[user.uid] = user));

				return {
					type: types.SEARCH_FETCH_LOAD_MORE_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.SEARCH_FETCH_FAILED,
					error: error?.message,
				};
			}
		})
	);
