import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType, getDialogs } from "redux/dialog/selectors";
import iconBack from "assets/images/Icons-back.svg";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import dialogsTypes from "consts/dialogsTypes";
import CloseIcon from "@mui/icons-material/Close";
import arrowBack from "assets/images/svgs/back.svg";
import { isMobile } from "react-device-detect";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(0),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(0),
	},
}));

export default function DDialog({
	withHeaderColor = false,
	isFullScreen,
	showGoBack = true,
	type,
	component: Component,
	withPadding = true,
	noPopupStyle = false,
	...prop
}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { dialogs } = useSelector(getDialogs);
	const handleClose = () => {
		dispatch(closeDialogByType(type));
	};

	return (
		<>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={true}
				className={`${
					noPopupStyle
						? "fullWidth"
						: withPadding
						? "dialogWrapper paddingDialog"
						: "dialogWrapper noPaddingDialog"
				} ${!isMobile && !isFullScreen && "webDialogView"}`}
				fullScreen={isFullScreen ? true : false}
				sx={{ top: "env(safe-area-inset-top)" }}>
				<Box id={"wrapDialogId"} sx={{ position: "relative" }}>
					{showGoBack && (
						<Box sx={{ minWidth: "100%", position: "relative" }}>
							<span
								onClick={handleClose}
								style={{
									position: "fixed",
									top: "calc(env(safe-area-inset-top) + 32px)",
									right: "15px",
									zIndex: 3,
									cursor: "pointer",
								}}>
								<img src={arrowBack} alt="" />
							</span>
						</Box>
					)}
					{withHeaderColor && (
						<Box
							sx={{
								position: "relative",
								minHeight: "18px",
								background: "#7496bf",
								width: "100%",
							}}></Box>
					)}
					<Component {...prop}></Component>
				</Box>
			</BootstrapDialog>
		</>
	);
}
