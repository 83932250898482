import { Box, CircularProgress } from "@mui/material";
import useUser from "hooks/useUser";
import { useDispatch } from "react-redux";
import { resetToSelectedList, updatePinChats } from "redux/chats/actions";
import chatService from "services/chatService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import i18next from "i18next";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useState, useEffect } from "react";

import remove from "assets/images/svgs/dustbin.svg";
import pinWhite from "assets/images/chat/pinWhite.png";
import unPinWhite from "assets/images/chat/unPinWhite.png";

export default ({ count, chatListSelected, setEditChats = () => {} }) => {
	const dispatch = useDispatch();
	const { user } = useUser();
	const [isLoad, setIsLoad] = useState(false);
	const [isPinned, setIsPinned] = useState(true);

	const cancel = () => {
		setEditChats(false);
		dispatch(resetToSelectedList());
	};

	const pinChats = async () => {
		setIsLoad(true);
		const arrIds = Object.keys(chatListSelected);
		if (arrIds.length) {
			await chatService.pinChat(arrIds);
		}
		cancel();
		setIsLoad(false);
	};

	const unpinChats = async () => {
		setIsLoad(true);
		const arrIds = Object.keys(chatListSelected);
		if (arrIds.length) {
			await chatService.unpinChat(arrIds);
		}
		cancel();
		setIsLoad(false);
	};

	const handleDeleteChat = () => {
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: `האם אתה רוצה למחוק את הצא'ט?`,
				onYes: async () => {
					await deleteChats();
					toggelClose();
				},
				onNo: toggelClose,
			})
		);
	};

	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};

	const deleteChats = async () => {
		setIsLoad(true);
		const arrIds = Object.keys(chatListSelected);
		if (arrIds.length) {
			await chatService.deleteChat(arrIds);
		}
		cancel();
		setIsLoad(false);
	};

	useEffect(() => {
		if (!!Object.values(chatListSelected).find((item) => item?.isPin)) {
			return setIsPinned(false);
		}
		return setIsPinned(true);
	}, [chatListSelected]);

	return (
		<Box className="chatActionsWrapper">
			{!isLoad ? (
				<>
					<Box className="wrapCountBack">
						<Box onClick={cancel} className="backIcon center">
							<ArrowBackIosIcon sx={{ fontSize: "20px" }} />
						</Box>
						{count || ""}
					</Box>
					<Box className="chatActionsContent">
						{isPinned ? (
							<Box className="iconAction" sx={{ width: "80px !important" }} onClick={pinChats}>
								<span>{i18next.t("Chat.pin")}</span>
								<img height={"20px"} src={pinWhite} alt="" />
							</Box>
						) : (
							<Box className="iconAction" sx={{ width: "80px !important" }} onClick={unpinChats}>
								<span style={{ width: "80px" }}>{i18next.t("Chat.removePin")}</span>
								<img height={"20px"} src={unPinWhite} alt="" />
							</Box>
						)}
						<Box className="iconAction" sx={{ marginRight: "10px" }} onClick={handleDeleteChat}>
							<span style={{ color: "#ff0000" }}>{i18next.t("Chat.delete")}</span>
							<img height={"20px"} src={remove} alt="" />
						</Box>
					</Box>
				</>
			) : (
				<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<CircularProgress sx={{ color: "#fff" }} />
				</Box>
			)}
		</Box>
	);
};
