import firebase from "firebase/app";
import "firebase/database";
import AppService from "./app";
import httpsCallable from "./httpsCallable";

class FcmTokenService {
	BASE_REF = "push_tokens";
	saveFcmToken = async (uid, fcmToken) => {
		return await httpsCallable.post("addPushToken", {
			extraData: { token: fcmToken },
		});
	};

	async removeCurrentFcmToken(uid) {
		const fcmToken = await this.getFcmTokenFromDevice();
		if (fcmToken) {
			return firebase.database().ref(this.BASE_REF).child(uid).child(fcmToken).remove();
		}
	}
}

export default new FcmTokenService();
