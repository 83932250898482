import { useState, useEffect } from "react";
import { Form, FormGroup, FormFeedback, Input } from "reactstrap";
import VerificationInput from "react-verification-input";
import i18next from "i18next";

export default ({ onChange, onValidityChange, hasError, setHasError, errMsg, onEnterPressed }) => {
	const [isValid, setIsValid] = useState(false);
	const [value, setValue] = useState("");

	const handleOnSubmit = (e) => {
		e.preventDefault();
		onEnterPressed();
	};

	const handleChange = (val) => {
		setValue(val);
		onChange(val);
		setHasError(false);
		const validLength = val.length === 6;

		if (isValid !== validLength) {
			setIsValid(validLength);
		}
	};

	useEffect(() => {
		onValidityChange(isValid);
	}, [isValid]);

	return (
		<Form onSubmit={handleOnSubmit} inline>
			<FormGroup>
				<VerificationInput
					inputProps={{ type: "tel" }}
					value={value}
					type="tel"
					length={6}
					placeholder=""
					validChars="\d"
					autoFocus={true}
					removeDefaultStyles={false}
					classNames={{
						container: "container",
						character: "character",
						characterInactive: "character--inactive",
						characterSelected: "character--selected",
					}}
					onChange={handleChange}
				/>
				{hasError && (
					<div
						style={{
							color: "#ef2e2e",
							display: "flex",
							justifyContent: "center",
						}}>
						<span style={{ textAlign: "center", maxWidth: "312px" }}>
							{i18next.t("Login.code incorrect")}
							<span>{i18next.t("Login.new code")}</span>
						</span>
					</div>
				)}
				{/* {hasError && (
					<>
						<Input invalid={true} style={{ display: "none" }} />
					</>
				)} */}
			</FormGroup>
		</Form>
	);
};
