import "assets/scss/linkBtns/linkBtns.scss";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import SiteButton from "components/ui/SiteButton";
import sendMsgIcon from "assets/images/svgs/mainCTA-message.svg";
import whatsappIcon from "assets/images/svgs/whatsapp.svg";
import phoneIcon from "assets/images/svgs/phonecall.svg";
import i18next from "i18next";

export default function LinkBtns({ user, show = false }) {
	const dispatch = useDispatch();
	const [userLinks, setUserLinks] = useState({});
	const message = `הי, הגעתי אליך דרך ששת ימים`;
	const whatsappLink = !user?.whatsappDisplay
		? ""
		: toString(user.whatsappDisplay).includes("972")
		? ` https://wa.me/${toString(user.whatsappDisplay).replace("+", "")}/?text=${encodeURIComponent(
				message
		  )}`
		: ` https://wa.me/972${user.whatsappDisplay}/?text=${encodeURIComponent(message)}`;

	useEffect(() => {
		const userLinksObj = {};
		if (user?.whatsappDisplay) {
			userLinksObj.whatsappDisplay = user?.whatsappDisplay;
		}
		if (user?.phoneDisplay) {
			userLinksObj.phoneDisplay = user?.phoneDisplay;
		}
		setUserLinks(userLinksObj);
	}, [user?.whatsappDisplay, user?.phoneDisplay]);

	const openSendMessagePopup = async () => {
		dispatch(openDialog({ dialog: dialogsTypes.SendMessageToTalentPopup, talent: user }));
		if (user?.uid) await functionsService.addTalentAction(user.uid, talentAction.clickSendMessage);
	};

	return (
		<Box
			className="linkBtnsWrapper"
			onClick={(e) => e.stopPropagation()}
			sx={{ flexWrap: window?.innerWidth <= 370 && "wrap" }}>
			<SiteButton
				text={[
					<img src={sendMsgIcon} style={{ marginLeft: "8px" }} />,
					<span style={{ width: "max-content" }}>{i18next.t("linkButtons.sendMsg")}</span>,
				]}
				onClickBtn={openSendMessagePopup}
				styledText={{ style: { display: "flex" } }}
			/>
			<Box className="linkTextWrapper">
				{!!userLinks &&
					Object.entries(userLinks || {}).map(([key, value], index) => (
						<Box key={key} className={`wrapTextitem ${index >= 1 && "separator"}`}>
							<>
								{key == "whatsappDisplay" ? (
									<Box
										sx={{ display: "flex", width: "max-content" }}
										onClick={async () => {
											window.gtag("event", "click", {
												event_category: "Button",
												event_label: "whatappClick",
											});
											if (user?.uid)
												await functionsService.addTalentAction(
													user.uid,
													talentAction.clickWhatsapp
												);
											window.open(whatsappLink);
										}}>
										<img src={whatsappIcon} />
										<span className="textInfoData">{i18next.t("linkButtons.whatsapp")}</span>
									</Box>
								) : (
									<a
										href={`tel:${value}`}
										onClick={async () => {
											if (user?.uid)
												await functionsService.addTalentAction(user.uid, talentAction.clickPhone);
										}}
										sx={{ display: "flex", width: "max-content" }}>
										<img src={phoneIcon} />
										<span className="textInfoData">{i18next.t("linkButtons.phone")}</span>
									</a>
								)}
							</>
						</Box>
					))}
			</Box>
		</Box>
	);
}
