import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getChatListSelected, getChatListSelectedCount } from "redux/chats/selectors";
import ChatActions from "./ChatActions";
import ChatListItem from "./ChatListItem";
import SearchChat from "./SearchChat";
import _ from "lodash";
import TabsChatList from "./TabsChatList";
import { Box } from "@mui/material";

export default () => {
	const chatsList = useSelector((state) => state.chats?.data);
	const [search, setSearch] = useState("");
	const [editChats, setEditChats] = useState(false);
	const [dataFiltered, setDataFiltered] = useState();
	const chatListSelectedCount = useSelector(getChatListSelectedCount);
	const chatListSelected = useSelector(getChatListSelected);

	const handleOnSearch = (data) => {
		setSearch(data);
	};

	const orderData = (data) => {
		const dataArr = Object.values(data || {});
		let orderData = _.orderBy(dataArr, ["pin", "lastSended"], ["desc", "desc"]);
		return orderData;
	};

	useEffect(() => {
		if (chatsList) {
			const data = orderData(chatsList);
			const dataFilteredArr = data.filter((item) => {
				const name = item.user?.name.toLowerCase();
				if (name) {
					return name.includes(search);
				}
			});
			setDataFiltered(dataFilteredArr);
		}
	}, [chatsList, search]);

	useEffect(() => {
		setEditChats(!!chatListSelectedCount);
	}, [chatListSelectedCount]);

	return (
		<Box className="chatListWrapper">
			{!!editChats && (
				<ChatActions
					setEditChats={setEditChats}
					editChats={editChats}
					count={chatListSelectedCount}
					chatListSelected={chatListSelected}></ChatActions>
			)}
			{!editChats && (
				<SearchChat
					onSearch={(data) => handleOnSearch(data)}
					setEditChats={setEditChats}></SearchChat>
			)}

			<TabsChatList editChats={editChats} list={dataFiltered}></TabsChatList>
		</Box>
	);
};
