import { useDispatch, useSelector } from "react-redux";

import { getPicturesArray, getMainPicture } from "redux/profile/selectors";

import PendingStatus from "consts/pendingStatus";
import { deleteImage, saveEditedImage, setMainPicture, updateImage } from "redux/profile/actions";
import ProfileService from "services/profile";
import QueueService from "services/queue";
import useUser from "./useUser";
import profileEvents from "consts/profileEvents";

export default function useProfileImages() {
	const { user } = useUser();
	const dispatch = useDispatch();

	const images = useSelector(getPicturesArray);

	const mainImage = useSelector(getMainPicture);

	const mappedImages = images.map((image) => {
		return {
			isMain: image.key === mainImage,
			rejected: image.status === PendingStatus.Rejected,
			pending: image.status === PendingStatus.Wait,
			...image,
		};
	});

	const setMainImage = (key) => {
		dispatch(setMainPicture(key));
	};

	const deleteProfileImage = (key) => {
		dispatch(deleteImage(key));
	};

	const editProfileImage = (cropImageName, file) => {
		dispatch(saveEditedImage(cropImageName, file));
	};

	const saveNewImage = async (file, isProfileImage = false) => {
		const userUid = user.uid;
		const data = await ProfileService.addImage(file, isProfileImage);
	};

	return {
		images: mappedImages,
		setMainImage,
		deleteProfileImage,
		editProfileImage,
		saveNewImage,
	};
}
