import { map, mergeMap, repeat } from "rxjs/operators";
import { ofType } from "redux-observable";

import * as authTypes from "redux/auth/actionTypes";
import * as types from "./actionTypes";
import ipHelper from "helpers/ip";

export const setClientInfo = (action$, store) => {
	return action$.pipe(
		ofType(authTypes.AUTH_APP_INIT),
		mergeMap(async () => {
			try {
				const data = await ipHelper.getInfo();
				return {
					type: types.SET_CLIENT_INFO,
					payload: data,
				};
			} catch (error) {
				return {
					type: "error",
					error: error?.message,
				};
			}
		})
	);
};
