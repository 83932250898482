import firebase from "firebase/app";
import "firebase/auth";

import { Observable } from "rxjs";

class AuthPhoneService {
	linkWithToken(token) {
		return new Observable((subscriber) => {
			firebase
				.auth()
				.signInWithCustomToken(token)
				.then((confirmationResult) => {
					if (confirmationResult) {
						this.confirmationResult = confirmationResult;
						subscriber.next();
					} else {
						throw new Error("Error Sending Phone");
					}
				})
				.catch((error) => {
					subscriber.error(error);
				});
		});
	}

	linkPhone(phoneNumber) {
		return new Observable((subscriber) => {
			firebase
				.auth()
				.signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
				.then((confirmationResult) => {
					if (confirmationResult) {
						this.confirmationResult = confirmationResult;
						subscriber.next();
					} else {
						throw new Error("Error Sending Phone");
					}
				})
				.catch((error) => {
					subscriber.error(error);
				});
		});
	}

	async verifyCode(code) {
		try {
			const { user } = await this.confirmationResult.confirm(code);
			return { user };
		} catch (error) {
			return { error };
		}
	}

	listenAuthChange() {
		return new Observable((subscriber) => {
			firebase.auth().onAuthStateChanged(
				(user) => {
					subscriber.next(user);
				},
				(error) => {
					subscriber.error(error);
				}
			);
		});
	}
	logout = () => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.signOut()
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					reject(this._handleError(error));
				});
		});
	};
}

export default new AuthPhoneService();
