import "assets/scss/popups/collectivelyAppeal/collectivelyAppeal.scss";
import "assets/scss/popups/sendMessage/sendMessage.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18next from "i18next";
import Text from "components/ui/Text";
import ErrorLabel from "components/ui/ErrorLabel";
import { Box, CircularProgress } from "@mui/material";
import RegisterBtnWrap from "components/register/RegisterBtnWrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteSelect from "components/ui/AutocompleteSelect";
import { getTags } from "redux/tags/selectors";
import useUser from "hooks/useUser";
import _ from "lodash";
import { phoneRegExp } from "helpers/regex";
import chatService from "services/chatService";
import { openNotification } from "redux/notification/actions";
import { closeDialogByType } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { ScrollToFieldError } from "helpers/FormikErrors";
import TextComponent from "components/ui/TextComponent";
import SiteButton from "components/ui/SiteButton";

export default function CollectivelyAppeal() {
	const dispatch = useDispatch();
	const [inProgress, setInProgress] = useState(false);
	const tags = useSelector(getTags);
	const { userProfile } = useUser();
	const [linkedTags, setLinkedTags] = useState([]);
	const [displayTags, setDisplayTags] = useState([]);

	const initialValues = {
		tags: [],
		eventsDate: "",
		name: userProfile?.name ?? "",
		phone: userProfile?.phoneToConnect
			? userProfile.phoneToConnect
			: userProfile?.phone
			? userProfile.phone
			: "",
		noteForTalent: "",
	};

	const validationSchema = Yup.object().shape({
		eventsDate: Yup.string().trim().required(i18next.t("validation.required")),
		name: Yup.string().trim().required(i18next.t("validation.required")),
		phone: Yup.string()
			.trim()
			.matches(phoneRegExp, i18next.t("validation.phone"))
			.required(i18next.t("validation.required")),
		noteForTalent: Yup.string().trim(),
		tags: Yup.array().required(i18next.t("validation.required")),
	});

	const changeTag = (values) => {
		formik.setFieldValue("tags", values);
	};

	// const handleSendCollectivelyAppeal = (e) => {
	// 	if (!userProfile || _.isEmpty(userProfile) || !userProfile.name) {
	// 		e.preventDefault();
	// 		dispatch(openDialog({ dialog: dialogsTypes.Login }));
	// 	}
	// };

	const onTagClick = (tag) => {
		const tags = formik.getFieldProps("tags").value;
		if (!tags.find((item) => item.key == tag.key)) {
			//if linked tag not in "tags" - then add tag
			const newTagsList = [...tags, tag];
			formik.setFieldValue("tags", newTagsList);
		}
	};

	const sendCollectivelyAppeal = async ({ ...values }) => {
		const tags = values.tags;
		if (!tags?.length) return;
		const eventsDate = values.eventsDate;
		const name = values.name;
		const phone = values.phone;
		const noteForTalent = values.noteForTalent;

		const data = { tags, eventsDate, name, phone, noteForTalent };

		const returnData = await chatService.addMassLetter(data);
		//returnData={newKey ,success}

		if (returnData.success) {
			dispatch(openNotification(i18next.t("notificationActions.messageSentSuccessfuly")));
			dispatch(closeDialogByType(dialogsTypes.CollectivelyAppeal));
		} else {
			dispatch(openNotification(i18next.t("notificationActions.messageFailed")));
		}
	};

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setInProgress(true);
			await sendCollectivelyAppeal(values);
			setInProgress(false);
		},
	});

	useEffect(() => {
		const listTags = formik.getFieldProps("tags").value;
		const listTagsFromTags = [];
		listTags.map((item) => {
			listTagsFromTags.push(tags.find((tag) => tag.key == item.key));
		});
		//need to add list from tag because "AutocompleteSelect" component
		setDisplayTags(listTagsFromTags);
		setLinkedTags([]);
		if (!!listTags && listTags.length) {
			const lastTag = listTags[listTags.length - 1];
			if (!!lastTag?.linkedTags && !!lastTag?.linkedTags.length) setLinkedTags(lastTag.linkedTags);
		}
	}, [formik.getFieldProps("tags").value]);

	return (
		<Box className="wrapSendMessageTo">
			<Box className=" center sendMessageTo">
				<span style={{ textAlign: "center", margin: "0 20px" }}>
					{i18next.t("work.sendCollectivelyAppeal")}
				</span>
			</Box>
			<form
				id="starter"
				onSubmit={formik.handleSubmit}
				style={{ overflow: "hidden", padding: "10px" }}>
				<Box className="boxWrapper">
					<span className="headLine">
						{i18next.t("work.whatLookingFor")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<Box className="tags">
						<AutocompleteSelect
							disableCloseOnSelect={false}
							placeholder={i18next.t("work.whichField")}
							data={tags}
							onChangeHandler={(value) => changeTag(value)}
							value={displayTags}
						/>
						{!formik.getFieldProps("tags")?.value?.length && (
							<ErrorLabel>{formik.touched.tags && i18next.t("validation.required")}</ErrorLabel>
						)}
					</Box>
				</Box>
				{!!linkedTags && !!linkedTags.length && (
					<Box className="boxWrapper">
						<Box>
							<span>{i18next.t("work.addMoreFieldsThatAreInteresting")}</span>
						</Box>

						{linkedTags.map((tag) => (
							<button
								key={tag.key}
								type="button"
								style={{
									margin: "5px 0 5px 5px",
									padding: "2px 15px",
									background: "#525bc2",
									borderRadius: "20px",
									border: "0",
									fontSize: "14px",
									fontWeight: "600",
									color: "#fff",
								}}
								onClick={() => onTagClick(tag)}>
								{tag.name}
							</button>
						))}
					</Box>
				)}
				<Box className="boxWrapper">
					<span>
						{i18next.t("work.eventsDate")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<TextComponent
						placeholder={i18next.t("work.describeEventsDate")}
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={4}
						id="eventsDate"
						value={"eventsDate"}
						{...formik.getFieldProps("eventsDate")}
					/>
					<ErrorLabel>{formik.touched.eventsDate && formik.errors.eventsDate}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>
						{i18next.t("work.name")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={2}
						id="name"
						value={"name"}
						{...formik.getFieldProps("name")}
					/>
					<ErrorLabel>{formik.touched.name && formik.errors.name}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>
						{i18next.t("work.phone")}
						<span style={{ color: "#ef2e2e" }}>{" *"}</span>
					</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={2}
						id="phone"
						value={"phone"}
						{...formik.getFieldProps("phone")}
					/>
					<ErrorLabel>{formik.touched.phone && formik.errors.phone}</ErrorLabel>
				</Box>
				<Box className="boxWrapper">
					<span>{i18next.t("work.noteForTalent")}</span>
					<TextComponent
						variant="standard"
						sx={{
							width: "100%",
							background: "#f8f8f8",
							color: "#4A4A4A",
							fontSize: "18px",
							fontWeight: "500",
							padding: "0 5px",
							borderRadius: "5px",
						}}
						multiline
						rows={2}
						id="noteForTalent"
						value={"noteForTalent"}
						{...formik.getFieldProps("noteForTalent")}
					/>
					<ErrorLabel>{formik.touched.noteForTalent && formik.errors.noteForTalent}</ErrorLabel>
				</Box>

				<Box className=" center sendWrapper">
					<span>
						{inProgress ? (
							<div
								style={{
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
									margin: "0 30px",
								}}>
								<CircularProgress size={40} sx={{ color: "#386BA8" }} />
							</div>
						) : (
							<SiteButton styledButton={{ type: "submit" }} text={i18next.t("work.sendMessage")} />
						)}
					</span>
				</Box>
				<ScrollToFieldError errors={formik.errors} />
			</form>
		</Box>
	);
}
