import firebase from "firebase/app";
import "firebase/functions";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";
import httpsCallable from "./httpsCallable";
const functions = firebase.functions();

class UploadImagesService {
	async upload(uid, imageName, file) {
		const result = await functions.httpsCallable("uploadImageToCloudinary")({
			uid,
			imageName,
			file,
		});
		return result.data;
	}

	async addImage(file, isProfileImage, imageName, code) {
		const result = await httpsCallable.post("addImage", {
			extraData: { file, isProfileImage, imageName, code },
		});

		return result;
	}
}

export default new UploadImagesService();
