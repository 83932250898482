import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useState, useEffect } from "react";
import i18next from "i18next";
import blockIcon from "assets/images/svgs/block.svg";
import reportIcon from "assets/images/svgs/report.svg";
import { useDispatch } from "react-redux";
import dialogsTypes from "consts/dialogsTypes";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import SettingListItem from "components/profile/settings/SettingListItem";
import SendReport from "./SendReport";
import functionsService from "services/functionsService";

export default function SwipeableTemporaryDrawer({ handleDrawer, closeDrawer, colleagueId }) {
	const [isOpen, setIsOpen] = useState(false);
	const [reportOpen, setReportOpen] = useState(false);
	const dispatch = useDispatch();

	const closeDrawerAndReport = () => {
		setReportOpen(false);
		closeDrawer();
	};
	const toggleDrawer = (open) => (event) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setIsOpen(open);
		!open && closeDrawerAndReport();
	};

	const handleDialogBlockUser = () => {
		closeDrawerAndReport();
		dispatch(
			openDialog({
				dialog: dialogsTypes.YesNo,
				question: i18next.t("blockUser.areUSure"),
				onYes: async () => {
					BlockUser();
					toggelClose();
				},
				onNo: toggelClose,
				yesBtn: "כן",
			})
		);
	};

	const toggelClose = () => {
		dispatch(closeDialogByType(dialogsTypes.YesNo));
	};

	const BlockUser = () => {
		if (!colleagueId) return;
		functionsService.blockUser(colleagueId);
	};
	const handleReportOpen = () => {
		setReportOpen(!reportOpen);
	};

	useEffect(() => {
		setIsOpen(handleDrawer);
	}, [handleDrawer]);

	return (
		<Box>
			<SwipeableDrawer
				anchor={"bottom"}
				open={isOpen}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}>
				<Box sx={{ width: "auto" }} role="presentation" onKeyDown={toggleDrawer(false)}>
					<List>
						<ListItem key={"חסום"} disablePadding onClick={handleDialogBlockUser}>
							<ListItemButton>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
									}}>
									<img src={blockIcon} alt="" />
									<Box
										sx={{
											marginLeft: "10px",
											color: "#252427",
											fontSize: "18px",
											fontWeight: "600",
										}}>
										{i18next.t("Chat.blockUser")}
									</Box>
								</Box>
							</ListItemButton>
						</ListItem>
						<ListItem key={"דווח"} disablePadding>
							<ListItemButton onClick={handleReportOpen}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										width: "100%",
									}}>
									<img src={reportIcon} alt="" />
									<Box
										sx={{
											marginLeft: "10px",
											color: "#252427",
											fontSize: "18px",
											fontWeight: "600",
										}}>
										{i18next.t("Chat.reportUser")}
									</Box>
								</Box>
							</ListItemButton>
						</ListItem>
					</List>
				</Box>
				{reportOpen && (
					<Box className="wrapReport">
						<List
							sx={{ width: "100%", background: "background.paper" }}
							component="nav"
							aria-labelledby="nested-list-subheader">
							<SettingListItem name={i18next.t("reportUser.spam")}>
								<Box sx={{ padding: "0 20px" }}>
									<SendReport
										closeDrawer={closeDrawerAndReport}
										colleagueId={colleagueId}
										type="spam"
									/>
								</Box>
							</SettingListItem>
							<SettingListItem name={i18next.t("reportUser.inappropriateMsg")}>
								<Box sx={{ padding: "0 20px" }}>
									<SendReport
										closeDrawer={closeDrawerAndReport}
										colleagueId={colleagueId}
										type="inappropriateMsg"
									/>
								</Box>
							</SettingListItem>
						</List>
					</Box>
				)}
			</SwipeableDrawer>
		</Box>
	);
}
