import produce from "immer";

const initialState = {
	dialogs: [],
};

export default produce((state, action) => {
	switch (action.type) {
		case "openDialog":
			const obj = { ...action.payload, number: state.dialogs.length };
			state.dialogs = [...state.dialogs, obj];
			break;
		case "closeAllDialogs":
			state.dialogs = [];
			break;
		case "closeDialog":
			const duplicateState = { ...state };
			duplicateState.dialogs.pop();
			state.dialogs = duplicateState.dialogs;

			break;
	}
}, initialState);
