import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import { useSelector } from "react-redux";
import { getTags } from "redux/tags/selectors";
import locationIcon from "assets/images/locationIcon.png";
import Image from "./Image";

export default ({}) => {
	const { userProfile } = useUser();

	return (
		<Box className="header-profile">
			<Box className="wrapHeaderProfileContent">
				<Box className="wrapHeaderImage">
					<Image></Image>
				</Box>
				<Box className="wrapHeaderTxt" sx={{ justifyContent: "start !important" }}>
					<h1
						style={{
							marginBottom: 0,
							color: "#313030",
							fontSize: "23px",
							fontWeight: "700",
							lineHeight: "1",
						}}>
						{userProfile.name}
					</h1>
					{!!userProfile?.title && (
						<h2
							style={{
								marginBottom: 0,
								color: "#313030",
								fontSize: "20px",
								fontWeight: "500",
								lineHeight: "0.9",
								marginTop: "10px",
							}}>
							{userProfile?.title}
						</h2>
					)}
					{/* {!!userProfile?.city_he && (
						<span
							style={{
								fontSize: "14px",
								lineHeight: "0.9",
								marginTop: "10px",
							}}>
							<img height={"12px"} style={{ margin: "0px 0px 4px 6px" }} src={locationIcon} />
							{userProfile.city_he}
						</span>
					)} */}
				</Box>
			</Box>
		</Box>
	);
};
