import { mergeMap, catchError, map, repeat, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import moment from "moment";

import LikesService from "services/likes";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import SearchService from "services/search";
import SearchQueryBuilderType from "consts/searchQueryBuilderType";
import ElasticSortOptions from "consts/elasticSortOptions";
import { getUserProfile } from "redux/profile/selectors";
import ProfileFields from "consts/profileFields";
import { getPitId, getSearchAfter } from "./selectors";
import PublicProfileService from "services/publicProfile";

export const fetchLikes = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(async () => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      try {
        const payload = await LikesService.fetchLikes(loggedInUserUID);
        return {
          type: types.LIKES_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.LIKES_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.LIKES_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchLikesUsers = (action$, store) =>
  action$.pipe(
    ofType(types.LIKES_USERS_FETCH, types.LIKES_USERS_FETCH_LOAD_MORE),
    mergeMap(async () => {
      const state = store.value;
      const profile = getUserProfile(state);
      const pitId = getPitId(state);
      const searchAfter = getSearchAfter(state);
      try {
        const payload = await SearchService.search(
          pitId,
          searchAfter,
          { userLocation: profile.location || profile[ProfileFields.CityLoc] },
          ElasticSortOptions.SortByLikedMeTimeStampDESC,
          SearchQueryBuilderType.Likes
        );

        payload.users = {};
        payload.items.map((user) => (payload.users[user.uid] = user));

        return {
          type: !pitId
            ? types.LIKES_USERS_FETCH_SUCCESSFULLY
            : types.LIKES_USERS_FETCH_LOAD_MORE_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.LIKES_USERS_FETCH_FAILED,
          error: error?.message,
        };
      }
    })
  );

export const fetchLikesAdded = (action$, store) =>
  action$.pipe(
    ofType(types.LIKES_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return LikesService.listenLikesAdded(uid).pipe(
        map((change) => {
          return {
            type: types.LIKES_ADDED,
            payload: change,
          };
        })
      );
    }),
    catchError((error) => {
      return of({
        type: types.LIKES_ADDED_ERROR,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );

export const fetchUserProfileForLikesAdded = (action$, store) =>
  action$.pipe(
    ofType(types.LIKES_ADDED),
    mergeMap(async ({ payload }) => {
      const uid = Object.keys(payload)[0];
      const user = await PublicProfileService.fetchProfile(uid);
      user.liked_me_timeStamp = moment.utc(payload[uid].timeStamp).format("YYYYMMDDhhmmss");

      return {
        type: types.LIKES_ADDED_FETCH_USER_PROFILE_SUCCESSFULLY,
        user,
      };
    }),
    catchError((error) => {
      return of({
        type: types.LIKES_ADDED_FETCH_USER_PROFILE_FAILED,
        payload: error?.message,
      });
    }),
    repeat()
  );

export const fetchLikesRemoved = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return LikesService.listenLikesRemoved(uid).pipe(
        map((change) => ({
          type: types.LIKES_REMOVED,
          payload: change,
        }))
      );
    }),
    catchError((error) => {
      return of({
        type: types.LIKES_REMOVED_ERROR,
        payload: error?.message,
      });
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    repeat()
  );
