import { useDispatch } from "react-redux";
import { search as searchAction } from "redux/search/actions";
import useQuery from "./useQuery";
import _ from "lodash";

export default function useSearch() {
	const { params } = useQuery();
	const dispatch = useDispatch();

	const getObjectSearchByParams = () => {
		if (params.tagsKeys && !_.isArray(params.tagsKeys)) {
			params.tagsKeys = params.tagsKeys.split(",");
		}
		if (params.freeText && !_.isArray(params.freeText)) {
			params.freeText = params.freeText.split(",");
		}
		if (params.area && !_.isArray(params.area)) {
			params.area = params.area.split(",");
		}
		return params;
	};

	const search = (cleanSearch = {}, sortOption = "") => {
		dispatch(searchAction(cleanSearch, sortOption));
	};

	return {
		search,
		getObjectSearchByParams,
	};
}
