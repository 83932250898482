import firebase from "firebase/app";
import "firebase/database";

class SubscriptionPlansService {
  BASE_REF = "subscription_plans";

  async fetchSubscriptionPlans() {
    return (
      await firebase
        .database()
        .ref(this.BASE_REF)
        .orderByChild("availableTo")
        .equalTo("users")
        .once("value")
    ).val();
  }
}

export default new SubscriptionPlansService();
