import { getCountryCallingCode, isValidNumber as isValid } from "libphonenumber-js";

const COUNTRY = "IL";

export const getPhoneWithPrefix = (phone) => {
  return `+${getCountryCallingCode(COUNTRY)} ${phone}`;
};

export const isValidNumber = (number) => {
  return isValid(number, COUNTRY);
};
