export const SUBSCRIPTIONS_FETCH_SUCCESSFULLY =
  "subscriptions.SUBSCRIPTIONS_FETCH_SUCCESSFULLY";
export const SUBSCRIPTIONS_FETCH_FAILED =
  "subscriptions.SUBSCRIPTIONS_FETCH_FAILED";
export const SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY =
  "subscriptions.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY";
export const SUBSCRIPTIONS_FETCH_PLANS_FAILED =
  "subscriptions.SUBSCRIPTIONS_FETCH_PLANS_FAILED";
export const SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM =
  "subscriptions.SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM";
export const SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY =
  "subscriptions.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY";
export const SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED =
  "subscriptions.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED";
export const SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED =
  "subscriptions.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED";
export const SUBSCRIPTIONS_CARDCOM_PURCHASE_SUCCESSFULLY =
  "subscriptions.SUBSCRIPTIONS_CARDCOM_PURCHASE_SUCCESSFULLY";
