import _ from "lodash";
import { createSelector } from "reselect";

import { getUserProfile } from "../profile/selectors";
import ProfileFields from "consts/profileFields";
import LoginSteps from "consts/loginSteps";
import Stance from "consts/stance";
import currentLanguage from "helpers/currentLanguage";

export const getAuth = (state) => {
	return state.auth;
};

export const getLoginPhone = createSelector(getAuth, (auth) => auth.loginPhone);

export const getLoginWithPhoneStatus = createSelector(getAuth, (auth) => auth.loginWithPhoneStatus);

export const getLoginWithPasswordStatus = createSelector(
	getAuth,
	(auth) => auth.loginWithPasswordStatus
);

export const getLoggedInUserUID = createSelector(getAuth, (auth) => auth.user?.uid);

export const getResendCodeStatus = createSelector(getAuth, (auth) => auth.resendCodeStatus);

export const getFacebookImageUrl = createSelector(
	getAuth,
	(auth) => auth.facebookData?.data?.picture.data.url
);

export const getLoginWithFacebookErrorMessage = createSelector(
	getAuth,
	(auth) => auth.loginWithFacebookErrorMessage
);

export const getFacebookAccessToken = createSelector(getAuth, (auth) => auth.facebookAccessToken);

export const getLoggedInUser = createSelector(getAuth, (auth) => {
	return auth.user;
});

export const isUserLoggedIn = createSelector(getAuth, (auth) => {
	return !!(auth.user && auth.user.uid);
});

export const getIsNewUser = createSelector(getAuth, (auth) => auth.isNewUser);

export const getLoginSteps = createSelector(
	getUserProfile,
	getUserProfile,
	(profile, _userProfile) => {
		if (_.isEmpty(profile)) {
			return [];
		}
		const conditions = getConditions(_userProfile);
		const result = Object.keys(conditions).filter((key) => conditions[key]);
		return result;
	}
);

export const getLoginWithPhoneErrorMessage = createSelector(
	getAuth,
	(auth) => auth.loginWithPhoneErrorMessage
);

export const getLoginWithGoogleStatus = createSelector(
	getAuth,
	(auth) => auth.loginWithGoogleStatus
);

const getConditions = (userProfile) => {
	return {
		[LoginSteps.Social]: _.isNil(userProfile[ProfileFields.Email]),
		[LoginSteps.Migration]: _.isNil(userProfile[ProfileFields.Email]),
		[LoginSteps.Name]: _.isNil(userProfile[ProfileFields.Name]),
		[LoginSteps.Gender]: _.isNil(userProfile[ProfileFields.Gender]),
		[LoginSteps.Age]: _.isNil(userProfile[ProfileFields.Dob]),
		[LoginSteps.MartialStatus]: _.isNil(userProfile[ProfileFields.MaritalStatus]),
		[LoginSteps.Children]: false, // _.isNil(userProfile[ProfileFields.HasChildren]),
		[LoginSteps.ChildrenCount]: false,
		// _.isNil(userProfile[ProfileFields.HasChildren]) ||
		// userProfile[ProfileFields.HasChildren] === children.Has,
		[LoginSteps.Stance]: _.isNil(userProfile[ProfileFields.Stance]),
		[LoginSteps.KeepShabbat]:
			_.isNil(userProfile[ProfileFields.Stance]) ||
			(userProfile[ProfileFields.Stance] === Stance.Mesorati &&
				_.isNil(userProfile[ProfileFields.KeepShabbat])),
		[LoginSteps.KosherFood]:
			_.isNil(userProfile[ProfileFields.Stance]) ||
			((userProfile[ProfileFields.Stance] === Stance.Mesorati ||
				userProfile[ProfileFields.Stance] === Stance.Datlash) &&
				_.isNil(userProfile[ProfileFields.EatKosherFood])),
		[LoginSteps.Height]: _.isNil(userProfile[ProfileFields.Height]),
		[LoginSteps.AboutMe]: _.isNil(userProfile[ProfileFields.AboutMe]),
		[LoginSteps.LivingCity]: _.isNil(userProfile[ProfileFields.Cites[currentLanguage()]]),
		[LoginSteps.UploadImage]: _.isNil(userProfile[ProfileFields.Pictures]),
	};
};

export const getIsAuthChecked = createSelector(getAuth, (auth) => auth.isAuthChecked);
