import firebase from "firebase/app";
import "firebase/auth";
import * as types from "../auth/actionTypes";
import QueueService from "services/queue";
import { getLoggedInUserUID } from "./selectors";
import AuthEvents from "consts/authEvents";
import AuthPhoneService from "services/authPhone";
import AuthPasswordService from "services/authPassword";
import { setSearchFilters } from "redux/search/actions";
import UsersTypes from "consts/UsersTypes";

export const loginWithPhone = (phone) => {
	return { type: types.AUTH_LOGIN_WITH_PHONE, phone };
};

export const loginWithToken = (token) => {
	return { type: types.AUTH_LOGIN_WITH_TOKEN, token };
};

export const loginWithPassword = (email, password) => {
	return async (dispatch) => {
		const result = await AuthPasswordService.login(email, password);
		if (result.data) {
			dispatch({ type: types.AUTH_LOGIN_WITH_PASSWORD_SUCCESSFULLY });
		} else {
			dispatch({
				type: types.AUTH_LOGIN_WITH_PASSWORD_FAILED,
				error: "user not exists",
				credentials: { email, password },
			});
		}
	};
};

export const verifyPhone = (verificationCode, type = UsersTypes.Client) => {
	setLoginChecked(false);
	return { type: types.AUTH_LOGIN_WITH_PHONE_VERIFICATION, verificationCode, typeUser: type };
};

export const resetLoginWithPhone = () => {
	return { type: types.AUTH_LOGIN_WITH_PHONE_RESET };
};

export const resendCode = (phone) => {
	return { type: types.AUTH_LOGIN_WITH_PHONE_RESEND_CODE, phone };
};

export const initApp = () => {
	return { type: types.AUTH_APP_INIT };
};

export const setLoginChecked = (checked) => {
	return { type: types.AUTH_LOGIN_SET_CHECKED, checked };
};

export const logOut = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const uid = getLoggedInUserUID(state);
		await QueueService.sendEvent(uid, AuthEvents.Disconect);
		await AuthPhoneService.logout();
		setSearchFilters({});
		dispatch({ type: types.AUTH_LOG_OUT });
	};
};

export function registerWithFacebook() {
	return { type: types.AUTH_REGISTER_WITH_FACEBOOK };
}

export function registerWithGoogle() {
	return { type: types.AUTH_REGISTER_WITH_GOOGLE };
}

export function registerWithApple() {
	return { type: types.AUTH_REGISTER_WITH_APPLE };
}
