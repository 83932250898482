import "assets/scss/tabsRouter/tabsRouter.scss";

import Box from "@mui/material/Box";

import i18next from "i18next";
import MultiUploadImages from "components/ui/MultiUploadImages";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import Button from "components/ui/Button";
import EditImages from "./EditImages";
import LightBox from "components/lightBox/LightBox";
import useUser from "hooks/useUser";

export default function MyImages({ withoutHeader = false }) {
	const { userProfile } = useUser();
	const [openLightBox, setOpenLightBox] = useState(false);
	const [imgIndex, setImgIndex] = useState(0);
	const [images, setImages] = useState([]);

	const handleImgIndex = (index) => {
		setImgIndex(index);
	};
	const handleArrImages = (images) => {
		setImages(images);
	};
	const handleopenLightBox = (open) => {
		setOpenLightBox(open);
	};

	return (
		<Box className="editAccountMyImages">
			{/* {!withoutHeader && (
				<Box
					style={{
						margin: "0 0 20px",
						display: "flex",
						justifyContent: "start",
						fontSize: "17px",
						fontWeight: 600,
					}}>
					{i18next.t("settings.myImages")}
				</Box>
			)} */}

			<EditImages
				user={userProfile}
				handleopenLightBox={handleopenLightBox}
				handleArrImages={handleArrImages}
				handleImgIndex={handleImgIndex}
			/>
			{openLightBox && (
				<LightBox
					images={images}
					imgIndex={imgIndex}
					isOpen={openLightBox}
					setUpdateOpenLightBox={setOpenLightBox}
				/>
			)}
		</Box>
	);
}
