import produce from "immer";

import * as types from "./actionTypes";

const initialState = {};

export default produce((state, action) => {
  switch (action.type) {
    case types.SHOW_POPUP:
      if (action.payload?.popupUnderPopup) {
        action.payload.popupUnderPopupType = action.payload.popupType;
      }
      if (action.payload?.secondPopupUnderPopup) {
        action.payload.secondPopupUnderPopupType = action.payload.popupType;
      }
      return (state.popupUnderPopupType &&
        action.payload.popupUnderPopupType !== false) ||
        (state.secondPopupUnderPopupType &&
          action.payload.secondPopupUnderPopupType !== false)
        ? { ...state, ...action.payload }
        : action.payload;
  }
}, initialState);
