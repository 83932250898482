import "assets/scss/profile/profileSettings/profileSettings.scss";
import { Box, CircularProgress } from "@mui/material";
import List from "@mui/material/List";
import useUser from "hooks/useUser";
import AccountSettings from "./accountSettings";
import SettingListItem from "./SettingListItem";
import i18next from "i18next";

import { useEffect, useState } from "react";
import blocks from "services/blocks";
import _ from "lodash";
import publicProfile from "services/publicProfile";
import UserBlockedPage from "pages/UserBlockedPage";
import { useDispatch } from "react-redux";
import { logOut } from "redux/auth/actions";
import useNavigator from "hooks/useNavigator";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import Notifications from "./Notifications";

export default ({}) => {
	const dispatch = useDispatch();
	const navigate = useNavigator();
	const { user } = useUser();

	const { userProfile } = useUser();
	const [listBlockUsers, setListBlockUsers] = useState([]);
	const [loader, setLoader] = useState(true);

	const logOutUser = () => {
		dispatch(logOut()).then(() => {
			navigate.redirectToHomePage();
		});
	};

	useEffect(async () => {
		if (userProfile?.uid) {
			await blocks.fetchBlocks(userProfile.uid).then((users) => {
				if (!_.isEmpty(users)) setListBlockUsers([]);

				Promise.all(
					Object.keys(users).map(async (Uids) => {
						return await publicProfile.fetchProfile(Uids);
					})
				).then((users) => {
					setListBlockUsers(users);
					setLoader(false);
				});
			});
		}
	}, [userProfile]);

	useEffect(async () => {
		if (!!listBlockUsers?.length) {
		}
	}, [listBlockUsers]);

	return (
		<>
			{user && user.uid && (
				<Box className="profile-setting">
					<List
						sx={{ width: "100%", background: "background.paper", minHeight: "55vh" }}
						component="nav"
						aria-labelledby="nested-list-subheader">
						<SettingListItem name={i18next.t("settings.account")}>
							<AccountSettings
								logOutUser={logOutUser}
								user={user}
								sx={{ width: "100%", background: "#C6C6C6" }}
							/>
						</SettingListItem>
						<SettingListItem name={i18next.t("settings.system")}>
							<Notifications />
						</SettingListItem>
						<SettingListItem name={i18next.t("settings.blocks")}>
							{loader ? (
								<Box className="center" sx={{ padding: "30px" }}>
									<CircularProgress size={40} sx={{ color: "#168187" }} />
								</Box>
							) : (
								<UserBlockedPage blockUsers={listBlockUsers} userProfile={userProfile} />
							)}
						</SettingListItem>
						{/* <SettingListItem name={i18next.t("settings.settingsPrivacy")}>
							<PrivacySetting />
						</SettingListItem>
						<SettingListItem name={i18next.t("settings.settingsDisplayFiltering")}>
							<FillterSettings />
						</SettingListItem> */}
						<Box sx={{ margin: "5px 15px" }}>
							<span
								style={{ color: "#c6c6c6", fontWeight: "600", cursor: "pointer" }}
								onClick={logOutUser}>
								{i18next.t("logOut")}
							</span>
						</Box>
					</List>

					<Box className="footerSetting">
						<Box className="boxFooter">{i18next.t("settings.sixDays")}</Box>
						<Box className="boxFooter">{i18next.t("settings.sixDaysContent")} 1.0</Box>
						<Box className="boxFooter terms">
							<a
								onClick={() => navigate.navigateToContactPage()}
								style={{
									padding: "0 5px",
									cursor: "pointer",
									textDecoration: "none",
									color: "#fff",
								}}>
								{i18next.t("settings.contactUs")}
							</a>
							|
							<a
								style={{ padding: "0 5px", cursor: "pointer" }}
								onClick={() => {
									dispatch(openDialog({ dialog: dialogsTypes.Terms }));
								}}>
								{i18next.t("settings.terms")}
							</a>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};
