import produce from "immer";
import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import ApiRequestStatus from "consts/apiRequestStatus";

const initialState = {
	status: ApiRequestStatus.INIT,
	data: null,
	selectedChat: "",
	selectedListForAction: {},
};

export default produce((state, action) => {
	switch (action.type) {
		case types.CHATS_FETCH_SUCCESSFULLY:
			return { ...state, data: action.payload };
		case types.CHATS_ADDED:
			const data = { ...state.data };
			data[action.payload.key] = action.payload;
			return { ...state, data };
		case types.CHATS_REMOVED:
			delete state["data"][action.payload];
			break;

		case authTypes.AUTH_LOG_OUT:
			return initialState;

		case types.CHATS_SET_SELECTED_LIST:
			let obj = { ...state["selectedListForAction"] };
			const id = action.payload.id;
			const isPin = action.payload.isPin;
			if (obj[id]) {
				delete obj[id];
			} else {
				obj[id] = { id, isPin: isPin };
			}
			return { ...state, selectedListForAction: obj };
		case types.CHATS_RESET_SELECTED_LIST:
			return { ...state, selectedListForAction: initialState.selectedListForAction };
		case types.CHATS_SET_SELECTED:
			return { ...state, selectedChat: action.payload };
		case types.CHATS_CLEAN_SELECTED:
			return { ...state, selectedChat: initialState.selectedChat };

		case types.CHATS_CHANGED:
			const payload = action.payload;
			let updatedObj = { ...payload, user: state["data"][payload.key].user };
			let newData = { ...state.data, [payload.key]: updatedObj };
			return { ...state, data: newData };
	}
}, initialState);
