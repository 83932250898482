import { Box } from "@mui/material";
import Loader from "components/Loader";
import ViewTalents from "components/searchTalent/ViewTalents";
import SiteClientBanner from "components/siteBunner/SiteClientBanner";
import SiteTalentBanner from "components/siteBunner/SiteTalentBanner";
import UsersTypes from "consts/UsersTypes";
import useSearch from "hooks/useSearch";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import functionsService from "services/functionsService";
import SearchService from "services/search";

export default function Favorite() {
	const [favoritesUsers, setFavoritesUsers] = useState([]);
	const [favoritesUids, setFavoritesUids] = useState([]);
	const [noFavoriteResults, setNoFavoriteResults] = useState(false);
	const { user, userProfile } = useUser();

	useEffect(async () => {
		if (!user.uid) return setNoFavoriteResults(true);
		const data = [];
		await functionsService.getFavorite(user.uid).then((snapshot) => {
			if (snapshot.val()) data.push(Object.keys(snapshot.val()));
		});

		if (data[0] == undefined) return setNoFavoriteResults(true);
		setFavoritesUids(data[0]);
	}, []);

	useEffect(async () => {
		if (favoritesUids !== undefined && favoritesUids.length) {
			const favirites = await SearchService.search(
				null,
				null,
				{
					freeText: "",
					userFilters: { uid: favoritesUids },
					userLocation: "",
				},
				"",
				"Search"
			);

			setFavoritesUsers(favirites?.items);
		}
	}, [favoritesUids]);
	return (
		<>
			{favoritesUsers && !!favoritesUsers.length && (
				<Box sx={{ padding: "20px", background: "#f8f8f8" }}>
					<ViewTalents
						users={favoritesUsers}
						onChange={() => {}}
						breakpointColumns={{ default: 1 }}
					/>
				</Box>
			)}
			{favoritesUsers && !favoritesUsers.length && !noFavoriteResults && (
				<Box className="center wrapLoader" sx={{ width: "100%", height: "90vh" }}>
					<Loader />
				</Box>
			)}
			{/* {!favoritesUsers.length && noFavoriteResults && (
				<Box className="center" sx={{ width: "100%" }}>
					{userProfile.type === UsersTypes.Talent ? (
						<Box className="center" sx={{ mt: 5 }}>
							<SiteTalentBanner />
						</Box>
					) : (
						<>
							{
								// <Box className="center" sx={{ mt: 5 }}>
								// 	<SiteClientBanner />
								// </Box>
							}
						</>
					)}
				</Box>
			)} */}
		</>
	);
}
