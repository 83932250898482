import firebase from "firebase/app";
import "firebase/database";
import { orderBy, toPairs } from "lodash";
import { Observable } from "rxjs";

class FaqsService {
	BASE_REF = "faq";

	async getFaqs() {
		const faqsData = (await firebase.database().ref(this.BASE_REF).once("value")).val() || {};
		return Object.values(faqsData || {});
	}
}

export default new FaqsService();
