import React from "react";
import moment from "moment";
import i18next from "i18next";

import { isSameDay } from "helpers/date";
import { dateFormat } from "consts/date";

export default ({ currentMessage, previousMessage, classDay }) => {
	const addDateBeforeMessage =
		currentMessage && !isSameDay(currentMessage, previousMessage) ? true : false;
	const isToday = moment(currentMessage.updated).isSame(new Date(), "day");
	const isYesterday = moment(currentMessage.updated).isSame(moment().add(-1, "days"), "day");

	return (
		<>
			{addDateBeforeMessage && (
				<div className={classDay ? `${classDay} dayDivider` : `dayDivider`}>
					<span>
						{isToday
							? i18next.t("Today")
							: isYesterday
							? i18next.t("Yesterday")
							: moment(currentMessage.updated).format("DD.MM.YYYY")}
					</span>
				</div>
			)}
		</>
	);
};
