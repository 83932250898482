import { Box, CircularProgress } from "@mui/material";
import Button from "components/ui/Button";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import defaultProfile from "../../assets/images/svgs/darkAvatar.svg";
import useNavigator from "hooks/useNavigator";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { getContactsMatchingByTalentId } from "redux/contacts/selectors";
import { useEffect, useState } from "react";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";
import SiteButton from "components/ui/SiteButton";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default ({ talentId, isWeb = false, setFriendsNumber = () => {} }) => {
	const dispatch = useDispatch();
	const { userProfile, isLoggedIn } = useUser();
	const contactsMatchingObject = useSelector(getContactsMatchingByTalentId(talentId));
	const [contactsMatchingArr, setContactsMatchingArr] = useState(
		Object.values(contactsMatchingObject || {})
	);
	const [loading, setLoading] = useState(true);

	const handleOpenLoginPopup = async () => {
		if (talentId) await functionsService.addTalentAction(talentId, talentAction.clickFriends);

		dispatch(openDialog({ dialog: dialogsTypes.Login }));
	};

	const handleOpenDownloadAppPopup = async () => {
		dispatch(openDialog({ dialog: dialogsTypes.DownloadApp }));
	};

	const addContactMatching = async () => {
		return functionsService.getSharedContactFriends(talentId);
	};

	useEffect(async () => {
		if (!talentId) return;
		if (!!userProfile.uid || !!userProfile?.syncContactsToNeo4j) {
			const res = addContactMatching(userProfile.uid);
			res.then((data) => {
				if (data?.data && !!data.data?.data?.length) {
					setContactsMatchingArr(data.data.data);
					if (!!isWeb) {
						setFriendsNumber(data.data.data.length);
					}
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	}, [userProfile.syncContactsToNeo4j]);

	return (
		<>
			{isLoggedIn ? (
				<>
					{!userProfile?.syncContactsToNeo4j ? (
						<Box sx={{ margin: "30px 0" }}>
							<Box className="center contactsText">
								{i18next.t("talent.toDisplaySharedFriends")}
							</Box>
							<Box className="center" sx={{ margin: "10px 0" }}>
								<SiteButton
									onClickBtn={handleOpenDownloadAppPopup}
									text={i18next.t("talent.openDownloadApp")}></SiteButton>
							</Box>
						</Box>
					) : (
						<Box className="wrapAcquaintances">
							{!isWeb && (
								<Box className="subheading">{`${
									(!!contactsMatchingArr?.length && contactsMatchingArr.length) || ""
								} ${i18next.t("talent.mutual acquaintances")}`}</Box>
							)}
							{!!loading ? (
								<Box className="">
									<CircularProgress size={40} sx={{ color: "#168187" }} />
								</Box>
							) : (
								<>
									{!contactsMatchingArr.length && (
										<Box className="dontFoundMaching">{i18next.t("talent.dontFoundMaching")}</Box>
									)}
									<Box className="center">
										{!!contactsMatchingArr?.length && (
											<Swiper
												style={{ padding: "0 10px", width: "100%" }}
												slidesPerView={"auto"}
												spaceBetween={10}
												pagination={{
													clickable: true,
												}}
												modules={[Pagination]}
												className="mySwiper">
												{contactsMatchingArr.map((item) => {
													return (
														<SwiperSlide style={{ width: "min-content" }}>
															{
																<a
																	className="center"
																	style={{
																		textDecoration: "none",
																		color: "#000",
																		flexDirection: "column",
																	}}
																	onClick={async (e) => {
																		if (talentId)
																			await functionsService.addTalentAction(
																				talentId,
																				talentAction.clickPhoneFriend
																			);
																	}}
																	href={`tel:${item.phone}`}>
																	<img
																		key={item.phoneId}
																		src={item.photo ? item.photo : getFirstCharImg(item)}
																		height="50px"
																		width="50px"
																	/>
																	<span>{item.name}</span>
																</a>
															}
														</SwiperSlide>
													);
												})}
											</Swiper>
										)}
									</Box>
								</>
							)}
						</Box>
					)}
				</>
			) : (
				<Box sx={{ margin: "30px 0" }}>
					<Box className="center contactsText">
						{i18next.t("talent.contacts between you and the customer")}
					</Box>
					<Box className="center" sx={{ margin: "10px 0" }}>
						<SiteButton
							onClickBtn={handleOpenLoginPopup}
							text={i18next.t("talent.login to see mutual acquaintances")}></SiteButton>
					</Box>
				</Box>
			)}
		</>
	);
};
