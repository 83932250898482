import "assets/scss/profile/profileWeb.scss";
import { Box, CircularProgress } from "@mui/material";
import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import i18next from "i18next";
import ProfilePage, { ProfileBtns } from "./ProfilePage";
import SwitchInnerProfileWeb from "components/profile/SwitchInnerProfileWeb";
import { useLocation } from "react-router-dom";
import functionsService from "services/functionsService";
import { useDispatch } from "react-redux";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import Image from "components/profile/Image";
import UsersTypes from "consts/UsersTypes";

export default () => {
	const { userProfile } = useUser();
	const [innerPage, setInnerPage] = useState(null);
	const [innerWidth, setInnerWidth] = useState(null);
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const innerProfilePages = [
		"settings",
		"favorites",
		"myRecomendationOnSuppliers",
		"recommendations",
		"myBusinessCard",
		"editAccount",
		"myImages",
		"statistics",
	];

	useEffect(() => {
		setInnerWidth(window.innerWidth);
	}, [innerPage]);

	useEffect(() => {
		functionsService.setOnline();
		if (pathname.includes("/profile/")) {
			let newPath = pathname.replace("/profile/", "");
			if (newPath.includes("/")) {
				newPath = newPath.replaceAll("/", "");
			}
			if (newPath == "myBusinessCard") {
				if (!userProfile?.uid) return;
				return dispatch(
					openDialog({
						dialog: dialogsTypes.ViewTalent,
						talentId: userProfile.uid,
						talent: userProfile,
					})
				);
			}

			if (innerProfilePages.includes(newPath)) setInnerPage(newPath);
		}
	}, [pathname]);

	return (
		<>
			{userProfile.uid && (
				<Box className="wrapProfileWebPage">
					<header className="profileWebHeader">
						<Box className="wrapHeaderProfileContent">
							<Box className="wrapHeaderImage">
								<Image></Image>
							</Box>
							<Box className="wrapHeaderTxt" sx={{ justifyContent: "start !important" }}>
								<h1>{userProfile.name}</h1>
								{!!userProfile?.title && <h2>{userProfile?.title}</h2>}
							</Box>
						</Box>
						{userProfile.type === UsersTypes.Talent && <ProfileBtns userProfile={userProfile} />}
					</header>
					<content className="innerProfileWebPage">
						<Box className="borderColumns profileColl1">
							<ProfilePage webPage={true} setInnerPage={setInnerPage} innerPage={innerPage} />
						</Box>

						<Box className={"borderColumns profileColl2"}>
							{innerPage && (
								<>
									<Box className="innerProfilePage">
										{/* <Box className="innerProfileHeader">
											{innerPage.includes("/user/recomendation")
												? i18next.t(`profile.myRecomendationOnSuppliers`)
												: innerPage.includes("recommendations")
												? i18next.t("profile.recommendationsOnMe")
												: i18next.t(`profile.${innerPage}`)}
										</Box> */}
										<SwitchInnerProfileWeb innerPage={innerPage} />
									</Box>
								</>
							)}
						</Box>
					</content>
				</Box>
			)}
		</>
	);
};
