import { mergeMap, catchError, repeat, map } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of, first } from "rxjs";
import { sortBy } from "lodash";

import SubscriptionService from "services/subscription";
import * as types from "./actionTypes";
import * as AuthTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import SubscriptionPlansService from "services/subscriptionPlans";
import HTTPFunctionService from "services/httpFunction";
import AdminActionService from "services/adminAction";
import { purchaseSuccessfully } from "./actions";

export const fetchSubscription = (action$, store) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(() => {
			const state = store.value;
			const loggedInUserUID = getLoggedInUserUID(state);
			return SubscriptionService.fetchUserSubscription(loggedInUserUID).pipe(
				map((payload) => ({
					type: types.SUBSCRIPTIONS_FETCH_SUCCESSFULLY,
					payload,
				}))
			);
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_FETCH_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchSubscriptionPlans = (action$) =>
	action$.pipe(
		ofType(AuthTypes.AUTH_LOGGED_IN),
		mergeMap(async () => {
			try {
				const plans = await SubscriptionPlansService.fetchSubscriptionPlans();
				const subscriptionsPlansNotSorted = Object.keys(plans || {}).map((key) => ({
					...plans[key],
					key,
				}));
				const payload = sortBy(subscriptionsPlansNotSorted, "index");
				return {
					type: types.SUBSCRIPTIONS_FETCH_PLANS_SUCCESSFULLY,
					payload,
				};
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_FETCH_PLANS_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_FETCH_PLANS_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const fetchCardCom = (action$) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_PURCHASE_WITH_CARDCOM),
		mergeMap(async ({ plan, showSuccessfullyPopup }) => {
			try {
				const response = await HTTPFunctionService.getCardcomPageLink(plan.key);

				return {
					type: types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY,
					response,
					showSuccessfullyPopup,
				};
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);

export const listenerCardcomPurchase = (action$, store) =>
	action$.pipe(
		ofType(types.SUBSCRIPTIONS_GET_CARDCOM_RESPONSE_SUCCESSFULLY),
		mergeMap(({ response, showSuccessfullyPopup }) => {
			try {
				const state = store.value;
				const userUid = getLoggedInUserUID(state);
				return AdminActionService._listenToResponse(userUid, response.responseKey, true).pipe(
					first(),
					map((finishPurchaseResponse) =>
						purchaseSuccessfully(finishPurchaseResponse, showSuccessfullyPopup)
					)
				);
			} catch (error) {
				return {
					type: types.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED,
					payload: error?.message,
				};
			}
		}),
		catchError((error) => {
			return of({
				type: types.SUBSCRIPTIONS_CARDCOM_PURCHASE_FAILED,
				payload: error?.message,
			});
		}),
		repeat()
	);
