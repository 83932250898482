import { createSelector } from "reselect";

export const getTags = (state) => state?.tags?.tags || [];

export const getTagsKeys = createSelector(getTags, (tags) => {
	return tags.map((item) => item.key);
});

export const getTagsCategory = createSelector(getTags, (tags) => {
	return tags.map((item) => item.key);
});

export const getTagsByCategoryKey = (categoryKey) => {
	return createSelector(getTags, (tags) => {
		if (categoryKey) return tags.filter((item) => item.categoryKey === categoryKey);
		else return null;
	});
};

export const getTagsByParams = (paramsTags = "") => {
	if (!Array.isArray(paramsTags)) {
		paramsTags = paramsTags.split(",");
	}
	return createSelector(getTags, (tags) => {
		return tags.filter((item) => paramsTags.includes(item.urlName));
	});
};

export const getPopularTags = () => {
	return createSelector(getTags, (tags) => {
		if (!tags) return [];
		return tags.filter((item) => item?.popular);
	});
};
