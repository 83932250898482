import "assets/scss/landing/landingFAQs.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import faqsService from "services/faqsService";
import { useEffect } from "react";
import { useState } from "react";
import i18next from "i18next";
import LandingColorizedTitle from "./LandingColorizedTitle";

export default function LandingFAQs() {
	const [faqs, setFaqs] = useState([,]);

	useEffect(() => {
		const getFaqs = async () => {
			const faqsData = await faqsService.getFaqs();
			if (!faqsData?.length) return;
			setFaqs(faqsData.filter((item) => !item?.deleted));
		};
		getFaqs();
	}, []);

	return (
		<Box className="landingFAQs">
			<LandingColorizedTitle
				text={i18next.t("landingPage.questions")}
				colorText={i18next.t("landingPage.answers")}
			/>
			<Box className="wrapFAQs">
				{!!faqs &&
					!!faqs?.length &&
					faqs.map((item) => (
						<Accordion key={item.key}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header">
								<Typography>{item.question}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography>{item.answer}</Typography>
							</AccordionDetails>
						</Accordion>
					))}
			</Box>
		</Box>
	);
}
