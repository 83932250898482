export default {
	[""]: "",
	["01"]: "ינואר",
	["02"]: "פברואר",
	["03"]: "מרץ",
	["04"]: "אפריל",
	["05"]: "מאי",
	["06"]: "יוני",
	["07"]: "יולי",
	["08"]: "אוגוסט",
	["09"]: "ספטמבר",
	["10"]: "אוקטובר",
	["11"]: "נובמבר",
	["12"]: "דצמבר",
};
