import "assets/scss/userBlocked/userBlocked.scss";
import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import defaultProfile from "assets/images/svgs/darkAvatar.svg";
import i18next from "i18next";
import functionsService from "services/functionsService";
import { getFirstCharImg } from "helpers/getFirstCharImg";

export default ({ blockUsers = [], userProfile = {} }) => {
	return (
		<Box className="wrapUserBlockPage">
			{blockUsers?.length ? (
				blockUsers.map((item) => <UserBlockLine key={item.key} item={item} />)
			) : (
				<Box className="userBlockLine">{i18next.t("settings.noBlocks")}</Box>
			)}
		</Box>
	);
};

const UserBlockLine = ({ item }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isUserBlock, setIsUserBlock] = useState(true);

	const handleUnBlock = async () => {
		setIsLoading(true);

		await unBlockUser();
		setIsUserBlock(!isUserBlock);

		setIsLoading(false);
	};
	const handleBlock = async () => {
		setIsLoading(true);

		await BlockUser();
		setIsUserBlock(!isUserBlock);

		setIsLoading(false);
	};

	const BlockUser = async () => {
		if (!item?.uid) return;
		await functionsService.blockUser(item.uid);
	};
	const unBlockUser = async () => {
		if (!item?.uid) return;
		await functionsService.unBlockUser(item.uid);
	};

	return (
		<Box className="center userBlockLine">
			<Box className="imgAndContent">
				<img
					src={item?.profileImage?.url ? item?.profileImage?.url : getFirstCharImg(item)}
					width={50}
				/>
				<Box className="center content">
					<span className="name">{item?.name ? item?.name : ""}</span>
					<span>{item?.title ? item?.title : ""}</span>
				</Box>
			</Box>
			<Box className="btns">
				{!isLoading ? (
					isUserBlock ? (
						<Button className="btnUnBlock" onClick={handleUnBlock} fontWeight={700}>
							{i18next.t("settings.unBlock")}
						</Button>
					) : (
						<Button className="btnBlock" onClick={handleBlock} fontWeight={700}>
							{i18next.t("settings.block")}
						</Button>
					)
				) : (
					<Box className="center" sx={{ padding: "0 30px" }}>
						<CircularProgress size={30} sx={{ color: isUserBlock ? "green" : "red" }} />
					</Box>
				)}
			</Box>
		</Box>
	);
};
