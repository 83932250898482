import "assets/scss/popups/yesNo/yesNo.scss";
import i18next from "i18next";
import { Box } from "@mui/material";
import Button from "components/ui/Button";

export default function YesNoPopup({ data }) {
	const handleYes = () => {
		data.onYes();
	};
	return (
		<Box>
			<Box
				style={{
					margin: "20px 0",
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontWeight: 700,
				}}>
				{data.question}
			</Box>
			<Box
				className=""
				style={{
					display: "flex",
					justifyContent: "space-evenly",
					alignItems: "center",
					margin: "20px 0",
					padding: "10px",
					width: "100%",
				}}>
				<Button
					sx={{
						borderRadius: "25px",
						padding: "0px 20px",
						border: "2px solid #386BA8 !important",
					}}
					size="18px"
					className="freelancer-btn-no"
					onClick={handleYes}
					fontWeight={600}>
					{data.yesBtn ? data.yesBtn : i18next.t("popup.delete")}
				</Button>
				<Button
					variant=""
					sx={{
						background: "#386BA8 !important",
						borderRadius: "25px",
						padding: "0px 20px",
					}}
					size="18px"
					className="freelancer-btn-yes"
					onClick={data.onNo}
					fontWeight={600}>
					{i18next.t("popup.no")}
				</Button>
			</Box>
		</Box>
	);
}
