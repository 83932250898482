import useUser from "hooks/useUser";
import { useState } from "react";
import _ from "lodash";

import useNavigator from "hooks/useNavigator";

export default () => {
	const [registerStep, setRegisterStep] = useState(null);
	const { isLoggedIn, userProfile } = useUser();
	const navigator = useNavigator();

	return (
		<>
			{userProfile.registerStep}
			{userProfile && userProfile.registerStep === 5 && <div>finish</div>}111
		</>
	);
};
