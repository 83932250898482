import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import ViewTalent from "components/talent/ViewTalent";

export default () => {
	const { userProfile } = useUser();
	return (
		<Box className="talentHeader">
			<ViewTalent talentId={userProfile?.uid} isChatWeb={true} />
		</Box>
	);
};
