import { Button } from "@mui/material";
import { Box } from "@mui/system";
import ViewTalent from "components/talent/ViewTalent";
import backDescPopup from "assets/images/backDescPopup.png";

import { Link, useParams } from "react-router-dom";
import httpsCallable from "services/httpsCallable";
import { useEffect, useState } from "react";
import useNavigator from "hooks/useNavigator";
import BaseHelmet from "components/seo/BaseHelmet";
import i18next from "i18next";
import { isMobile } from "react-device-detect";
import UserWebPopup from "components/searchTalent/UserWebPopup";

export default () => {
	let { id } = useParams();
	const [talentProfile, setTalentProfile] = useState({});
	const navigate = useNavigator();

	useEffect(async () => {
		const result = await httpsCallable.post("getTalent", {
			extraData: {
				talentId: id,
			},
		});
		setTalentProfile(result?.data?.data?.items[0]);
	}, []);

	useEffect(async () => {
		if (!talentProfile) {
			navigate.navigateToHomePage();
		}
	}, [talentProfile]);

	return (
		<>
			<BaseHelmet
				title={`${
					talentProfile?.name ? talentProfile?.name : ""
				} - פרטי הקשר והמלצות של לקוחות באתר ששת ימים`}
				description={
					talentProfile?.title ? talentProfile?.title : i18next.t("seo.homePage.description")
				}
				url={`talent/${talentProfile?.internalID}`}></BaseHelmet>

			{!isMobile && (
				<Link
					to={"/"}
					style={{
						textDecoration: "none",
					}}>
					<button
						style={{
							width: "50px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							outline: "0",
							border: "0",
							background: "#ffffff00",
							position: "absolute",
							top: "30px",
							right: "30px",
							zIndex: "3",
						}}>
						<img src={backDescPopup} alt="" />
					</button>
				</Link>
			)}
			{isMobile ? (
				<ViewTalent talentId={id} telentFromOutSide={talentProfile}></ViewTalent>
			) : (
				<UserWebPopup talent={talentProfile} talentId={id} noBack={true} withoutTop={true} />
			)}
		</>
	);
};
