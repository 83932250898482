import * as Yup from "yup";
import { Box, Switch } from "@mui/material";
import i18next from "i18next";
import EditTextLine from "./editLineHelpers/EditTextLine";
import profileFields from "consts/profileFields";
import { useState } from "react";
import { phoneRegExp } from "helpers/regex";

export default function CustomersContactMe({ user, handleSave = () => {} }) {
	return (
		<>
			{!!user?.uid && (
				<Box className="inner-section account-details">
					<h3 className="edit-title">{i18next.t("editProfile.howWillCustomersContactMe")}</h3>
					<Box className="union-line switch-line">
						<EditTextLine
							keyField={profileFields.PhoneToConnect}
							valueField={user[profileFields.PhoneToConnect]}
							handleSave={handleSave}
							fieldName={i18next.t("register.phoneToConnect")}
							formikValidation={{
								phoneToConnect: Yup.string().matches(phoneRegExp, i18next.t("validation.phone")),
							}}
						/>
						<SwitchShowCustomer
							checked={user[profileFields.ShowPhone]}
							switchKey={profileFields.ShowPhone}
							handleSave={handleSave}
						/>
					</Box>
					<Box className="union-line switch-line">
						<EditTextLine
							keyField={profileFields.Whatsapp}
							valueField={user[profileFields.Whatsapp]}
							handleSave={handleSave}
							fieldName={i18next.t("editProfile.whatsapp")}
							formikValidation={{
								whatsapp: Yup.string().matches(phoneRegExp, i18next.t("validation.phone")),
							}}
						/>
						<SwitchShowCustomer
							checked={user[profileFields.ShowWhatsapp]}
							switchKey={profileFields.ShowWhatsapp}
							handleSave={handleSave}
						/>
					</Box>
				</Box>
			)}
		</>
	);
}

const SwitchShowCustomer = ({ checked, handleSave = () => {}, switchKey }) => {
	const [showPhoneChecked, setShowPhoneChecked] = useState(checked || false);

	const handleShowPhoneChange = (event) => {
		setShowPhoneChecked(event.target.checked);
		handleSave(switchKey, event.target.checked);
	};

	return (
		<Box className="wrapShowCustomer" sx={{}}>
			<span className="showClient">{i18next.t("editProfile.showClient")}</span>
			<Switch
				checked={showPhoneChecked}
				onChange={handleShowPhoneChange}
				inputProps={{ "aria-label": "controlled" }}
			/>
		</Box>
	);
};
