import { BrowserRouter as Router, Switch, Redirect, useLocation } from "react-router-dom";
import CommonRoute from "./CommonRoute";
import LoginLayout from "layouts/loginLayout";
import EmptyLayout from "layouts/emptyLayout";
import TermsPage from "pages/TermsPage";
import LoginPhoneVerificationPage from "pages/LoginPhoneVerificationPage";
import HomePage from "pages/HomePage";
import Register from "pages/Register";
import FinishRegister from "pages/FinishRegister";
import TalentPage from "pages/TalentPage";
import LoginAndRegisterPage from "pages/LoginPage";
import HomePageApp from "pages/HomePageApp";
import NewTalentRegister from "pages/NewTalentRegister";
import { Route } from "react-router-dom";
import ProfilePage from "../../pages/ProfilePage";
import Recommendation from "components/profile/Recommendation";
import Settings from "components/profile/settings/Settings";
import EditAccountPage from "components/profile/EditAccountPage";
import ProfileWrapper from "components/profile/profileWrapper/ProfileWrapper";
import i18next from "i18next";
import settingIcon from "assets/images/profile/setting.png";
import star from "assets/images/profile/star.png";
import useUser from "hooks/useUser";
import ChatPage from "../../pages/ChatPage";
import MyBusinessCard from "components/profile/settings/MyBusinessCard";
import businessCardYellow from "assets/images/talent/businessCardYellow.png";
import UsersTypes from "consts/UsersTypes";
import AuthRoute from "config/routes/AuthRoute";
import Favorite from "components/favorite/Favorite";
import TabsLayout from "layouts/tabsLayout";
import WebLayout from "layouts/webLayout";
import LoginTalent from "pages/LoginTalent";
import UserRecomendationPage from "pages/UserRecomendationPage";
import ChatWebPage from "pages/ChatWebPage";

import { isMobile } from "react-device-detect";
import ProfileWebPage from "pages/ProfileWebPage";
import MyRecomendationOnSuppliers from "pages/MyRecomendationOnSuppliers";
import HomeWebPage from "pages/HomeWebPage";
import LatestRecommendations from "components/profile/LatestRecommendations";
import ImpersonateUser from "components/ImpersonateUser/ImpersonateUser";
import Cookies from "js-cookie";
import LandingHomePage from "pages/LandingHomePage";
import ContactPage from "pages/ContactPage";

import MainPage from "pages/MainPage";
import LandingPage from "pages/LandingPage";
import SuppliersPage from "pages/SuppliersPage";
import BlogCategoryPage from "pages/BlogCategoryPage";
import BlogPage from "pages/BlogPage";
import BlogArticle from "components/blog/BlogArticle";
import UserRecomendationIframePage from "pages/UserRecomendationIframePage";
import UserRecomendationFriendsPage from "pages/UserRecomendationFriendsPage";
import MyImages from "components/profile/settings/MyImages";
import StatisticsPage from "pages/StatisticsPage";
import ProfileBlockedPage from "pages/ProfileBlockedPage";
import OpenBusinessCardAppPage from "pages/OpenBusinessCardAppPage";

export default ({ children, platform }) => {
	const { userProfile, isLoggedIn, isCompleteRegister } = useUser();

	return (
		<Router>
			<Switch>
				<Redirect exact from="/login" to="/login/phone" />
				<CommonRoute component={ImpersonateUser} layout={EmptyLayout} path="/token/:id" />
				<CommonRoute
					component={NewTalentRegister}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/thanksSuppliers"
				/>
				<CommonRoute
					component={UserRecomendationPage}
					exact
					layout={LoginLayout}
					path="/user/recomendation/:id"
				/>
				<CommonRoute
					component={UserRecomendationIframePage}
					exact
					layout={LoginLayout}
					path="/recomendationIframe/:id"
				/>
				<CommonRoute
					component={UserRecomendationFriendsPage}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/recomendationFriends"
				/>

				<CommonRoute
					component={BlogCategoryPage}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/blog/category/:urlName"
				/>

				<CommonRoute
					component={BlogArticle}
					layout={isMobile ? TabsLayout : WebLayout}
					path="/blog/:urlName"
				/>
				<CommonRoute
					component={BlogPage}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/blog"
				/>

				<CommonRoute component={TalentPage} exact layout={LoginLayout} path="/talent/:id" />
				<CommonRoute component={LoginTalent} exact layout={LoginLayout} path="/login/supplier" />
				<CommonRoute
					component={LoginAndRegisterPage}
					exact
					layout={LoginLayout}
					path="/login/phone"
				/>
				<CommonRoute
					component={LoginPhoneVerificationPage}
					exact
					layout={LoginLayout}
					path="/login/phoneverify"
				/>
				<CommonRoute auth component={Register} exact layout={LoginLayout} path="/register" />
				<CommonRoute
					auth
					component={FinishRegister}
					exact
					layout={LoginLayout}
					path="/finishRegister"
				/>

				<CommonRoute
					component={LatestRecommendations}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/latestRecommendations"
				/>
				{<CommonRoute component={TermsPage} exact layout={EmptyLayout} path="/terms" />}
				<CommonRoute
					component={ContactPage}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/contact"
				/>
				<CommonRoute
					auth
					component={ProfileBlockedPage}
					exact
					layout={EmptyLayout}
					path="/profileBlocked"
				/>

				<CommonRoute
					component={isMobile ? HomePage : HomeWebPage}
					exact
					layout={isMobile ? TabsLayout : WebLayout}
					path="/search"
				/>

				<CommonRoute
					component={OpenBusinessCardAppPage}
					exact
					layout={EmptyLayout}
					path="/openBusinessCardAppPage"
				/>

				{platform !== "web" && (
					<CommonRoute
						component={HomePageApp}
						exact
						layout={isCompleteRegister ? TabsLayout : EmptyLayout}
						path="/"
						platform={platform}
					/>
				)}

				{platform === "web" && (
					<CommonRoute
						component={MainPage}
						exact
						layout={isMobile ? (isLoggedIn ? TabsLayout : EmptyLayout) : WebLayout}
						path="/"
					/>
				)}
				<CommonRoute component={SuppliersPage} layout={EmptyLayout} path="/landingSuppliers" />

				{/* {isTest === "1" && (
					<CommonRoute
						component={isMobile ? HomePage : HomeWebPage}
						exact
						layout={isMobile ? TabsLayout : WebLayout}
						path="/"
					/>
				)}
blog
				{isTest !== "1" && (
					<CommonRoute
						component={LandingHomePage}
						exact
						layout={isMobile ? TabsLayout : WebLayout}
						path="/"
					/>
				)} */}

				{/*  && process.env.NODE_ENV !== "production" */}
				{!isMobile ? (
					<CommonRoute component={ChatWebPage} auth exact layout={WebLayout} path="/chat" />
				) : (
					<CommonRoute component={ChatPage} auth exact layout={TabsLayout} path="/chat" />
				)}
				{/* {platform !== "web" && (
					<CommonRoute component={HomePageApp} exact layout={TabsLayout} path="/" />
				)}
				{platform === "web" && (
					<CommonRoute
						component={isMobile ? HomePage : HomeWebPage}
						exact
						layout={isMobile ? TabsLayout : EmptyLayout}
						path="/"
					/>
				)} */}

				{/*  && process.env.NODE_ENV !== "production" */}
				{!isMobile ? (
					<CommonRoute component={ProfileWebPage} auth layout={WebLayout} path="/profile" />
				) : (
					<CommonRoute component={ProfilePage} auth exact layout={TabsLayout} path="/profile" />
				)}

				<Route path="/profile/myRecomendationOnSuppliers">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={MyRecomendationOnSuppliers}
							headerTitle={i18next.t("profile.myRecomendation")}
						/>
					</AuthRoute>
				</Route>

				<Route path="/profile/recommendations">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={Recommendation}
							headerTitle={i18next.t("profile.recommendationsOnMe")}
						/>
					</AuthRoute>
				</Route>

				<Route path="/profile/editAccount">
					{userProfile.type === UsersTypes.Talent && userProfile.registerStep < 6 ? (
						<ProfileWrapper
							userProfile={userProfile}
							component={Register}
							headerTitle={i18next.t("profile.editAccount")}
							// goBack={() => navigator.navigateToProfilePage()}
						/>
					) : (
						<AuthRoute checkAuth={true}>
							<ProfileWrapper
								userProfile={userProfile}
								component={EditAccountPage}
								headerTitle={i18next.t("profile.editAccount")}
							/>
						</AuthRoute>
					)}
				</Route>

				<Route exact path="/profile/settings">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={Settings}
							headerTitle={i18next.t("profile.settings")}
						/>
					</AuthRoute>
				</Route>
				<Route exact path="/profile/myBusinessCard">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={MyBusinessCard}
							headerTitle={i18next.t("profile.myBusinessCard")}
						/>
					</AuthRoute>
				</Route>
				<Route exact path="/profile/favorites">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={Favorite}
							headerTitle={i18next.t("profile.favorites")}
						/>
					</AuthRoute>
				</Route>
				<Route exact path="/profile/myImages">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={MyImages}
							headerTitle={i18next.t("profile.myImages")}
						/>
					</AuthRoute>
				</Route>
				<Route exact path="/profile/statistics">
					<AuthRoute checkAuth={true}>
						<ProfileWrapper
							userProfile={userProfile}
							component={StatisticsPage}
							headerTitle={i18next.t("profile.statistics")}
						/>
					</AuthRoute>
				</Route>
				<Redirect from="*" to="/" />
			</Switch>
			{children}
		</Router>
	);
};
