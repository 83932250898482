import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { of, map, filter, repeat, takeUntil } from "rxjs";

import PromotionService from "services/promotion";
import * as types from "./actionTypes";
import * as authTypes from "../auth/actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import { LIKES_ADD } from "redux/likes/actionTypes";
import { SKIPPED_ADD } from "redux/skips/actionTypes";
import { getIsPromotedUser } from "./selectors";

export const fetchPromotions = (action$, store) =>
  action$.pipe(
    ofType(authTypes.AUTH_LOGGED_IN),
    mergeMap(async () => {
      const state = store.value;
      const loggedInUserUID = getLoggedInUserUID(state);
      try {
        const payload = await PromotionService.fetch(loggedInUserUID);
        return {
          type: types.PROMOTIONS_FETCH_SUCCESSFULLY,
          payload,
        };
      } catch (error) {
        return {
          type: types.PROMOTIONS_FETCH_FAILED,
          error: error?.message,
        };
      }
    }),
    catchError((error) => {
      return of({
        type: types.PROMOTIONS_FETCH_FAILED,
        payload: error?.message,
      });
    })
  );

export const fetchPromotionUpdate = (action$, store) =>
  action$.pipe(
    ofType(types.PROMOTIONS_FETCH_SUCCESSFULLY),
    mergeMap(() => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      return PromotionService.listenPromotionUpdates(uid).pipe(
        map((change) => ({
          type: types.PROMOTIONS_UPDATED,
          payload: change,
        }))
      );
    }),
    takeUntil(action$.pipe(ofType(authTypes.AUTH_LOG_OUT))),
    catchError((error) => {
      return of({
        type: types.PROMOTIONS_UPDATED_ERROR,
        payload: error?.message,
      });
    }),
    repeat()
  );

export const updatePromotionStatusOnLikeOrDislike = (action$, store) =>
  action$.pipe(
    ofType(LIKES_ADD, SKIPPED_ADD),
    filter(({ payload: colleagueUid }) =>
      getIsPromotedUser(colleagueUid)(store.value)
    ),
    map(({ payload: colleagueUid }) => {
      const state = store.value;
      const uid = getLoggedInUserUID(state);
      PromotionService.updateStatusLikeOrDislike(uid, colleagueUid);
      return {
        type: types.PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE,
        colleagueUid,
      };
    }),
    catchError((error) => {
      return of({
        type: types.PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE_ERROR,
        payload: error?.message,
      });
    }),
    repeat()
  );
