import "assets/scss/contacts/contacts.scss";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Label } from "reactstrap";

import Button from "components/Button";
import { getUserProfile } from "redux/profile/selectors";

import TextArea from "components/TextArea";
import usePopups from "hooks/usePopups";
import SubjectsTypes from "consts/subjectsTypes";
import { Box } from "@mui/system";
import contactsService from "services/contactsService";
import { CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export default ({ subject }) => {
	const MAX_SIZE_5MB = 1024 * 1024 * 5;

	const [message, setMessage] = useState("");
	const [key, setKey] = useState(true);
	const user = useSelector(getUserProfile);
	const { name, email, phone } = user;
	const [localEmail, setLocalEmail] = useState(email ?? "");
	const [localPhone, setLocalPhone] = useState(phone ?? "");
	const [loading, setLoading] = useState(false);
	const [imgUrl, setImgUrl] = useState("");

	// const { showContactFormSentSuccessfullyPopup, showBigImagePopup } = usePopups();

	const onSend = () => {
		if (!!message) {
			setLoading(true);

			contactsService
				.contactUs(localEmail, message, localPhone, imgUrl)
				.then((data) => {
					if (!!data?.data?.success) {
						// showContactFormSentSuccessfullyPopup();
						//! open success popup
						setMessage("");
						setKey(!key);
					}
					setLoading(false);
				})
				.catch((e) => console.log(e));
		}
	};

	const handleSaveNewImage = (e) => (e ? setImgUrl(e) : null);

	const handleOnChangeEmail = (e) => setLocalEmail(e.target.value);

	const handleOnChangePhone = (e) => setLocalPhone(e.target.value);

	const onSelectFileInput = (e) => {
		if (!(e.target.files && e.target.files.length > 0)) return;

		if ((e.target.files[0]?.size || 0) >= MAX_SIZE_5MB) {
			// return showBigImagePopup();
			//! open is big img popup
		}

		const reader = new FileReader();
		reader.addEventListener("load", () => {
			const base64String = reader.result.replace(/^data:image\/(png|jpg);base64,/, "");
			handleSaveNewImage(base64String);
		});
		reader.readAsDataURL(e.target.files[0]);

		e.target.value = "";
	};

	useEffect(() => {
		if (!user) return;
		setLocalEmail(user?.email ?? "");
		setLocalPhone(user?.phone ?? "");
	}, [user]);

	return (
		<Box className="wrapContacts">
			{!!name && (
				<h5 className="mainTitle" style={{ margin: 0 }}>
					{i18next.t("Contact.hey")} {name},
				</h5>
			)}
			<h5 className="mainTitle">{i18next.t("Contact.Sub Header")}</h5>
			<Form inline>
				<Label for="email">{i18next.t("Account Settings.Email")}</Label>
				<Input
					id="email"
					type="email"
					onChange={handleOnChangeEmail}
					value={localEmail}
					disabled={!!email}
				/>
				{subject === SubjectsTypes.Feedback && (
					<>
						<Label for="phone">{i18next.t("Account Settings.Phone number")}</Label>
						<Input
							id="phone"
							type="phone"
							onChange={handleOnChangePhone}
							value={localPhone}
							disabled={!!phone}
						/>
					</>
				)}
			</Form>
			<TextArea onChange={setMessage} key={key} rows={4} />

			<Box className="wrapUploadImg" sx={{ margin: "10px 0" }}>
				<h6>ניתן לצרף קובץ לפנייה (במידה ויש צורך)</h6>
				<input
					className="empty"
					accept="image/*"
					id="contained-button-file"
					type="file"
					onChange={onSelectFileInput}
				/>
				{!!imgUrl && (
					<Box sx={{ display: "flex", gap: "10px" }}>
						<img src={imgUrl} alt="" />
						<DeleteIcon width="30px" style={{ cursor: "pointer" }} onClick={() => setImgUrl("")} />
					</Box>
				)}
			</Box>

			<Box className="wrapMainBtn">
				{!!loading ? (
					<CircularProgress size={40} className="text-secondary" />
				) : (
					<Button
						disabled={message.trim() === ""}
						className="mainAccountBtn"
						onClick={onSend}
						text={i18next.t("Contact.Send")}
					/>
				)}
			</Box>
		</Box>
	);
};
