import { createSelector } from "reselect";
import { isNil } from "lodash";

export const getLikeMatch = (state) => state.likeMatch;

export const getMatches = createSelector(getLikeMatch, (matchLike) => {
  return Object.keys(matchLike.data || {})
    .map((uid) => ({ uid, ...matchLike.data[uid] }))
    .filter(({ startChat, deleted }) => !startChat && deleted !== true);
});

export const getMatchUser = (userUid) =>
  createSelector(getLikeMatch, (matchLike) => matchLike.data?.[userUid]);

export const getIsMatch = (userUid) =>
  createSelector(getLikeMatch, (matches) => {
    return (
      matches?.data &&
      !isNil(matches.data[userUid]) &&
      matches.data[userUid].deleted !== true
    );
  });
