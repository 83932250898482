import { Box } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { useState } from "react";
import LightBox from "components/lightBox/LightBox";
import functionsService from "services/functionsService";
import talentAction from "consts/talentAction";

export default ({ user }) => {
	const [openLightBox, setOpenLightBox] = useState(false);
	const [imgIndex, setImgIndex] = useState(0);
	const userImagesUrls = Object.entries(user?.pictures).map((arr) => arr[1].url) ?? [];
	const [images, setImages] = useState(userImagesUrls);

	const handleopenLightBox = (open) => {
		setOpenLightBox(open);
	};
	const handleImgIndex = (index) => {
		setImgIndex(index);
	};
	const handleArrImages = (images) => {
		setImages(images);
	};
	const handleImgClick = async (index) => {
		if (user?.uid) functionsService.addTalentAction(user?.uid, talentAction.clickPhotos);
		setImgIndex(index);
		setOpenLightBox(true);
	};

	return (
		<Box className={"wrapImages"}>
			<Swiper
				slidesPerView={"auto"}
				spaceBetween={10}
				pagination={{
					clickable: true,
				}}
				modules={[Pagination]}
				className="mySwiper">
				{user &&
					user.pictures &&
					Object.values(user.pictures).map((item, index) => {
						return (
							<SwiperSlide style={{ width: "min-content" }}>
								<Box style={{ width: "min-content" }} onClick={() => handleImgClick(index)}>
									<img src={item.url} key={index} />
								</Box>
							</SwiperSlide>
						);
					})}
			</Swiper>
			{openLightBox && (
				<LightBox
					images={images}
					imgIndex={imgIndex}
					isOpen={openLightBox}
					setUpdateOpenLightBox={setOpenLightBox}
				/>
			)}
		</Box>
	);
};
