import TabsRouter from "components/TabsRouter";
import useUser from "hooks/useUser";

export default ({ children }) => {
	const { userProfile } = useUser();
	return (
		<>
			<div className="">
				{children}
				<TabsRouter userProfile={userProfile}></TabsRouter>
			</div>
		</>
	);
};
