import SearchWebHeader from "components/searchWeb/SearchWebHeader";

export default ({ children }) => {
	return (
		<>
			<div className="">
				<SearchWebHeader />
				{children}
			</div>
		</>
	);
};
