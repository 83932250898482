import "assets/scss/landing/landingYoutubeVideo.scss";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { Button } from "reactstrap";
import logoWhite from "assets/images/svgs/landing/flat-logo-white.svg";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default function LandingYoutubeVideo({ isMobile = false, youTubeUrlVideo = "" }) {
	const dispatch = useDispatch();

	const openLoginPopup = () => {
		return dispatch(openDialog({ dialog: dialogsTypes.Login, registerType: "talent" }));
	};

	const createIframe = () => {
		if (!youTubeUrlVideo) return;
		const urlParams = new URL(youTubeUrlVideo);
		const videoId = urlParams.searchParams.get("v");
		if (!videoId) return <span></span>;
		const iframe = `<iframe width="100%"  height="max-content" style="height:100%; min-height:max-content; " src="https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&rel=0&showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		if (!iframe) return <span></span>;
		return iframe;
	};

	return (
		<Box className="landingYoutubeVideo">
			{!!isMobile && (
				<Box>
					<Button onClick={openLoginPopup}>{i18next.t("landingPage.joinNow")}</Button>
					<Box sx={{ color: "#fff", fontSize: "18px", fontWeight: "700" }}>
						{i18next.t("landingPage.toOurFreelancersTeam")}
					</Box>
				</Box>
			)}
			<Box className="wrapIframe">
				{!!youTubeUrlVideo && (
					<Box
						className="wrapYtVideo"
						sx={{
							overflow: "hidden",
							borderRadius: "20px",
						}}
						dangerouslySetInnerHTML={{
							__html: createIframe(),
						}}
					/>
				)}
			</Box>
			<Box className="contentIframe">
				{/* <Box className="sixDays">{i18next.t("landingPage.sixDays")}</Box> */}
				<img src={logoWhite} width={"150px"} />

				<Box sx={{ maxWidth: "700px" }}>
					{`${i18next.t("landingPage.YouBringYourSkills")}...`}
					<Box>
						<b>{i18next.t("landingPage.YouHaveTalentWeConnections")}</b>
					</Box>
				</Box>
				{!isMobile && (
					<Button onClick={openLoginPopup}>
						{i18next.t("landingPage.joinNowOutFreelancersTeam")}
					</Button>
				)}
			</Box>
		</Box>
	);
}
