import firebase from "firebase/app";
import "firebase/database";
import queueService from "services/queue";
import { Observable } from "rxjs";
import recommendationsEvents from "consts/recommendationsEvents";
import httpsCallable from "./httpsCallable";

class RecommendationsService {
	BASE_REF = "talent_recommendations";

	async deleteRecommendation(key, talentId = null) {
		const result = await httpsCallable.post("deleteRecommendation", {
			extraData: {
				key,
				talentId,
			},
		});
		return result;
	}

	async getMyRecommendations(id) {
		if (!id) return null;
		return (await firebase.database().ref("my_recommendations").child(id).once("value")).val();
	}

	async fetchRecommendations(id) {
		return (await firebase.database().ref(this.BASE_REF).child(id).once("value")).val();
	}

	async getRecommendations(id) {
		const recommendation = await this.fetchRecommendations(id);
		const arr = [];
		Object.entries(recommendation || {}).map(([key, value]) => {
			arr.push({ ...value, key });
		});
		return Object.values(arr).reverse();
	}

	async addRecommendation(uid, talentId, text) {
		return httpsCallable.post(recommendationsEvents.AddRecommendation, {
			extraData: { talentId, text },
		});
	}

	async getFriendsRecommendations() {
		return httpsCallable.post(recommendationsEvents.getFriendsRecommendations, {});
	}

	async updateRecommendationsIsRead(talentId) {
		const recommendation = (
			await firebase
				.database()
				.ref(this.BASE_REF)
				.child(talentId)
				.orderByChild("status")
				.equalTo(0)
				.once("value")
		).val();

		Object.keys(recommendation || {}).map((key) => {
			firebase.database().ref(this.BASE_REF).child(talentId).child(key).update({ status: 1 });
		});
	}

	listenRecommendationUnread(id) {
		return new Observable((subscriber) => {
			console.log("listen");
			if (!id) return;

			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(id)
				.orderByChild("status")
				.equalTo(0);

			const callbackFn = ref.on(
				"value",
				(snapshot) => {
					subscriber.next(snapshot.val());
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("value", callbackFn);
		});
	}

	listenRecommendationAdded(id) {
		return new Observable((subscriber) => {
			console.log("listen");

			const ref = firebase
				.database()
				.ref(this.BASE_REF)
				.child(id)
				.orderByChild("created")
				.startAt(Date.now());

			const callbackFn = ref.on(
				"child_added",
				(snapshot) => {
					subscriber.next({
						...snapshot.val(),
						key: snapshot.key,
						animate: "animate__fadeInLeftBig",
					});
				},
				(error) => subscriber.error(error)
			);
			return () => ref.off("child_added", callbackFn);
		});
	}
}

export default new RecommendationsService();
