import { Box, Switch } from "@mui/material";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useEffect, useState } from "react";
import functionsService from "services/functionsService";

export default function Notifications() {
	const { userProfile } = useUser();

	const notificationSwitchs = [
		{
			key: "allowSharedContact",
			value: userProfile?.allowSharedContact,
		},
		{
			key: "emailApplication",
			value: userProfile?.emailApplication,
		},
		{
			key: "SMSApplication",
			value: userProfile?.SMSApplication,
		},
		{
			key: "generalAdv",
			value: userProfile?.generalAdv,
		},
	];

	const handleSwitchChange = async (key, value) => {
		return await functionsService.updateProfile({ [key]: value });
	};

	return (
		<Box className="notificationsSettings">
			{notificationSwitchs.map((item) => (
				<SwitchText
					key={item?.key}
					keySwitch={item?.key}
					title={i18next.t(`settings.notifications.${item?.key}`)}
					value={!!item?.value}
					handleSwitchChange={handleSwitchChange}
				/>
			))}
		</Box>
	);
}

function SwitchText({ keySwitch = "", value = false, handleSwitchChange = () => {}, title = "" }) {
	const [active, setActive] = useState(false);

	const handleChange = (checked) => {
		setActive(checked);
		handleSwitchChange(keySwitch, checked);
	};

	useEffect(() => setActive(value), []);
	return (
		<Box className={`wrapSwitchText ${active ? "activeSwitchText" : ""}`}>
			<Switch
				style={{ color: active && "#fff" }}
				key={keySwitch}
				checked={active}
				title={title}
				value={active}
				onChange={(e) => handleChange(e.target.checked)}
			/>
			<h6 className={`titleSwitch ${active ? "activeTitle" : "notActiveTitle"}`}>{title}</h6>
		</Box>
	);
}
