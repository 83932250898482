import React from "react";
const ThemeLtr = React.lazy(() => import("./themeLtr"));

const ThemeSelector = ({ children, lang }) => {
	return (
		<>
			<React.Suspense fallback={() => null}>
				{lang === "he" && <ThemeLtr>{children}</ThemeLtr>}
			</React.Suspense>
		</>
	);
};

export default ThemeSelector;
