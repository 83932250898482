import { createSelector } from "reselect";
import { isNil } from "lodash";

import API_REQUESTS_STATUS from "consts/apiRequestStatus";
import { getBlocks } from "redux/blocks/selectors";

const getLikes = (state) => state.likes;

const getLikesData = createSelector(getLikes, (likes) => likes.data || {});

export const isLike = (userUid) =>
	createSelector(getLikesData, (likesData) => !isNil(likesData[userUid]));

export const getPitId = createSelector(getLikes, (likes) => likes.pitId);

export const getSearchAfter = createSelector(getLikes, (likes) => likes.searchAfter);

export const getLikesUsers = createSelector(getLikes, getBlocks, (likes, blocks) => {
	const users = Object.keys(likes.users)
		.sort(
			(uidA, uidB) => likes.users[uidB].liked_me_timeStamp - likes.users[uidA].liked_me_timeStamp
		)
		.map((uid) => likes.users[uid]);
	return users.filter((user) => !user.deleted).filter((user) => !blocks[user.uid]);
});

export const getIsLoadingMore = createSelector(
	getLikes,
	(likes) => likes.loadMoreStatus === API_REQUESTS_STATUS.PENDING
);

export const getHasMoreResults = createSelector(getLikes, (likes) => likes.hasMoreResults);

export const getLikesStatus = createSelector(getLikes, (likes) => likes.apiStatus);
