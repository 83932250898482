import "assets/scss/appEnterPage/appEnterPage.scss";
import "swiper/swiper-bundle.css";

import { Box } from "@mui/material";
import HeaderEnterPage from "components/enterPageApp/HeaderEnterPage";
import { Pagination, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import group1 from "assets/images/svgs/enterPageApp/enterAppGroup1.svg";
import group2 from "assets/images/svgs/enterPageApp/enterAppGroup2.svg";
import SiteButton from "components/ui/SiteButton";

export default function AppEnterPage({ goToLoginPage = () => {} }) {
	const swiperPages = [
		{
			img: group1,
			text: [
				<Box>{"סמן את המועדפים, חפש מומחים ושכור את הטובים ביותר!"}</Box>,
				<Box style={{ fontWeight: "700" }}>{"בחר מומחים בעזרת מכרים!"}</Box>,
			],
			btnText: "המשך",
		},
		{
			img: group2,
			text: "פתח פרופיל עסקי שיביא לעסק שלך לקוחות חדשים באמצעות המלצות אותנטיות",
			btnText: "המשך",
		},
	];

	return (
		<Box className="appEnterPageWrapper">
			<HeaderEnterPage />
			<Box className="titleEnterPage"> {"זירת הפרילאנסרים, המשרות והמומחים של המגזר!"}</Box>
			<Swiper
				loop={true}
				autoplay={{
					delay: 2000,
					disableOnInteraction: false,
				}}
				pagination={true}
				modules={[Autoplay, FreeMode, Pagination]}
				className="mySwiper appEnterPageSwiper">
				{swiperPages.map((item, index) => (
					<SwiperSlide className="wrapSwiperPage">
						<ImgTextBtn
							key={index}
							img={item.img}
							text={item.text}
							btnText={item.btnText}
							goToLoginPage={goToLoginPage}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</Box>
	);
}

function ImgTextBtn({ img, text, btnText, goToLoginPage }) {
	return (
		<>
			<img alt="imgSite" src={img} />
			<Box>{text}</Box>
			<Box sx={{ marginTop: "10px" }}>
				<SiteButton text={btnText} onClickBtn={goToLoginPage} />
			</Box>
		</>
	);
}
