import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import useNavigator from "hooks/useNavigator";
import TermsLink from "components/login/TermsLink";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import { loginWithPhone } from "redux/auth/actions";
import {
	getLoginWithPhoneStatus,
	isUserLoggedIn,
	getIsAuthChecked,
	getLoginWithPhoneErrorMessage,
} from "redux/auth/selectors";
import { getPhoneWithPrefix } from "helpers/mobileNumber";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import phone1Image from "assets/images/birdsLogin.svg";
import Loader from "components/Loader";
import LoginHeader from "components/login/LoginHeader";
import sixDaysWedLogo from "assets/images/sixDaysWedLogo.png";
import UsersTypes from "consts/UsersTypes";
import { Box, Checkbox } from "@mui/material";
import dialogsTypes from "consts/dialogsTypes";
import { openDialog } from "redux/dialog/selectors";
import logoSixDays from "assets/images/svgs/landing/logoLogin.svg";
import HeaderEnterPage from "components/enterPageApp/HeaderEnterPage";

export default ({ setLoginStep = () => {}, goToTalentRegister = false }) => {
	const dispatch = useDispatch();
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const isLoggedIn = useSelector(isUserLoggedIn);
	const isAuthChecked = useSelector(getIsAuthChecked);
	const navigator = useNavigator();
	const [hasError, setHasError] = useState();
	const [errMsg, setErrMsg] = useState();
	const [showSubtitle, setShowSubtitle] = useState(true);
	const loginWithPhoneErrorMessage = useSelector(getLoginWithPhoneErrorMessage);
	const [showTalentSubtitle, setShowTalentSubtitle] = useState(false);
	const [terms, setTerms] = useState(false);
	const [mustApproveTerms, setMustApproveTerms] = useState(false);
	const [isFromRecommend, setIsFromRecommend] = useState(false);
	const termsRef = useRef();

	const dialogsState = useSelector((state) =>
		state.dialogs?.dialogs.find((item) => item.dialog == dialogsTypes.Login)
	);

	const handleShowSubtitle = (bool) => {
		// setShowSubtitle(bool);
	};
	const handleSubmit = (value) => {
		if (!terms) {
			termsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
			setMustApproveTerms(true);
			return false;
		}
		const phoneWithPrefix = getPhoneWithPrefix(value);
		dispatch(loginWithPhone(phoneWithPrefix));
	};

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.WAIT_FOR_VERIFICATION:
				setLoginStep(2);
				break;
			case LOGIN_WITH_PHONE_STATUS.LOGIN_FAILED:
				setHasError(true);
				setErrMsg(
					i18next.t(
						`Phone Error.${
							loginWithPhoneErrorMessage.indexOf("blocked") !== -1 ? "Many Requests" : ""
						}`
					)
				);
				break;
			default:
				break;
		}
	}, [loginStatus]);

	useEffect(() => {
		if (dialogsState?.registerType == UsersTypes.Talent || goToTalentRegister) {
			setShowTalentSubtitle(true);
		}
		if (!!dialogsState?.isFromRecommend) {
			setIsFromRecommend(true);
			setTerms(false);
		}
	}, []);

	const titleTxt = isFromRecommend
		? i18next.t(`Login.recTitle`, {
				name: dialogsState?.recommendName,
		  })
		: dialogsState?.isFromMsgToTalent
		? i18next.t("Login.msgToTalentTitle")
		: showTalentSubtitle
		? i18next.t("Login.everyCostumer")
		: i18next.t("Login.chooseExpertsByEcquaintances");

	const subTitleTxt = showSubtitle
		? isFromRecommend
			? i18next.t("Login.recSubtitle")
			: dialogsState?.isFromMsgToTalent
			? i18next.t(`Login.msgToTalentSubTitle`, {
					name: dialogsState?.msgTalentName,
			  })
			: showTalentSubtitle
			? i18next.t("Login.talentSubtitle")
			: i18next.t("Login.IdentifyYourselfKnowExperts")
		: "";

	return isAuthChecked ? (
		<div className={`loginPagesWrapper ${isFromRecommend ? "recommendLoginWrapper" : ""}`}>
			<div className="loginPopup">
				<HeaderEnterPage />
				<div className="loginHolder phoneEnter">
					<PhoneQuestion
						title={titleTxt}
						subTitle={subTitleTxt}
						handleShowSubtitle={handleShowSubtitle}
						onSubmit={handleSubmit}
						hasError={hasError}
						errMsg={errMsg}
						showLoader={loginStatus === LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING}
					/>
					<Box className="center" sx={{ marginTop: "20px" }} ref={termsRef}>
						<Box
							onClick={() => {
								if (!terms) {
									setMustApproveTerms(false);
								}
								setTerms(!terms);
							}}>
							<Checkbox
								checked={terms}
								style={{
									fontSize: "1rem",
									color: mustApproveTerms ? "red" : "#5A5A5A",
									padding: "0",
								}}
							/>
						</Box>
						<span
							className="center"
							style={{
								fontSize: "13px",
								lineHeight: "1",
								textAlign: "center",
								marginRight: "5px",
							}}>
							<span>
								<span style={{ color: mustApproveTerms ? "red" : "#5A5A5A" }}>
									{i18next.t("settings.termsDesc")}
								</span>
								<a
									style={{
										cursor: "pointer",
										color: mustApproveTerms ? "red" : "#5A5A5A",
										textDecoration: "underline",
									}}
									onClick={() => {
										dispatch(openDialog({ dialog: dialogsTypes.Terms }));
									}}>
									{i18next.t("settings.terms")}
								</a>
							</span>
						</span>
					</Box>
				</div>
			</div>
		</div>
	) : (
		<Loader />
	);
};
