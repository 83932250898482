import { Button } from "@mui/material";

export default ({
	text = "",
	styledText = {},
	variant = "containd",
	styledButton = {},
	onClickBtn = () => {},
}) => {
	return (
		<Button className="wrapSiteBtn" onClick={onClickBtn} variant={variant} {...styledButton}>
			<span {...styledText}>{text}</span>
		</Button>
	);
};
