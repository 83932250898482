import i18next from "i18next";
import { useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";

export default ({
	field,
	onChange,
	initValue = "",
	title,
	rows,
	className = "",
	MAX_LENGTH = 400,
	placeholder = i18next.t("Type"),
	required = false,
	setIsEmpty,
}) => {
	const [value, setValue] = useState(initValue);

	const onNext = (text) => {
		setValue(text);
		if (required && text.trim() === "") return setIsEmpty(true);
		if (required && text.trim() !== "") setIsEmpty(false);
		field ? onChange(field, text) : onChange(text);
	};

	const onBlur = () => {
		if (!required) return;
		if (value.trim() === "") {
			setValue(initValue);
			setIsEmpty(false);
		}
	};

	return (
		<Form inline>
			<FormGroup className={className}>
				<Label>{title}</Label>
				<div class="grow-wrap" data-replicated-value={value}>
					<Input
						maxLength={MAX_LENGTH}
						value={value}
						onChange={(e) => onNext(e.target.value)}
						placeholder={placeholder}
						type="textarea"
						rows={rows}
						onBlur={onBlur}
					/>
				</div>
			</FormGroup>
			<span className="numrest">
				{value.length}/{MAX_LENGTH}
			</span>
		</Form>
	);
};
