import advertising from "assets/images/svgs/category/advertising.svg";
import architect from "assets/images/svgs/category/architect.svg";
import beauty from "assets/images/svgs/category/beauty.svg";
import coaching from "assets/images/svgs/category/coaching.svg";
import coding from "assets/images/svgs/category/coding.svg";
import constructions from "assets/images/svgs/category/constructions.svg";
import design from "assets/images/svgs/category/design.svg";
import events from "assets/images/svgs/category/events.svg";
import finance from "assets/images/svgs/category/finance.svg";
import fitness from "assets/images/svgs/category/fitness.svg";
import internetSocial from "assets/images/svgs/category/internet-social.svg";
import home from "assets/images/svgs/category/key.svg";
import lecturer from "assets/images/svgs/category/lecturer.svg";
import managing from "assets/images/svgs/category/managing.svg";
import music from "assets/images/svgs/category/music.svg";
import teachers from "assets/images/svgs/category/teachers.svg";
import video from "assets/images/svgs/category/video.svg";
import genery from "assets/images/svgs/category/genery.svg";

import advertisingMob from "assets/images/svgs/category/advertisingMob.svg";
import architectMob from "assets/images/svgs/category/architectMob.svg";
import beautyMob from "assets/images/svgs/category/beautyMob.svg";
import coachingMob from "assets/images/svgs/category/coachingMob.svg";
import codingMob from "assets/images/svgs/category/codingMob.svg";
import constructionsMob from "assets/images/svgs/category/constructionsMob.svg";
import designMob from "assets/images/svgs/category/designMob.svg";
import eventsMob from "assets/images/svgs/category/eventsMob.svg";
import financeMob from "assets/images/svgs/category/financeMob.svg";
import fitnessMob from "assets/images/svgs/category/fitnessMob.svg";
import internetSocialMob from "assets/images/svgs/category/internet_socialMob.svg";
import homeMob from "assets/images/svgs/category/keyMob.svg";
import lecturerMob from "assets/images/svgs/category/lecturerMob.svg";
import managingMob from "assets/images/svgs/category/managingMob.svg";
import musicMob from "assets/images/svgs/category/musicMob.svg";
import teachersMob from "assets/images/svgs/category/teachersMob.svg";
import videoMob from "assets/images/svgs/category/videoMob.svg";
import generyMob from "assets/images/svgs/category/generyMob.svg";

export const getCategoryImg = (key, isWeb = false) => {
	if (!!isWeb) {
		switch (key) {
			case "-NWplWYMmwC9i9jrd7Nv":
				return advertising;
			case "-NWplAzmHJSp314fIfar":
				return architect;
			case "-NXdTsnUOcN3GIUHFayG":
				return beauty;
			case "-NWpkbeWZmwsTL4SPhbE":
				return coaching;
			case "-NWplDXWeZ4D1HOHkfKX":
				return coding;
			case "-NWpjc_HoYKh8aw_yxQB":
				return constructions;
			case "-NXdUPcCU7ztFVsugQLa":
				return design;
			case "-NWpl0ZE_JCxIBiMQ4kk":
				return events;
			case "-NWpk7x0SXQ761FP5Pzn":
				return finance;
			case "-NWpjrdVOzo2Vmg8wgLa":
				return fitness;
			case "-NWpj6rdDsqaeadybmsY":
				return internetSocial;
			case "-NWplRysl1c8e0vwV-cr":
				return home;
			case "-NWplHRsSxHkprSh1Fts":
				return lecturer;
			case "-NXdVDuOCsgtgKIWX7Yd":
				return managing;
			case "-NWplKiFlu6jgJJ5vlcj":
				return music;
			case "-NXdVo-5kubMr6YRhWLC":
				return teachers;
			case "-NWpl5ibrkYqsfuBAsFP":
				return video;

			default:
				return genery;
		}
	} else {
		switch (key) {
			case "-NWplWYMmwC9i9jrd7Nv":
				return advertisingMob;
			case "-NWplAzmHJSp314fIfar":
				return architectMob;
			case "-NXdTsnUOcN3GIUHFayG":
				return beautyMob;
			case "-NWpkbeWZmwsTL4SPhbE":
				return coachingMob;
			case "-NWplDXWeZ4D1HOHkfKX":
				return codingMob;
			case "-NWpjc_HoYKh8aw_yxQB":
				return constructionsMob;
			case "-NXdUPcCU7ztFVsugQLa":
				return designMob;
			case "-NWpl0ZE_JCxIBiMQ4kk":
				return eventsMob;
			case "-NWpk7x0SXQ761FP5Pzn":
				return financeMob;
			case "-NWpjrdVOzo2Vmg8wgLa":
				return fitnessMob;
			case "-NWpj6rdDsqaeadybmsY":
				return internetSocialMob;
			case "-NWplRysl1c8e0vwV-cr":
				return homeMob;
			case "-NWplHRsSxHkprSh1Fts":
				return lecturerMob;
			case "-NXdVDuOCsgtgKIWX7Yd":
				return managingMob;
			case "-NWplKiFlu6jgJJ5vlcj":
				return musicMob;
			case "-NXdVo-5kubMr6YRhWLC":
				return teachersMob;
			case "-NWpl5ibrkYqsfuBAsFP":
				return videoMob;

			default:
				return generyMob;
		}
	}
};
