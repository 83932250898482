import i18next from "i18next";
import "assets/scss/TalentBox/talentBox.scss";
import { Box, Card } from "@mui/material";
import useNavigator from "hooks/useNavigator";
import LinkBtns from "./linkBtns/LinkBtns";
import saveSelected from "assets/images/saveSelected.png";
import saveNoSelected from "assets/images/saveNoSelected.png";
import { useEffect, useRef, useState } from "react";
import ProfileContent from "components/profileContent/ProfileContent";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import functionsService from "services/functionsService";
import useUser from "hooks/useUser";
import { addFiltersSelected } from "redux/search/actions";
import IconsMenu from "components/talent/IconsMenu";
import talentAction from "consts/talentAction";
import { useHistory } from "react-router-dom";
import RecommendationBox from "components/talent/RecommendationBox";
import InfoTalent from "./infoTalent/InfoTalent";

export default ({
	user: talent,
	onChange,
	favoriteList = [],
	webPage,
	setUserWebPopup = () => {},
}) => {
	const navigator = useNavigator();
	const dispatch = useDispatch();
	const history = useHistory();
	const talentRef = useRef();

	const openTalentPopup = () => {
		if (!!webPage) {
			setUserWebPopup(talent);
		} else {
			dispatch(openDialog({ dialog: dialogsTypes.ViewTalent, talentId: talent.uid, talent }));
		}
		const talentUrl = `/talent/${talent?.internalID}`;
		window.history.pushState({}, "", talentUrl);
	};

	const searchByTag = (e, tagName) => {
		e.stopPropagation();
		dispatch(addFiltersSelected({ filters: { freeText: [tagName] }, research: true }));
	};

	useEffect(() => {
		if (!talent?.aboutMe) return;

		if ((talentRef.current?.offsetHeight || 0) > 105) {
			talentRef.current.classList.add("longText");
		} else {
			if (talentRef.current) talentRef.current.classList.remove("longText");
		}
	}, []);

	return (
		<Box onClick={openTalentPopup} className="TalentBox">
			<Card variant="outlined" className="TalentBoxCard">
				{/* <Box
					onClick={(e) => e.stopPropagation()}
					sx={{ position: "absolute", right: "2px", top: "0" }}>
					<IconsMenu sx={{ position: "absolute", right: "-10px", top: "20px" }} user={talent} />
				</Box> */}
				<ProfileContent user={talent} favoriteList={favoriteList} />

				{talent?.aboutMe && (
					<section className="more-data">
						<div ref={talentRef} className="wrapAboutMe">
							{talent.aboutMe}
						</div>
						{/* <InformationBtns user={talent} /> */}
					</section>
				)}
				<section className={"tags"}>
					{talent.tagsObj &&
						Object.values(talent.tagsObj || {})
							.sort((a, b) => {
								return a.priority - b.priority;
							})
							.map(
								(item, index) =>
									index < 5 && (
										<button onClick={(e) => searchByTag(e, item.name)} key={index}>
											{item.name}
										</button>
									)
							)}
				</section>

				<Box>
					<InfoTalent user={talent} />
				</Box>
				<Box>
					<LinkBtns user={talent} />
				</Box>
				{!!webPage && talent?.lastRecommendation && talent?.lastRecommendation?.uid && (
					<Box className="wrapLastRec">
						<Box className="recommendationWrapper" sx={{}}>
							<Box className="recommendationList">
								<RecommendationBox talentId={talent?.uid} item={talent?.lastRecommendation} />
							</Box>
						</Box>
					</Box>
				)}
			</Card>
		</Box>
	);
};
