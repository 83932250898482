export default {
	SortByLastLoginCombinedWithBustsDESC: "SortByLastLoginCombinedWithBustsDESC",
	SortByLastLikeMeDESC: "SortByLastLikeMeDESC",
	SortByDistanceASC: "SortByDistanceDESC",
	SortByLikeTimeStampDESC: "SortByLikeTimeStampDESC",
	SortByLikedMeTimeStampDESC: "SortByLikedMeTimeStampDESC",
	SortByViewTimeStampDESC: "SortByViewTimeStampDESC",
	SortByViewedMeTimeStampDESC: "SortByViewedMeTimeStampDESC",
	SortByCreateTimeDESC: "SortByCreateTimeDESC",
	SortByCreateTimeASC: "SortByCreateTimeASC",
	SortByLastLoginTimeDESC: "SortByLastLoginTimeDESC",
	SortByManualRankAndRandomDESC: "SortByManualRankAndRandomDESC",
	SortByRankAndLastLoginDESC: "SortByRankAndLastLoginDESC",
};
