import produce from "immer";

import * as types from "./actionTypes";
import * as authTypes from "redux/auth/actionTypes";
import PromotionStatus from "consts/promotionStatus";

const initialState = {};

export default produce((state, action) => {
	switch (action.type) {
		case types.PROMOTIONS_FETCH_SUCCESSFULLY:
		case types.PROMOTIONS_UPDATED:
			return { ...state, ...action.payload };
		case types.PROMOTIONS_VIEWED:
			state[action.colleagueUid].status = PromotionStatus.Viewed;
			break;
		case types.PROMOTIONS_UPDATE_STATUS_LIKEORDISLIKE:
			state[action.colleagueUid].status = PromotionStatus.LikeOrDisLike;
			break;
		case authTypes.AUTH_LOG_OUT:
			return initialState;
	}
}, initialState);
