import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ChatListItems from "./ChatListItems";
import { useEffect, useState } from "react";
import i18next from "i18next";
import SiteTalentBanner from "components/siteBunner/SiteTalentBanner";
import useUser from "hooks/useUser";
import UsersTypes from "consts/UsersTypes";
import SiteClientBanner from "components/siteBunner/SiteClientBanner";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default ({ list = [], editChats = false }) => {
	const { userProfile } = useUser();

	const [tabValue, setTabValue] = useState(0);
	const [dataByTab, setDataByTab] = useState(list);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const filterDataByTab = (value) => {
		let newData;
		switch (value) {
			case 0:
				newData = list;
				break;
			case 1:
				newData = list.filter((item) => item.isSend);
				break;
			case 2:
				newData = list.filter((item) => item.isReceive);
				break;
		}

		setDataByTab(newData);
	};

	useEffect(() => {
		filterDataByTab(tabValue);
	}, [tabValue, list]);

	return (
		<Box className="tabsChatListWrapper" sx={{ width: "100%" }}>
			<Box className="tabs">
				<Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
					<Tab label={i18next.t("tabs.allMessages")} {...a11yProps(0)} />
					<Tab label={i18next.t("tabs.inquiriesFromMe")} {...a11yProps(1)} />
					<Tab label={i18next.t("tabs.inquiriesReceived")} {...a11yProps(2)} />
				</Tabs>
			</Box>
			<ChatListItems editChats={editChats} list={dataByTab}></ChatListItems>

			{userProfile.type === UsersTypes.Talent ? (
				<>
					{dataByTab.filter((item) => !item.deleted).length <= 2 && (
						<Box className="center" sx={{ margin: "50px 0 100px" }}>
							<SiteTalentBanner />
						</Box>
					)}
				</>
			) : (
				<>
					{/* {dataByTab.filter((item) => !item.deleted).length <= 2 && (
						<Box className="center" sx={{ margin: "50px 0 100px" }}>
							<SiteClientBanner />
						</Box>
					)} */}
				</>
			)}
		</Box>
	);
};
