import * as Yup from "yup";
import { Box } from "@mui/material";
import i18next from "i18next";
import EditTextLine from "./editLineHelpers/EditTextLine";
import profileFields from "consts/profileFields";

export default function EmailDetails({ user, handleSave = () => {} }) {
	return (
		<>
			{!!user?.uid && (
				<Box className="inner-section account-details">
					<h3 className="edit-title">{i18next.t("editProfile.email")}</h3>
					<EditTextLine
						keyField={profileFields.Email}
						valueField={user[profileFields.Email]}
						handleSave={handleSave}
						fieldName={i18next.t("register.email")}
						formikValidation={{
							email: Yup.string().email(i18next.t("validation.email")),
						}}
					/>
				</Box>
			)}
		</>
	);
}
