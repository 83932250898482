import useUser from "hooks/useUser";
import UserRecomendationPage from "./UserRecomendationPage";

export default () => {
	const { userProfile } = useUser();

	return (
		<>
			<UserRecomendationPage idFromOut={userProfile?.uid} withoutHeader={true} />
		</>
	);
};
