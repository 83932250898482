import * as Yup from "yup";
import i18next from "i18next";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import EditTextLine from "./editLineHelpers/EditTextLine";
import profileFields from "consts/profileFields";

export default function ClientAccountDetails({ user, handleSave }) {
	return (
		<>
			{!!user?.uid && (
				<Box className="inner-section account-details">
					<h3 className="edit-title">{i18next.t("editProfile.accountDetails")}</h3>
					<EditTextLine
						keyField={profileFields.Name}
						valueField={user[profileFields.Name]}
						handleSave={handleSave}
						fieldName={i18next.t("register.name")}
						formikValidation={{
							name: Yup.string()
								.min(2, i18next.t("validation.minText"))
								.max(50, i18next.t("validation.longName"))
								.required(i18next.t("validation.required")),
						}}
					/>
				</Box>
			)}
		</>
	);
}
