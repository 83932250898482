export default {
	Uid: "uid",
	Phone: "phone",
	Name: "name",
	Dob: "dob",
	Gender: "gender",
	MaritalStatus: "maritalStatus",
	HasChildren: "hasChildren",
	ChildrenCount: "ChildrenCount",
	KeepShabbat: "keepShabbat",
	EatKosherFood: "eatKosherFood",
	Profession: "profession",
	AboutMe: "aboutMe",
	Body: "body",
	Stance: "stance",
	Height: "height",
	AboutSpouse: "aboutSpouse",
	Zodiac: "zodiac",
	Hair: "hair",
	Education: "education",
	Languages: "languages",
	Subscription: "subscription",
	Location: "location",
	HasCompleteRegistration: "hasCompleteRegistration",
	Pictures: "pictures",
	MainPictureName: "mainPictureName",
	CityLoc: "city_loc",
	CityPlaceId: "city_placeid",
	Cites: {
		he: "city_he",
		en: "city_en",
	},
	LookingFor: "lookingFor",
	Hobbies: "hobbies",
	Smoking: "smoking",
	Religion: "religion",
	PrayerTime: "prayerTime",
	Origin: "origin",
	HeadCover: "headCover",
	KindKipa: "kindKipa",
	Health: "health",
	Kosher: "kosher",
	Food: "food",
	Army: "army",
	Cohen: "cohen",
	ToraTime: "toraTime",
	EducationalInstitution: "educationalInstitution",
	Email: "email",
	Auth_UserID: "auth_userId",
	FbToken: "fbToken",
	AppleIdentityToken: "appleIdentityToken",
	ModestyLevel: "ModestyLevel",
	ModestyFilter: "ModestyFilter",
	Tags: "tags",
	Providers: "providers",
	FacebookName: "FacebookName",
	FacebookDob: "FacebookDob",
	FacebookEmail: "FacebookEmail",
	OneLiner: "oneLiner",
	Clothing: "clothing",
	Characteristics: "characteristics",
	IsPicturesPrivate: "isPicturesPrivate",
	Address: "address",
	Title: "title",
	Experience: "experience",
	Skills: "skills",
	PhoneToConnect: "phoneToConnect",
	Whatsapp: "whatsapp",
	Site: "site",
	Instagram: "instagram",
	Facebook: "facebook",
	Linkedin: "linkedin",
	TikTok: "tikTok",
	YoutubeChanel: "youtubeChanel",
	ShowPhone: "showPhone",
	ShowWhatsapp: "showWhatsapp",
	YouTubeUrlVideo: "youTubeUrlVideo",
};
