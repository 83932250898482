import "assets/scss/search/searchFilterDialog.scss";
import { Box } from "@mui/system";
import dialogsTypes from "consts/dialogsTypes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import { addFiltersSelected } from "redux/search/actions";
import AreaFilter from "./advanceFilter/AreaFilter";
import MaxGuestsFilter from "./advanceFilter/MaxGuestsFilter";
import { Badge, Button } from "@mui/material";
import i18next from "i18next";
import AutocompleteCity from "components/ui/AutocompleteCity";
import useUser from "hooks/useUser";
import functionsService from "services/functionsService";
import LocationService from "services/location";
import _ from "lodash";
import { isMobile } from "react-device-detect";

export default ({}) => {
	const dispatch = useDispatch();
	const [extraFilters, setExtraFilters] = useState({});
	const [invisible, setInvisible] = useState(false);

	const [lastSearch, setLastSearch] = useState([]);
	const [addressObj, setAddressObj] = useState({});
	const { user } = useUser();

	const handleChangeAutoComplete = (event) => {
		let cityObj = {};
		if (!event.geometry) return;
		if (!!event.address_components) {
			const findCityObjFromAllList = event.address_components.map((add) => {
				if (add.types && add.types.includes("locality")) return add;
			});
			cityObj = findCityObjFromAllList.find((obj) => obj !== undefined);
			if (!cityObj) {
				//when no "locality" found
				const findCityObjFromAllList = event.address_components.map((add) => {
					if (add.types && add.types.includes("administrative_area_level_2")) return add;
				});
				cityObj = findCityObjFromAllList.find((obj) => obj !== undefined);
			}
		}
		const city = {
			lng: event.geometry.location.lng(),
			lat: event.geometry.location.lat(),
			placeId: event.place_id,
			city_he: cityObj?.long_name || "",
			address: event.formatted_address,
		};
		setAddressObj(city);
	};

	// const clean = () => {
	//     dispatch(addFiltersSelected({ filters: { sort: "SortByDistanceASC" }, research: true }));
	// 	dispatch(closeDialogByType(dialogsTypes.OrderBy));
	// };

	const sortOut = () => {
		if (!addressObj?.lng || !addressObj?.lat) return;
		dispatch(
			addFiltersSelected({
				filters: { sort: "SortByDistanceASC", location: addressObj },
				research: true,
			})
		);
		functionsService.addUserLocation(addressObj?.lat, addressObj.lng, addressObj.address);
		dispatch(closeDialogByType(dialogsTypes.OrderBy));
	};
	const handleLastSerach = (lastSrch) => {
		if (!lastSrch?.location?.lng || !lastSrch?.location?.lat) return;
		dispatch(
			addFiltersSelected({
				filters: { sort: "SortByDistanceASC", location: lastSrch.location },
				research: true,
			})
		);
		dispatch(closeDialogByType(dialogsTypes.OrderBy));
	};

	useEffect(async () => {
		if (!!user && user?.uid) {
			await LocationService.getUserLastSearchLocations(user.uid).then((locationObj) => {
				if (!!locationObj && !_.isEmpty(locationObj)) {
					const allLastSrch = Object.values(locationObj);
					setLastSearch(allLastSrch);
				}
			});
		}
	}, []);

	useEffect(() => {
		const element = document.querySelector(".MuiDialog-paperScrollPaper");
		if (!isMobile && element) {
			element.style.maxWidth = "600px";
			element.style.height = "90%";
		}

		return () => {
			if (element) {
				element.style.maxWidth = "initial";
				element.style.height = "initial";
			}
		};
	}, []);

	return (
		<Box className="searchFilterDialogWrapper">
			{/* <Box className="filter">
				<span onClick={clean} style={{ position: "relative" }}>
					<Badge
						sx={{ position: "absolute", top: "0", left: "0" }}
						color="error"
						variant="dot"
						invisible={invisible}></Badge>
					{i18next.t("Search.clearFilter")}
				</span>
			</Box> */}

			<Box className="mbBox">
				<Box className="filterTitle filterHeader" sx={{ margin: "0 0 10px" }}>
					<h3>{i18next.t("Search.orderByLocation")}</h3>
				</Box>
			</Box>

			<Box className="mbBox">
				<Box className="filterTitle">
					<h4>{i18next.t("Search.address")}</h4>
				</Box>
				<Box sx={{ margin: "10px 0" }}>
					<AutocompleteCity
						placeholder={i18next.t("Search.location")}
						onChangeAutoComplete={handleChangeAutoComplete}></AutocompleteCity>
				</Box>
			</Box>

			{lastSearch && !!lastSearch?.length && (
				<Box className="mbBox">
					<Box className="filterTitle">
						<h4>{i18next.t("Search.prevSearch")}</h4>
					</Box>

					{lastSearch.map((item) => (
						<button
							key={item.key}
							style={{
								border: "#386ba8 0.5px solid",
								margin: "5px",
								borderRadius: "10px",
								background: "#fff",
								padding: "5px 20px",
								minHeight: " 15px",
								fontSize: "13px",
								color: "#313030",
							}}
							onClick={() => {
								handleLastSerach(item);
							}}>
							{item?.locationName ?? ""}
						</button>
					))}
				</Box>
			)}

			<Box className="filterResults">
				<Button
					variant=""
					onClick={sortOut}
					sx={{
						borderRadius: "20px",
						color: "#fff",
						background: "#386ba8 !important",
					}}>
					{i18next.t("Search.sortOut")}
				</Button>
			</Box>
		</Box>
	);
};
