import "assets/scss/recommendation/recomendUserDialog.scss";
import { Box } from "@mui/material";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import dialogsTypes from "consts/dialogsTypes";
import UserRecomendationPage from "pages/UserRecomendationPage";
import { useState, useEffect } from "react";
import backIcon from "assets/images/svgs/back.svg";
import { closeDialogByType, openDialog } from "redux/dialog/selectors";
import UserImage from "components/talent/userImage";
import UsersTypes from "consts/UsersTypes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useNavigator from "hooks/useNavigator";

export default ({}) => {
	const [recomendUserDialog, setRecomendUserDialog] = useState({});

	const data = useSelector((state) =>
		state.dialogs.dialogs.filter((item) => item?.dialog == dialogsTypes.UserRecomendationDialog)
	);

	const { recId, userProfile } = data[0];

	useEffect(() => {
		if (!recomendUserDialog?.uid) return;

		const prevPath = window.location.pathname;
		window.history.pushState({}, "", `/user/recomendation/${recomendUserDialog?.uid}`);

		return () => {
			window.history.pushState({}, "", `${prevPath}`);
		};
	}, [recomendUserDialog]);

	return (
		<Box className="recomendUserDialog">
			<UserRecomendationDialogHeader recUser={recomendUserDialog} dialog={data} />
			<UserRecomendationPage idFromOut={recId} setRecomendUserDialog={setRecomendUserDialog} />
		</Box>
	);
};

export function UserRecomendationDialogHeader({ recUser, dialog = null }) {
	const dispatch = useDispatch();
	const { navigateToHomePage } = useNavigator();
	const [wideView, setWideView] = useState(true);

	const closePopup = () => {
		if (!!dialog) return dispatch(closeDialogByType(dialogsTypes.UserRecomendationDialog));
		return navigateToHomePage();
	};
	const openTalentPopup = () => {
		if (recUser?.type !== UsersTypes.Talent) return;

		return dispatch(openDialog({ dialog: dialogsTypes.ViewTalent, talentId: recUser?.uid }));
	};

	useEffect(() => {
		const elevationDivs = document.querySelectorAll(".MuiPaper-elevation");
		if (!elevationDivs?.length) return;
		const lastElevationDivs = elevationDivs[elevationDivs.length - 1];
		if (!lastElevationDivs) return;

		const scrollerId = lastElevationDivs.querySelector("#wrapDialogId");
		if (!scrollerId) return;
		scrollerId.style.overflow = "auto";

		const handleScroll = () => {
			const currentScrollPos = scrollerId.scrollTop;

			if (currentScrollPos > 0) {
				setWideView(false);
			} else {
				setWideView(true);
			}
		};

		scrollerId.addEventListener("scroll", handleScroll);

		return () => {
			scrollerId.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<Box className={`stickyRecHeader ${wideView ? "wideViewRec" : "backwardsAniRec"}`}>
			<Box className="innerRecHeader">
				<Box className="hBackgroundColor">
					<Box className="wrapBackBtn" onClick={closePopup}>
						<img src={backIcon} />
					</Box>
				</Box>

				<Box className="wrapHeaderContent">
					<Box className="wrapImg">
						<UserImage user={recUser} key={recUser.uid} />
					</Box>
					<Box className="wrapName">
						<span
							onClick={openTalentPopup}
							style={{
								marginRight: "3px",
								cursor: recUser?.type === UsersTypes.Talent && "pointer",
								textDecoration: recUser?.type === UsersTypes.Talent && "underline",
							}}>
							{i18next.t("talent.recommendOf")}
							<span>{` ${recUser?.name ?? ""}`}</span>
						</span>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
