import firebase from "firebase/app";
import "firebase/database";

class AuthGoogleService {
	async login() {
		const provider = new firebase.auth.GoogleAuthProvider();
		provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
		provider.addScope("https://www.googleapis.com/auth/userinfo.email");
		try {
			const authUser = firebase.auth().currentUser;
			const result = await authUser.linkWithPopup(provider);
			return {
				googleData: result.additionalUserInfo?.profile,
				googleToken: result.credential.idToken,
			};
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
}

export default new AuthGoogleService();
