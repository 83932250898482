import "assets/scss/searchBtns/searchBtns.scss";
import { Box, Tab, Tabs } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addFiltersSelected } from "redux/search/actions";
import { getFilters } from "redux/search/selectors";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import useUser from "hooks/useUser";
import AppService from "services/app";

export default function SearchBtns({ setSortOption, ...prop }) {
	const dispatch = useDispatch();
	const filters = useSelector(getFilters);
	const [platform, setPlatform] = useState();
	const { isLoggedIn, userProfile } = useUser();

	const [sort, setSort] = useState(filters?.sort ?? 0);

	const handleChange = (newValue) => {
		const valObj = {
			"": 0,
			SortByDistanceASC: 1,
			SortByRecommendationAsc: 2,
			SortFriends: 3,
			// SortAi: 3,
		};
		handleSortOption(newValue);
		setSort(valObj[newValue]);
	};
	const openLoginPopup = () => {
		dispatch(openDialog({ dialog: dialogsTypes.Login }));
	};

	const handleOpenDownloadAppPopup = () => {
		dispatch(openDialog({ dialog: dialogsTypes.DownloadApp }));
	};

	const handleSortOption = (type) => {
		if (type !== "SortByDistanceASC") {
			dispatch(addFiltersSelected({ filters: { sort: type }, research: true }));
		}
	};

	return (
		<Box className="searchBtnsWrapper" {...prop}>
			<Tabs className="searchBtns" value={sort} onChange={handleChange} centered>
				<span className="orderBy">{i18next.t("Search.orderBy")}:</span>
				<span className={`spanTab center ${sort == 0 ? "spanTabBold" : ""}`}>
					<Tab
						value={0}
						label={
							<span
								onClick={() => {
									if (sort !== 0) handleChange("");
								}}>
								{i18next.t("Search.results")}
							</span>
						}
					/>
				</span>
				<span className={`spanTab center ${sort == 3 ? "spanTabBold" : ""}`}>
					<Tab
						value={3}
						label={
							<span
								onClick={() => {
									if (!isLoggedIn) {
										return openLoginPopup();
									}

									if (sort !== 3) handleChange("SortFriends");
								}}>
								{i18next.t("Search.SortFriends")}
							</span>
						}
					/>
				</span>
				<span
					className={`spanTab center ${sort == 1 ? "spanTabBold" : ""}`}
					onClick={() => {
						dispatch(openDialog({ dialog: dialogsTypes.OrderBy }));
						if (sort !== 1) handleChange("SortByDistanceASC");
					}}>
					<Tab
						value={1}
						label={
							<span style={{ color: sort === "SortByDistanceASC" && "#000000" }}>
								{i18next.t("Search.closest")}
							</span>
						}
					/>
				</span>
				<span
					className={`spanTab center ${sort == 2 ? "spanTabBold" : ""}`}
					onClick={() => {
						if (sort !== 2) handleChange("SortByRecommendationAsc");
					}}>
					<Tab
						value={2}
						label={
							<span style={{ color: sort === "SortByRecommendationAsc" && "#000000" }}>
								{i18next.t("Search.recommended")}
							</span>
						}
					/>
				</span>

				{/* <span className={`spanTab center ${sort == 3 ? "spanTabBold" : ""}`}>
							<Tab
								value={3}
								label={
									<span
										onClick={() => {
											if (!isLoggedIn) {
												return openLoginPopup();
											}
											if (sort !== 3) handleChange("SortAi");
										}}>
										{i18next.t("Search.SortAi")}
									</span>
								}
							/>
						</span> */}
			</Tabs>
		</Box>
	);
}
