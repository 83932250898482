import { Box } from "@mui/material";
import i18next from "i18next";

export default ({ item }) => {
	return (
		<Box className="wrapOffer">
			<Box className="titleOffer">{i18next.t("work.name")}:</Box>
			<Box>{item?.name}</Box>
			<Box className="titleOffer mtOffer">{i18next.t("work.phone")}:</Box>
			<Box>{item.phone}</Box>
			<Box className="titleOffer mtOffer">{i18next.t("work.essence of reference")}:</Box>
			<Box>{item?.eventsDate}</Box>
			{item?.noteForTalent && (
				<>
					<Box className="titleOffer mtOffer">{i18next.t("work.budgetIfRelevant")}:</Box>
					<Box>{item?.noteForTalent}</Box>
				</>
			)}
		</Box>
	);
};
