import "assets/scss/landing/landingSatisfiedSuppliers.scss";
import { Box } from "@mui/material";
import TalentBox from "components/searchTalent/TalentBox";
import useSearch from "hooks/useSearch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSearchUsers } from "redux/search/selectors";
import LandingColorizedTitle from "./LandingColorizedTitle";
import i18next from "i18next";
import { FreeMode, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function LandingSatisfiedSuppliers({
	usersIds = [],
	usersFromOut = [],
	withoutHeader = false,
	freeText = "",
}) {
	const dispatch = useDispatch();
	const search = useSearch();
	const [displayUsers, setDisplayUsers] = useState(usersFromOut);
	const users = useSelector(getSearchUsers);

	useEffect(() => {
		if (!usersIds?.length || usersFromOut?.length) return;
		search.search({ uids: usersIds, freeText });
	}, [usersIds]);

	useEffect(() => {
		if (!usersIds?.length || !!usersFromOut?.length) return;
		if (users && users?.length) setDisplayUsers(users);
	}, [users]);

	const favoriteList = displayUsers?.filter((u) => u.uid);
	return (
		<Box className="landingSatisfiedSuppliers">
			{!withoutHeader && (
				<LandingColorizedTitle
					text={i18next.t("landingPage.selectionSuppliers")}
					colorText={i18next.t("landingPage.satisfied")}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "start",
					overflow: "hidden",
					width: "100%",
					maxWidth: "100%",
				}}>
				{!!displayUsers && !!displayUsers?.length && (
					<Swiper
						id="usersSwiper"
						slidesPerView={"auto"}
						spaceBetween={50}
						pagination={{
							clickable: true,
						}}
						loop={true}
						autoplay={{
							delay: 2000,
							disableOnInteraction: false,
						}}
						navigation={true}
						modules={[Autoplay, FreeMode, Pagination]}
						className="mySwiper">
						{displayUsers.map((user, index) => (
							<SwiperSlide key={index} style={{ width: "min-content" }}>
								<TalentBox favoriteList={favoriteList} user={user} webPage={false} />
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</Box>
		</Box>
	);
}
