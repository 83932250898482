import "assets/scss/blog/blogArticle.scss";
import BaseHelmet from "components/seo/BaseHelmet";
import blogService from "services/blogService";
import useNavigator from "hooks/useNavigator";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import i18next from "i18next";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { Button } from "reactstrap";
import useUser from "hooks/useUser";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "redux/notification/actions";
import { Card } from "@mui/material";
import LandingBlog from "components/landing/LandingBlog";
import LandingSatisfiedSuppliers from "components/landing/LandingSatisfiedSuppliers";
import LandingFooter from "components/landing/LandingFooter";
import useSearch from "hooks/useSearch";
import { getSearchUsers } from "redux/search/selectors";
import profile from "services/profile";
import publicProfile from "services/publicProfile";
import dialogsTypes from "consts/dialogsTypes";
import { openDialog } from "redux/dialog/selectors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { addFiltersSelected } from "redux/search/actions";
import backDescPopup from "assets/images/backDescPopup.png";
import articlesStatus from "consts/articlesStatus";
import functionsService from "services/functionsService";

export default function BlogArticle({}) {
	const dispatch = useDispatch();
	const { navigateToPath, redirectWithReload } = useNavigator();
	const { urlName } = useParams();
	const { userProfile } = useUser();
	const history = useHistory();
	const search = useSearch();
	const searchUsersSelectors = useSelector(getSearchUsers);

	const [articleData, setArticleData] = useState({});
	const [users, setUsers] = useState([]);
	const [publicUser, setPublicUser] = useState({});

	const closeBlog = () => {
		if (articleData?.categorySlug)
			return navigateToPath(`/blog/category/${articleData?.categorySlug}`);
		return navigateToPath(`/blog`);
	};

	const openTalentPopup = (uid) => {
		dispatch(openDialog({ dialog: dialogsTypes.ViewTalent, talentId: uid }));

		const currentUrl = history.location.pathname + history.location.search;
		history.push(currentUrl);
	};

	const searchByTag = (e, tagName) => {
		e.stopPropagation();
		dispatch(
			addFiltersSelected({
				filters: { freeText: ["עיצוב אתרים"], tagsKeys: "", category: "", research: true },
			})
		);
		navigateToPath(`/search?freeText=${tagName}&tagsKeys=&category=`);
	};

	const shareArticle = async () => {
		if (!articleData?.slug) return;

		const affId = !!userProfile?.internalID ? `?affId=${userProfile?.internalID}` : "";
		const articleUrl = `${window.location.origin}/blog/${articleData?.slug}${affId}`;
		if (!!isMobile) {
			try {
				return await navigator.share({
					title: `מצאתי מאמר באתר "ששת ימים" שיכול לעניין אותך`,
					text: `${articleData?.title}`,
					url: articleUrl,
				});
			} catch (error) {
				console.log(error);
			}
		} else {
			navigator.clipboard
				.writeText(articleUrl)
				.then(() => dispatch(openNotification(i18next.t("notificationActions.copyLink"))));
		}
	};

	useEffect(async () => {
		if (articleData?.tags && !!articleData.tags?.length) {
			const tagsKeys = (articleData?.tags || []).map((tag) => tag.key);
			search.search({ tagsKeys });
		}
		if (!!articleData?.linkedAuthor) {
			publicProfile.fetchProfile(articleData?.linkedAuthor).then((pUser) => {
				if (!pUser || !pUser?.uid) return;
				setPublicUser(pUser);
			});
		}
	}, [articleData]);

	useEffect(async () => {
		if (!!searchUsersSelectors && !!searchUsersSelectors?.length) setUsers(searchUsersSelectors);
	}, [searchUsersSelectors]);

	useEffect(async () => {
		if (!urlName) return navigateToPath("/blog");

		await blogService.getArticleByUrlName(urlName).then((data) => {
			if (!data) return navigateToPath("/blog");
			const art = Object.values(data || {})[0];
			if (
				!art ||
				(window.location.search !== "?draft=t" && art?.status !== articlesStatus.published)
			)
				return navigateToPath("/blog");
			blogService.updateArticleViews(art.key);
			setArticleData(art);
		});

		if (!isMobile) {
			const element = document.querySelector(".wrapSearchWebHeader");

			if (element) {
				element.style.position = "fixed";
				element.style.zIndex = "10";
			}
		}
	}, []);

	useEffect(() => {
		functionsService.setOnline();

		dispatch(
			addFiltersSelected({
				filters: { freeText: "", tagsKeys: "", category: "" },
			})
		);

		return () => {
			const element = document.querySelector(".wrapSearchWebHeader");
			if (element) {
				element.style.position = "initial";
				element.style.zIndex = "initial";
			}
		};
	}, []);
	return (
		<>
			{!!articleData && (
				<>
					<BaseHelmet
						title={articleData?.title ? `ששת ימים - ${articleData?.title}` : "ששת ימים"}
						description={articleData?.description ? articleData?.description : "מאמר"}
						url={`blog/${articleData?.slug}`}></BaseHelmet>
					<Box className="wrapArticlePage" style={{ paddingBottom: !!isMobile && "84px" }}>
						<Box className="bigImg">
							{!!isMobile && articleData?.key && (
								<Box className="wrapShareAndBack">
									<Box className="wrapBackBlog">
										<img src={backDescPopup} onClick={closeBlog} />
									</Box>
									<Button onClick={shareArticle}>שיתוף</Button>
								</Box>
							)}

							{!articleData?.panoramicImage ? (
								<Skeleton animation="pulse" className="skeleton imgSkeleton" />
							) : (
								<img src={articleData?.panoramicImage} alt="img" />
							)}
						</Box>

						<Box className="articlePage">
							<Box className="articleWrapper">
								{!isMobile && (
									<Box className="wrapBackBlog">
										<img style={{ cursor: "pointer" }} src={backDescPopup} onClick={closeBlog} />
									</Box>
								)}
								<Box className="wrapHeaderContent">
									{!articleData?.key ? (
										<>
											<Skeleton animation="wave" className="skeleton titleSkeleton" />
											<Skeleton animation="wave" className="skeleton authorSkeleton" />
										</>
									) : (
										<>
											<h2>{articleData?.title}</h2>
											{!!isMobile && <Box className="descText">{articleData?.description}</Box>}
											<Box className="authorAndShare">
												<Box className="authorArticle">
													<span
														onClick={() => {
															if (!!publicUser?.uid) openTalentPopup(publicUser?.uid);
														}}
														className={`name ${
															!!publicUser?.uid && !!publicUser?.profileImage?.url ? "withImg" : ""
														}`}>
														<>
															{!!publicUser?.uid && !!publicUser?.profileImage?.url && (
																<Box className="wrapPublicUserImg">
																	<img src={publicUser?.profileImage?.url} />
																</Box>
															)}
														</>
														{articleData?.author}
													</span>

													<Box className="wrapViewNDate">
														<span>{moment(articleData?.publishData).format("DD.MM.YYYY")}</span>
														{/* <span>{`${articleData?.views ? articleData?.views : 0} צפיות`}</span> */}
													</Box>
												</Box>
												{!isMobile && <Button onClick={shareArticle}>שיתוף</Button>}
											</Box>
										</>
									)}
								</Box>

								<Box className="wrapContentAndMoreArt">
									<Box className="wrapContent">
										{!articleData?.key ? (
											<>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"100%"}
													height={"100px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"60%"}
													height={"100px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"100%"}
													height={"80px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"70%"}
													height={"200px"}
												/>
												<Skeleton
													animation="wave"
													className="skeleton mb-3"
													width={"80%"}
													height={"50px"}
												/>
											</>
										) : (
											<>
												{!isMobile && <Box className="descText">{articleData?.description}</Box>}

												{!!articleData?.content && (
													<div
														className="contentText"
														dangerouslySetInnerHTML={{ __html: articleData?.content }}></div>
												)}
												{!!articleData?.tags && !!articleData?.tags?.length && (
													<Box className="wrapFooterTags">
														{articleData.tags.map((tag, index) => (
															<button
																onClick={(e) => searchByTag(e, tag.name)}
																className="tag"
																key={`tag${index}`}>
																{tag?.name}
															</button>
														))}
													</Box>
												)}
											</>
										)}
									</Box>
									{!isMobile && (
										<Box className="moreArticle">
											<LandingBlog
												withoutHeader={true}
												countBlogs={5}
												sortBy={articleData?.categoryKey || ""}
												blogKey={articleData?.key}
											/>
										</Box>
									)}
								</Box>
								{!!users && !!users?.length && (
									<Box className="wrapSuppliers">
										<h4 className="suppliersTitle">המומחים של ששת ימים בתחום</h4>
										<LandingSatisfiedSuppliers withoutHeader={true} usersFromOut={users} />
									</Box>
								)}
							</Box>
						</Box>
						<LandingFooter />
					</Box>
				</>
			)}
		</>
	);
}
