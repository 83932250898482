import produce from "immer";
import tags from "services/tags";

import * as types from "./actionTypes";

const initialState = {
	tags: [],
};

export default produce((state, action) => {
	switch (action.type) {
		case types.INIT_TAGS_SUCCESSFULLY:
			return { ...state, tags: action.payload };
		case types.TAG_ADDED:
			return { ...state, tags: [...state.tags, action.payload] };
	}
}, initialState);
