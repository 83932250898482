import { Box } from "@mui/material";
import i18next from "i18next";
import logo from "assets/images/svgs/landing/flat-logo-white.svg";
import instagram from "assets/images/svgs/landing/instagram.svg";
import facebook from "assets/images/svgs/landing/facebook.svg";
import linkedin from "assets/images/svgs/landing/linkedin.svg";
import twitter from "assets/images/svgs/landing/tweeter.svg";
import youtube from "assets/images/svgs/landing/youtube.svg";
import useNavigator from "hooks/useNavigator";

export default function FooterLinkBtns() {
	const { navigateToHomePage } = useNavigator();

	const links = [
		{ img: instagram, url: "https://sheshet.co.il/" },
		{ img: facebook, url: "https://sheshet.co.il/" },
		{ img: linkedin, url: "https://sheshet.co.il/" },
		{ img: twitter, url: "https://sheshet.co.il/" },
		{ img: youtube, url: "https://sheshet.co.il/" },
	];

	const openLink = (url) => {
		window.open(url, "_blank");
	};

	return (
		<Box className="boxWrapper footerLinkBtns">
			<img src={logo} alt="" style={{ cursor: "pointer" }} onClick={navigateToHomePage} />
			<Box className="wrapLinks">
				<Box className="followUs">{i18next.t("landingPage.followUs")}</Box>

				<Box className="links">
					{links.map((item, index) => (
						<img key={index} onClick={() => openLink(item.url)} src={item.img} />
					))}
				</Box>
			</Box>
		</Box>
	);
}
