import * as types from "./actionTypes";

export const setSelectedChat = (payload) => {
	return { type: types.CHATS_SET_SELECTED, payload };
};

export const cleanSelectedChat = () => {
	return { type: types.CHATS_SET_SELECTED };
};

export const addRemoveToSelectedList = (id, isPin) => {
	return { type: types.CHATS_SET_SELECTED_LIST, payload: { id, isPin } };
};

export const resetToSelectedList = (payload) => {
	return { type: types.CHATS_RESET_SELECTED_LIST, payload };
};
