import firebase from 'firebase/app';
import "firebase/database";

class PublicProfileService {
  BASE_REF = 'users_public_profile';

  async fetchProfile(uid) {
    return (
      await firebase.database()
        .ref(this.BASE_REF)
        .child(uid)
        .once('value')
    ).val();
  }
}

export default new PublicProfileService();
