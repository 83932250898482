import { createSelector } from "reselect";
import { isNil } from "lodash";

import { getSubscriptionLimits } from "../system/selectors";
import SubscriptionStatus from "consts/subscriptionStatus";
import Subscription from "consts/subscription";
import SubscriptionLimits from "consts/subscriptionLimits";
import ApiRequestStatus from "consts/apiRequestStatus";

const getSubscription = (state) => state.subscription;

export const getSubscriptionData = createSelector(getSubscription, (subscription) => subscription.data);

export const getUserSubscriptionType = createSelector(getSubscriptionData, (subscription) => {
  return isNil(subscription) ||
    isNil(subscription?.status) ||
    subscription?.status === SubscriptionStatus.EXPIRED ||
    subscription?.status === SubscriptionStatus.NONE
    ? Subscription.NONE
    : subscription.type;
});

export const getReadMessagesLimits = createSelector(
  getSubscriptionLimits,
  (subscriptionLimits) => subscriptionLimits[SubscriptionLimits.ReadMessages]
);

export const canReadMessages = createSelector(
  getUserSubscriptionType,
  getReadMessagesLimits,
  (subscriptionType, subscriptionReadMessageLimits) => subscriptionReadMessageLimits?.[subscriptionType] === true
);

export const getSubscriptionPlans = createSelector(getSubscription, (subscriptions) => subscriptions?.plans);

export const getSelectedPlan = createSelector(getSubscription, (subscriptions) => {
  return subscriptions.selectedPlan;
});

export const getFetchCardcomApiStatus = createSelector(
  getSubscription,
  (subscriptions) => subscriptions.fetchCardcomApiStatus
);

export const getFetchSubscriptionPlansApiStatus = createSelector(
  getSubscription,
  (subscriptions) => subscriptions.fetchSubscriptionPlansApiStatus
);

export const getCardComUrl = createSelector(getSubscription, (subscriptions) => subscriptions.cardComUrl);

export const getIsPurchaseDone = createSelector(
  getSubscription,
  (subscriptions) =>
    subscriptions.purchaseStatus === ApiRequestStatus.SUCCESSFULLY ||
    subscriptions.purchaseStatus === ApiRequestStatus.FAILED
);

export const getPurchaseStatus = createSelector(getSubscription, (subscriptions) => subscriptions.purchaseStatus);

export const getSubscriptionPlan = createSelector(getSubscription, (subscriptions) => {
  const key = subscriptions.data?.selected_plan;
  return subscriptions.plans?.find((plan) => plan.key === key) || {};
});
