import moment from "moment";

export const isSameDay = (currentMessage, diffMessage) => {
	if (!diffMessage || !diffMessage.updated) {
		return false;
	}

	const currentCreatedAt = moment(currentMessage.updated);
	const diffCreatedAt = moment(diffMessage.updated);

	if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
		return false;
	}

	return currentCreatedAt.isSame(diffCreatedAt, "day");
};

export const getAgeByDob = (dob) => {
	return moment().diff(dob * 1000, "years");
};
