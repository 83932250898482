import "assets/scss/searchHeader/searchHeader.scss";
import { Box } from "@mui/material";
import i18next from "i18next";
import _ from "lodash";
import SearchFilterDialog from "components/searchTalent/SearchFilterDialog";
import SearchCategory from "components/searchTalent/categoriesTags/SearchCategory";

export default () => {
	return (
		<>
			<Box className="searchFilterDialogWrapper">
				<Box className="mbBox" style={{ marginTop: 0 }}>
					<Box className="filterTitle">
						<h4>{i18next.t("Search.searchByCategory")}</h4>
					</Box>
				</Box>
				<SearchCategory webPage={true} />
			</Box>

			{/* <SearchFilterDialog webPage={true} /> */}
		</>
	);
};
