import { Button } from "@mui/material";
import { Box } from "@mui/system";
import ViewTalent from "components/talent/ViewTalent";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useParams } from "react-router-dom";
import useUser from "hooks/useUser";
import arrowBack from "assets/images/svgs/arrowBack.svg";
import { isMobile } from "react-device-detect";
import UserWebPopup from "components/searchTalent/UserWebPopup";
import backIcon from "assets/images/svgs/back.svg";

export default () => {
	const { userProfile } = useUser();
	const [congratulations, setCongratulations] = useState(true);
	const [user, setUser] = useState({});

	useEffect(() => {
		if (!!userProfile?.uid) {
			setUser(userProfile);
		}
	}, [userProfile]);

	return (
		<Box className="thanksSuppliersPage">
			{congratulations && (
				<Box
					className="center"
					sx={{
						background:
							"linear-gradient(0deg, rgb(81 86 162 / 90%) 30%, rgb(56 107 168 / 90%) 60%, rgb(22 129 135 / 90%) 100%)",
						zIndex: 10001,
						width: "100%",
						height: "100%",
						position: "fixed",
					}}>
					<Box
						className="center"
						sx={{
							width: "100%",
							flexDirection: "column",
							gap: "30px",
						}}>
						<Box
							sx={{
								color: "#fff",
								textAlign: "center",
								fontWeight: "700",
								fontSize: "24px",
								lineHeight: 1,
								padding: "0 20px",
							}}>
							{"הפרופיל שלך נוצר בהצלחה!"}
						</Box>
						<Box
							sx={{
								color: "#fff",
								textAlign: "center",
								fontWeight: "700",
								fontSize: "36px",
								lineHeight: 1,
								padding: "0 20px",
							}}>
							{"ברוך הבא לנבחרת המומחים של ששת!"}
						</Box>
						<CloseIcon
							onClick={() => setCongratulations(false)}
							sx={{
								cursor: "pointer",
								color: "#fff",
								fontSize: "30px",
							}}
						/>
						{isMobile && (
							<Box
								sx={{
									maxWidth: "260px",
									color: "#D9D9D9",
									textAlign: "center",
									fontWeight: "400",
									fontSize: "16px",
									lineHeight: 1,
									margin: "20px",
									padding: "20px",
									border: "1px solid #D9D9D9",
									borderRadius: "30px",
									position: "absolute",
									bottom: "calc(env(safe-area-inset-bottom) + 104px)",
								}}>
								{
									"בלחיצה על הלשונית פרופיל בתפריט תוכל בכל זמן לערוך ולשנות את המידע על העסק ואת הפרטים שמילאת בתהליך ההרשמה."
								}
							</Box>
						)}
					</Box>
				</Box>
			)}

			{user?.uid && (
				<>
					{isMobile ? (
						<ViewTalent talentId={user?.uid} telentFromOutSide={user} />
					) : (
						<UserWebPopup talent={user} noBack={true} />
					)}
				</>
			)}
		</Box>
	);
};
