import "assets/scss/recommendation/userFriendsRec.scss";
import useUser from "hooks/useUser";
import UserRecomendationPage from "./UserRecomendationPage";
import { useEffect, useState } from "react";
import recommendations from "services/recommendations";
import { Box, Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import recLike from "assets/images/svgs/newRec/newRecIcon.svg";

export default () => {
	const { isLoggedIn } = useUser();
	const history = useHistory();
	const [userFriendsRec, setUserFriendsRec] = useState([]);
	const [loadingData, setLoadingData] = useState(true);

	useEffect(() => {
		if (!isLoggedIn) return;

		recommendations.getFriendsRecommendations().then((data) => {
			if (!!data?.data?.data?.length) {
				const friends = data?.data.data.filter((item) => {
					return item?.talentId !== item?.uid;
				});

				setUserFriendsRec(friends);
				setLoadingData(false);
			} else {
				setLoadingData(false);
			}
		});
	}, [isLoggedIn]);

	return (
		<>
			{!loadingData ? (
				<Box className="wrapUserFriendsRec">
					<Box className="stickyHeaderFriendsRec">
						<Box className="center wrapInnerRecLike">
							<img src={recLike} />
						</Box>
					</Box>
					<Box className="center wrapTitleRecFriends">
						<h4>{"המלצות אחרונות על"}</h4>
						<h4 style={{ fontWeight: "700" }}>{"המומחים של ששת"}</h4>
					</Box>
					<UserRecomendationPage userFriendsRec={userFriendsRec} withoutHeader={true} />
				</Box>
			) : (
				<Box
					className="wrapSkeletons"
					sx={{
						width: "100%",
						height: "90vh",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Skeleton className="skeleton" animation="pulse" height="135px" width="100%" />
					<Skeleton className="skeleton" animation="pulse" height="35px" width="100%" />
					<Skeleton className="skeleton" animation="pulse" height="180px" width="80%" />
					<Skeleton className="skeleton" animation="pulse" height="35px" width="100%" />
					<Skeleton className="skeleton" animation="pulse" height="180px" width="80%" />
				</Box>
			)}
		</>
	);
};
