import "assets/scss/searchWeb/searchWebTabs.scss";

import { Box, Tab, Tabs, Badge, Button } from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addFiltersSelected } from "redux/search/actions";
import { getFilters, getFiltersSelected } from "redux/search/selectors";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import SearchTags from "components/searchTalent/SearchTags";
import useSearch from "hooks/useSearch";
import SearchIcon from "@mui/icons-material/Search";
import _ from "lodash";
import badgeImg from "assets/images/talent/badge.png";
import useUser from "hooks/useUser";
import AppService from "services/app";

export default function SearchWebTabs({ setSortOption, ...prop }) {
	const dispatch = useDispatch();
	const filters = useSelector(getFilters);
	const search = useSearch();
	const advanceSearchData = useSelector(getFiltersSelected);
	const [invisible, setInvisible] = useState(true);
	const [sort, setSort] = useState(filters?.sort ?? 0);
	// const [platform, setPlatform] = useState();
	const { isLoggedIn, userProfile } = useUser();

	const handleChange = (newValue) => {
		const valObj = {
			"": 0,
			SortByDistanceASC: 1,
			SortByRecommendationAsc: 2,
			SortFriends: 3,
			// SortAi: 3,
		};
		handleSortOption(newValue);
		setSort(valObj[newValue]);
	};

	const handleSortOption = (type) => {
		if (type !== "SortByDistanceASC") {
			dispatch(addFiltersSelected({ filters: { sort: type }, research: true }));
		}
	};

	const handleSubmit = async () => {
		//const input = document.getElementById("tags-standard");
		let obj = { category: "" };
		/* 	if (input.value) {
			obj = { ...obj, freeText: [input.value] };

			input.value = "";
		} */
		search.search(obj);
	};
	const openLoginPopup = () => {
		dispatch(openDialog({ dialog: dialogsTypes.Login }));
	};

	const handleOpenDownloadAppPopup = async () => {
		dispatch(openDialog({ dialog: dialogsTypes.DownloadApp }));
	};

	useEffect(() => {
		if (
			(!!advanceSearchData?.area && !!advanceSearchData?.area?.length) ||
			!!advanceSearchData?.maximumNumberOfGuests
		) {
			setInvisible(false);
		} else {
			setInvisible(true);
		}
	}, [advanceSearchData]);

	// useEffect(async () => {
	// 	const platformApp = await AppService.getPlatform();
	// 	setPlatform(platformApp);
	// }, []);

	return (
		<Box className="stickyWebWrapper">
			<Box className="searchWebTabsWrapper" {...prop}>
				{/* <Box className="center webSearch">
					<SearchTags>
						<Box className="wrapSearchAndFilter">
							<Box>
								<Button onClick={handleSubmit} className="center searchBtn">
									<SearchIcon style={{ color: "#000", width: "25px" }} />
								</Button>
							</Box>
						</Box>
					</SearchTags>
				</Box> */}
				<Box className="center wrapTabsWeb">
					<span style={{ color: "#000000", width: "max-content" }}>
						{i18next.t("Search.orderBy")}:
					</span>
					<Tabs className="searchBtns" value={sort} onChange={handleChange} centered>
						<span className={`spanTab center ${sort === 0 ? "spanTabBold" : ""}`}>
							<Tab
								value={0}
								label={
									<span
										onClick={() => {
											if (sort !== 0) handleChange("");
										}}>
										{i18next.t("Search.results")}
									</span>
								}
							/>
						</span>
						<span className={`spanTab center ${sort === 3 ? "spanTabBold" : ""}`}>
							<Tab
								value={3}
								label={
									<span
										onClick={() => {
											if (!isLoggedIn) {
												return openLoginPopup();
											}

											if (sort !== 3) handleChange("SortFriends");
										}}>
										{i18next.t("Search.SortFriends")}
									</span>
								}
							/>
						</span>
						<span
							className={`spanTab center ${sort === 1 ? "spanTabBold" : ""}`}
							onClick={() => {
								dispatch(openDialog({ dialog: dialogsTypes.OrderBy }));
								if (sort !== 1) handleChange("SortByDistanceASC");
							}}>
							<Tab
								value={1}
								label={
									<span style={{ color: sort === "SortByDistanceASC" && "#fff" }}>
										{i18next.t("Search.closest")}
									</span>
								}
							/>
						</span>
						<span
							className={`spanTab center ${sort == 2 ? "spanTabBold" : ""}`}
							onClick={() => {
								if (sort !== 2) handleChange("SortByRecommendationAsc");
							}}>
							<Tab
								value={2}
								label={
									<span style={{ color: sort === "SortByRecommendationAsc" && "#fff" }}>
										{i18next.t("Search.recommended")}
									</span>
								}
							/>
						</span>

						{/* <span className={`spanTab center ${sort == 3 ? "spanTabBold" : ""}`}>
							<Tab
								value={3}
								label={
									<span
										onClick={() => {
											if (!isLoggedIn) {
												return openLoginPopup();
											}
											if (sort !== 3) handleChange("SortAi");
										}}>
										{i18next.t("Search.SortAi")}
									</span>
								}
							/>
						</span> */}
					</Tabs>
				</Box>
			</Box>
		</Box>
	);
}
