import "assets/scss/profile/editAccount.scss";
import { Box } from "@mui/material";
import useUser from "hooks/useUser";
import functionsService from "services/functionsService";
import { openNotification } from "redux/notification/actions";
import { useDispatch } from "react-redux";
import i18next from "i18next";

import AccountDetails from "./editAccount/AccountDetails";
import MoreDetails from "./editAccount/MoreDetails";
import CustomersContactMe from "./editAccount/CustomersContactMe";
import EmailDetails from "./editAccount/EmailDetails";
import SocialLinks from "./editAccount/SocialLinks";
import UsersTypes from "consts/UsersTypes";
import ClientAccountDetails from "./editAccount/ClientAccountDetails";

export default ({}) => {
	const { user } = useUser();
	const dispatch = useDispatch();

	const handleSave = async (key, value) => {
		await functionsService.updateProfile({ [key]: value });
		return dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
	};

	return (
		<Box className="edit-account-single-page">
			{user.type === UsersTypes.Talent && (
				<>
					<AccountDetails user={user} handleSave={handleSave} />
					<MoreDetails user={user} handleSave={handleSave} />
					<CustomersContactMe user={user} handleSave={handleSave} />
					<EmailDetails user={user} handleSave={handleSave} />
					<SocialLinks user={user} handleSave={handleSave} />
				</>
			)}
			{user.type === UsersTypes.Client && (
				<ClientAccountDetails user={user} handleSave={handleSave} />
			)}
		</Box>
	);
};
