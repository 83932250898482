import "assets/scss/profile/editAccount.scss";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import AutocompleteCity from "components/ui/AutocompleteCity";
import functionsService from "services/functionsService";
import { openNotification } from "redux/notification/actions";
import { isMobile } from "react-device-detect";

export default function EditCityLine({ valueField = "", fieldName = "", subField = "" }) {
	const dispatch = useDispatch();
	const [value, setValue] = useState(valueField);
	const [displayValue, setDisplayValue] = useState(valueField);
	const [cityObj, setCityObj] = useState();
	const [showEditField, setShowEditField] = useState(true);

	const handleChangeCity = (event) => {
		let cityObj = {};

		if (!event.geometry) return;
		if (!!event.address_components) {
			const findCityObjFromAllList = event.address_components.map((add) => {
				if (add.types && add.types.includes("locality")) return add;
			});
			cityObj = findCityObjFromAllList.find((obj) => obj !== undefined);
			if (!cityObj) {
				//when no "locality" found
				const findCityObjFromAllList = event.address_components.map((add) => {
					if (add.types && add.types.includes("administrative_area_level_2")) return add;
				});
				cityObj = findCityObjFromAllList.find((obj) => obj !== undefined);
			}
		}
		const city = {
			lng: event.geometry.location.lng(),
			lat: event.geometry.location.lat(),
			placeId: event.place_id,
			city_he: cityObj?.long_name || "",
			address: event.formatted_address,
		};
		setValue(city.address);
		setDisplayValue(city.address);

		setCityObj(city);
	};

	const saveField = async () => {
		if (value === valueField) return setShowEditField(false);

		setShowEditField(false);
		await functionsService.updateProfile(cityObj);
		return dispatch(openNotification(i18next.t("Edit Profile.Successfully")));
	};

	useEffect(() => {
		setShowEditField(value !== valueField);
	}, [value]);

	return (
		<Box className="edit-text-line" style={{ borderBottom: isMobile && "0" }}>
			<Box className="edit-text-title-field">
				<Box className="noSelection field-name">
					<Box className="">{fieldName}</Box>
					{subField && <Box className="sub-title">{subField}</Box>}
				</Box>
				<Box className="field-value">
					<AutocompleteCity
						onChange={(e) => setDisplayValue(e.target.value)}
						placeholder={i18next.t("register.businessLocation")}
						defaultValue={displayValue}
						onChangeAutoComplete={handleChangeCity}
						value={displayValue}></AutocompleteCity>
				</Box>
			</Box>
			<Box className="edit-text-btns-field">
				{!showEditField ? (
					<></>
				) : (
					<Box className="save-edit">
						<Button className="editBtn saveBtn" onClick={saveField} variant="outlined">
							<span>{i18next.t("editProfile.save")}</span>
						</Button>
						<Button
							className="editBtn cancelBtn"
							onClick={() => setValue(valueField)}
							variant="outlined">
							<span>{i18next.t("editProfile.cancel")}</span>
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	);
}
