import i18next from "i18next";

import SearchTalentPage from "./SearchTalentPage";
import { Box } from "@mui/material";
import SearchHeader from "components/searchTalent/SearchHeader";
import BaseHelmet from "../components/seo/BaseHelmet";

export default () => {
	return (
		<Box sx={{ overflow: "scroll" }}>
			<BaseHelmet
				title={i18next.t("seo.homePage.title")}
				description={i18next.t("seo.homePage.description")}
				url={`search${window.location.search}`}></BaseHelmet>
			{/* <Box className="wrapSearchHeaderContent">
				<Box
					sx={{
						position: "relative",
						minHeight: "18px",
						background: "#168187",
						width: "100%",
					}}></Box>
				<Box
					sx={{
						padding: "0 20px",
					}}>
					<SearchHeader />
				</Box>
			</Box> */}
			{/* <Box
				sx={{
					position: "relative",
					minHeight: "77px",
					background: "#fff",
					width: "100%",
				}}></Box> */}
			<SearchTalentPage></SearchTalentPage>
		</Box>
	);
};
