import "assets/scss/tabsRouter/tabsRouter.scss";
import { Box, Button, Tab, Tabs } from "@mui/material";
import useNavigator from "hooks/useNavigator";
import useUser from "hooks/useUser";
import i18next from "i18next";
import { useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import profileGrayIcon from "assets/images/svgs/menu-profile.svg";
import profileIcon from "assets/images/svgs/menu-profile-active.svg";
import chatIndicatorIcon from "assets/images/svgs/menu-messages-Unread-active.svg";
import chatIndicatorGrayIcon from "assets/images/svgs/menu-messages-Unread.svg";
import chatGrayIcon from "assets/images/svgs/menu-message.svg";
import chatIcon from "assets/images/svgs/menu-message-active.svg";
import heartIcon from "assets/images/svgs/menu-saved-active.svg";
import heartGrayIcon from "assets/images/svgs/menu-saved.svg";
import searchGrayIcon from "assets/images/svgs/menu-search.svg";
import searchIcon from "assets/images/svgs/menu-search-active.svg";
import RecommendationsService from "services/recommendations";
import { useEffect } from "react";
import usePopups from "hooks/usePopups";
import popupsTypes from "consts/popupsTypes";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { useSelector } from "react-redux";
import UsersTypes from "consts/UsersTypes";
import chatService from "services/chatService";
import recommendations from "services/recommendations";
import functionsService from "services/functionsService";
import logoSixDaysMob from "assets/images/svgs/landing/logoSixDaysMob.svg";
import AppService from "services/app";

export default ({ userProfile = {} }) => {
	const navigator = useNavigator();
	const { isLoggedIn } = useUser();
	let isHomePage = useRouteMatch("/");
	let { pathname } = useLocation();
	const arrPath = pathname.split("/");
	const isProfile = arrPath.includes("profile");
	const [value, setValue] = useState(isHomePage.isExact ? 1 : isProfile ? 2 : 0);
	const [profileIndicator, setProfileIndicator] = useState(0);
	const [chatIndicator, setChatIndicator] = useState(0);
	const [platform, setPlatform] = useState();
	const { showPopupHandler } = usePopups();
	const dispatch = useDispatch();
	const selectedChat = useSelector((state) => state.chats.selectedChat);

	const handleChange = (event, newValue) => {
		if (newValue !== -1) window.scrollTo(0, 0);
		functionsService.setOnline();
		switch (newValue) {
			case -1:
				dispatch(openDialog({ dialog: dialogsTypes.Login }));
				setValue(1);
				return;
			case 0:
				navigator.navigateToPath("/chat");
				break;
			case 1:
				navigator.navigateToPath("/search");
				break;
			case 2:
				navigator.navigateToPath("/profile");
				break;
			case 3:
				navigator.navigateToPath("/recomendationFriends");
				break;
		}
		setValue(newValue);
	};

	// const getImageIcon = () => {
	// 	if (value === 0) {
	// 		if (chatIndicator >= 1) {
	// 			return <img src={chatIndicatorIcon} />;
	// 		}
	// 		return <img src={chatIcon} />;
	// 	} else {
	// 		if (chatIndicator >= 1) {
	// 			return <img src={chatIndicatorGrayIcon} />;
	// 		}
	// 		return <img src={chatGrayIcon} />;
	// 	}
	// };

	let match = useRouteMatch({
		path: "/talent",
		exact: true,
	});

	useEffect(() => {
		if (userProfile && userProfile.type == UsersTypes.Talent) {
			const listenRecommendationAdded = chatService
				.listenChatsUnread(userProfile.uid)
				.subscribe((data) => {
					setChatIndicator(data);
				});

			return () => {
				listenRecommendationAdded.unsubscribe();
			};
		}
	}, [userProfile]);

	useEffect(() => {
		if (
			pathname == "/search" ||
			pathname == "/profile" ||
			pathname == "/recomendationFriends" ||
			pathname == "/chat"
		) {
			setValue(
				pathname == "/search"
					? 1
					: pathname.includes("/chat")
					? 0
					: pathname == "/recomendationFriends"
					? 3
					: 2
			);
		}
		if (pathname == "/") setValue(-2);
	}, [pathname]);

	useEffect(async () => {
		if (!!platform) return;
		const platformApp = await AppService.getPlatform();
		setPlatform(platformApp);
	}, []);

	useEffect(() => {
		if (!userProfile?.uid) return;
		const listenNotifications = functionsService
			.listenUnreadNotification$(userProfile.uid)
			.subscribe((data) => {
				const sumCount = Object.values(data || {}).reduce(
					(accumulator, currentValue) => accumulator + (currentValue?.count || 0),
					0
				);
				setProfileIndicator(sumCount);
			});

		return () => {
			listenNotifications.unsubscribe();
		};
	}, [userProfile?.uid]);

	return (
		<>
			{(value || !selectedChat) && (
				<Box
					className="tabsWrapper"
					sx={{
						bgcolor: "background.paper",
					}}>
					{match && "> "}
					{!isLoggedIn ? (
						<Button
							onClick={() => {
								dispatch(openDialog({ dialog: dialogsTypes.Login }));
							}}
							className="connectBtn">
							{i18next.t("tabs.connect")}
						</Button>
					) : (
						<Tabs className="tabs" value={value} onChange={handleChange} centered>
							<span
								className="spanTab"
								onClick={() => {
									handleChange("", 1);
								}}>
								<Tab
									label={
										<span className="spanTabText" style={{ color: value === 1 && "#000000" }}>
											{i18next.t("tabs.searchTalent")}
										</span>
									}
									icon={value === 1 ? <img src={searchIcon} /> : <img src={searchGrayIcon} />}
									className="tabs-search"
								/>
							</span>
							<span
								className="spanTab"
								onClick={() => {
									handleChange("", isLoggedIn ? 0 : -1);
								}}>
								{chatIndicator >= 1 && <span className="indicator">{chatIndicator}</span>}
								<Tab
									label={
										<span className="spanTabText" style={{ color: value === 0 && "#000000" }}>
											{i18next.t("tabs.message")}
										</span>
									}
									icon={value === 0 ? <img src={chatIcon} /> : <img src={chatGrayIcon} />}
									className="tabs-message"
								/>
							</span>
							<span
								className="spanTab"
								onClick={() => {
									handleChange("", isLoggedIn ? 2 : -1);
								}}>
								{profileIndicator >= 1 && <span className="indicator">{profileIndicator}</span>}
								<Tab
									label={
										<span className="spanTabText" style={{ color: value === 2 && "#000000" }}>
											{i18next.t("tabs.profile")}
										</span>
									}
									icon={value === 2 ? <img src={profileIcon} /> : <img src={profileGrayIcon} />}
									className="tabs-profile"
								/>
							</span>
							<span
								className="spanTab"
								onClick={() => {
									if (!userProfile?.syncContactsToNeo4j && platform === "web") {
										return dispatch(openDialog({ dialog: dialogsTypes.DownloadApp }));
									}
									handleChange("", isLoggedIn ? 3 : -1);
								}}>
								<span className="isNew">חדש!</span>
								<Tab
									label={
										<span className="spanTabText" style={{ color: value === 3 && "#000000" }}>
											{i18next.t("tabs.friendsRec")}
										</span>
									}
									icon={value === 3 ? <img src={heartIcon} /> : <img src={heartGrayIcon} />}
									className="tabs-favorites"
								/>
							</span>
							{/* <span
								className="spanTab"
								onClick={() => {
									handleChange("", isLoggedIn ? 3 : -1);
								}}>
								<Tab
									label={
										<span className="spanTabText" style={{ color: value === 3 && "#000000" }}>
											{i18next.t("tabs.favorites")}
										</span>
									}
									icon={value === 3 ? <img src={heartIcon} /> : <img src={heartGrayIcon} />}
									className="tabs-favorites"
								/>
							</span> */}
						</Tabs>
					)}
					<Box className="wrapSiteLogo">
						<img
							onClick={() => {
								if (pathname !== "/") return navigator.navigateToPath("/?cfs=t");
								window.scrollTo(0, 0);
							}}
							src={logoSixDaysMob}
							alt="logo"
						/>
					</Box>
				</Box>
			)}
		</>
	);
};
