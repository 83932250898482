import { Box } from "@mui/material";
import List from "@mui/material/List";
import i18next from "i18next";
import SettingListItem from "components/profile/settings/SettingListItem";
import SendReport from "components/chat/chatConversation/SendReport";
import { useDispatch, useSelector } from "react-redux";
import { closeDialogByType } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";

export default ({}) => {
	const dispatch = useDispatch();
	const { colleagueId } = useSelector((state) => state.dialogs.dialogs[0]);

	return (
		<Box className="wrapReport" sx={{ padding: "20px" }}>
			<List
				sx={{ width: "100%", background: "background.paper" }}
				component="nav"
				aria-labelledby="nested-list-subheader">
				<SettingListItem name={i18next.t("reportUser.spam")}>
					<Box sx={{ padding: "0 20px" }}>
						<SendReport
							closeDrawer={() => {
								dispatch(closeDialogByType(dialogsTypes.ReportUser));
							}}
							colleagueId={colleagueId}
							type="spam"
						/>
					</Box>
				</SettingListItem>
				<SettingListItem name={i18next.t("reportUser.inappropriateMsg")}>
					<Box sx={{ padding: "0 20px" }}>
						<SendReport
							closeDrawer={() => {
								dispatch(closeDialogByType(dialogsTypes.ReportUser));
							}}
							colleagueId={colleagueId}
							type="inappropriateMsg"
						/>
					</Box>
				</SettingListItem>
			</List>
		</Box>
	);
};
