import "assets/scss/AutocompleteSelect/AutocompleteSelect.scss";
import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { getFiltersSelected } from "redux/search/selectors";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

export default function AutocompleteSelect({
	placeholder = "הוסף...",
	data,
	value,
	label,
	onChangeHandler,
	popupIcon = "",
	disableCloseOnSelect = true,
	onInputChange = (data) => {},
	handleBlur = () => {},
	handleFocus = () => {},
	freeSolo = false,
}) {
	const advanceSearchData = useSelector(getFiltersSelected);
	const baseHandleBlur = () => {
		if (!freeSolo) return;
		const input = document.getElementById("tags-standard");
		//alert(3);
		if (input && input.value) {
			console.log(advanceSearchData);
			const freeText = advanceSearchData.freeText || [];
			const arr = [...freeText, ...[input.value]];
			onChangeHandler(arr);
			input.value = "";
		}
		handleBlur();
	};

	const onChangeHandle = (value) => {
		onChangeHandler(value);
	};

	return (
		<Stack spacing={3} className="autocompleteSelectWrapper">
			<Autocomplete
				onBlur={baseHandleBlur}
				limitTags={!!isMobile ? 2 : 4}
				onFocus={handleFocus}
				disableClearable={true}
				popupIcon={popupIcon}
				disableCloseOnSelect={disableCloseOnSelect}
				autoComplete
				multiple
				onInputChange={onInputChange}
				onChange={(e, value) => onChangeHandle(value)}
				id="tags-standard"
				options={data}
				clearOnBlur={true}
				getOptionLabel={(option) => {
					if (typeof option === "string") {
						return option;
					} else {
						return option?.name;
					}
				}}
				value={value}
				freeSolo={freeSolo}
				renderInput={(params) => (
					<TextField {...params} variant="standard" label={label} placeholder={placeholder} />
				)}
			/>
		</Stack>
	);
}
