import useUser from "hooks/useUser";
import { useEffect, useState } from "react";
import functionsService from "services/functionsService";
import TalentBox from "./TalentBox";
import DynamicMasonry from "components/masonry/DynamicMasonry";

export default ({
	users,
	onChange,
	webPage,
	setUserWebPopup = () => {},
	setFavoriteList = () => {},
	breakpointColumns = { default: 3, 1500: 2, 1140: 1 },
}) => {
	const [favorites, setFavorites] = useState([]);
	const { user } = useUser();
	const myUid = user.uid;

	useEffect(() => {
		if (!myUid) return;
		functionsService.listenFavorites$(myUid).subscribe((data) => {
			if (!data) return;
			const listFavo = Object.keys(data);
			setFavorites(listFavo);
			setFavoriteList(listFavo);
		});
	}, [myUid]);

	return (
		<>
			<DynamicMasonry breakpointColumns={breakpointColumns}>
				{users &&
					users.length > 0 &&
					users.map((user) => {
						return (
							<TalentBox
								key={user.uid}
								user={user}
								onChange={onChange}
								favoriteList={favorites}
								setUserWebPopup={setUserWebPopup}
								webPage={webPage}></TalentBox>
						);
					})}
			</DynamicMasonry>
		</>
	);
};
