import "assets/scss/landing/landingFreelancersHeader.scss";
import { Box } from "@mui/material";
import i18next from "i18next";
import LoginLanding from "./LoginLanding";
import animation from "assets/images/animation/landingAnimation.json";
import Lottie from "react-lottie";
import manCharacter from "assets/images/svgs/landing/matanTzur.png";
import BaseHelmet from "components/seo/BaseHelmet";

export default function LandingFreelancersHeader({ isMobile = false }) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<>
			<BaseHelmet
				title={i18next.t("landingPage.baseHelmet.title")}
				description={i18next.t("landingPage.baseHelmet.desc")}></BaseHelmet>
			<Box className="landingFreelancersHeader">
				<Box className="freelancersContent">
					<h1 className="noSelection freeTitle">
						<Box>
							{`${i18next.t("landingPage.everyCustomer")} `}
							<span className="customTextSecondary">{`${i18next.t("landingPage.pleased")} `}</span>
						</Box>
						<Box>{`${i18next.t("landingPage.hasCircleFriends")} `}</Box>
						<Box>
							<span>{`${i18next.t("landingPage.thatLook")} `}</span>
							<span className="customTextPrimary">{`${i18next.t("landingPage.exactlyYou")} `}</span>
						</Box>
					</h1>
					<h2 className="noSelection freeSubTitle">
						<Box>
							{`${i18next.t("landingPage.thePlatform")} `}
							<span className="bolder">{`${i18next.t("landingPage.onlyIsrael")} `}</span>
							{`${i18next.t("landingPage.thatConnects")} `}
						</Box>
						<Box>{`${i18next.t("landingPage.businessesToCustomers")} `}</Box>
						<Box>
							{`${i18next.t("landingPage.andContacts")} `}
							<span className="bolder">{`${i18next.t("landingPage.Ai")}`}</span>
						</Box>
					</h2>
					<Box className="phoneInput">
						<Box className="innerPhoneInput">
							<span className="noSelection phoneTitle">
								{`${i18next.t("landingPage.yourSuccessStarts")} `}
								<span className="customTextSecondary">{`${i18next.t("landingPage.here")}`}</span>
							</span>
							{!isMobile && (
								<span className="noSelection phoneSubTitle">{`${i18next.t(
									"landingPage.startEnterPhone"
								)} `}</span>
							)}
							<LoginLanding />
						</Box>
					</Box>
				</Box>
				<Box className="wrapHeaderImg">
					<img src={manCharacter} />
					{!isMobile && <Lottie options={defaultOptions} />}
				</Box>
			</Box>
		</>
	);
}
