const phoneRegExp = /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/;
const fbRegExp = /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/?/;
const tikTokRegExp = /(?:(?:http|https):\/\/)?(?:www.)?tiktok.com\/?/;
const instagramRegExp =
	/(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;
// const youtubeRegExp =
// 	/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;
// const youtubeChanelRegExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))/;
const youtubeRegExp =
	/^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)|^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)$/;
const youtubeChanelRegExp =
	/^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(channel\/[a-zA-Z0-9_-]+|@([a-zA-Z0-9_-]+))$/;
const linkedinRegExp = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm;
export {
	phoneRegExp,
	fbRegExp,
	tikTokRegExp,
	instagramRegExp,
	youtubeRegExp,
	youtubeChanelRegExp,
	linkedinRegExp,
};
