import * as Yup from "yup";
import i18next from "i18next";
import dialogsTypes from "consts/dialogsTypes";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { openDialog } from "redux/dialog/selectors";

import EditTextLine from "./editLineHelpers/EditTextLine";
import profileFields from "consts/profileFields";
import EditTagsLine from "./editLineHelpers/EditTagsLine";
import EditCityLine from "./editLineHelpers/EditCityLine";

export default function AccountDetails({ user, handleSave }) {
	const dispatch = useDispatch();
	return (
		<>
			{!!user?.uid && (
				<Box className="inner-section account-details">
					<h3 className="edit-title">{i18next.t("editProfile.accountDetails")}</h3>
					<EditTextLine
						keyField={profileFields.Name}
						valueField={user[profileFields.Name]}
						handleSave={handleSave}
						fieldName={i18next.t("register.NameServiceProviderAndBusiness")}
						formikValidation={{
							name: Yup.string()
								.min(2, i18next.t("validation.minText"))
								.max(50, i18next.t("validation.longName"))
								.required(i18next.t("validation.required")),
						}}
					/>
					<EditTextLine
						keyField={profileFields.Title}
						valueField={user[profileFields.Title]}
						handleSave={handleSave}
						fieldName={i18next.t("register.title")}
						subField={i18next.t("register.underNameDescribeExpert")}
						formikValidation={{
							title: Yup.string()
								.max(50, i18next.t("validation.longText"))
								.required(i18next.t("validation.required")),
						}}
					/>
					<Box className="union-line">
						<EditTagsLine
							keyField={profileFields.Tags}
							valueField={user[profileFields.Tags]}
							handleSave={handleSave}
							fieldName={i18next.t("register.areasSpecializationAndRelevantTags")}
							subField={`${i18next.t(
								"register.choose the tags that customers can use to find you"
							)} ${i18next.t("register.until 5 tags")}`}
						/>
						<span className="tags-btn">
							<span>{"חיפשת תגית מסויימת ולא מצאת?"}</span>
							<Button
								variant="text"
								sx={{
									background: "#fff !important",
									padding: "0px",
									color: "#1b8386",
									lineHeight: 1,
									minWidth: 0,
									justifySelf: "start",
								}}
								size="12px"
								className=""
								onClick={() => {
									dispatch(openDialog({ dialog: dialogsTypes.TagsSuggestions }));
								}}
								fontWeight={500}>{`לחץ כאן להוספת תגית חדשה`}</Button>
						</span>
					</Box>

					<EditCityLine
						valueField={user[profileFields.Address]}
						fieldName={i18next.t("register.addressServiceProviderAndBusiness")}
					/>
				</Box>
			)}
		</>
	);
}
