import "assets/scss/landing/landingBlog.scss";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import LandingColorizedTitle from "./LandingColorizedTitle";
import i18next from "i18next";
import { useState } from "react";
import { useEffect } from "react";
import blogService from "services/blogService";
import { useHistory } from "react-router-dom";
import useNavigator from "hooks/useNavigator";
import articlesStatus from "consts/articlesStatus";

export default function LandingBlog({
	withoutHeader = false,
	sortBy = "",
	countBlogs = 3,
	blogKey = "",
	blogsFromOut = [],
}) {
	const navigate = useNavigator();

	const [blogs, setBlogs] = useState([]);
	const [displayblogs, setDisplayBlogs] = useState(blogsFromOut);
	const handleClickBlog = (slug) => {
		if (!slug) return;
		return navigate.redirectWithReload(`blog/${slug}`);
	};

	useEffect(() => {
		if (!!blogsFromOut?.length) return;
		blogService.getBlogs().then((data) => {
			if (!data) return;
			let sortedData = Object.values(data || [])
				.filter((art) => art?.status === articlesStatus.published)
				.sort((a, b) => b?.publishData - a?.publishData);
			setBlogs(sortedData);
		});
	}, []);

	useEffect(() => {
		if (!blogs || !blogs?.length) return;

		let sortedData = blogs;
		if (!!blogKey) {
			sortedData = sortedData.filter((blog) => {
				return blog?.key !== blogKey;
			});
		}
		if (!!sortBy) {
			sortedData = sortedData.sort((a, b) => {
				if (a.categoryKey === sortBy) return -1;
				if (b.categoryKey === sortBy) return 1;
				if (a.categoryKey === undefined) return 1;
				if (b.categoryKey === undefined) return -1;
				return (a.categoryKey || "").localeCompare(b.categoryKey || "");
			});
		}
		setDisplayBlogs(sortedData);
	}, [blogs]);

	return (
		<Box className="landingBlog">
			{!withoutHeader && <LandingColorizedTitle colorText={i18next.t("landingPage.blog")} />}
			<Box className={`wrapBlogs ${!!displayblogs && !!displayblogs?.length ? "fadeInBlogs" : ""}`}>
				{!!displayblogs &&
					!!displayblogs?.length &&
					displayblogs.slice(0, countBlogs).map((item) => (
						<Card key={item.key} className="cardBlog" onClick={() => handleClickBlog(item?.slug)}>
							<CardMedia className="cardMedia" image={item.mainImage} title="image title" />
							<CardContent>
								<Typography
									gutterBottom
									variant="h5"
									component="div"
									className="wrapTitle noSelection">
									{item.title}
								</Typography>
								<Typography variant="body2" color="text.secondary" className="wrapDesc noSelection">
									{item.description}
								</Typography>
							</CardContent>
						</Card>
					))}
			</Box>
		</Box>
	);
}
