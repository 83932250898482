import { useMemo } from "react";
import { BrowserRouter as Router, Link, useHistory, useLocation } from "react-router-dom";
export default function useQuery() {
	const { search, pathname } = useLocation();
	const history = useHistory();

	const params = useMemo(() => {
		let params = {};
		new URLSearchParams(search).forEach((value, key) => {
			let decodedKey = decodeURIComponent(key);
			let decodedValue = decodeURIComponent(value);
			// This key is part of an array
			params[decodedKey] = decodedValue;
		});

		return params;
	}, [search]);

	const pushQueryParams = (searchObj = {}) => {
		const search = new URLSearchParams(searchObj).toString();
		history.replace({ pathname: pathname, search: search });
	};

	return { params, pushQueryParams };
}
