export const getFieldErrorNames = (formikErrors) => {
	const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			if (!value) return;

			const nextKey = key;
			if (typeof value === "object") {
				transformObjectToDotNotation(value, nextKey, result);
			} else {
				result.push(nextKey);
			}
		});

		return result;
	};

	return transformObjectToDotNotation(formikErrors);
};
export const ScrollToFieldError = (errors = {}) => {
	const fieldErrorNames = getFieldErrorNames(errors);
	if (fieldErrorNames.length <= 0) return null;

	const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
	if (!element) return null;

	// 	// Scroll to first known error into view
	element.scrollIntoView({ behavior: "smooth", block: "center" });
	// eslint-disable-line react-hooks/exhaustive-deps

	return null;
};
