import "assets/scss/profile/editAccount.scss";
import { Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import penEdit from "assets/images/svgs/penEdit.svg";
import i18next from "i18next";
import AutocompleteSelect from "components/ui/AutocompleteSelect";
import { getTags } from "redux/tags/selectors";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { isMobile } from "react-device-detect";
export default function EditTagsLine({
	keyField = "",
	valueField = [],
	handleSave = () => {},
	showEdit = false,
	fieldName = "",
	subField = "",
}) {
	const tags = useSelector(getTags);
	const [value, setValue] = useState(valueField);
	const [showEditField, setShowEditField] = useState(showEdit);

	const changeTag = (values) => {
		const MAX_TAGS = 5;
		if (!values || values?.length > MAX_TAGS) return;
		const listTags = values.filter((tag) => !!tag?.key);
		setValue(listTags);
	};

	const saveField = () => {
		if (isEqual(value, valueField)) return setShowEditField(false);
		handleSave(keyField, value);
		setShowEditField(false);
	};

	useEffect(() => {
		setShowEditField(!isEqual(value, valueField));
	}, [value]);

	return (
		<Box className="edit-text-line" style={{ borderBottom: isMobile && "0" }}>
			<Box className="edit-text-title-field">
				<Box className="noSelection field-name">
					<Box className="">{fieldName}</Box>
					{subField && <Box className="sub-title">{subField}</Box>}
				</Box>
				<Box className="field-value">
					<AutocompleteSelect
						data={tags}
						placeholder={""}
						onChangeHandler={(value) => changeTag(value)}
						// onInputChange={handleInputChange}
						freeSolo={false}
						value={value}
					/>
				</Box>
			</Box>
			<Box className="edit-text-btns-field">
				{!showEditField ? (
					<></>
				) : (
					<Box className="save-edit">
						<Button className="editBtn saveBtn" onClick={saveField} variant="outlined">
							<span>{i18next.t("editProfile.save")}</span>
						</Button>
						<Button
							className="editBtn cancelBtn"
							onClick={() => setValue(valueField)}
							variant="outlined">
							<span>{i18next.t("editProfile.cancel")}</span>
						</Button>
					</Box>
				)}
			</Box>
		</Box>
	);
}
