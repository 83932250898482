import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";

import LandingCategories from "components/landing/LandingCategories";
import LandingSatisfiedSuppliers from "components/landing/LandingSatisfiedSuppliers";
import LandingFAQs from "components/landing/LandingFAQs";
import LandingBlog from "components/landing/LandingBlog";
import LandingHeader from "components/landing/LandingHeader";
import LandingFreelancersHeader from "components/landing/LandingFreelancersHeader";
import LandingYoutubeVideo from "components/landing/LandingYoutubeVideo";
import LandingExposure from "components/landing/LandingExposure";
import LandingInTheMedia from "components/landing/LandingInTheMedia";
import LandingFooter from "components/landing/LandingFooter";
import LandingUsersHeader from "components/landing/LandingUsersHeader";
import LandingHowWork from "components/landing/LandingHowWork";
import LandingWhySixDays from "components/landing/LandingWhySixDays";
import useUser from "hooks/useUser";
import homePageService from "services/homePage.service";
import useNavigator from "hooks/useNavigator";

export default function LandingPage({ isFreelancers = false }) {
	const { userProfile, isLoggedIn } = useUser();
	const { navigateToSearchPage } = useNavigator();
	const [data, setData] = useState({});
	const [usersIds, setUsersIds] = useState([]);

	const handleUsersIds = (d) => {
		if (!d?.users) return;

		const uidsToDisplay = Object.entries(d?.users)
			.filter(([key, value]) => value === true)
			.map(([key, value]) => key);

		setUsersIds(uidsToDisplay);
	};

	useEffect(async () => {
		window.scrollTo(0, 0);
		homePageService.getSystemHomePageData().then((data) => {
			if (!data) return;
			setData(data);
			handleUsersIds(data);
		});

		if (!isFreelancers && !isMobile) {
			const element = document.querySelector(".wrapSearchWebHeader");
			if (element) {
				element.style.position = "fixed";
				element.style.zIndex = "10";
			}
		}
	}, []);

	useEffect(() => {
		return () => {
			const element = document.querySelector(".wrapSearchWebHeader");
			if (element) {
				element.style.position = "initial";
				element.style.zIndex = "initial";
			}
		};
	}, []);

	useEffect(() => {
		if (!!isLoggedIn && !isFreelancers && window.location.search !== "?cfs=t")
			navigateToSearchPage();
	}, []);

	return (
		<Box
			className="landingPage"
			sx={{
				minHeight: "100vh",
				minWidth: "100vw",
				marginBottom: !!isMobile && !!isLoggedIn && !isFreelancers && "85px",
				paddingTop: !isFreelancers && !isMobile && "70px",
			}}>
			{/* {((!!isMobile && !userProfile?.completeTalentRegister) || !!isFreelancers) && ( */}
			{((!!isMobile && (!isLoggedIn || (!!isLoggedIn && userProfile?.type !== "talent"))) ||
				!!isFreelancers) && <LandingHeader isMobile={isMobile} isFreelancers={isFreelancers} />}
			{!!isFreelancers ? (
				<>
					<LandingFreelancersHeader isMobile={isMobile} />
				</>
			) : (
				<LandingUsersHeader />
			)}
			<LandingCategories isFreelancers={isFreelancers} isMobile={isMobile} />
			{!!isFreelancers && (
				<>
					<LandingHowWork isMobile={isMobile} />
					<LandingYoutubeVideo isMobile={isMobile} youTubeUrlVideo={data?.join_now} />
					<LandingSatisfiedSuppliers usersIds={usersIds} />
				</>
			)}
			<LandingWhySixDays isMobile={isMobile} />
			<LandingExposure isMobile={isMobile} />
			{/* <LandingInTheMedia weInMediaLinks={data?.we_in_media} /> */}
			<LandingFAQs />
			<LandingBlog />
			<LandingFooter />
		</Box>
	);
}
