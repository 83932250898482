import "assets/scss/recommendation/recommendationHeader.scss";
import { Box } from "@mui/material";
import UserImage from "./userImage";
import moment from "moment";
import useUser from "hooks/useUser";
import DeleteIcon from "@mui/icons-material/Delete";

export default function RecommendationHeader({
	user = {},
	item = {},
	displayDeleteBtn = false,
	openUserRecomendationPage = () => {},
	deleteRecommendationPopup = () => {},
}) {
	const { userProfile } = useUser();

	return (
		<Box className="wrapRecommendationHeader">
			<Box
				className="userImg"
				onClick={openUserRecomendationPage}
				style={{ cursor: user.uid ? "pointer" : "default" }}>
				<UserImage user={user} className="userProfileImg" item={item}></UserImage>
			</Box>

			<Box className="innerRecHeader">
				<Box className="textDate">
					<span style={{ fontWeight: 600 }}>{user.name ?? item.name}</span>
					{` | ${moment(item.created).format("DD.MM.YYYY")}`}
				</Box>
				{/* <Box className="countRec">{`${!item?.countRec ? 1 : item?.countRec} המלצות`}</Box> */}
			</Box>

			<Box className="wrapDeleteBtn">
				{displayDeleteBtn && (
					<DeleteIcon
						onClick={() => deleteRecommendationPopup(userProfile?.uid === item?.uid)}
						sx={{ width: "14px", color: "#dddddd" }}
					/>
				)}
			</Box>
		</Box>
	);
}
