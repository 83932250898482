import * as types from "./actionTypes";
import { getLoggedInUserUID } from "../auth/selectors";
import BlocksService from "services/blocks";
import QueueService from "services/queue";
import BlockAndReportEvents from "consts/blockAndReportEvents";

export const fetchBlockedMeSuccessfully = (payload) => {
  return { type: types.BLOCKS_BLOCKED_ME_FETCH_SUCCESSFULLY, payload };
};

export const fetchBlockedMeFail = (error) => {
  return { type: types.BLOCKS_BLOCKED_ME_FETCH_FAILED, error: error?.message };
};

export const blockedMeAdded = (user, payload) => {
  return {
    type: types.BLOCKS_BLOCKED_ME_BY_USER_ADDED,
    payload: { [user]: payload },
  };
};

export const blockUser = (blockedUserUid) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = getLoggedInUserUID(state);
    dispatch({ type: types.BLOCKS_BLOCK_USER, user: blockedUserUid });
    try {
      await BlocksService.blockUser(uid, blockedUserUid);
      dispatch(sendBlockUserSuccessfully(blockedUserUid));
      QueueService.sendEvent(uid, BlockAndReportEvents.BlockUser, {
        uid: blockedUserUid,
      });
    } catch (error) {
      dispatch(sendBlockUserFail(error, blockedUserUid));
    }
  };
};

export const reportUser = (blockedUserUid, reason, message) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = getLoggedInUserUID(state);
    dispatch({ type: types.BLOCKS_BLOCK_USER, user: blockedUserUid });
    try {
      await BlocksService.blockUser(uid, blockedUserUid, reason);
      dispatch(
        sendBlockUserSuccessfully(blockedUserUid, {
          reason,
          message,
        })
      );
      QueueService.sendEvent(uid, BlockAndReportEvents.ReportUser, {
        uid: blockedUserUid,
        reason,
        reasonText: reason,
        message,
      });
    } catch (error) {
      dispatch(sendBlockUserFail(error, blockedUserUid));
    }
  };
};

const sendBlockUserSuccessfully = (blockedUser, payload) => {
  return {
    type: types.BLOCKS_BLOCK_USER_SUCCESSFULLY,
    user: blockedUser,
    payload,
  };
};

const sendBlockUserFail = (error, blockedUser) => {
  return {
    type: types.BLOCKS_BLOCK_USER_FAILED,
    user: blockedUser,
    error: error?.message,
  };
};

const sendUnBlockUserSuccessfully = (blockedUser) => {
  return {
    type: types.BLOCKS_UNBLOCK_USER_SUCCESSFULLY,
    user: blockedUser,
  };
};

const sendUnBlockUserFail = (error, blockedUser) => {
  return {
    type: types.BLOCKS_UNBLOCK_USER_FAILED,
    user: blockedUser,
    error: error?.message,
  };
};

export const unBlockUser = (blockedUser) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = getLoggedInUserUID(state);
    dispatch({ type: types.BLOCKS_UNBLOCK_USER, user: blockedUser });
    try {
      await BlocksService.unblockUser(uid, blockedUser.uid);
      dispatch(sendUnBlockUserSuccessfully(blockedUser));
      QueueService.sendEvent(uid, BlockAndReportEvents.UnBlockUser, {
        uid: blockedUser.uid,
      });
    } catch (error) {
      dispatch(sendUnBlockUserFail(error, blockedUser.uid));
    }
  };
};
