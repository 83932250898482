import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import PhoneQuestion from "components/login/Questions/PhoneQuestion";
import i18next from "i18next";
import { getLoginPhone, getLoginWithPhoneStatus } from "redux/auth/selectors";
import { getPhoneWithPrefix } from "helpers/mobileNumber";
import { useDispatch, useSelector } from "react-redux";
import { loginWithPhone } from "redux/auth/actions";
import LOGIN_WITH_PHONE_STATUS from "consts/loginWithPhoneStatus";
import { openDialog } from "redux/dialog/selectors";
import dialogsTypes from "consts/dialogsTypes";
import { Button } from "reactstrap";

export default function LoginLanding() {
	const dispatch = useDispatch();
	const loginStatus = useSelector(getLoginWithPhoneStatus);
	const [isValid, setIsValid] = useState(false);
	const [captchaKey, setCaptchaKey] = useState(1); //need to re-render the component because reCAPTCHA need to rerender
	const [phoneValue, setPhoneValue] = useState("");

	const handleSubmit = (value) => {
		const phoneWithPrefix = getPhoneWithPrefix(value);
		dispatch(loginWithPhone(phoneWithPrefix));
	};
	const openLoginPopup = () => {
		return dispatch(openDialog({ dialog: dialogsTypes.Login, registerType: "talent" }));
	};

	const handleOnValidityChange = (_isValid) => {
		setIsValid(_isValid);
	};

	useEffect(() => {
		switch (loginStatus) {
			case LOGIN_WITH_PHONE_STATUS.WAIT_FOR_VERIFICATION:
				openLoginPopup();
				setTimeout(() => {
					setCaptchaKey(captchaKey + 1);
				}, 100);
				break;
			case LOGIN_WITH_PHONE_STATUS.LOGIN_FAILED:
				break;
			default:
				break;
		}
	}, [loginStatus]);

	return (
		<Box className="wrapPhone" key={captchaKey}>
			<PhoneQuestion
				setPhoneValue={setPhoneValue}
				defaultInputValue={"05"}
				onSubmit={handleSubmit}
				hasError={false}
				errMsg={""}
				showSubmitButton={false}
				showLoader={loginStatus === LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING}
				handleChangeValidation={handleOnValidityChange}
			/>
			{loginStatus === LOGIN_WITH_PHONE_STATUS.LOGIN_PENDING ? (
				<CircularProgress size={40} sx={{ color: "#1b8386" }} />
			) : (
				<Button disabled={!isValid} onClick={() => handleSubmit(phoneValue)}>
					{i18next.t("landingPage.continue")}
				</Button>
			)}
		</Box>
	);
}
