import { useDispatch, useSelector } from "react-redux";

import * as galleryActions from "redux/galleryModal/actions";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import GalleryTypes from "consts/galleryTypes";
import { getUserProfile } from "redux/profile/selectors";
import { getPopups } from "redux/popups/selectors";

const useGalleryModal = () => {
	const active_userId = useSelector((state) => state.chat?.active_userId);
	const { currentUserProfile } = useSelector(getPopups);

	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || currentUserProfile;

	const loggedInUserProfile = useSelector(getUserProfile);

	const publicMainPictureIndex = Object.keys(activeUserProfile?.pictures || {}).findIndex(
		(picName) => picName === activeUserProfile.mainPictureName
	);

	const privateMainPictureIndex = Object.keys(loggedInUserProfile?.pictures || {}).findIndex(
		(picName) => picName === loggedInUserProfile.mainPictureName
	);

	const dispatch = useDispatch();

	const setActiveIndex = (index) => dispatch(galleryActions.setActiveIndex(index));

	const setActiveIndexMainPicture = (galleryType) =>
		dispatch(
			galleryActions.setActiveIndex(
				galleryType === GalleryTypes.Public ? publicMainPictureIndex : privateMainPictureIndex
			)
		);

	const setGalleryType = (galleryType) => dispatch(galleryActions.setGalleryType(galleryType));

	return {
		setActiveIndex,
		setActiveIndexMainPicture,
		setGalleryType,
	};
};

export default useGalleryModal;
