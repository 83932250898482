import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import CardsView from "components/CardsView";

import {
	getSearchUsers,
	getIsLoadingMore,
	getTotal,
	getHasMoreResults,
	getSearchFreeTextFilter,
	getStatus,
} from "redux/search/selectors";
import { searchLoadMore } from "redux/search/actions";
import API_REQUEST_STATUS from "consts/apiRequestStatus";
import Loader from "components/Loader";
import { useCallback, useEffect, useRef, useState } from "react";
import ViewTalents from "./ViewTalents";
import useSearch from "hooks/useSearch";
import i18next from "i18next";
import { Box } from "@mui/material";
import SearchBtns from "components/searchTalent/searchBtns/SearchBtns";
import useQuery from "hooks/useQuery";
import { getTagsByParams } from "redux/tags/selectors";
import stickyBrown from "assets/images/stickyBrown.png";
import CloseIcon from "@mui/icons-material/Close";
import dialogsTypes from "consts/dialogsTypes";
import { openDialog } from "redux/dialog/selectors";
import useUser from "hooks/useUser";
import SearchWebTabs from "../searchWeb/SearchWebTabs";
import SearchAd from "components/searchWeb/SearchAd";
import SearchBunnerApp from "./SearchBunnerApp";
import { getFilters } from "redux/search/selectors";
import AppService from "services/app";

export default ({ setSortOption, onChange, webPage = false, setUserWebPopup = () => {} }) => {
	const users = useSelector(getSearchUsers);
	const total = useSelector(getTotal);
	const isLoadingMore = useSelector(getIsLoadingMore);
	const hasMoreResults = useSelector(getHasMoreResults);
	const status = useSelector(getStatus);
	const freeText = useSelector(getSearchFreeTextFilter);
	const dispatch = useDispatch();
	const search = useSearch();
	const { params } = useQuery();
	const tagsByParams = useSelector(getTagsByParams(params.tagsKeys));
	const [showSticky, setShowSticky] = useState(true);
	const [fetchMoreDataData, setFetchMoreDataData] = useState(0);
	const loader = useRef(null);
	const { isLoggedIn, userProfile } = useUser();

	const filters = useSelector(getFilters);
	const [platform, setPlatform] = useState();
	const [showSearchBunnerApp, setShowSearchBunnerApp] = useState(false);

	const tagsNames = tagsByParams.map((item) => item.name).join(", ");

	const handleRegisterTalent = () => {
		dispatch(openDialog({ dialog: dialogsTypes.Login, registerType: "talent" }));
	};
	const handleCollectivelyAppeal = () => {
		if (!isLoggedIn) {
			dispatch(openDialog({ dialog: dialogsTypes.Login }));
		} else {
			dispatch(openDialog({ dialog: dialogsTypes.CollectivelyAppeal }));
		}
	};
	const closeSticky = (e) => {
		e.stopPropagation();
		setShowSticky(false);
	};
	const fetchData = () => {
		if (!isLoadingMore && hasMoreResults) {
			dispatch(searchLoadMore(freeText));
		}
	};
	const handleObserver = (entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			setFetchMoreDataData((oldData) => ++oldData);
		}
	};

	useEffect(() => {
		if (fetchMoreDataData > 0) {
			fetchData();
		}
	}, [fetchMoreDataData]);

	useEffect(() => {
		const option = {
			root: null,
			rootMargin: "250px",
			threshold: 0,
		};
		const observer = new IntersectionObserver(handleObserver, option);
		if (loader.current) {
			observer.observe(loader.current);
		}
	}, [status, hasMoreResults, isLoadingMore]);

	useEffect(async () => {
		if (!!platform) return;
		const platformApp = await AppService.getPlatform();
		setPlatform(platformApp);
	}, []);

	useEffect(() => {
		setShowSearchBunnerApp(
			filters?.sort === "SortFriends" && !userProfile?.syncContactsToNeo4j && platform === "web"
		);
	}, [filters, userProfile, platform]);

	return (
		<>
			{webPage ? (
				<>
					{/* <SearchAd /> */}
					<SearchWebTabs setSortOption={setSortOption}></SearchWebTabs>
				</>
			) : (
				<SearchBtns setSortOption={setSortOption} />
			)}
			<div
				id="scrollableDiv"
				className={showSearchBunnerApp && "searchBunnerWrapper"}
				style={{
					height: "100%",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
					justifyContent: !showSearchBunnerApp && "space-around",
					padding: "0 12px",
				}}>
				{status === API_REQUEST_STATUS.SUCCESSFULLY ? (
					<>
						{showSearchBunnerApp ? (
							<SearchBunnerApp isWeb={webPage} />
						) : (
							<>
								<ViewTalents
									users={users}
									onChange={onChange}
									setUserWebPopup={setUserWebPopup}
									webPage={webPage}
								/>
								<div ref={loader} />
							</>
						)}
					</>
				) : (
					<Loader className="loaderBeforeResults" />
				)}
				{!!hasMoreResults && status === API_REQUEST_STATUS.SUCCESSFULLY && !showSearchBunnerApp && (
					<Loader className="loaderBeforeResults" />
				)}
			</div>

			{/* {showSticky && !webPage && (
				<Box
					className="stickySrch"
					onClick={isLoggedIn ? handleCollectivelyAppeal : handleRegisterTalent}>
					{isLoggedIn ? (
						<span>{i18next.t("Search.sendToSeveralSuppliers")}</span>
					) : (
						<span>{i18next.t("register.registerAsTalent")}</span>
					)}

					// <Box className="stickySrchBox">
					// 	<CloseIcon className="stickySrchClose" onClick={closeSticky} />
					// </Box>
				</Box>
			)} */}
		</>
	);
};
