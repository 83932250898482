import firebase from "firebase/app";
import "firebase/database";
import { orderBy, toPairs } from "lodash";
import { Observable } from "rxjs";

class CategoriesService {
	BASE_REF = "tags_categories";

	async getCategories() {
		const categories = (await firebase.database().ref(this.BASE_REF).once("value")).val() || {};
		return Object.values(categories || {});
	}
}

export default new CategoriesService();
