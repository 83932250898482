import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import AuthRoute from "./AuthRoute";

export default ({ layout: Layout, component: Component, auth, ...rest }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [Component]);

	return (
		<Route
			{...rest}
			render={(matchProps) => (
				<AuthRoute checkAuth={auth}>
					<Layout>
						<Component platform={rest?.platform} {...matchProps} />
					</Layout>
				</AuthRoute>
			)}
		/>
	);
};
