import "assets/scss/popups/collectivelyAppeal/collectivelyAppeal.scss";
import { Modal, ModalBody } from "reactstrap";
import usePopups from "hooks/usePopups";
import BackComponentWrapper from "components/backComponentWrapper/backComponentWrapper";
import CollectivelyAppeal from "components/talent/CollectivelyAppeal/CollectivelyAppeal";

export default () => {
	const { closePopup } = usePopups();
	return (
		<Modal isOpen={true} toggle={closePopup} className="simpleDialog collectivelyAppealPopup">
			<ModalBody className="collectivelyAppealModal">
				<BackComponentWrapper goBack={closePopup} component={CollectivelyAppeal} />
			</ModalBody>
		</Modal>
	);
};
