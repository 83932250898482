import { Box } from "@mui/material";
import UserBlocked from "components/talent/UserBlockedTalent/UserBlocked";

export default ({ blockUsers = [], userProfile = {} }) => {
	return (
		<Box>
			<UserBlocked blockUsers={blockUsers} userProfile={userProfile} />
		</Box>
	);
};
